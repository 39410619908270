import React from 'react';
import { useNavigate } from 'react-router-dom';
import propTypes from 'prop-types';
import AngleIcon from '@nxlog/common-ui/dist/components/svgs/angle-icon';
import './settings.scss';

function Settings(props) {
    const { name, icon, route } = props;

    const navigate = useNavigate();

    return (
        <div className="settings-component-wrapper">
            <div className="icon-background">{icon}</div>
            <span>{name}</span>
            <div
                className="next"
                data-testid="next"
                onClick={() => navigate(route)}
                role="button"
                tabIndex="0"
                onKeyPress={() => navigate(route)}
            >
                <span>Next</span>
                <AngleIcon.Right fill="#00a1ed" width="10" height="5.12" />
            </div>
        </div>
    );
}

Settings.defaultProps = {
    name: '',
    icon: '',
    route: 'settings'
};

Settings.propTypes = {
    name: propTypes.string,
    icon: propTypes.element,
    route: propTypes.string
};

export default Settings;
