import React from 'react';
import { Button, Form } from '@nxlog/common-ui/components';
import { FormProvider } from 'react-hook-form';
import FormInput from '../../common/formInput';
import FormSelect from '../../common/formSelect';
import './editExtensionForm.scss';

export default function EditExtensionForm() {
    const handleSubmit = (data) => {
        // eslint-disable-next-line no-console
        console.log('data', data); // TODO: replace with real logic
    };

    return (
        <Form classname="edit-extension-form" onSubmit={handleSubmit} FormProvider={FormProvider}>
            <div className="edit-extension-form-block" data-testid="edit-extension-form">
                <div className="edit-extension-form-block-header">
                    <div className="title">Parameters</div>
                    <div className="mode">Expert mode</div>
                </div>
                <div className="edit-extension-form-block-title">Common parameters</div>
                <FormSelect
                    options={[
                        {
                            value: 'Connect to agent manager',
                            label: 'Connect to agent manager'
                        }
                    ]}
                    classname="edit-extension-form-input"
                    name="name"
                    label="Name"
                    placeholder="Name"
                />
                <FormSelect
                    options={[
                        {
                            value: 'Connect to agent manager',
                            label: 'Connect to agent manager'
                        }
                    ]}
                    classname="edit-extension-form-input"
                    name="module"
                    label="Module"
                    placeholder="Module"
                />
                <div className="edit-extension-form-block-title">Module specific parameters</div>
                <FormInput
                    classname="edit-extension-form-input"
                    name="keyQuoteCharacter"
                    label="Key quote character"
                    placeholder=""
                />
                <FormInput
                    classname="edit-extension-form-input"
                    name="valueQuoteCharacter"
                    label="Value quote character"
                    placeholder=""
                />
                <FormInput
                    classname="edit-extension-form-input"
                    name="escapeCharacter"
                    label="Escape character"
                    placeholder=""
                />
                <FormInput
                    classname="edit-extension-form-input"
                    name="keyValueDelimiter"
                    label="Key-value delimiter"
                    placeholder=""
                />
                <FormInput
                    classname="edit-extension-form-input"
                    name="keyValuePairDelimiter"
                    label="Key-value pair delimiter"
                    placeholder=""
                />
            </div>
            <div className="edit-extension-form-buttons-wrapper">
                <Button className="cancel">Cancel</Button>
                <div className="flex">
                    <Button className="config" ghostBtn>
                        View config
                    </Button>
                    <Button type="submit" className="template">
                        Save config
                    </Button>
                </div>
            </div>
        </Form>
    );
}
