import React, { useState } from 'react';
import { Table, TableColumn, Pagination } from '@nxlog/common-ui/components';
import propTypes from 'prop-types';
import { Offline, Success } from '@nxlog/common-ui/dist/components/svgs';
import { capitalizeFirstLetter } from '../../../utils/helpers/strings';
import './tableClusters.scss';

const statusIcon = (status) => {
    let icon;
    switch (status) {
        case 'unknown':
            icon = <Offline fill="#8195A9" />;
            break;
        default:
            icon = <Success outlined fill="#228D3A" />;
    }
    return (
        <div>
            {icon}
            <span className={`status-${status}`}>
                {status ? capitalizeFirstLetter(status) : '-'}
            </span>
        </div>
    );
};

export default function TableClusters({ allData }) {
    // Commenting out selection as no action is required for now
    // const [selected, setSelected] = useState([]);
    const [sort, setSort] = useState({ column: 'id', order: 'asc' });
    const [currentPage, setCurrentPage] = useState({ value: 1 });
    const [itemsPerPageValue, setItemsPerPageValue] = useState(15);

    const handleSort = (column) => {
        let order = 'asc';
        if (column === sort.column) {
            order = sort.order === 'asc' ? 'desc' : 'asc';
        }
        setSort({ column, order });
    };

    if (sort.column) {
        allData.sort((a, b) => {
            if (a[sort.column] < b[sort.column]) {
                return sort.order === 'asc' ? -1 : 1;
            }
            if (a[sort.column] > b[sort.column]) {
                return sort.order === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    return (
        <div className="table-clusters-wrapper">
            <div className="table-clusters">
                <div className="main-table">
                    <div data-testid="table-clusters-header" className="table-header">
                        <Pagination
                            className="table-pagination"
                            onPageSizeChange={setItemsPerPageValue}
                            itemsAmount={allData.length}
                            itemsPerPage={itemsPerPageValue}
                            currentIndex={((currentPage?.value ?? 1) - 1) * itemsPerPageValue}
                            onPageChange={(pageIndex) => setCurrentPage({ value: pageIndex })}
                        />
                    </div>
                    <Table
                        pageSize={10}
                        isLoading={false}
                        className="main-table"
                        disableOverlay
                        rows={allData.slice(
                            ((currentPage?.value ?? 1) - 1) * itemsPerPageValue,
                            ((currentPage?.value ?? 1) - 1) * itemsPerPageValue + itemsPerPageValue
                        )}
                    >
                        {/*

                        // ## Commenting out SelectColumn as no action is required for now ##
                        
                        <SelectColumn
                            name="select"
                            className="column-select"
                            idField="id"
                            ids={allData.map((e) => e.id)}
                            selected={selected}
                            onChange={setSelected}
                        >
                            {() => null}
                        </SelectColumn> */}
                        <TableColumn
                            name="status"
                            className="column-status"
                            title="Status"
                            direction={sort.column === 'status' ? sort.order : null}
                            onSort={() => handleSort('status')}
                        >
                            {(item) => statusIcon(item.status)}
                        </TableColumn>
                        <TableColumn
                            name="id"
                            className="column-id"
                            title="ID"
                            direction={sort.column === 'id' ? sort.order : null}
                            onSort={() => handleSort('id')}
                        />
                        <TableColumn
                            name="address"
                            className="column-address"
                            title="Address"
                            direction={sort.column === 'address' ? sort.order : null}
                            onSort={() => handleSort('address')}
                        />
                    </Table>
                    <div data-testid="table-clusters-footer" className="table-footer">
                        <Pagination
                            className="table-pagination"
                            onPageSizeChange={setItemsPerPageValue}
                            itemsAmount={allData.length}
                            itemsPerPage={itemsPerPageValue}
                            currentIndex={((currentPage?.value ?? 1) - 1) * itemsPerPageValue}
                            onPageChange={(pageIndex) => setCurrentPage({ value: pageIndex })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

TableClusters.defaultProps = {
    allData: []
};

TableClusters.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    allData: propTypes.array
};
