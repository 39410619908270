import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImDbi = {
    label: 'DBI',
    type: 'collect',
    name: 'im_dbi',
    defaultDirectives: `Module      im_dbi`,
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Driver',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'SQL',
            type: 'multiline-string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Option',
            type: 'string',
            multiple: true,
            defaultValue: [{ value: '' }]
        },
        {
            name: 'PollInterval',
            type: 'number',
            defaultValue: 1
        },
        {
            name: 'SavePos',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        ...GENERAL_INPUT_DIRECTIVES('im_dbi'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
