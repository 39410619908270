import React from 'react';
import propTypes from 'prop-types';
import LoaderSvg from '@nxlog/common-ui/dist/components/svgs/loader';
import './loader.scss';

function Loader(props) {
    const { fill } = props;
    const fillValue = !fill ? '#072341' : fill;

    return (
        <div data-testid="loader" className="loader-container">
            <LoaderSvg fill={fillValue} />
        </div>
    );
}

Loader.defaultProps = {
    fill: '#072341'
};

Loader.propTypes = {
    fill: propTypes.string
};

export default Loader;
