import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import { InputCheckbox as Checkbox } from '@nxlog/common-ui/core';
import { SelectDropArrow } from '@nxlog/common-ui/dist/components/svgs';
import classNames from 'classnames';
import './tableSidebarFilters.scss';

export default function TableSidebarFilters(props) {
    const {
        applyFilter,
        removeFilter,
        filters,
        appliedFilters,
        initialFilters = [],
        disabledFilterIds = []
    } = props;
    const [expanded, setExpanded] = useState([]);

    const handleExpend = (item) => {
        if (expanded.includes(item.id)) {
            setExpanded(expanded.filter((id) => id !== item.id));
        } else {
            setExpanded([...expanded, item.id]);
        }
    };

    const convertToFilter = (item) =>
        JSON.parse(
            JSON.stringify({
                ...item,
                dropdown: undefined,
                checked: undefined,
                disabled: undefined,
                indeterminate: undefined
            })
        );

    const handleChange = (item) => (e) => {
        const value = e.target.checked;
        if (value) {
            applyFilter(convertToFilter(item));
            if (Array.isArray(item.dropdown) && item.dropdown.length > 0) {
                item.dropdown.forEach((elm) => {
                    removeFilter(convertToFilter(elm));
                });
            }
        } else {
            removeFilter(convertToFilter(item));
        }
    };

    const handleChangeSubItem = (dropdownItem, item) => (e) => {
        if (item.checked) {
            removeFilter(convertToFilter(item));
            item.dropdown
                .filter((elm) => elm.id !== dropdownItem.id)
                .forEach((elm) => {
                    applyFilter(convertToFilter(elm));
                });
        } else {
            const value = e.target.checked;
            if (value) {
                if (
                    item.dropdown.every(
                        (elm) =>
                            elm.id === dropdownItem.id ||
                            appliedFilters.map((f) => f.id).includes(elm.id)
                    )
                ) {
                    item.dropdown.forEach((elm) => {
                        removeFilter(convertToFilter(elm));
                    });
                    applyFilter(convertToFilter(item));
                    return;
                }
            }
            handleChange(dropdownItem)(e);
        }
    };

    const initialIds = initialFilters.map((f) => f.id);
    // this keeps initial filters open on the first load
    const activeKeys = filters.reduce((acc, filter, index) => {
        if (filter.data.find((f) => initialIds.includes(f.id))) acc.push(index);
        return acc;
    }, []);

    return (
        <div className="table-sidebar-filters">
            <Accordion defaultActiveKey={activeKeys} alwaysOpen>
                {filters.map((filter, index) => (
                    <Accordion.Item
                        data-testid="table-sidebar-filters-category"
                        eventKey={index}
                        key={filter.name}
                        className="single-filter"
                    >
                        <Accordion.Header
                            data-testid="table-sidebar-filters-category-name"
                            className="filter-label"
                        >
                            {filter.name}
                        </Accordion.Header>
                        <Accordion.Body className="filter-body">
                            {filter.data
                                .map((item) => ({
                                    ...item,
                                    checked: appliedFilters.map((f) => f.id).includes(item.id),
                                    disabled:
                                        initialFilters.map((f) => f.id).includes(item.id) ||
                                        disabledFilterIds.includes(item.id)
                                }))
                                .map((item) => ({
                                    ...item,
                                    indeterminate:
                                        !item.checked &&
                                        Array.isArray(item.dropdown) &&
                                        item.dropdown.some((elm) =>
                                            appliedFilters.map((f) => f.id).includes(elm.id)
                                        )
                                }))
                                .map((item) => (
                                    <>
                                        <div
                                            data-testid="table-sidebar-filters-item-name"
                                            key={item.id}
                                            className={classNames(
                                                'single-filter-body',
                                                appliedFilters.includes(item) && 'active'
                                            )}
                                        >
                                            <div className="checkbox-wrapper">
                                                <Checkbox
                                                    id={item.id}
                                                    checked={item.checked}
                                                    onChange={handleChange(item)}
                                                    label={item.label}
                                                    disabled={item.disabled}
                                                    indeterminate={item.indeterminate}
                                                />
                                                <span className="filter-count">
                                                    {item.itemsCount?.toString() || ''}
                                                </span>
                                                {Array.isArray(item.dropdown) &&
                                                item.dropdown.length > 0 ? (
                                                    <button
                                                        className={classNames(
                                                            'filter-expand-arrow',
                                                            expanded.includes(item.id)
                                                                ? 'expanded'
                                                                : null
                                                        )}
                                                        onClick={() => handleExpend(item)}
                                                    >
                                                        {!item.disabled &&
                                                        Array.isArray(item.dropdown) &&
                                                        item.dropdown.length > 0 ? (
                                                            <SelectDropArrow fill="#a7a7a7" />
                                                        ) : null}
                                                    </button>
                                                ) : null}
                                            </div>
                                        </div>
                                        {expanded.includes(item.id) &&
                                            !item.disabled &&
                                            Array.isArray(item.dropdown) &&
                                            item.dropdown.length > 0 && (
                                                <div className="sidebar-filters-dropdown-items">
                                                    {item.dropdown
                                                        .map((dropdownItem) => ({
                                                            ...dropdownItem,
                                                            checked: appliedFilters
                                                                .map((f) => f.id)
                                                                .includes(dropdownItem.id),
                                                            disabled:
                                                                initialFilters
                                                                    .map((f) => f.id)
                                                                    .includes(dropdownItem.id) ||
                                                                disabledFilterIds.includes(
                                                                    dropdownItem.id
                                                                )
                                                        }))
                                                        .map((dropdownItem) => (
                                                            <div
                                                                data-testid="table-sidebar-filters-item-name"
                                                                key={dropdownItem.id}
                                                                className={classNames(
                                                                    'single-filter-body single-filter-dropdown-item',
                                                                    appliedFilters.includes(item) &&
                                                                        'active'
                                                                )}
                                                            >
                                                                <div className="checkbox-wrapper">
                                                                    <Checkbox
                                                                        id={dropdownItem.id}
                                                                        checked={
                                                                            item.checked ||
                                                                            dropdownItem.checked
                                                                        }
                                                                        onChange={handleChangeSubItem(
                                                                            dropdownItem,
                                                                            item
                                                                        )}
                                                                        label={dropdownItem.label}
                                                                        disabled={
                                                                            dropdownItem.disabled
                                                                        }
                                                                    />
                                                                    <span className="filter-count">
                                                                        {dropdownItem.itemsCount?.toString() ||
                                                                            ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            )}
                                    </>
                                ))}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
}

TableSidebarFilters.defaultProps = {
    filters: [],
    appliedFilters: [],
    removeFilter: () => {},
    applyFilter: () => {},
    initialFilters: [],
    disabledFilterIds: []
};

TableSidebarFilters.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    filters: propTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    appliedFilters: propTypes.array,
    removeFilter: propTypes.func,
    applyFilter: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    initialFilters: propTypes.array,
    disabledFilterIds: propTypes.arrayOf(propTypes.string)
};
