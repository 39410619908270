import React from 'react';
import propTypes from 'prop-types';
import { Dropdown } from '@nxlog/common-ui/dist/components';
import DotsIcon from '@nxlog/common-ui/dist/components/svgs/dots';
import { useSelector } from 'react-redux';
import { AgentType } from '../../../../utils/constants/propTypes';
import { getAgentMenuOptions, getStatus } from '../../../../utils/helpers/functions';
import { LICENSES_STATUS } from '../../../../utils/constants/ids';
import { selectIsEnrollmentDisabled } from '../../../../redux/reducers/licenses';

export default function ActionsDropdown(props) {
    const { rowData, onSelectOption, licenseStatus } = props;
    const isEnrollmentDisabled = useSelector(selectIsEnrollmentDisabled);
    const status = getStatus(rowData);

    const handleSelectOption = (value) => {
        if (licenseStatus === LICENSES_STATUS.ERROR && value === 'enroll') return;
        onSelectOption(value, [rowData]);
    };

    const actions = getAgentMenuOptions({
        item: rowData,
        licenseStatus,
        isEnrollmentDisabled
    });

    return (
        <Dropdown
            options={actions}
            icon={<DotsIcon />}
            onClick={handleSelectOption}
            className="dropdown-actions"
            status={status}
        />
    );
}

ActionsDropdown.defaultProps = {
    rowData: null,
    onSelectOption: () => {},
    licenseStatus: null
};

ActionsDropdown.propTypes = {
    rowData: AgentType,
    onSelectOption: propTypes.func,
    licenseStatus: propTypes.string
};
