export const drop = {
    label: 'Drop Event',
    name: 'drop',
    type: 'action',
    match: (content) => /^\s*drop\s*\(\s*\)\s*;?\s*$/i.test(content),
    inline: true,
    getCode: (action, level) => `${'  '.repeat(level)}drop();`
};

export default null;
