import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import {
    getDistinctFieldData,
    selectAgents,
    selectAgentsFilterItems,
    selectDistinct,
    selectPaginatedAgents,
    updateAgentsFilterItems
} from '../../redux/reducers/agents';
import { createAgentsTableFilter } from '../helpers/filters';
import { getModulesData, selectModules } from '../../redux/reducers/licenses';

// props may include applyFilter, removeFilter and appliedFilters
// to make new checkboxes dynamically turn on in case of
// "Other versions" is checked and agents has been fetched,
// appliedFilters also used to check if all them listed in filters
function useCreateFilters(props) {
    const dispatch = useDispatch();
    const { applyFilter, removeFilter, appliedFilters, ...restProps } = props;

    const agents = useSelector(selectAgents);
    const paginatedAgents = useSelector(selectPaginatedAgents);
    const modulesData = useSelector(selectModules);
    const filters = useSelector(selectAgentsFilterItems);
    const osReleaseList = useSelector(selectDistinct('os-release')) || [];
    const archList = useSelector(selectDistinct('arch')) || [];
    const [versions, setVersions] = useState([]);
    const prevVersions = useRef([]);
    const [modules, setModules] = useState(new Map());

    useEffect(() => {
        const modulesMap = new Map();
        Object.values(modulesData).forEach((moduleGroup) => {
            // modulesData is an object of structure {extension: {xm_admin:{module_count}, xm_syslog: {module_count}, ...etc}, input:{...}, output:{...}, processor:{...}}
            Object.entries(moduleGroup).forEach(([key, value]) => {
                const count = value?.module_count || null;
                modulesMap.set(key, count);
            });
        });
        setModules(modulesMap);
    }, [modulesData]);

    useEffect(() => {
        dispatch(getModulesData());
    }, [agents, paginatedAgents]);

    useEffect(() => {
        const newItems = createAgentsTableFilter({
            ...restProps,
            versions,
            modules,
            appliedFilters,
            removeFilter,
            osReleaseList,
            archList
        });
        dispatch(updateAgentsFilterItems(newItems));
    }, [versions, versions.length, modules, JSON.stringify({ osReleaseList, archList })]);

    useEffect(() => {
        dispatch(getDistinctFieldData('os-release'));
        dispatch(getDistinctFieldData('arch'));
    }, []);

    // this useEffect checks the new coming versions that appear in list when 'other' version is checked
    useEffect(() => {
        // if hook has no control over applied filters
        if (!applyFilter || !appliedFilters || !removeFilter) {
            return;
        }

        // if 'other' is not checked
        const otherVersionFilter = props.appliedFilters.find((f) =>
            f.id.startsWith('OtherVersions')
        );
        if (!otherVersionFilter) {
            prevVersions.current = versions;
            return;
        }

        // if there are some new versions
        const diffVersions = versions.filter((ver) => !prevVersions.current.includes(ver));
        if (diffVersions.length > 0) {
            const versionFilters = filters.find((f) => f.name === 'Version')?.data;
            if (versionFilters) {
                removeFilter(otherVersionFilter);
                diffVersions.forEach((key) => {
                    const filter = versionFilters.find((f) => f.id === key);
                    if (filter) {
                        applyFilter(filter);
                    }
                });
            }
        }
    }, [filters, versions, applyFilter, removeFilter, appliedFilters]);

    useEffect(() => {
        setVersions((currentVersions) => {
            const versionsSet = [...currentVersions, ...agents.map((agent) => agent?.version)]
                .filter((val) => !!val)
                .filter((ent, idx, arr) => arr.findIndex((e) => e === ent) === idx)
                .sort();

            if (currentVersions.length !== versionsSet.length && versionsSet.length > 0) {
                return [...versionsSet];
            }
            // no state change
            return currentVersions;
        });
    }, [agents, paginatedAgents]);

    // okAgentsCount, warningAgentsCount, errorAgentsCount, offlineAgentsCount reuse status filters count values
    const statusFilters =
        filters.find((group) => group.name?.toLowerCase() === 'status')?.data || [];
    const okAgentsCount = statusFilters.find((e) => e.id === 'ok')?.itemsCount ?? null;
    const warningAgentsCount = statusFilters.find((e) => e.id === 'warning')?.itemsCount ?? null;
    const errorAgentsCount = statusFilters.find((e) => e.id === 'error')?.itemsCount ?? null;
    const offlineAgentsCount = statusFilters.find((e) => e.id === 'offline')?.itemsCount ?? null;

    return { filters, okAgentsCount, warningAgentsCount, errorAgentsCount, offlineAgentsCount };
}

export default useCreateFilters;

useCreateFilters.defaultProps = {
    versions: [],
    applyFilter: null,
    removeFilter: null,
    appliedFilters: null
};

useCreateFilters.propTypes = {
    versions: propTypes.arrayOf(propTypes.string),
    applyFilter: propTypes.func,
    removeFilter: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    appliedFilters: propTypes.arrayOf(propTypes.object)
    // also allowed props --
    // counter functions like okAgentsCount, errorAgentsCount etc.
};
