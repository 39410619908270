export const addField = {
    label: 'Add Field',
    name: 'addfield',
    type: 'action',
    getCode: (action, level) =>
        !action.field || !action.value
            ? ''
            : `${'  '.repeat(level)}${action.field} = ${action.value};`
};

export default null;
