import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import './agentNotifications.scss';

export default function AgentsNotifications(props) {
    const { amount, type, text, setTableData, setCurrentPage, isActive } = props;

    const handleSeeAll = () => {
        setCurrentPage({ label: 1, value: 1 });
        setTableData(type);
    };

    return (
        <div
            className={classNames('agent-notifications', type, isActive && `active active-${type}`)}
            onClick={handleSeeAll}
            aria-hidden="true"
            data-testid="agent-notifications"
        >
            <div className="notifications-amount">{amount ?? 0}</div>
            <div className="notifications-bottom">
                <div className={classNames('notifications-text', type)}>{text}</div>
                <button className="toggle-button">See all</button>
            </div>
        </div>
    );
}

AgentsNotifications.defaultProps = {
    isActive: false,
    amount: null,
    type: '',
    text: '',
    setTableData: () => {},
    setCurrentPage: () => {}
};

AgentsNotifications.propTypes = {
    isActive: propTypes.bool,
    amount: propTypes.number,
    type: propTypes.string,
    text: propTypes.string,
    setTableData: propTypes.func,
    setCurrentPage: propTypes.func
};
