import React, { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
    Dropdown,
    Pagination,
    Table,
    TableColumn,
    SelectColumn
} from '@nxlog/common-ui/components';
import Success from '@nxlog/common-ui/dist/components/svgs/success';
import DisabledIcon from '@nxlog/common-ui/dist/components/svgs/disable';
import Dots from '@nxlog/common-ui/dist/components/svgs/dots';
import classNames from 'classnames';
import sort from '../../../utils/helpers/tables';
import { selectItemsPerPageValue, setItemsPerPage } from '../../../redux/reducers/agents';
import { API_SECURITY_TABLE_ROW_OPTIONS } from '../../../utils/constants/options';
import './tableApiSecurity.scss';

const statusIcon = (rowData) => (
    <>
        {rowData.status === 'Active' ? (
            <Success outlined fill="228D3A" />
        ) : (
            <DisabledIcon fill="#8195A9" />
        )}
        <span className={classNames('filter-value', rowData.status === 'Disabled' && 'disabled')}>
            {rowData.status}
        </span>
    </>
);

export default function TableApiSecurity(props) {
    const dispatch = useDispatch();
    const {
        checkedRows,
        setCheckedRows,
        tableData,
        setTableData,
        currentPage,
        setCurrentPage,
        allData
    } = props;

    const itemsPerPage = useSelector(selectItemsPerPageValue) || 0;
    const isFirstPage = currentPage?.value === 1;
    const paginationIndex = isFirstPage ? 0 : itemsPerPage * (currentPage.value - 1);
    const clonedArray = tableData?.slice();
    const paginatedTableData = clonedArray?.splice(paginationIndex, itemsPerPage);
    const [sortColumn, setSortColumn] = useState({
        name: 'asc',
        status: 'asc',
        created_by: 'asc',
        created_on: 'asc',
        expires_on: 'asc'
    });

    const handleChangeItemsAmount = (value) => {
        dispatch(setItemsPerPage({ label: value, value }));
    };

    const filterTable = (filterParam) => {
        sort(filterParam, sortColumn, allData, setSortColumn, setTableData);
    };

    const handleSelectRow = useCallback(
        (ids) => {
            const records = paginatedTableData.filter((rec) => ids.includes(rec.id));
            setCheckedRows(records);
        },
        [paginatedTableData, setCheckedRows]
    );

    const itemsPerPageValue = useSelector(selectItemsPerPageValue);
    const allDataAmount = tableData?.length;

    return (
        <div className="table-api-security-wrapper">
            <div className="table-api-security">
                <div data-testid="table-api-security-pagination-top" className="table-top">
                    <Pagination
                        className="table-pagination"
                        onPageSizeChange={handleChangeItemsAmount}
                        itemsAmount={allDataAmount}
                        itemsPerPage={itemsPerPageValue}
                        currentIndex={(currentPage.value - 1) * itemsPerPage}
                        onPageChange={(pageIndex) => setCurrentPage({ value: pageIndex })}
                    >
                        <Dropdown title="Actions" disabled={!checkedRows?.size} />
                    </Pagination>
                </div>
                <div data-testid="table-api-security-table" className="main-table">
                    <Table
                        pageSize={itemsPerPage}
                        className="api-security-table"
                        disableOverlay
                        rows={paginatedTableData}
                        rowProps={(item) => ({
                            className: item.status === 'Disabled' && 'disabled'
                        })}
                    >
                        <SelectColumn
                            name="select"
                            className="column-select"
                            idField="id"
                            ids={paginatedTableData.map((e) => e.id)}
                            selected={[...checkedRows.values()].map(({ id }) => id)} // list of selected IDs
                            onChange={handleSelectRow} // on select callback
                        />
                        <TableColumn
                            name="name"
                            className="column-name"
                            title="Name"
                            direction={sortColumn.name}
                            onSort={() => filterTable('name')}
                        />
                        <TableColumn
                            name="status"
                            className="column-status"
                            title="Status"
                            direction={sortColumn.status}
                            onSort={() => filterTable('status')}
                        >
                            {statusIcon}
                        </TableColumn>
                        <TableColumn
                            name="created_by"
                            className="column-creator"
                            title="Created by"
                            direction={sortColumn.created_by}
                            onSort={() => filterTable('created_by')}
                        />
                        <TableColumn
                            name="created_on"
                            className="column-created"
                            title="Created on"
                            direction={sortColumn.created_on}
                            onSort={() => filterTable('created_on')}
                        />
                        <TableColumn
                            name="expires_on"
                            className="column-expires"
                            title="Expires on"
                            direction={sortColumn.expires_on}
                            onSort={() => filterTable('expires_on')}
                        />
                        <TableColumn name="actions" title="Actions" className="column-actions">
                            {() => (
                                <Dropdown
                                    options={API_SECURITY_TABLE_ROW_OPTIONS}
                                    icon={<Dots fill="#0081C1" />}
                                    withIcon
                                    className="dropdown-actions"
                                />
                            )}
                        </TableColumn>
                    </Table>
                    <div
                        data-testid="table-api-security-pagination-bottom"
                        className="table-footer"
                    >
                        <Pagination
                            className="table-pagination"
                            onPageSizeChange={handleChangeItemsAmount}
                            itemsAmount={allDataAmount}
                            itemsPerPage={itemsPerPageValue}
                            currentIndex={(currentPage.value - 1) * itemsPerPage}
                            onPageChange={(pageIndex) => setCurrentPage({ value: pageIndex })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

TableApiSecurity.defaultProps = {
    tableData: null,
    setTableData: () => {},
    currentPage: null,
    setCurrentPage: () => {},
    allData: null,
    checkedRows: new Map(),
    setCheckedRows: () => {}
};

TableApiSecurity.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    tableData: propTypes.array,
    setTableData: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    currentPage: propTypes.object,
    setCurrentPage: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    allData: propTypes.array,
    checkedRows: propTypes.instanceOf(Map),
    setCheckedRows: propTypes.func
};
