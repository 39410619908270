import React, { useState, useRef, useEffect } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from '@nxlog/common-ui/components';
import DotsIcon from '@nxlog/common-ui/dist/components/svgs/dots';
import WarningIcon from '@nxlog/common-ui/dist/components/svgs/warningalt';
import Success from '@nxlog/common-ui/dist/components/svgs/success';
import CircleX from '@nxlog/common-ui/dist/components/svgs/circle-x';
import { AgentType } from '../../../../utils/constants/propTypes';
import { addressPortSplit, capitalizeFirstLetter } from '../../../../utils/helpers/strings';

export default function TableRow(props) {
    const { rowData, onSelectOption } = props;

    const navigate = useNavigate();
    const ref = useRef(null);

    const [coord, setCoords] = useState(null);

    useEffect(() => {
        if (ref?.current && ref.current.parentElement.clientHeight - ref.current.offsetTop <= 336) {
            setCoords({ X: '36px' });
        }
    }, [rowData]);

    const handleSelectOption = (value) => {
        onSelectOption(value, [rowData]);
    };

    const handleClick = () => {
        navigate(`/agents/agent/${rowData?.id}`);
    };

    const status = rowData?.data?.status && rowData?.data?.status.toLowerCase();

    return (
        <div className="table-rows" ref={ref}>
            <div className="agents-row" key={`row-${rowData?.id}`}>
                <div
                    data-testid="table-row-status"
                    className={classNames('row-block status-row', `status-${status}`)}
                >
                    {status === 'ok' && (
                        <Success
                            data-testid="table-row-status-icon-success"
                            outlined
                            fill="228D3A"
                        />
                    )}
                    {status === 'warning' && (
                        <WarningIcon data-testid="table-row-status-icon-warning" fill="#E67500" />
                    )}
                    {status === 'error' && (
                        <CircleX
                            data-testid="table-row-status-icon-circlex"
                            outlined
                            fill="#E03520"
                        />
                    )}
                    <span data-testid="table-row-status-value" className="filter-value">
                        {status ? capitalizeFirstLetter(status) : '-'}
                    </span>
                </div>
                <div className="row-block agent-row">
                    <button
                        data-testid="table-row-hostname-button"
                        className="filter-button"
                        onClick={handleClick}
                    >
                        {rowData?.hostname ?? '--'}
                    </button>
                </div>
                <div className="row-block ip-row">
                    <span className="filter-value" data-testid="table-row-address-value">
                        {(rowData?.data?.address && addressPortSplit(rowData.data.address)[0]) ??
                            '--'}
                    </span>
                </div>
                <div className="row-block eps-row">
                    <span data-testid="table-row-eps-value" className="filter-value">
                        {rowData?.data.events_per_second ?? '--'}
                    </span>
                </div>
                <div className="row-block types-row">
                    <span data-testid="table-row-types-value" className="filter-value">
                        {rowData?.types ?? '--'}
                    </span>
                </div>
                <div className="row-block item-row">
                    <span data-testid="table-row-item-value" className="filter-value">
                        {rowData?.item ?? '--'}
                    </span>
                </div>
                <div data-testid="table-row-options" className="row-block actions-row">
                    <Dropdown
                        options={null}
                        icon={<DotsIcon fill="#0081C1" />}
                        withIcon
                        onClick={handleSelectOption}
                        className="dropdown-actions"
                        coords={coord}
                        status={status}
                    />
                </div>
            </div>
        </div>
    );
}

TableRow.defaultProps = {
    rowData: null,
    onSelectOption: () => {}
};

TableRow.propTypes = {
    rowData: AgentType,
    onSelectOption: propTypes.func
};
