import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImMaces = {
    label: 'macOS Endpoint Security',
    name: 'im_maces',
    type: 'collect',
    defaultDirectives: 'Module      im_maces',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'MutedPathLiteral',
            type: 'string',
            defaultValue: [{ value: '' }],
            multiple: true
        },
        {
            name: 'MutedPathPrefix',
            type: 'string',
            defaultValue: [{ value: '' }],
            multiple: true
        },
        {
            name: 'NotifyEvents',
            type: 'select',
            options: [
                'access',
                'clone',
                'close',
                'create',
                'dup',
                'exchangedata',
                'fcntl',
                'lookup',
                'open',
                'rename',
                'truncate',
                'write',
                'unlink',
                'deleteextattr',
                'fsgetpath',
                'getattrlist',
                'getextattr',
                'listextattr',
                'readdir',
                'setacl',
                'setattrlist',
                'setextattr',
                'setflags',
                'setmode',
                'setowner',
                'stat',
                'utimes',
                'file_provider_materialize',
                'file_provider_update',
                'link',
                'readlink',
                'unlink',
                'mount',
                'unmount',
                'mmap',
                'mprotect',
                'chdir',
                'chroot',
                'exec',
                'exit',
                'fork',
                'get_task',
                'proc_check',
                'signal',
                'uipc_bind',
                'uipc_connect',
                'settime',
                'iokit_open',
                'kextload',
                'kextunload',
                'pty_grant',
                'pty_close'
            ],
            separator: ', ',
            multiple: true,
            defaultValue: []
        },
        ...GENERAL_INPUT_DIRECTIVES('im_maces'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
