/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Pagination, Table, TableColumn } from '@nxlog/common-ui/components';
import WarningIcon from '@nxlog/common-ui/dist/components/svgs/warningalt';
import CircleX from '@nxlog/common-ui/dist/components/svgs/circle-x';
import Success from '@nxlog/common-ui/dist/components/svgs/success';
import New from '@nxlog/common-ui/dist/components/svgs/new';
import Offline from '@nxlog/common-ui/dist/components/svgs/offline';
import WarningCircleIcon from '@nxlog/common-ui/dist/components/svgs/warning_circle';
import ConfigureIcon from '@nxlog/common-ui/dist/components/svgs/configure';
import NotConfiguredIcon from '@nxlog/common-ui/dist/components/svgs/not_configured';
import EnrollWarningIcon from '@nxlog/common-ui/dist/components/svgs/enrollWarning';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { AGENTS_FIELDS_MAP } from '../../../utils/constants/tables';
import useSelectRow from '../../../utils/hooks/useSelectRow';
import { capitalizeFirstLetter } from '../../../utils/helpers/strings';
import { formatMemoryUsage, getStatus } from '../../../utils/helpers/functions';
import { selectLicensesStatus } from '../../../redux/reducers/licenses';
import { selectItemsPerPageValue, setItemsPerPage } from '../../../redux/reducers/agents';
import CollapseIcon from '../../../assets/img/filter-collapse.svg';
import TableSidebarFilters from './tableSidebarFilters';
import TableAgentDetails from './tableAgentDetails';
import ActionsDropdown from './actionsDropdown';
import SelectColumn from './selectColumn';
import './tableAgents.scss';

export const statusIcon = (status, item) => {
    let icon;
    if (item?.enrolled) {
        switch (status) {
            case 'online':
                icon = <Success outlined fill="#228D3A" />;
                break;
            case 'warning':
                icon = <WarningIcon fill="#E67500" />;
                break;
            case 'offline':
                icon = <Offline fill="#8195A9" />;
                break;
            case 'error':
                icon = <CircleX outlined fill="#E03520" width="18px" height="18px" />;
                break;
            default:
                icon = <Success outlined fill="#228D3A" />;
        }
    } else {
        icon = <WarningCircleIcon width={18} height={18} fill="#823794" />;
        status = 'new';
    }

    return (
        <>
            {icon}
            <span className={`status-${status}`}>
                {status ? capitalizeFirstLetter(status) : '-'}
            </span>
        </>
    );
};

export const enrolledIcon = (item) => {
    let icon = null;
    let label = null;
    if (!item?.enrolled) {
        icon = 'New';
        label = 'New';
    } else if (item?.enrolled && !item?.configured) {
        label = 'Enrolled';
        switch (getStatus(item)) {
            case 'warning':
                icon = 'Enrolled-warning';
                break;
            case 'error':
                icon = 'Enrolled-error';
                break;
            default:
                icon = 'Enrolled';
        }
        label = 'Enrolled';
    } else if (item?.enrolled && item?.configured) {
        switch (getStatus(item)) {
            case 'warning':
                icon = 'Configured-warning';
                break;
            case 'error':
                icon = 'Configured-error';
                break;
            default:
                icon = 'Configured';
        }
        label = 'Configured';
    }
    return (
        <>
            {icon === 'Enrolled' && <ConfigureIcon fill="#fff" />}
            {icon === 'Enrolled-warning' && <EnrollWarningIcon fill="#fff" />}
            {icon === 'Enrolled-error' && <NotConfiguredIcon fill="#fff" />}
            {icon === 'Configured' && <ConfigureIcon fill="#fff" />}
            {icon === 'Configured-warning' && <EnrollWarningIcon fill="#fff" />}
            {icon === 'Configured-error' && <NotConfiguredIcon fill="#fff" />}
            {icon === 'New' && <New fill="#072341" />}
            <span className="filter-value">{label}</span>
        </>
    );
};

export default function TableAgents(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        onChangeItemsAmount,
        withFilters,
        onSort,
        appliedFilters,
        applyFilter,
        allDataAmount,
        removeFilter,
        checkedRows,
        setCheckedRows,
        hideTopFilter,
        tableData,
        filters,
        initialFilters,
        currentPage,
        setCurrentPage,
        onSelectOption,
        toggleRunningProcesses
    } = props;
    const [isSidebarFiltersOpened, setSidebarFiltersOpened] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sortColumn, setSortColumn] = useState({
        status: null,
        hostname: null,
        address: null,
        'events-per-second': null,
        'memory-used': null,
        'cpu-load': null,
        deployment: null,
        'agent-group': null,
        'template-name': null
    });
    const [paginatedTableData, setPaginatedTableData] = useState([]);
    const itemsPerPageValue = useSelector(selectItemsPerPageValue);
    const licenseStatus = useSelector(selectLicensesStatus);

    const [showDetails, setShowDetails] = useState([]);

    const handleToggleActionRow = (id) => {
        setShowDetails((state) => {
            if (state.includes(id)) {
                const newState = [...state];
                newState.splice(
                    newState.findIndex((item) => item === id),
                    1
                );
                return newState;
            }
            return [...state, id];
        });
    };

    const handleChangeItemsAmount = (value) => {
        dispatch(setItemsPerPage({ label: value, value }));
        onChangeItemsAmount(value);
    };

    useEffect(() => {
        setPaginatedTableData(tableData[currentPage.value] ?? []);
    }, [tableData, currentPage]);

    const handleSort = (filterParam) => {
        let sortFields = [filterParam];
        if (AGENTS_FIELDS_MAP[filterParam] && typeof AGENTS_FIELDS_MAP[filterParam] === 'object') {
            sortFields = [...AGENTS_FIELDS_MAP[filterParam]];
        }
        Object.keys(sortColumn)
            .filter((key) => key !== filterParam)
            .forEach((key) => {
                sortColumn[key] = null;
            });
        const order = sortColumn[filterParam] === 'asc' ? 'desc' : 'asc';
        onSort(sortFields.map((param) => ({ name: param, order })));
        const newSortColumn = { ...sortColumn };
        newSortColumn[filterParam] = order;
        setSortColumn(newSortColumn);
    };

    const handleSidebarFiltersOpen = () => {
        setSidebarFiltersOpened(!isSidebarFiltersOpened);
    };

    useEffect(() => {
        if (paginatedTableData.length > 0) {
            setLoading(false);
        } else if (loading) {
            setTimeout(() => setLoading(false), 3000);
        }
    }, [paginatedTableData, loading, setLoading]);

    const handleClick = useCallback(
        (id) => {
            navigate(`/agents/agent/${id}`);
        },
        [navigate]
    );

    const expandedView = useCallback(
        (data) => data && <TableAgentDetails rowData={{ data }} />,
        [TableAgentDetails, showDetails]
    );

    const handleSelectRow = useSelectRow(
        checkedRows,
        setCheckedRows,
        paginatedTableData,
        toggleRunningProcesses
    );

    const handleClearAllFilters = () => {
        removeFilter('all');
    };

    return (
        <div
            data-testid="table-agents"
            className={classNames('table-agents-wrapper', isSidebarFiltersOpened && 'opened')}
        >
            <TableSidebarFilters
                filters={filters}
                applyFilter={applyFilter}
                removeFilter={removeFilter}
                appliedFilters={appliedFilters}
                initialFilters={initialFilters}
            />
            <div className="table-agents">
                {!hideTopFilter && (
                    <div data-testid="table-agents-top-filters" className="table-top">
                        <div className="table-filters">
                            {withFilters && (
                                <>
                                    <div className="hideFilterContainer">
                                        <button
                                            data-testid="table-agents-filters-open"
                                            className={classNames(
                                                'toggle-filters',
                                                isSidebarFiltersOpened && 'opened'
                                            )}
                                            onClick={handleSidebarFiltersOpen}
                                        >
                                            <img
                                                src={CollapseIcon}
                                                alt="Filter collapse"
                                                className="toggle-image"
                                            />
                                            {isSidebarFiltersOpened
                                                ? 'Hide filters'
                                                : 'Show filters'}
                                        </button>
                                        <div className="filters-count-container">
                                            <span className="filters-count">
                                                {appliedFilters.length}
                                            </span>
                                        </div>
                                        <span className="blue-dot" />
                                    </div>

                                    {appliedFilters.length ? (
                                        <button
                                            data-testid="table-agents-filters-clear"
                                            className="toggle-filters clear-filter"
                                            onClick={handleClearAllFilters}
                                        >
                                            Clear all filters
                                        </button>
                                    ) : null}
                                </>
                            )}
                        </div>
                        <Pagination
                            className="table-pagination"
                            onPageSizeChange={handleChangeItemsAmount}
                            itemsAmount={allDataAmount}
                            itemsPerPage={itemsPerPageValue}
                            currentIndex={((currentPage?.value ?? 1) - 1) * itemsPerPageValue}
                            onPageChange={(pageIndex) =>
                                setCurrentPage({ label: pageIndex, value: pageIndex })
                            }
                        />
                    </div>
                )}
                <Table
                    pageSize={itemsPerPageValue}
                    isLoading={loading}
                    className="main-table"
                    disableOverlay
                    rows={paginatedTableData}
                    rowProps={(item) => ({
                        isExpanded: showDetails.includes(item?.id)
                    })}
                    expandedRow={expandedView}
                >
                    <SelectColumn
                        name="select"
                        className="column-select"
                        idField="id" // field to be used as identifier on the items
                        ids={paginatedTableData.map((e) => e.id)} // list of all available IDs in the page
                        selected={[...checkedRows.values()].map((e) => e.id)} // list of selected IDs
                        onChange={handleSelectRow} // on select callback
                    >
                        {(item) => <span className={`left-status-bar status-${getStatus(item)}`} />}
                    </SelectColumn>
                    <TableColumn
                        name="status"
                        className="column-status"
                        title="Status"
                        direction={sortColumn.status}
                        onSort={() => {
                            handleSort('status');
                        }}
                    >
                        {(item) => statusIcon(getStatus(item), item)}
                    </TableColumn>
                    <TableColumn
                        name="hostname"
                        className="column-hostname"
                        title="Name"
                        direction={sortColumn.hostname}
                        onSort={() => {
                            handleSort('hostname');
                        }}
                    >
                        {(item) => (
                            <button
                                data-testid="table-agents-hostname"
                                className="btn-hostname"
                                onClick={() => handleClick(item.id)}
                            >
                                {item?.hostname ?? '--'}
                            </button>
                        )}
                    </TableColumn>
                    {/* TODO: uncomment this, when MIND-1265 task is done */}
                    {/* <TableColumn
                        name="address"
                        className="column-address"
                        title="IP address"
                        direction={sortColumn.address}
                        onSort={() => {
                            handleSort('address');
                        }}>
                        {(data) => (data?.address && addressPortSplit(data.address)[0]) ?? '--'}
                        // import addressPortSplit from helpers/strings
                    </TableColumn> */}
                    <TableColumn
                        name="os_release"
                        className="column-os-release column-nowrap"
                        title="OS-Release"
                    >
                        {(data) => data?.osRelease ?? '--'}
                    </TableColumn>
                    <TableColumn name="arch" className="colum-arch column-nowrap" title="Arch">
                        {(data) => data?.arch ?? '--'}
                    </TableColumn>
                    <TableColumn
                        name="events_per_second"
                        className="column-eps"
                        title="EPS"
                        direction={sortColumn['events-per-second']}
                        onSort={() => {
                            handleSort('events-per-second');
                        }}
                    >
                        {(data) => Number(data?.eventsPerSecond).toFixed(2) ?? '--'}
                    </TableColumn>
                    <TableColumn
                        name="memory_used"
                        className="column-memory"
                        title="Memory use"
                        direction={sortColumn['memory-used']}
                        onSort={() => {
                            handleSort('memory-used');
                        }}
                    >
                        {(data) => `${formatMemoryUsage(data?.memoryUsed)}` ?? '--'}
                    </TableColumn>
                    <TableColumn
                        name="cpu_load"
                        className="column-cpu"
                        title="Load"
                        direction={sortColumn['cpu-load']}
                        onSort={() => {
                            handleSort('cpu-load');
                        }}
                    >
                        {(data) => Number(data?.cpuLoad).toFixed(2) ?? '--'}
                    </TableColumn>
                    <TableColumn
                        name="enrolled"
                        className="column-enrolled"
                        title="Deployments state"
                        direction={sortColumn.deployment}
                        onSort={() => {
                            handleSort('deployment');
                        }}
                    >
                        {(item) => enrolledIcon(item)}
                    </TableColumn>
                    <TableColumn
                        name="agentTemplate"
                        className="column-template"
                        title="Template"
                        direction={sortColumn['template-name']}
                        onSort={() => {
                            handleSort('template-name');
                        }}
                    >
                        {(data) => data?.templateName ?? '--'}
                    </TableColumn>
                    <TableColumn name="actions" title="Actions" className="column-actions">
                        {(item) => (
                            <>
                                <button
                                    data-testid="table-agents-toggle-row"
                                    aria-label="Details"
                                    type="button"
                                    className={classNames(
                                        'agent-details-button',
                                        showDetails.includes(item.id) && 'row-expanded'
                                    )}
                                    onClick={() => handleToggleActionRow(item.id)}
                                >
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </button>
                                <ActionsDropdown
                                    onSelectOption={onSelectOption}
                                    rowData={item}
                                    licenseStatus={licenseStatus}
                                />
                            </>
                        )}
                    </TableColumn>
                </Table>
                <div data-testid="table-agents-footer" className="table-footer">
                    <Pagination
                        className="table-pagination"
                        onPageSizeChange={handleChangeItemsAmount}
                        itemsAmount={allDataAmount}
                        itemsPerPage={itemsPerPageValue}
                        currentIndex={((currentPage?.value ?? 1) - 1) * itemsPerPageValue}
                        onPageChange={(pageIndex) => setCurrentPage({ value: pageIndex })}
                    />
                </div>
            </div>
        </div>
    );
}

TableAgents.defaultProps = {
    withFilters: true,
    allDataAmount: 0,
    tableData: {},
    onChangeItemsAmount: () => {},
    currentPage: null,
    setCurrentPage: () => {},
    appliedFilters: [],
    filters: [],
    onSort: () => {},
    initialFilters: [],
    hideTopFilter: false,
    checkedRows: new Map(),
    setCheckedRows: () => {},
    applyFilter: () => {},
    onSelectOption: () => {},
    removeFilter: () => {},
    toggleRunningProcesses: () => {}
};

TableAgents.propTypes = {
    withFilters: propTypes.bool,
    allDataAmount: propTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    tableData: propTypes.object,
    hideTopFilter: propTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    currentPage: propTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    filters: propTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    initialFilters: propTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    appliedFilters: propTypes.array,
    onChangeItemsAmount: propTypes.func,
    onSort: propTypes.func,
    removeFilter: propTypes.func,
    applyFilter: propTypes.func,
    setCurrentPage: propTypes.func,
    checkedRows: propTypes.instanceOf(Map),
    setCheckedRows: propTypes.func,
    onSelectOption: propTypes.func,
    toggleRunningProcesses: propTypes.func
};
