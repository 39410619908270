import PropTypes from 'prop-types';
import i18next from 'i18next';
import AlertHeading from '../../common/alertHeading';
import Confirm from '../confirmModal';

function UnenrollConfirm(props) {
    const { count, show, onCancel, onConfirm } = props;

    const title = (
        <AlertHeading>
            {i18next.t('alert_messages.confirm.unenroll_heading', { count })}
        </AlertHeading>
    );

    const body = i18next.t('alert_messages.confirm.unenroll_body');

    return (
        <Confirm
            show={show}
            title={title}
            body={body}
            cancel="Cancel"
            confirm="Unenroll"
            onCancel={onCancel}
            onConfirm={onConfirm}
        />
    );
}

UnenrollConfirm.propTypes = {
    count: PropTypes.number.isRequired,
    show: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

export default UnenrollConfirm;
