import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import LanguageDetector from 'i18next-browser-languagedetector';
import sharedPlatformEN from '@nxlog/common-ui/dist/assets/locales/en.json';
import minderEN from './en.json';

const resources = {
    en: {
        translation: { ...sharedPlatformEN, ...minderEN }
    }
};

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'en',
        saveMissing: true,
        resources,

        interpolation: {
            escapeValue: false,
            format: (value, format) => {
                if (value instanceof Date) {
                    return moment(value).format(format);
                }
                return value;
            }
        }
    });

export default i18n;
