/* eslint-disable no-console */

function log() {
    const messages = [];

    if (process.env.REACT_APP_COMMIT_REF) {
        messages.push(`REF:  ${process.env.REACT_APP_COMMIT_REF}`);
    }

    if (process.env.REACT_APP_COMMIT_SHA) {
        messages.push(`SHA:  ${process.env.REACT_APP_COMMIT_SHA}`);
    }

    messages.push(
        `TYPE: ${process.env.REACT_APP_ON_PREMISE === 'true' ? 'On premise' : 'On cloud'}`
    );

    console.log(messages.join('\n'));
}

export default log;
