import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImRuby = {
    label: 'Ruby Language',
    name: 'im_ruby',
    type: 'collect',
    defaultDirectives: 'Module      im_ruby',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'RubyCode',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Call',
            type: 'string',
            defaultValue: 'read_data'
        },
        ...GENERAL_INPUT_DIRECTIVES('im_ruby'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
