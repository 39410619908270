import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Copy, Export } from '@nxlog/common-ui/dist/components/svgs';
import { WithTooltip } from '@nxlog/common-ui/components';

function CodeBlock({ code, onInsert, hideCopy }) {
    const [tooltip, setTooltip] = useState(null);

    const copyToClipboard = useCallback(() => {
        if (navigator?.clipboard) {
            navigator.clipboard
                .writeText(code)
                .then(() => {
                    setTooltip('Copied!');
                })
                .catch(() => {
                    setTooltip('Error copy to clipboard');
                });
        } else {
            setTooltip('Clipboard unavailable');
        }
    }, [code]);

    const renderTooltip = useCallback(
        (popoverProps) => (
            <Popover
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...popoverProps}
                className={tooltip ? 'code-block-snippet-popover' : 'hidden'}
            >
                {tooltip}
            </Popover>
        ),
        [tooltip]
    );

    return (
        <div className="code-block">
            <pre>{code}</pre>
            <div>
                {onInsert ? (
                    <WithTooltip placement="top" message="Insert">
                        <button
                            onClick={() => onInsert(code)}
                            className="code-block-button"
                            aria-label="Insert"
                        >
                            <Export />
                        </button>
                    </WithTooltip>
                ) : null}
                {!hideCopy ? (
                    <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="right"
                        overlay={renderTooltip}
                    >
                        <button
                            onClick={copyToClipboard}
                            className="code-block-button"
                            aria-label="Copy"
                        >
                            <Copy />
                        </button>
                    </OverlayTrigger>
                ) : null}
            </div>
        </div>
    );
}

CodeBlock.defaultProps = {
    code: '',
    onInsert: null,
    hideCopy: false
};

CodeBlock.propTypes = {
    code: PropTypes.string,
    onInsert: PropTypes.func,
    hideCopy: PropTypes.bool
};

export default CodeBlock;
