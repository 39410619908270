import Loader from '@nxlog/common-ui/dist/components/svgs/loader';
import { appendCurrentPortToUrl } from '@nxlog/common-ui/dist/utils';
import { useEffect } from 'react';

export default function LogoutPage() {
    useEffect(() => {
        const ssoUrl = localStorage.getItem('SSO_URL');
        const url = new URL(ssoUrl);
        window.location.href = appendCurrentPortToUrl(url.toString());
    }, []);

    return (
        <div className="redirector-wrapper">
            <Loader />
        </div>
    );
}
