import React, { useState, useRef, useEffect } from 'react';
import propTypes from 'prop-types';
import { Dropdown } from '@nxlog/common-ui/components';
import DotsIcon from '@nxlog/common-ui/dist/components/svgs/dots';
import { AgentType } from '../../../../utils/constants/propTypes';
import { CONFIG_TABLE_ROW_OPTIONS } from '../../../../utils/constants/options';

export default function ActionsDropdown(props) {
    const { rowData, onSelectOption } = props;

    const ref = useRef(null);

    const [coord, setCoords] = useState(null);

    useEffect(() => {
        if (ref?.current && ref.current.parentElement.clientHeight - ref.current.offsetTop <= 336) {
            setCoords({ X: '36px' });
        }
    }, [rowData]);

    const handleSelectOption = (value) => {
        onSelectOption(value, [rowData]);
    };

    const status = rowData?.data?.status && rowData?.data?.status.toLowerCase();

    return (
        <Dropdown
            options={CONFIG_TABLE_ROW_OPTIONS.filter(
                ({ value }) => !rowData.draft || !['assignAgents'].includes(value)
            )}
            icon={<DotsIcon />}
            onClick={handleSelectOption}
            className="dropdown-actions"
            coords={coord}
            status={status}
            withIcon
        />
    );
}

ActionsDropdown.defaultProps = {
    rowData: null,
    onSelectOption: () => {}
};

ActionsDropdown.propTypes = {
    rowData: AgentType,
    onSelectOption: propTypes.func
};
