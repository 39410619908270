import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { Input, Dropdown } from '@nxlog/common-ui/components';
import { removePersistedNotification } from '../../redux/reducers/notifications';
import { notificationTagNormalizer } from '../../utils/helpers/strings';
import {
    handleNotificationsFilter,
    extractTagComparatorSearchTerm
} from '../../utils/helpers/tables';
import { NOTIFICATIONS_TABLE_ROW_OPTIONS } from '../../utils/constants/options';
import './searchNotifications.scss';

export default function SearchNotification(props) {
    const dispatch = useDispatch();
    const { allData, setTableData, checkedRows, paginatedTableData, setCheckedRows } = props;
    const [tags, setTags] = useState([]);
    const validTags = {
        type: true,
        message: true,
        additionalinfo: true,
        time: true
    };

    const validateTag = (newTagValue) => {
        if (newTagValue.length < 1 || newTagValue.length > 48) {
            return false;
        }

        const { tag, comparator } = extractTagComparatorSearchTerm(newTagValue);
        if (!comparator) return true;

        return validTags[notificationTagNormalizer(tag)];
    };

    const sampleSearchTags = [
        'message:restart',
        'additionalinfo:skipped',
        'type:warning',
        'time:jul 14'
    ];

    const filterDataBySearchTerm = (appliedTags, setData = setTableData) => {
        if (appliedTags.length === 0) {
            setTableData(allData);
            return;
        }

        handleNotificationsFilter(appliedTags, setData, allData);
    };

    const handleDropdownOptions = (option) => {
        const ids = new Set(checkedRows.keys());
        if (option === 'delete') {
            ids.forEach((id) => {
                const notification = {
                    id
                };
                dispatch(removePersistedNotification(notification));
            });
            const updatedTableData = paginatedTableData.filter((row) => !ids.has(row.id));
            setTableData(updatedTableData);
            setCheckedRows([]);
            sessionStorage.setItem('notifications', JSON.stringify(updatedTableData));
        }
    };

    return (
        <div className="notifications-search">
            <Input
                data-testid="search-notifications-input"
                type="text"
                classname="with-search-icon"
                placeholder="Search or filter using tags e.g: status=OK"
                isSearch
                isDropdown={false}
                tags={tags}
                tagName="NotificationTags"
                validTags={validTags}
                validateTag={validateTag}
                sampleSearchTags={sampleSearchTags}
                setTableData={setTableData}
                setTags={setTags}
                filterDataBySearchTerm={filterDataBySearchTerm}
            />

            <Dropdown
                data-testid="search-notifications-dropdown"
                options={NOTIFICATIONS_TABLE_ROW_OPTIONS}
                title="Operations"
                withIcon
                className="dropdown-actions"
                onClick={(option) => handleDropdownOptions(option)}
                disabled={!checkedRows.size}
            />
        </div>
    );
}

SearchNotification.defaultProps = {
    allData: [],
    setTableData: () => {},
    checkedRows: [],
    setCheckedRows: () => {},
    paginatedTableData: []
};

SearchNotification.propTypes = {
    setTableData: propTypes.func,
    setCheckedRows: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    allData: propTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    checkedRows: propTypes.instanceOf(Map),
    // eslint-disable-next-line react/forbid-prop-types
    paginatedTableData: propTypes.array
};
