export const autoCompleteKeys = [
    '$raw_event',
    'get_abs_path()',
    'get_rel_path()',
    'get_url()',
    '$MessageSourceAddress',
    '$CharactersTransferred',
    '$Command',
    '$CompatFlag',
    '$CoreDumpedFlag',
    '$EventTime',
    '$ExitCode',
    '$ForkFlag',
    '$Group',
    '$GroupID',
    '$MajorPageFaults',
    '$MemoryUsage',
    '$MinorPageFaults',
    '$RealTime',
    '$RWBlocks',
    '$Severity',
    '$SeverityValue',
    '$SuFlag',
    '$SysTime',
    '$User',
    '$UserID',
    '$UserTime',
    '$XSIGFlag',
    '$AccountName',
    '$ApplicationName',
    '$DestinationIPv4Address',
    '$DestinationPort',
    '$Direction',
    '$EventDuration',
    '$Hostname',
    '$Interface',
    '$RecordNumber',
    '$SourceIPv4Address',
    '$SourceName',
    '$SourcePort',
    '$EventReceivedTime',
    '$AccountType',
    '$ActivityID',
    '$Category',
    '$Channel',
    '$ChannelID',
    '$Domain',
    '$EventID',
    '$EventType',
    '$ExecutionProcessID',
    '$ExecutionThreadID',
    '$Keywords',
    '$Level',
    '$LevelValue',
    '$Opcode',
    '$OpcodeValue',
    '$PacketData',
    '$ParsedPacketData',
    '$ProviderGuid',
    '$TaskValue',
    '$Version',
    'file_name()',
    'record_number()',
    'is_scanning()',
    '$Digest',
    '$DigestName',
    '$FileName',
    '$FileSize',
    '$ModificationTime',
    '$Object',
    '$PrevDigest',
    '$PrevFileName',
    '$PrevFileSize',
    '$PrevModificationTime',
    '$InsertId',
    '$JsonPayload',
    '$LogName',
    '$ProtoPayload',
    '$ReceiveTimestamp',
    '$SpanId',
    '$TextPayload',
    '$Timestamp',
    '$Trace',
    '$TraceSampled',
    '$Data',
    '$MessageId',
    '$OrderingKey',
    '$PublishTime',
    '$ErrorCode',
    '$Message',
    '$ModuleName',
    '$ModuleType',
    '$ProcessID',
    '$SourceModuleName',
    '$SourceModuleType',
    '$arch',
    '$auid',
    '$cmd',
    '$egid',
    '$euid',
    '$fsgid',
    '$fsuid',
    '$gid',
    '$mode',
    '$new_gid',
    '$oauid',
    '$obj_gid',
    '$obj_uid',
    '$ogid',
    '$ouid',
    '$sauid',
    '$sgid',
    '$suid',
    '$syscall',
    '$uid',
    '$acct',
    '$addr',
    '$cap_fi',
    '$cap_fp',
    '$cap_pe',
    '$cap_pi',
    '$cap_pp',
    '$capability',
    '$cgroup',
    '$comm',
    '$cwd',
    '$data',
    '$dev',
    '$devmajor',
    '$devminor',
    '$exe',
    '$exit',
    '$family',
    '$filetype',
    '$flags',
    '$hostname',
    '$icmptype',
    '$id',
    '$inode',
    '$inode_gid',
    '$inode_uid',
    '$items',
    '$key',
    '$list',
    '$msg',
    '$msgtype',
    '$name',
    '$new-disc',
    '$new-mem',
    '$new-net',
    '$new-vcpu',
    '$obj',
    '$obj_lev_high',
    '$obj_lev_low',
    '$obj_role',
    '$obj_user',
    '$ocomm',
    '$old_prom',
    '$opid',
    '$oses',
    '$path',
    '$perm',
    '$pid',
    '$ppid',
    '$prom',
    '$proto',
    '$res',
    '$result',
    '$saddr',
    '$ses',
    '$sig',
    '$subj',
    '$subj_clr',
    '$subj_role',
    '$subj_sen',
    '$subj_user',
    '$success',
    '$terminal',
    '$tty',
    '$vm',
    '$AccessMode',
    '$AccessTime',
    '$ACL',
    '$MessageVersion',
    '$ACLAction',
    '$AvailableBlocks',
    '$BlockSize',
    '$ClientClass',
    '$ClientType',
    '$CommonAttributes',
    '$CreateMode',
    '$DeviceVersion',
    '$Directory',
    '$DirectoryAttributes',
    '$ExistingFile',
    '$ExtendedAttributes',
    '$ExtendedFlags',
    '$File',
    '$FileAttributes',
    '$FileFlags',
    '$FileMode',
    '$Filename',
    '$FilesystemID',
    '$FilesystemSubType',
    '$FilesystemType',
    '$FilesystemTypeName',
    '$FirstFile',
    '$ForkAttributes',
    '$FreeBlocks',
    '$FreeFileNodes',
    '$GID',
    '$KernelExtension',
    '$MachTimestamp',
    '$MemoryAddress',
    '$MemoryMapFilePosition',
    '$MemoryMapFlags',
    '$MemoryMapMaxProtection',
    '$MemoryMapProtection',
    '$MemorySize',
    '$MessageSequence',
    '$Modified',
    '$ModifiedTime',
    '$MountedFrom',
    '$MountedOn',
    '$MountFlags',
    '$OpenFlags',
    '$OwnerUID',
    '$ProcessCheckFlavor',
    '$ProcessCheckType',
    '$ProcessCodeDirectoryHash',
    '$ProcessCodesigningFlags',
    '$ProcessEffectiveGID',
    '$ProcessEffectiveUID',
    '$ProcessESClient',
    '$ProcessExecutable',
    '$ProcessOriginalParentPID',
    '$ProcessParentPID',
    '$ProcessPID',
    '$ProcessPIDVersion',
    '$ProcessPlatformBinary',
    '$ProcessRealGID',
    '$ProcessRealUID',
    '$ProcessSessionID',
    '$ProcessSigningID',
    '$ProcessTeamID',
    '$ProcessTty',
    '$ProcessUID',
    '$Protection',
    '$Protocol',
    '$SecondFile',
    '$Signal',
    '$SocketMode',
    '$SocketType',
    '$TargetFile',
    '$TargetProcessArgs',
    '$TargetProcessCodeDirectoryHash',
    '$TargetProcessCodesigningFlags',
    '$TargetProcessEffectiveGID',
    '$TargetProcessEffectiveUID',
    '$TargetProcessESClient',
    '$TargetProcessExecutable',
    '$TargetProcessOriginalParentPID',
    '$TargetProcessParentPID',
    '$TargetProcessPID',
    '$TargetProcessPIDVersion',
    '$TargetProcessPlatformBinary',
    '$TargetProcessRealGID',
    '$TargetProcessRealUID',
    '$TargetProcessSessionID',
    '$TargetProcessSigningID',
    '$TargetProcessTeamID',
    '$TargetProcessTty',
    '$TargetProcessUID',
    '$TotalDataBlocks',
    '$TotalFileNodes',
    '$TransferBlockSize',
    '$UID',
    '$VolumeAttributes',
    '$activityIdentifier',
    '$bootUUID',
    '$category',
    '$eventMessage',
    '$eventType',
    '$formatString',
    '$machTimestamp',
    '$messageType',
    '$parentActivityIdentifier',
    '$processID',
    '$processImagePath',
    '$processImageUUID',
    '$senderImagePath',
    '$senderImageUUID',
    '$senderProgramCounter',
    '$signpostID',
    '$signpostName',
    '$signpostScope',
    '$signpostType',
    '$subsystem',
    '$threadID',
    '$traceID',
    '$TTL',
    '$LogType',
    '$CategoryNumber',
    '$EventTimeWritten',
    '$EventData.SubjectUserName',
    '$SubjectUserName',
    '$EventXML',
    '$ERROR_EVT_UNRESOLVED',
    '$RelatedActivityID',
    '$ThreadID',
    'CAST()',
    'read_data()',
    '$ver',
    '$PrevValueSize',
    '$RegistryValueName',
    '$ValueSize',
    '$AuditSession',
    '$AuditUID',
    '$BootID',
    '$Capabilities',
    '$CodeFile',
    '$CodeFunc',
    '$CodeLine',
    '$CoredumpUnit',
    '$CoredumpUserUnit',
    '$DevLink',
    '$DevName',
    '$DevNode',
    '$Errno',
    '$Facility',
    '$KernelDevice',
    '$KernelSubsystem',
    '$MachineID',
    '$MessageID',
    '$ObjAuditSession',
    '$ObjAuditUID',
    '$ObjGroup',
    '$ObjProcessCmdLine',
    '$ObjProcessExecutable',
    '$ObjProcessID',
    '$ObjProcessName',
    '$ObjSystemdCGroup',
    '$ObjSystemdOwnerUID',
    '$ObjSystemdSession',
    '$ObjSystemdUnit',
    '$ObjUser',
    '$ProcessCmdLine',
    '$ProcessName',
    '$SelinuxContext',
    '$SysInvID',
    '$SystemdCGroup',
    '$SystemdOwnerUID',
    '$SystemdSession',
    '$SystemdSlice',
    '$SystemdUnit',
    '$SystemdUserUnit',
    '$Transport',
    'recvmmsg()',
    '$EventData',
    '$param1',
    '$param2',
    '$Task',
    '$UserData',
    'drop()',
    'get_response_code()',
    'get_retry_count()',
    'file_size()',
    'to_json()',
    'set_http_request_path()',
    'add_http_header()',
    'sql_error()',
    'write_data()',
    '$sourceaddr',
    'is_blocking()'
];

export default null;
