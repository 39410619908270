import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImMark = {
    label: 'Heartbeat',
    type: 'collect',
    name: 'im_mark',
    defaultDirectives: `Module      im_mark`,
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Mark',
            type: 'string',
            defaultValue: '-- MARK --'
        },
        {
            name: 'MarkInterval',
            type: 'number',
            defaultValue: 30
        },
        ...GENERAL_INPUT_DIRECTIVES('im_mark'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
