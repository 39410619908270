import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Pagination, Table, TableColumn } from '@nxlog/common-ui/components';
import { useToggle } from '@nxlog/common-ui/hooks';
import { AGENTS_FIELDS_MAP } from '../../../utils/constants/tables';
import { getStatus } from '../../../utils/helpers/functions';
import CollapseIcon from '../../../assets/img/filter-collapse.svg';
import { selectItemsPerPageValue, setItemsPerPage } from '../../../redux/reducers/agents';
import TableSidebarFilters from '../tableAgents/tableSidebarFilters';
import SelectColumn from '../tableAgents/selectColumn';
import TableAgentDetails from './tableAgentDetails';
import useSelectRow from '../../../utils/hooks/useSelectRow';
import { enrolledIcon, statusIcon } from '../tableAgents';
import './tableAssignAgents.scss';

export default function TableAssignAgents(props) {
    const dispatch = useDispatch();
    const {
        appliedFilters,
        filters,
        onSort,
        initialFilters,
        disabledFilterIds,
        applyFilter,
        allDataAmount,
        removeFilter,
        resetFilters,
        checkedRows,
        setCheckedRows,
        tableData,
        currentPage,
        setCurrentPage
    } = props;
    const [isSidebarFiltersOpened, toggleSidebarFiltersOpened] = useToggle(false);
    const [sortColumn, setSortColumn] = useState({
        status: null,
        hostname: null,
        address: null,
        'events-per-second': null,
        'memory-used': null,
        'cpu-load': null,
        deployment: null,
        'agent-group': null,
        'template-name': null
    });
    const [paginatedTableData, setPaginatedTableData] = useState([]);
    const [showDetails, setShowDetails] = useState([]);
    const itemsPerPageValue = useSelector(selectItemsPerPageValue);
    const fromPageValue =
        currentPage.value === 1 ? 1 : itemsPerPageValue * (currentPage.value - 1) + 1;

    const handleChangeItemsAmount = (value) => {
        dispatch(setItemsPerPage({ label: value, value }));
    };

    const handleToggleActionRow = (id) => {
        setShowDetails((state) => {
            if (state.includes(id)) {
                const newState = [...state];
                newState.splice(
                    newState.findIndex((item) => item === id),
                    1
                );
                return newState;
            }
            return [...state, id];
        });
    };

    useEffect(() => {
        const newPaginatedData =
            tableData[currentPage.value]?.map(({ data, ...rest }) => ({
                ...rest,
                ...data
            })) ?? [];
        const sortedBy = Object.entries(sortColumn).find((column) => !!column[1])?.[0];
        if (sortedBy) {
            setPaginatedTableData(orderBy(newPaginatedData, sortedBy, [sortColumn[sortedBy]]));
            return;
        }
        setPaginatedTableData(newPaginatedData);
    }, [tableData, currentPage]);

    const handleSort = (filterParam) => {
        let sortFields = [filterParam];
        if (AGENTS_FIELDS_MAP[filterParam] && typeof AGENTS_FIELDS_MAP[filterParam] === 'object') {
            sortFields = [...AGENTS_FIELDS_MAP[filterParam]];
        }
        const order = sortColumn[filterParam] === 'asc' ? 'desc' : 'asc';
        onSort(sortFields.map((param) => ({ name: param, order })));
        const newSortColumn = { ...sortColumn };
        newSortColumn[filterParam] = order;
        setSortColumn(newSortColumn);
    };

    const expandedView = useCallback(
        (item) => (item ? <TableAgentDetails rowData={{ data: item ?? {} }} /> : null),
        [TableAgentDetails, showDetails, handleToggleActionRow]
    );

    const handleSelectRow = useSelectRow(checkedRows, setCheckedRows, paginatedTableData);

    const handleClearAllFilters = () => {
        resetFilters();
    };

    useEffect(() => {
        resetFilters();
        return () => resetFilters();
    }, []);

    return (
        <div
            data-testid="table-assign-agents"
            className={classNames(
                'table-assign-agents-wrapper',
                isSidebarFiltersOpened && 'opened'
            )}
        >
            <TableSidebarFilters
                filters={filters}
                applyFilter={applyFilter}
                removeFilter={removeFilter}
                appliedFilters={appliedFilters}
                initialFilters={initialFilters}
                disabledFilterIds={disabledFilterIds}
            />
            <div className="table-assign-agents">
                <div className="table-top">
                    <div className="table-filters">
                        <div className="hideFilterContainer">
                            <button
                                data-testid="table-assign-agents-toggle-sidebar"
                                className={classNames(
                                    'toggle-filters',
                                    isSidebarFiltersOpened && 'opened'
                                )}
                                onClick={toggleSidebarFiltersOpened}
                            >
                                <img
                                    src={CollapseIcon}
                                    alt="Filter collapse"
                                    className="toggle-image"
                                />
                                {isSidebarFiltersOpened ? 'Hide' : 'Show'}
                                filters
                            </button>
                            <div className="filters-count-container">
                                <span className="filters-count">{appliedFilters.length}</span>
                            </div>
                            <span className="blue-dot" />
                        </div>

                        {appliedFilters.length > 1 ? (
                            <button
                                data-testid="table-assign-agents-reset-filters"
                                className="toggle-filters clear-filter"
                                onClick={handleClearAllFilters}
                            >
                                Clear all filters
                            </button>
                        ) : null}

                        {allDataAmount ? (
                            <span className="filters-results">
                                Items
                                {fromPageValue}-
                                {itemsPerPageValue * currentPage.value < allDataAmount
                                    ? itemsPerPageValue * currentPage.value
                                    : allDataAmount}
                                /{allDataAmount}
                            </span>
                        ) : (
                            <span className="filters-results">No items</span>
                        )}
                    </div>
                </div>
                <div data-testid="table-assign-agents-table" className="main-table">
                    <Table
                        pageSize={itemsPerPageValue}
                        disableOverlay
                        rows={paginatedTableData}
                        rowProps={(item) => ({
                            isExpanded: showDetails.includes(item?.id)
                        })}
                        expandedRow={
                            // view to be shown in case of expanded row
                            expandedView
                        }
                    >
                        <SelectColumn
                            name="select"
                            className="column-select"
                            idField="id"
                            ids={paginatedTableData.map((e) => e.id)}
                            selected={[...checkedRows.values()].map((e) => e.id)}
                            onChange={handleSelectRow}
                        />
                        <TableColumn
                            name="status"
                            className="column-status"
                            title="Status"
                            direction={sortColumn.status}
                            onSort={() => handleSort('status')}
                        >
                            {(item) => statusIcon(getStatus(item), item)}
                        </TableColumn>
                        <TableColumn
                            name="hostname"
                            className="column-hostname"
                            title="Name"
                            direction={sortColumn.hostname}
                            onSort={() => handleSort('hostname')}
                        />
                        <TableColumn
                            name="enrolled"
                            className="column-enrolled"
                            title="Deployments state"
                            direction={sortColumn.deployment}
                            onSort={() => handleSort('deployment')}
                        >
                            {(item) => enrolledIcon(item)}
                        </TableColumn>
                        <TableColumn
                            name="agentTemplate"
                            className="column-template"
                            title="Template"
                            direction={sortColumn['template-name']}
                            onSort={() => {
                                handleSort('template-name');
                            }}
                        >
                            {(data) => data?.templateName ?? '--'}
                        </TableColumn>
                        <TableColumn name="actions" title="Actions" className="column-actions">
                            {(item) => (
                                <button
                                    data-testid="table-assign-agents-button-details"
                                    aria-label="Details"
                                    type="button"
                                    className={classNames(
                                        'agent-details-button',
                                        showDetails.includes(item.id) && 'row-expanded'
                                    )}
                                    onClick={() => handleToggleActionRow(item.id)}
                                >
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </button>
                            )}
                        </TableColumn>
                    </Table>
                </div>
                <div data-testid="table-assign-agents-pagination-bottom" className="table-footer">
                    <Pagination
                        className="table-pagination"
                        onPageSizeChange={handleChangeItemsAmount}
                        itemsAmount={allDataAmount}
                        itemsPerPage={itemsPerPageValue}
                        currentIndex={(currentPage.value - 1) * itemsPerPageValue}
                        onPageChange={(pageIndex) => setCurrentPage({ value: pageIndex })}
                    />
                </div>
            </div>
        </div>
    );
}

TableAssignAgents.defaultProps = {
    allDataAmount: 0,
    tableData: {},
    appliedFilters: [],
    filters: [],
    initialFilters: [],
    disabledFilterIds: [],
    applyFilter: () => {},
    removeFilter: () => {},
    resetFilters: () => {},
    currentPage: null,
    setCurrentPage: () => {},
    checkedRows: new Map(),
    setCheckedRows: () => {},
    onSort: () => {}
};

TableAssignAgents.propTypes = {
    allDataAmount: propTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    tableData: propTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    filters: propTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    appliedFilters: propTypes.array, // eslint-disable-next-line react/forbid-prop-types
    initialFilters: propTypes.array,
    disabledFilterIds: propTypes.arrayOf(propTypes.string),
    removeFilter: propTypes.func,
    resetFilters: propTypes.func,
    applyFilter: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    currentPage: propTypes.object,
    setCurrentPage: propTypes.func,
    checkedRows: propTypes.instanceOf(Map),
    setCheckedRows: propTypes.func,
    onSort: propTypes.func
};
