import React, { useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import LoaderSvg from '@nxlog/common-ui/dist/components/svgs/loader';
import {
    getSolutionPacksData,
    selectSolutionPacks,
    selectSolutionPacksLoading
} from '../../../redux/reducers/solutionPacks';
import Loader from '../../common/loader/index';
import { SolutionPackBadge } from '../../common/badges';
import { ItemEmetter } from '../dragAndDropItems';
import { MAX_SHOW_SNIPPETS_PER_SECTION } from '../utils/constants';
import { collectModules, sendToModules, actions } from '../modules';
import { DragAndDropContext } from '../utils/helper';

export function ConfigEditorList({ title, list, q, loading }) {
    const [showAll, setShowAll] = useState(false);
    const { showTextEditor, isTextEditorMode } = useContext(DragAndDropContext);
    let items = list
        .map((item, i) => {
            item.key = `${item.label}-${i}`;
            return item;
        })
        .filter((item) =>
            `${item.label} ${item.moduleName}`.toLowerCase().includes(`${q}`.toLowerCase())
        );
    const showLoadMoreBtn = items.length > MAX_SHOW_SNIPPETS_PER_SECTION && !showAll;
    if (showLoadMoreBtn) {
        items = [...items].slice(0, MAX_SHOW_SNIPPETS_PER_SECTION - 1);
    }
    return (
        <div className="ConfigEditorList">
            {title ? <b>{title}</b> : null}
            <div className="ConfigEditorList-list" data-testid="ConfigEditorList-list">
                {loading ? (
                    <div data-testid="loader">
                        <Loader />
                    </div>
                ) : (
                    items.map((item) => (
                        <ItemEmetter
                            disabled={
                                showTextEditor ||
                                isTextEditorMode ||
                                (item.type === 'sp' && !item.purchased)
                            }
                            key={item.key}
                            data={item}
                            testid={item.name}
                        >
                            <span>{item.label}</span>
                            {item.type === 'sp' && item.version && (
                                <span className="secondary-text">v{item.version}</span>
                            )}
                            {item.type === 'sp' && <SolutionPackBadge />}
                        </ItemEmetter>
                    ))
                )}
                {showLoadMoreBtn ? (
                    <button
                        disabled={showTextEditor || isTextEditorMode}
                        onClick={() => setShowAll(true)}
                        className="ConfigEditorList-loadmore"
                        data-testid={`${title}-load-more-btn`}
                    >
                        + Load more
                    </button>
                ) : null}
            </div>
        </div>
    );
}

ConfigEditorList.defaultProps = {
    title: '',
    loading: false,
    q: '',
    list: []
};

ConfigEditorList.propTypes = {
    title: propTypes.string,
    loading: propTypes.bool,
    q: propTypes.string,
    list: propTypes.arrayOf(
        propTypes.shape({
            label: propTypes.string
        })
    )
};

export function SnippetsList({ q }) {
    return (
        <>
            <ConfigEditorList title="Collect From" q={q} list={collectModules} />
            <ConfigEditorList title="Process" q={q} list={actions} />
            <ConfigEditorList title="Send to" q={q} list={sendToModules} />
        </>
    );
}

SnippetsList.defaultProps = {
    q: ''
};

SnippetsList.propTypes = {
    q: propTypes.string
};

export function SolutionPacks({ q }) {
    const dispatch = useDispatch();
    const solutionPacks = useSelector(selectSolutionPacks);
    const spLoading = useSelector(selectSolutionPacksLoading);
    const [spList, setSpList] = useState([]);

    useEffect(() => {
        dispatch(getSolutionPacksData());
    }, []);

    useEffect(() => {
        setSpList(
            solutionPacks.map((sp) => {
                const spCopy = JSON.parse(JSON.stringify(sp));
                spCopy.label = spCopy.title ?? spCopy.identifier;
                return spCopy;
            })
        );
    }, [solutionPacks]);

    return !solutionPacks?.length && spLoading ? (
        <LoaderSvg fill="#072341" />
    ) : (
        <ConfigEditorList title="Solution packs" q={q} list={spList} />
    );
}

SolutionPacks.defaultProps = {
    q: ''
};

SolutionPacks.propTypes = {
    q: propTypes.string
};
