import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImFim = {
    label: 'File Integrity Monitoring',
    name: 'im_fim',
    type: 'collect',
    defaultDirectives: 'Module      im_fim',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'File',
            type: 'string',
            defaultValue: [],
            multiple: true,
            required: true
        },
        {
            name: 'Digest',
            type: 'select',
            options: [
                'md2',
                'md5',
                'mdc2',
                'rmd160',
                'sha',
                'sha1',
                'sha224',
                'sha256',
                'sha384',
                'sha512'
            ],
            defaultValue: 'sha1'
        },
        {
            name: 'Exclude',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'ExcludeSize',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'NoEscape',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'Recursive',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'ScanInterval',
            type: 'number',
            defaultValue: 86400
        },
        {
            name: 'FollowSymlinks',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        ...GENERAL_INPUT_DIRECTIVES('im_fim'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
