import classNames from 'classnames';
import React from 'react';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { useFormContext, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Success from '@nxlog/common-ui/dist/components/svgs/success';
import WithTooltip from '@nxlog/common-ui/dist/components/with_tooltip';
import { InfoCircle } from '@nxlog/common-ui/dist/components/svgs';
import InputErrorMessagePopover, { formatMessage } from '../inputErrorMessagePopover';
import './formInput.scss';

function FormInput(props) {
    const {
        label,
        classname,
        required,
        placeholder,
        name,
        type,
        pattern,
        validationProps,
        disabled,
        isSuccess,
        popoverMessage
    } = props;

    const {
        register,
        formState: { errors }
    } = useFormContext();
    const { message } = get(errors, name) || {};
    const hasError = !!get(errors, name);
    const { t } = useTranslation();

    return (
        <div
            data-testid="form-input"
            className={classNames('input-form-container', disabled && 'input-disabled', classname)}
        >
            <div className="input-form-label">
                <label data-testid="form-input-label" htmlFor={name}>
                    {label}
                    {required && (
                        <FontAwesomeIcon data-testid="form-input-asterisk" icon={faAsterisk} />
                    )}
                </label>
                <WithTooltip placement="left" message={t('form_input.host_tooltip_message')}>
                    <InfoCircle />
                </WithTooltip>
            </div>
            <InputErrorMessagePopover message={popoverMessage && message ? message : false}>
                <input
                    data-testid="form-input-control"
                    name={name}
                    disabled={disabled}
                    className={classNames('input-form', hasError && 'input-error')}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register(name, {
                        ...validationProps,
                        required: required && t('form_validation.required'),
                        pattern
                    })}
                    placeholder={placeholder}
                    type={type}
                />
            </InputErrorMessagePopover>
            {isSuccess && (
                <div data-testid="form-input-success" className="success-wrapper">
                    <Success outlined fill="#228D3A" />
                </div>
            )}
            {Boolean(message) && !popoverMessage && (
                <span data-testid="form-input-error" className="error">
                    {formatMessage(message)}
                </span>
            )}
        </div>
    );
}

FormInput.defaultProps = {
    label: '',
    classname: '',
    required: false,
    placeholder: '',
    type: 'text',
    pattern: '',
    disabled: false,
    isSuccess: false,
    popoverMessage: false,
    validationProps: {}
};

FormInput.propTypes = {
    label: propTypes.string,
    classname: propTypes.string,
    popoverMessage: propTypes.bool,
    required: propTypes.bool,
    placeholder: propTypes.string,
    pattern: propTypes.oneOfType([
        propTypes.string,
        propTypes.shape({
            value: propTypes.shape(RegExp),
            message: propTypes.string
        })
    ]),
    // eslint-disable-next-line react/forbid-prop-types
    validationProps: propTypes.object,
    name: propTypes.string.isRequired,
    disabled: propTypes.bool,
    isSuccess: propTypes.bool,
    type: propTypes.string
};

export default FormInput;
