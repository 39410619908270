export const parseSyslogBsd = {
    label: 'Parse Syslog BSD',
    name: 'parse_syslog_bsd',
    type: 'action',
    extension: [
        {
            module: 'xm_syslog',
            content: `<Extension syslog>\n\tModule    xm_syslog\n</Extension>`
        }
    ],
    match: (content) => /^\s*parse_syslog_bsd\s*\(\s*\)\s*;?\s*$/i.test(content),
    inline: true,
    getCode: (action, level) => `${'  '.repeat(level)}syslog->parse_syslog_bsd();`
};

export default null;
