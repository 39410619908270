/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Select } from '@nxlog/common-ui/components';
import CirclePlusIcon from '@nxlog/common-ui/dist/components/svgs/circle-plus';
import CloseIcon from '@nxlog/common-ui/dist/components/svgs/close-icon';
import TrashIcon from '@nxlog/common-ui/dist/components/svgs/trash';
import {
    deleteAutoEnrollRule,
    getAutoEnrollRules,
    saveAutoEnrollRule
} from '../../api/endpoints/agents';
import { MoveIcon } from '../../components/icons';
import Loader from '../../components/common/loader/index';
import { getTemplatesData, selectTemplates } from '../../redux/reducers/templates';
import useEnrollmentAddress from '../../utils/hooks/useEnrollmentAddress';
import { createNotification } from '../../utils/helpers/functions';
import { addNotifications } from '../../redux/reducers/notifications';
import MQLInput from '../../components/mqlEditor/MQLInput';
import './index.scss';

function AutoEnrollPage() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [itemToSelectTemplate, setItemToSelectTemplate] = useState(false);
    const [itemToConfirmDelete, setItemToConfirmDelete] = useState(false);
    const configNames = useSelector(selectTemplates);
    const dispatch = useDispatch();
    const enrollmentAddress = useEnrollmentAddress();

    useEffect(() => {
        dispatch(getTemplatesData({ fields: 'id,name,tags' }));
    }, []);

    const pushNotification = (type, message) => {
        const notification = createNotification(type, message);
        dispatch(addNotifications(notification));
    };

    const handleErrors = (error) => {
        pushNotification('error', error?.message || 'There was an error processing your request.');
    };

    const loadData = () => {
        setLoading(true);
        getAutoEnrollRules()
            .then(({ data }) => (Array.isArray(data) ? data : []))
            .then((data) => data.sort((a, b) => b.priority - a.priority))
            .then((data) => setItems(data))
            .catch(handleErrors)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const updatedItems = Array.from(items);
        updatedItems.splice(
            result.destination.index,
            0,
            updatedItems.splice(result.source.index, 1)[0]
        );
        setItems(updatedItems);
    };

    const addRule = () => setItems((list) => [...list, { tmpId: `id-${Date.now()}` }]);

    const onSave = () => {
        items.forEach((item) => item.resetErrors());
        if (!items.map((item) => item.validation()).includes(false)) {
            Promise.allSettled(items.map((item) => item.save())).then((results) => {
                const hasFailure = results.some((result) => result.status === 'rejected');
                if (hasFailure) return;
                pushNotification('success', 'Changes saved successfully');
                loadData();
            });
        }
    };

    const templates = Array.isArray(configNames)
        ? configNames.map(({ id, name }) => ({ value: id, label: name }))
        : [];
    const templatesNames = Object.fromEntries(templates.map(({ value, label }) => [value, label]));

    const initItem = (item, i) => {
        if (!item.$original) {
            item.$original = JSON.parse(JSON.stringify(item));
        }

        const getParsedOptionsJson = () => {
            try {
                return JSON.parse(item.optionsJson) || {};
            } catch (e) {
                return {};
            }
        };

        item.set = (data = {}) => {
            Object.assign(item, data);
            setItems([...items]);
        };
        item.automatic = true;
        item.priority = (items.length - i) * 10;
        item.optionsJson ??= JSON.stringify({
            connection: {
                mode: 'connect',
                address: enrollmentAddress
            }
        });

        const parsedOptions = getParsedOptionsJson(item.optionsJson);
        item.selectedTemplate =
            templatesNames[parsedOptions.templateId || parsedOptions['config-id']] ?? null;
        item.errors ??= [];
        item.bind = (name, className) => ({
            value: item[name] || '',
            'data-testid': `${name}-${item.id || item.tmpId}`,
            className: `${className || ''} ${item.errors.includes(name) ? 'has-error' : ''}`.trim(),
            onChange: (e) => {
                item[name] = e.target.value || '';
                item.errors = item.errors.filter((n) => n !== name);
                item.set();
            }
        });
        item.validation = () => {
            item.errors = ['name', 'selector'].filter((name) => !item[name]);
            item.set();
            return item.errors.length === 0;
        };
        item.resetErrors = () => {
            item.errors = [];
            item.set();
        };
        item.getChangedFields = () =>
            ['name', 'comment', 'automatic', 'priority', 'selector', 'optionsJson'].filter(
                (field) => item[field] !== item.$original[field]
            );
        item.save = () => {
            let changedFields = item.getChangedFields();
            if (changedFields.length) {
                if (!item.id && changedFields.includes('optionsJson')) {
                    item.options = JSON.parse(item.optionsJson);
                    changedFields = changedFields.map((field) =>
                        field === 'optionsJson' ? 'options' : field
                    );
                    item.set();
                }
                return saveAutoEnrollRule(_.pick(item, ['id', ...changedFields]))
                    .then(({ data }) => {
                        if (data && typeof data === 'string') {
                            item.id = data;
                            item.set();
                        }
                    })
                    .catch((error) => {
                        if (error && error.message && typeof error.message === 'string') {
                            if (error.message.includes('MQL selector provided')) {
                                item.errors = ['selector'];
                                item.set();
                            }
                        }
                        handleErrors(error);
                        throw new Error(error);
                    });
            }
            return Promise.resolve(true);
        };
        item.onSelectTemplate = () => {
            item.template = templates.find(
                ({ value }) => value === getParsedOptionsJson(item.optionsJson).templateId
            );
            item.set();
            setItemToSelectTemplate(item);
        };
        item.addTemplate = () => {
            if (item.template && item.template.value) {
                item.set({ optionsJson: JSON.stringify({ 'config-id': item.template.value }) });
            }
            setItemToSelectTemplate(null);
        };
        item.removeTemplate = () => {
            item.optionsJson = JSON.stringify({
                connection: {
                    mode: 'connect',
                    address: enrollmentAddress
                }
            });
            item.set();
        };
        item.delete = () => setItemToConfirmDelete(item);
        item.deleteConfirmed = () => {
            if (item.id) {
                deleteAutoEnrollRule(item.id).then(loadData).catch(handleErrors);
            } else {
                setItems([...items.filter((elm) => elm !== item)]);
            }
            setItemToConfirmDelete(null);
        };
    };

    items.forEach(initItem);
    const somethingChanged =
        items.map((item) => item.getChangedFields()).filter((v) => v.length > 0).length > 0;

    return (
        <div className="auto-enroll-page">
            {loading && (
                <div className="auto-enroll-loader">
                    <Loader />
                </div>
            )}
            <div className="auto-enroll-header">
                <h1>Auto-Enroll Agents</h1>
                <span />
                <Button onClick={onSave} disabled={!somethingChanged} data-testid="save">
                    Save
                </Button>
                <Button onClick={addRule} data-testid="add-rule">
                    {' '}
                    <CirclePlusIcon /> Add Rule
                </Button>
            </div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="items">
                    {(provided) => (
                        <div className="auto-enroll-list">
                            <div className="auto-enroll-numbers">
                                {items.map(({ id, tmpId }, index) => (
                                    <div key={id || tmpId}>
                                        <span>{index + 1}</span>
                                    </div>
                                ))}
                            </div>
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="auto-enroll-items"
                            >
                                {items.map((item, index) => (
                                    <Draggable
                                        key={item.id || item.tmpId}
                                        draggableId={item.id || item.tmpId}
                                        index={index}
                                    >
                                        {(args, snapshot) => (
                                            <div
                                                ref={args.innerRef}
                                                {...args.draggableProps}
                                                {...args.dragHandleProps}
                                                className={`auto-enroll-item${
                                                    snapshot.isDragging ? ' is-dragging' : ''
                                                }`}
                                                data-testid={item.id || item.tmpId}
                                            >
                                                <MoveIcon />
                                                <div>
                                                    <MQLInput
                                                        {...item.bind(
                                                            'selector',
                                                            'auto-enroll-field'
                                                        )}
                                                        placeholder="Selector"
                                                    />
                                                    <input
                                                        {...item.bind('name', 'auto-enroll-field')}
                                                        placeholder="Filter name"
                                                    />
                                                    <input
                                                        {...item.bind(
                                                            'comment',
                                                            'auto-enroll-field'
                                                        )}
                                                        placeholder="Comment"
                                                    />
                                                    <button
                                                        className="auto-enroll-btn1"
                                                        onClick={item.onSelectTemplate}
                                                        data-testid={`select-tpl-${
                                                            item.id || item.tmpId
                                                        }`}
                                                    >
                                                        Select template
                                                    </button>
                                                    <span className="auto-enroll-no-tpl">
                                                        {item.selectedTemplate ? (
                                                            <span>
                                                                <span>{item.selectedTemplate}</span>
                                                                <button
                                                                    className="auto-enroll-remove-tpl"
                                                                    aria-label="delete template"
                                                                    onClick={item.removeTemplate}
                                                                    data-testid={`remove-tpl-${
                                                                        item.id || item.tmpId
                                                                    }`}
                                                                >
                                                                    <CloseIcon />
                                                                </button>
                                                            </span>
                                                        ) : (
                                                            'No template selected'
                                                        )}
                                                    </span>
                                                </div>
                                                <button
                                                    className="auto-enroll-btn"
                                                    data-testid={`btn-del-${item.id || item.tmpId}`}
                                                    onClick={item.delete}
                                                    aria-label="delete rule"
                                                >
                                                    <TrashIcon fill="#E03520" />
                                                </button>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Modal
                isShown={!!itemToSelectTemplate}
                onClose={() => setItemToSelectTemplate(false)}
                title={<h2 className="auto-enroll-select-template-title">Select Template</h2>}
            >
                <div className="auto-enroll-select">
                    <span>Select config template</span>
                    <Select
                        dropdownOptions={templates}
                        placeholder="Please select one"
                        defaultValue={itemToSelectTemplate?.template}
                        onClick={(template) => itemToSelectTemplate.set({ template })}
                    />
                </div>
                <div className="auto-enroll-modal-actions">
                    <Button ghostBtn onClick={() => setItemToSelectTemplate(false)}>
                        Cancel
                    </Button>
                    <Button onClick={itemToSelectTemplate?.addTemplate}>Add template</Button>
                </div>
            </Modal>
            <Modal
                isShown={!!itemToConfirmDelete}
                onClose={() => setItemToConfirmDelete(false)}
                title={<h2 className="auto-enroll-modal-title">Delete rule?</h2>}
            >
                <p>Are you sure you want to delete this rule?</p>
                <div className="auto-enroll-modal-actions">
                    <Button ghostBtn onClick={() => setItemToConfirmDelete(false)}>
                        Cancel
                    </Button>
                    <Button
                        className="auto-enroll-btn-danger"
                        onClick={itemToConfirmDelete?.deleteConfirmed}
                    >
                        Delete rule
                    </Button>
                </div>
            </Modal>
            {items.length === 0 && !loading ? (
                <div className="auto-enroll-empty">
                    <b>No Rules Added</b>
                    <small>Please add new rule by selecting &quot;Add Rule&quot; button</small>
                </div>
            ) : null}
        </div>
    );
}

export default AutoEnrollPage;
