export const toCef = {
    label: 'Convert to CEF',
    name: 'to_cef',
    type: 'action',
    extension: [
        {
            module: 'xm_cef',
            content: `<Extension cef>\n\tModule    xm_cef\n</Extension>`
        }
    ],
    match: (content) => /^\s*to_cef\s*\(\s*\)\s*;?\s*$/i.test(content),
    inline: true,
    getCode: (action, level) => `${'  '.repeat(level)}to_cef();`
};

export default null;
