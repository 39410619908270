import { useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Button, Modal } from '@nxlog/common-ui/components';
import { MINDER_SUBDOMAIN } from '@nxlog/common-ui/dist/app/config';
import { Loader } from '@nxlog/common-ui/dist/components/svgs';
import { useDispatch } from 'react-redux';
import { DragAndDropContext } from '../utils/helper';
import { renderSolutionPack } from '../../../api/endpoints/solutionPacks';
import { addNotifications } from '../../../redux/reducers/notifications';
import { createNotification } from '../../../utils/helpers/functions';
import { TOAST_TYPES } from '../../../utils/constants/ids';
import { getBuilderDataFromText } from '../utils/parser';
import spParamConditionCheck, {
    trimStringsAndArrays,
    reorderSpParams,
    getAgentResources
} from '../utils/spParamHelpers';
import { ParamInput } from './paramInput';
import { SolutionPackFileList } from './solutionPackFileList';
import './solutionPackForm.scss';

// on submit form sends required params to render SP template
// then invokes afterSubmit function with parsed SP as a prop
function SolutionPackForm({ afterSubmit }) {
    const { spInputs, spFiles, spManifest, selectedSP, clearSelectedSP } =
        useContext(DragAndDropContext);

    const [formState, setFormState] = useState({});
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedSP?.paramValues) {
            setFormState(selectedSP.paramValues);
        }
    }, [selectedSP, setFormState]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const agentResources = getAgentResources(spManifest.agent_resource_paths, formState);
            const formBody = Object.entries(formState).reduce((bodyObject, [key, value]) => {
                bodyObject[`params_${key}`] = value;
                return bodyObject;
            }, {});
            trimStringsAndArrays(formBody);
            /**
             * TODO: minimize sending props to the defined minimum.
             * The set of params to send should be defined by SP team,
             * until then form sends params with all the manifest props
             */
            const body = {
                ...formBody,
                ...spManifest,
                ...spManifest.agent_resource_paths,
                namespace_prefix_var: MINDER_SUBDOMAIN
            };
            const spTextTemplate = await renderSolutionPack({
                identifier: selectedSP?.identifier,
                version: selectedSP?.version,
                body
            });
            const spBuilderData = getBuilderDataFromText(spTextTemplate);
            afterSubmit({
                spData: spBuilderData,
                spName: `${selectedSP?.title ?? 'SP'} ${
                    selectedSP?.version ? `v${selectedSP?.version}` : ''
                }`,
                identifier: selectedSP?.identifier,
                version: selectedSP?.version,
                content: spTextTemplate,
                paramValues: formState,
                agentResources
            });
            setFormState({});
            clearSelectedSP();
        } catch (error) {
            const errorNote = createNotification(
                TOAST_TYPES.ERROR,
                'Solution pack error',
                error.message
            );
            dispatch(addNotifications(errorNote));
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setFormState({});
        clearSelectedSP();
    };

    return (
        <Modal
            isShown={!!spInputs}
            onClose={handleCancel}
            className="solution-pack-form"
            title={null}
        >
            <div className="form-wrapper">
                <form onSubmit={handleSubmit} data-testid="sp-form">
                    <h2 className="form-heading">
                        {selectedSP?.title || 'The solution pack parameters'}
                    </h2>
                    <h4 className="version-tag">Version: {selectedSP?.version || ''}</h4>
                    {selectedSP?.description && (
                        <p className="description">{selectedSP.description}</p>
                    )}
                    <h4 className="data-title">Add Needed Information</h4>
                    <div className="data-wrapper">
                        {spInputs &&
                            reorderSpParams(spInputs).map(([propName, inp]) => (
                                <ParamInput
                                    key={propName}
                                    paramName={propName}
                                    paramProps={inp}
                                    formState={formState}
                                    setFormState={setFormState}
                                    disabled={!spParamConditionCheck(formState, inp.enabled)}
                                />
                            ))}
                    </div>
                    <div className="button-block">
                        <Button ghostBtn type="button" onClick={handleCancel} testid="cancel-btn">
                            Cancel
                        </Button>
                        <Button type="submit" disabled={loading}>
                            Continue
                        </Button>
                    </div>
                </form>
                <SolutionPackFileList spFiles={spFiles} />
            </div>
            {loading && (
                <div className="form-loader">
                    <Loader />
                </div>
            )}
        </Modal>
    );
}

SolutionPackForm.propTypes = {
    afterSubmit: propTypes.func.isRequired
};

export default SolutionPackForm;
