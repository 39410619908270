import { useState, useEffect, useCallback } from 'react';

export const useConditionalFetch = (callback, condition, ...args) => {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = (...fetchArgs) => {
        callback(...fetchArgs)
            .then((res) => {
                setResponse(res.data);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (condition) {
            setLoading(true);
            fetchData(...args);
        }
    }, [condition]);

    const refetch = useCallback(
        (...refetchArgs) => {
            if (!condition) return;
            setLoading(true);
            if (refetchArgs.length) {
                fetchData(...refetchArgs);
            } else {
                fetchData(...args);
            }
        },
        [condition]
    );

    return {
        response,
        loading,
        error,
        refetch
    };
};

const useFetch = (callback, ...args) => useConditionalFetch(callback, true, ...args);

export default useFetch;
