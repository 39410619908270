import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImUdp = {
    label: 'UDP Transport',
    type: 'collect',
    name: 'im_udp',
    defaultDirectives: 'Module      im_udp',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ListenAddr',
            type: 'string',
            required: true,
            requiredInConfigText: true,
            value: '0.0.0.0:1514',
            defaultValue: '0.0.0.0:1514'
        },
        {
            name: 'AllowIP',
            type: 'string',
            multiple: true,
            defaultValue: []
        },
        {
            name: 'BlockIP',
            type: 'string',
            multiple: true,
            defaultValue: []
        },
        {
            name: 'ReusePort',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'ReuseAddr',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'ExclusiveAddrUse',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'SockBufSize',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UseRecvmmsg',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        ...GENERAL_INPUT_DIRECTIVES('im_udp'),
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
