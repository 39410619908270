import {
    ipv4or6Regex,
    noSpacesRegex,
    notBlankRegex,
    portNumberRegex,
    winOrLinuxPathRegex
} from './regex';

export const VALIDATE_ADDRESS_PATTERN = {
    value: noSpacesRegex,
    message: 'should not contain spaces'
};

export const VALIDATE_INTEGER_PATTERN = {
    value: /^(([-+]?\d*))?$/,
    message: 'enter a valid integer number'
};

export const VALIDATE_IP_PATTERN = {
    value: ipv4or6Regex,
    message: 'enter a valid IP address'
};

export const VALIDATE_NO_BLANK_SPACES_PATTERN = {
    value: notBlankRegex,
    message: 'blank space is not valid'
};

export const VALIDATE_NUMBER_PATTERN = {
    value: /^([-+]?([0-9]+(\.[0-9]+)?|\.[0-9]+))?$/,
    message: 'enter a valid number'
};

export const VALIDATE_PATH_PATTERN = {
    value: winOrLinuxPathRegex,
    message: 'enter a valid path format'
};

export const VALIDATE_PORT_PATTERN = {
    value: portNumberRegex,
    message: 'invalid port format'
};
