import propTypes from 'prop-types';
import { Modal } from '@nxlog/common-ui/components';
import { useEffect, useRef, useState } from 'react';
import asciidoctor from 'asciidoctor';
import { getSPFile } from '../../../../api/endpoints/solutionPacks';
import './instructionsModal.scss';

const Asciidoctor = asciidoctor();

function InstructionsModal({ identifier, version, path, onClose }) {
    const [title, setTitle] = useState('Loading...');
    const [content, setContent] = useState('');
    const [trimmedPath, setTrimmedPath] = useState('');

    const contentRef = useRef(null);

    const cleanOnClose = () => {
        setTitle('Loading...');
        setContent('');
        onClose();
    };

    useEffect(() => {
        setTrimmedPath(path);
    }, [path]);

    useEffect(() => {
        const loadInstruction = async () => {
            if (!(identifier && version && trimmedPath)) {
                return;
            }
            if (['.', '/'].includes(trimmedPath[0])) {
                setTrimmedPath((p) => p.slice(1));
                return;
            }
            const response = await getSPFile({ identifier, version, filePath: trimmedPath });
            setTitle(trimmedPath.split('/').pop().split('.')[0].split('_').join(' '));
            setContent(response.data ?? '');
        };

        loadInstruction();
    }, [identifier, version, trimmedPath]);

    useEffect(() => {
        if (!content) {
            return;
        }
        contentRef.current.innerHTML = Asciidoctor.convert(content);
    }, [content, contentRef]);

    return (
        <Modal
            isShown={!!(path && identifier && version)}
            onClose={cleanOnClose}
            className="view-instructions modal-xl"
            title={title}
        >
            <div className="scroll" data-testid="instructions-content">
                <div className="instructions-content" ref={contentRef} />
            </div>
        </Modal>
    );
}

export default InstructionsModal;

InstructionsModal.defaultProps = {
    onClose: () => {},
    identifier: null,
    version: null,
    path: null
};

InstructionsModal.propTypes = {
    onClose: propTypes.func,
    identifier: propTypes.string,
    version: propTypes.string,
    path: propTypes.string
};
