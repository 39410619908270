/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from '@nxlog/common-ui/dist/components/button';
import { DragAndDropContext, structuredClone } from '../utils/helper';
import createRoute from '../utils/createRoute';
import { ItemReceiver } from '../dragAndDropItems';
import RouteEditor from '../routeEditor';
import ConfigTextEditor from '../configTextEditor';
import SolutionPackForm from '../solutionPackForm';

function ConfigEditorItem() {
    const {
        item,
        update,
        showTextEditor,
        isTextEditorMode,
        handleCancelClick,
        handleSaveClick,
        handleSaveDraftClick,
        errors,
        bind,
        setSelectedSP
    } = useContext(DragAndDropContext);

    const handleCreateNewRoute = () => {
        item.routes.push(createRoute());
        update();
    };

    const handleDelete = (route) => () => {
        item.routes = item.routes.filter((elm) => elm !== route);
        update();
    };

    const handleSolutionPackDrop = (sp) => {
        setSelectedSP(sp);
    };

    const afterSPRender = ({
        spData,
        spName,
        identifier,
        version,
        content,
        paramValues,
        agentResources
    }) => {
        const solutionPack = {
            identifier,
            version,
            content,
            name: spName,
            routes: [],
            paramValues,
            agentResources
        };
        spData.routes?.forEach((route) => {
            const newRoute = structuredClone(route);
            newRoute.key = uuidv4();
            newRoute.collect = newRoute.collect.map((mdl) => {
                if (mdl.module) mdl.module.sp = true;
                return mdl;
            });
            newRoute.sendTo = newRoute.sendTo.map((mdl) => {
                if (mdl.module) mdl.module.sp = true;
                return mdl;
            });
            solutionPack.routes.push(newRoute);
        });
        item.name = item.name || spName;
        item.comment = item.comment || `Created on ${new Date().toISOString()}`;
        if (item.solutionPacks) {
            // if SP with this id is in the template, then substitute it,
            // this saves templateId from the old solution pack object
            if (item.solutionPacks[identifier]) {
                item.solutionPacks[identifier] = {
                    ...item.solutionPacks[identifier],
                    ...solutionPack
                };
            } else {
                item.solutionPacks = { [identifier]: solutionPack, ...item.solutionPacks };
            }
        } else {
            item.solutionPacks = { [identifier]: solutionPack };
        }

        update();
    };

    let hideControls = false;
    if (item?.solutionPacks && Object.values(item?.solutionPacks).length) {
        hideControls = !!Object.values(item?.solutionPacks).find(
            (sPack) => Array.isArray(sPack?.routes) && sPack?.routes.length
        );
    }

    return (
        <>
            <ItemReceiver
                key="config-editor-item"
                className="config-editor-item"
                testid="config-editor-item"
                hiddenReceiver
                type="sp"
                onDrop={handleSolutionPackDrop}
            >
                <div className="ConfigEditorItem">
                    <section className="ConfigEditorSection">
                        <b>Configuration Information</b>
                        <div className="configinfos">
                            <div className="input-form-container">
                                <input
                                    className={`input-form template-name ${
                                        errors?.name ? 'has-error' : ''
                                    }`}
                                    placeholder="Name"
                                    {...bind(item, 'name')}
                                />
                                {errors?.name && (
                                    <span className="error-message">{errors.name}</span>
                                )}
                            </div>
                            <div className="input-form-container">
                                <input
                                    className="input-form"
                                    placeholder="Comment"
                                    {...bind(item, 'comment')}
                                />
                            </div>
                        </div>
                    </section>
                    {showTextEditor || isTextEditorMode ? (
                        <ConfigTextEditor readOnly={!isTextEditorMode} />
                    ) : (
                        <>
                            {item.solutionPacks &&
                                Object.values(item.solutionPacks).map((sPack) =>
                                    sPack.routes?.map((route, i) => (
                                        <RouteEditor
                                            key={route.key}
                                            route={route}
                                            routeNumber={i + 1}
                                            update={update}
                                            onDelete={() => {}}
                                            solutionPack={sPack}
                                        />
                                    ))
                                )}
                            {item.routes.map((route, i) => (
                                <RouteEditor
                                    key={route.key}
                                    route={route}
                                    routeNumber={i + 1}
                                    update={update}
                                    onDelete={handleDelete(route)}
                                    hideControls={hideControls}
                                />
                            ))}
                            <b>Route #{item.routes.length + 1}</b>
                            <button
                                className="add-new-route"
                                data-testid="add-new-route"
                                onClick={handleCreateNewRoute}
                            >
                                + Add another route
                            </button>
                        </>
                    )}
                    <div className="main-actions">
                        <Button ghostBtn onClick={handleCancelClick}>
                            Cancel
                        </Button>
                        {item.isUsed ? null : (
                            <Button ghostBtn onClick={handleSaveDraftClick}>
                                <span data-testid="btn:draft">Save Draft</span>
                            </Button>
                        )}
                        <Button onClick={handleSaveClick}>
                            <span data-testid="btn:save">Save Configuration</span>
                        </Button>
                    </div>
                </div>
            </ItemReceiver>
            <SolutionPackForm afterSubmit={afterSPRender} />
        </>
    );
}

export default ConfigEditorItem;
