/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

function AutocompleteList({
    cursorPosition,
    autocomleteListVisible,
    filteredAutoCompleteList,
    selectedIndex,
    handleClickItem
}) {
    return (
        <div
            className="mql-editor-autocomplete"
            style={{
                ...cursorPosition,
                display: autocomleteListVisible ? 'block' : 'none'
            }}
        >
            {filteredAutoCompleteList.map((item) => (
                <div
                    className={`mql-editor-autocomplete-item ${
                        selectedIndex === filteredAutoCompleteList.indexOf(item) ? 'selected' : ''
                    }`}
                    key={item}
                    onClick={handleClickItem(item)}
                >
                    {item}
                </div>
            ))}
        </div>
    );
}
AutocompleteList.defaultProps = {
    cursorPosition: {},
    autocomleteListVisible: false,
    filteredAutoCompleteList: [],
    selectedIndex: 0,
    handleClickItem: () => {}
};

AutocompleteList.propTypes = {
    cursorPosition: PropTypes.object,
    autocomleteListVisible: PropTypes.bool,
    filteredAutoCompleteList: PropTypes.array,
    selectedIndex: PropTypes.number,
    handleClickItem: PropTypes.func
};

export default AutocompleteList;
