import classNames from 'classnames';
import React from 'react';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { useFormContext } from 'react-hook-form';
import './formTextarea.scss';

function FormTextarea(props) {
    const { label, classname, required, placeholder, name } = props;

    const { register } = useFormContext();

    return (
        <div
            data-testid="form-textarea"
            className={classNames('textarea-form-container', classname)}
        >
            <label data-testid="form-textarea-label" htmlFor={name}>
                {label}
                {required && (
                    <FontAwesomeIcon data-testid="form-textarea-asterisk" icon={faAsterisk} />
                )}
            </label>
            <textarea
                data-testid="form-textarea-control"
                name={name}
                className="textarea-form"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register(name, { required })}
                placeholder={placeholder}
            />
        </div>
    );
}

FormTextarea.defaultProps = {
    label: '',
    classname: '',
    required: false,
    placeholder: ''
};

FormTextarea.propTypes = {
    label: propTypes.string,
    classname: propTypes.string,
    required: propTypes.bool,
    placeholder: propTypes.string,
    name: propTypes.string.isRequired
};

export default FormTextarea;
