import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImAzure = {
    label: 'Microsoft Azure',
    name: 'im_azure',
    type: 'collect',
    defaultDirectives: 'Module      im_azure',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Mode',
            type: 'select',
            options: ['Table', 'Blob', 'Analytics'],
            required: true,
            defaultValue: ''
        },
        {
            name: 'SharedKey',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            if: (item) => item.Mode !== 'Analytics',
            name: 'StorageName',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            if: (item) => item.Mode !== 'Blob',
            name: 'TableName',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            if: (item) => item.Mode === 'Blob',
            name: 'BlobName',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'ClientID',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'TenantID',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'WorkspaceID',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'Address',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSAllowExpired',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSAllowUntrusted',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPBasicAuthUser',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPBasicAuthPassword',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSCADir',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSCAFile',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSCAThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSSearchAllCertStores',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSCertFile',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSCertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSCertThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSCRLDir',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSCRLFile',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSDHFile',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSKeyPass',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSRequireCert',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSSSLCompression',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'HTTPSSSLProtocol',
            type: 'select',
            options: ['SSLv2', 'SSLv3', 'TLSv1', 'TLSv1.1', 'TLSv1.2', 'TLSv1.3'],
            multiple: true,
            separator: ', ',
            defaultValue: []
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'LocalPort',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'PollInterval',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'ReadFromLast',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'SavePos',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            if: (item) => item.Mode === 'Analytics',
            name: 'URL',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UseCNGCertificates',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        ...GENERAL_INPUT_DIRECTIVES('im_azure'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
