import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Angle } from '@nxlog/common-ui/dist/components/svgs';
import { DraftBadge, PaidBadge, SolutionPackBadge } from '../../../common/badges';
import ModulesField from '../modulesField';
import ActionsDropdown from '../actionsDropdown';
import { parseModules } from '../../../../utils/helpers/strings';
import { MODULE_LIST, TEMPLATE_TAGS } from '../../../../utils/templates';
import ItemTile from '../itemTile';
import './configurationRow.scss';

export default function ConfigurationRow(props) {
    const modulesLib = MODULE_LIST;

    const { item, onClick, onSelectOption, checked, onCheckedChanged } = props;

    const [rowData, setRowData] = useState(item);
    const [extended, setExtended] = useState(false);

    useEffect(() => {
        if (!item) {
            return;
        }
        const parsedInputs = parseModules(item.content, 'Input');
        const parsedOutputs = parseModules(item.content, 'Output');
        const mapFunction = (oneModule) => {
            const { Module, ...rest } = oneModule;
            if (!Module) return { type: '', name: '' };
            const libModule = modulesLib.find(({ type }) => type === Module);
            const name = libModule?.name || Module;
            return { ...rest, type: Module, name, inUse: item.usedByAgents?.length || 0 };
        };
        const reduceDuplicates = (acc, m) => {
            const existingModule = acc.find((mod) => mod.type === m.type);
            if (existingModule) {
                existingModule.inUse += m.inUse;
                return acc;
            }
            acc.push(m);
            return acc;
        };
        const inputs = parsedInputs.map(mapFunction).reduce(reduceDuplicates, []);
        const outputs = parsedOutputs.map(mapFunction).reduce(reduceDuplicates, []);

        setRowData((data) => ({
            ...data,
            ...item,
            inputs,
            outputs,
            solutionPack:
                !!item.tags?.find((tag) => tag === TEMPLATE_TAGS.solutionPack) ||
                rowData.solutionPackKeys?.length > 0,
            paid: !!item.tags?.find((tag) => tag === TEMPLATE_TAGS.paid),
            draft: !!item.tags?.find((tag) => tag === TEMPLATE_TAGS.draft)
        }));
    }, [item]);

    return (
        <div
            data-testid="configuration-row-main"
            className={classNames('config-row-wrapper', extended && 'extended')}
        >
            <div className="basic-view">
                <Form.Check
                    className="configuration-row-checkbox"
                    onChange={onCheckedChanged}
                    checked={checked}
                />
                <div className="column-name">
                    <span className="cell-label">Name</span>
                    <button
                        className="btn-name"
                        data-testid="configuration-row-click-name"
                        onClick={onClick}
                    >
                        <span className="config-name">{rowData.name}</span>{' '}
                        <span className="assigned-value">
                            ({rowData.usedByAgents?.length || '0'})
                        </span>
                        <div className="name-badges">
                            {rowData.solutionPack && <SolutionPackBadge />}
                            {rowData.paid && <PaidBadge />}
                            {rowData.draft && <DraftBadge />}
                        </div>
                    </button>
                </div>
                {!extended && (
                    <>
                        <div className="column-inputs">
                            <span className="cell-label">Collect from</span>
                            <ModulesField
                                modules={rowData.inputs || undefined}
                                withCount
                                label="Collect from"
                            />
                        </div>
                        <div className="column-outputs">
                            <span className="cell-label">Send to</span>
                            <ModulesField
                                modules={rowData.outputs || undefined}
                                label="Collect to"
                            />
                        </div>
                    </>
                )}
                <div data-testid="configuration-row-actions" className="column-actions">
                    <button
                        data-testid="configuration-row-expand"
                        className="action-extend"
                        onClick={() => setExtended((ext) => !ext)}
                    >
                        {extended ? <Angle.Up /> : <Angle.Down />}
                    </button>
                    <ActionsDropdown onSelectOption={onSelectOption} rowData={rowData} />
                </div>
            </div>
            {extended && (
                <div className="extended-view">
                    <div className="extended-cell">
                        <span className="cell-label" aria-label="Label" />
                        <ItemTile
                            heading="Agents"
                            value={rowData.usedByAgents?.length || '0'}
                            label="Usage"
                        />
                    </div>
                    <div className="extended-cell">
                        <span className="cell-label">Collect from</span>
                        {rowData.inputs?.map((inp, i) => (
                            <ItemTile
                                // eslint-disable-next-line react/no-array-index-key
                                key={inp.type + i}
                                heading={inp.name || inp.type}
                                value={inp.connections || inp.inUse}
                                label={inp.connections ? 'Sources' : 'Usage'}
                            />
                        ))}
                    </div>
                    <div className="extended-cell">
                        <span className="cell-label">Send to</span>
                        {rowData.outputs?.map((outp, i) => (
                            <ItemTile
                                // eslint-disable-next-line react/no-array-index-key
                                key={outp.type + i}
                                heading="Send to"
                                value={outp.name || outp.type}
                                // TODO: additional rules must be defined
                                label={outp.File ? outp.File : outp.Host}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

ConfigurationRow.defaultProps = {
    item: {},
    onClick: () => {},
    onSelectOption: () => {},
    onCheckedChanged: () => {},
    checked: false
};

ConfigurationRow.propTypes = {
    item: propTypes.shape({
        id: propTypes.string,
        name: propTypes.string,
        content: propTypes.string,
        lastModification: propTypes.string,
        tags: propTypes.arrayOf(propTypes.string),
        usedByAgents: propTypes.arrayOf(propTypes.string),
        usedByEnrollRules: propTypes.arrayOf(propTypes.string),
        usedByTemplates: propTypes.arrayOf(propTypes.string),
        inputs: propTypes.arrayOf(propTypes.string),
        outputs: propTypes.arrayOf(propTypes.string)
    }),
    onClick: propTypes.func,
    onSelectOption: propTypes.func,
    onCheckedChanged: propTypes.func,
    checked: propTypes.bool
};
