import React, { useState } from 'react';
import { Button, Input } from '@nxlog/common-ui/components';
import { useSelector, useDispatch } from 'react-redux';
import { useToggle } from '@nxlog/common-ui/hooks';
import CirclePlusIcon from '@nxlog/common-ui/dist/components/svgs/circle-plus';
import { selectFilteredAgentsCount, selectPaginatedAgents } from '../../redux/reducers/agents';
import Loader from '../../components/common/loader';
import timeOut from '../../utils/helpers/functions';
import AssignLicensesSideBar from './assignLicenses';
import {
    getEntitlementsData,
    getModulesData,
    selectLicensesLimit,
    selectLicensesAmount
} from '../../redux/reducers/licenses';
import TableAgentsWithLicenses from '../../components/tables/tableAgentsWithLicenses';
import DataSources from '../../components/dataSources';
import useRepeatWhenVisible from '../../utils/hooks/useRepeatWhenVisible';
import './licenses.scss';

export default function LicensesPage() {
    const dispatch = useDispatch();
    const filteredAgentsCount = useSelector(selectFilteredAgentsCount);
    const paginatedAgents = useSelector(selectPaginatedAgents);
    const [isSidebarOpen, toggleSidebarOpen] = useToggle(false);
    const totalNumberOfUsedAgents = useSelector(selectLicensesAmount);
    const limitNumberOfAgents = useSelector(selectLicensesLimit);

    const [currentPage, setCurrentPage] = useState({ label: 1, value: 1 });
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const getData = () => {
        dispatch(getModulesData());
        dispatch(getEntitlementsData());
    };

    useRepeatWhenVisible(getData, 10000, [], true);

    const filterDataBySearchTerm = (appliedTags) => {
        // eslint-disable-next-line no-console
        console.log(appliedTags);
    };

    timeOut(loading, setLoading, 1000);

    // temporary for hiding static components
    const hideStaticComponents = true;

    return (
        <div className="licenses-page w-100">
            {loading ? (
                <Loader />
            ) : (
                <div className="w-100">
                    <div className="statistics-wrapper">
                        <DataSources
                            title="Total number of log sources"
                            value={totalNumberOfUsedAgents}
                            max={limitNumberOfAgents}
                            available={Math.max(limitNumberOfAgents - totalNumberOfUsedAgents, 0)}
                        />
                    </div>
                    {!hideStaticComponents && (
                        <>
                            <div className="licenses-search">
                                <Input
                                    type="text"
                                    classname="with-search-icon"
                                    placeholder="Search agents"
                                    validateTag={() => true}
                                    filterDataBySearchTerm={filterDataBySearchTerm}
                                    isSearch
                                    tags={tags}
                                    setTags={setTags}
                                />
                                <Button
                                    className="assign-licenses"
                                    icon={<CirclePlusIcon fill="#fff" />}
                                    onClick={toggleSidebarOpen}
                                >
                                    Assign License
                                </Button>
                            </div>
                            <TableAgentsWithLicenses
                                allDataAmount={filteredAgentsCount}
                                tableData={paginatedAgents}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </>
                    )}
                </div>
            )}

            {!hideStaticComponents && (
                <AssignLicensesSideBar isOpen={isSidebarOpen} onToggle={toggleSidebarOpen} />
            )}
        </div>
    );
}
