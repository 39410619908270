export const saveTextFile = (content, fileName) => {
    const text = typeof content === 'object' ? JSON.stringify(content, null, 4) : content;
    const blob = new Blob([text], {
        type: 'text/plain'
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    a.click();
};

export const saveZipFile = (file, fileName) => {
    const blob = new Blob([file], {
        type: 'octet/stream'
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    a.click();
};

// this populates file input with file content
export const populateFileInput = (input, content, name = 'file') => {
    const file = new File([content], name, {
        lastModified: new Date().getTime()
    });
    const container = new DataTransfer();
    container.items.add(file);
    input.files = container.files;
};

export default null;
