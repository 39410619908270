import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from '@nxlog/common-ui/components';
import i18next from 'i18next';
import { addNotifications } from '../../redux/reducers/notifications';
import { formatDateTime, createNotification } from '../../utils/helpers/functions';
import { TOAST_TYPES } from '../../utils/constants/ids';
import InfoMessage from './infoMessage';
import './infoSidebar.scss';

export default function InfoSidebar(props) {
    const dispatch = useDispatch();
    const { children, options, data, agentStatus, title, comment, messages, onSelectOption } =
        props;

    const id = data.find(({ name }) => name === 'ID')?.value;

    const handleSelectOption = (value) => {
        if (agentStatus.enrolled && value === 'enroll') {
            const notification = createNotification(
                TOAST_TYPES.WARNING,
                i18next.t('notifications_messages.warning.enroll', {
                    count: 1
                }),
                i18next.t('notifications_messages.warning.enroll_info', {
                    count: 1
                })
            );
            notification.id = 'enrollAgent.warning';
            dispatch(addNotifications(notification));
        } else if (!agentStatus.online && value === 'restart') {
            const notification = createNotification(
                TOAST_TYPES.WARNING,
                i18next.t('notifications_messages.warning.restart', {
                    count: 1
                }),
                i18next.t('notifications_messages.warning.restart_info', {
                    count: 1
                })
            );
            notification.id = 'restartAgent.warning';
            dispatch(addNotifications(notification));
        } else {
            const hostName = data.find(({ name }) => name === 'Host name')?.value;
            onSelectOption(value, id, hostName);
        }
    };

    return (
        <div className="info">
            <div className="info-title">
                <div data-testid="info-sidebar-title" className="text">
                    {title}
                </div>
                <Dropdown
                    data-testid="info-sidebar-menu"
                    options={options}
                    icon={<FontAwesomeIcon icon={faEllipsis} />}
                    withIcon
                    onClick={handleSelectOption}
                    className="info-dropdown"
                />
            </div>
            <div className="info-content">
                {!!messages?.length &&
                    messages?.map((topic) => <InfoMessage message={topic} key={topic.id} />)}
                {data?.map((item) => {
                    if (item.name === 'Started' || item.name === 'Agent time') {
                        return (
                            <div
                                key={item.name}
                                data-testid="info-sidebar-data"
                                className="info-content-row"
                            >
                                <p
                                    data-testid="info-sidebar-data-name"
                                    className="info-content-row-name"
                                >
                                    {item.name}:
                                </p>
                                <p
                                    data-testid="info-sidebar-data-value"
                                    className="info-content-row-value"
                                >
                                    {formatDateTime(item.value)}
                                </p>
                            </div>
                        );
                    }
                    return (
                        <div
                            key={item.name}
                            data-testid="info-sidebar-data"
                            className="info-content-row"
                        >
                            <p
                                data-testid="info-sidebar-data-name"
                                className="info-content-row-name"
                            >
                                {item.name}:
                            </p>
                            <p
                                data-testid="info-sidebar-data-value"
                                className="info-content-row-value"
                            >
                                {item.value}
                            </p>
                        </div>
                    );
                })}
                {comment && (
                    <div className="info-content-comment">
                        <p className="info-content-comment-title">Comment</p>
                        <p data-testid="info-sidebar-comment" className="info-content-comment-text">
                            {comment}
                        </p>
                    </div>
                )}
                {children}
            </div>
        </div>
    );
}

InfoSidebar.defaultProps = {
    title: 'Agent details',
    options: null,
    comment: null,
    messages: [],
    children: null,
    onSelectOption: () => {}
};

InfoSidebar.propTypes = {
    title: propTypes.string,
    comment: propTypes.string,
    messages: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.string.isRequired,
            type: propTypes.string.isRequired,
            message: propTypes.string.isRequired
        })
    ),
    data: propTypes.arrayOf(
        propTypes.shape({
            value: propTypes.oneOfType([propTypes.number, propTypes.string]),
            name: propTypes.string
        })
    ).isRequired,
    agentStatus: propTypes.shape({
        enrolled: propTypes.bool,
        online: propTypes.bool
    }).isRequired,
    options: propTypes.arrayOf(
        propTypes.shape({
            value: propTypes.string.isRequired,
            label: propTypes.string.isRequired,
            icon: propTypes.element,
            classname: propTypes.string
        })
    ),
    onSelectOption: propTypes.func,
    children: propTypes.oneOfType([propTypes.element, propTypes.array])
};
