import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImMaculs = {
    label: 'macOS Unified Logging System (ULS)',
    name: 'im_maculs',
    type: 'collect',
    defaultDirectives: 'Module      im_maculs',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ActiveFiles',
            type: 'number',
            defaultValue: 20
        },
        {
            name: 'CacheSize',
            type: 'string',
            defaultValue: '2M'
        },
        {
            name: 'Caching',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'DirCheckInterval',
            type: 'number',
            defaultValue: 2
        },
        {
            name: 'PollInterval',
            type: 'number',
            defaultValue: 1
        },
        {
            name: 'ReadFromLast',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'PrintDiagnosticErrors',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'SavePos',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'TimeSyncPath',
            type: 'string',
            defaultValue: '/var/db/diagnostics/timesync/'
        },
        {
            name: 'TraceV3Path',
            type: 'string',
            defaultValue: '/var/db/diagnostics/'
        },
        {
            name: 'UUIDTextPath',
            type: 'string',
            defaultValue: '/var/db/uuidtext/'
        },
        ...GENERAL_INPUT_DIRECTIVES('im_maculs'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
