import { orderBy } from 'lodash';
import { clusterTagNormalizer, templateTagNormalizer, notificationTagNormalizer } from '../strings';

export default function sort(
    filterParam,
    sortColumn,
    allData,
    setSortColumn,
    setTableData,
    options = { multiColumnSort: false }
) {
    const data = [...allData];
    const newSortColumn = { ...sortColumn };
    if (newSortColumn[filterParam] === 'asc') newSortColumn[filterParam] = 'desc';
    else newSortColumn[filterParam] = 'asc';

    const sortedTableData = orderBy(data, filterParam, [newSortColumn[filterParam]]);

    if (!options.multiColumnSort) {
        Object.keys(newSortColumn).forEach((key) => {
            if (key !== filterParam) {
                newSortColumn[key] = null;
            }
        });
    }
    setSortColumn({ ...newSortColumn });
    setTableData([...sortedTableData]);
}

export function searchClusterTable(appliedTags, allAgents) {
    appliedTags?.forEach((param) => {
        const paramSplit = param.split(':');
        const tag = clusterTagNormalizer(paramSplit[0]);
        const searchTerm = paramSplit[1];

        if (tag === 'agent_connections' || tag === 'load') {
            allAgents = allAgents.filter((data) => Number(data[tag]) === Number(searchTerm));
        } else {
            allAgents = allAgents.filter((data) =>
                data[tag]
                    ?.toLowerCase()
                    .replace(/\s/g, '')
                    .includes(searchTerm?.toLowerCase().replace(/\s/g, ''))
            );
        }
    });

    return allAgents;
}

export function extractTagComparatorSearchTerm(searchTerm) {
    let comparator;
    const validComparators = [':', '=', '>', '<', '>=', '<='];

    validComparators.forEach((c) => {
        if (searchTerm.indexOf(c) !== -1) {
            comparator = c;
        }
    });

    const [tag, searchText] = searchTerm.split(comparator);
    return { tag: tag?.trim(), comparator: comparator?.trim(), searchText: searchText?.trim() };
}

export function handleNotificationsFilter(appliedTags, setTableData, allNotifications) {
    const tags = {};
    const searchTerms = appliedTags.map((searchTerm) => {
        const { tag, comparator, searchText } = extractTagComparatorSearchTerm(searchTerm);

        if (comparator) tags[searchText] = tag;
        if (!comparator) return tag;

        return searchText;
    });

    const filteredData = allNotifications?.filter((notification) =>
        searchTerms.every((t) => {
            const { additionalInfo, date, message, type } = notification;
            const regex = new RegExp(t, 'i');

            if (tags[t]) {
                switch (notificationTagNormalizer(tags[t])) {
                    case 'additionalinfo':
                        return regex.test(additionalInfo);
                    case 'time':
                        return regex.test(date);
                    case 'message':
                        return regex.test(message);
                    case 'type':
                        return regex.test(type);
                    default:
                        return null;
                }
            }

            return (
                regex.test(additionalInfo) ||
                regex.test(date) ||
                regex.test(message) ||
                regex.test(type)
            );
        })
    );

    setTableData(filteredData || []);
}

export function createAgentsFilterStrFromTags(tags) {
    return tags.join(' and ');
}

export function createTemplatesFilterStrFromTags(tags) {
    let currentSearchTerms = '';
    tags.forEach((searchTerm) => {
        let { tag, comparator, searchText } = extractTagComparatorSearchTerm(searchTerm);
        if (comparator === ':') comparator = '=';
        if (comparator) tag = templateTagNormalizer(tag);
        else {
            searchText = tag;
            tag = 'all';
        }
        searchText = typeof searchText === 'string' ? searchText.toLowerCase() : searchText;
        switch (tag) {
            case 'name':
                searchText = `name like "%${searchText}%"`;
                break;
            case 'content':
                searchText = `content like "%${searchText}%"`;
                break;
            case 'comment':
                searchText = `comment like "%${searchText}%"`;
                break;
            case 'all':
                searchText = `name like "%${searchText}%" or comment like "%${searchText}%" or content like "%${searchText}%"`;
                break;
            default:
                break;
        }
        if (currentSearchTerms.length > 1) {
            currentSearchTerms += ' and ';
            currentSearchTerms += searchText;
        } else currentSearchTerms += searchText;
    });

    return currentSearchTerms;
}

export function handleAgentApiFilter(
    tags,
    resetAndFetchAgents,
    createFilterString,
    appliedFilters = []
) {
    if (appliedFilters.length) {
        resetAndFetchAgents(createFilterString([...appliedFilters]), null, null, tags);
    } else {
        resetAndFetchAgents(null, null, null, tags);
    }
}

export function sortAgentTable(
    filterParam,
    sortColumn,
    allData,
    setSortColumn,
    itemsPerPage,
    updateOrder,
    order
) {
    const combinedData = [].concat(...Object.values(allData));

    combinedData.sort((a, b) => {
        const keyA = a[filterParam] || '';
        const keyB = b[filterParam] || '';

        let sortOrder = 1;
        if (order === 'asc' || (sortColumn[filterParam] === 'asc' && !order)) {
            sortOrder = -1;
        }

        if (filterParam === 'hostname') {
            return keyA.toLowerCase() < keyB.toLowerCase() ? sortOrder : -sortOrder;
        }

        return keyA < keyB ? sortOrder : -sortOrder;
    });

    const pageSize = itemsPerPage;
    const sortedData = {};
    for (let i = 0; i < combinedData.length; i += pageSize) {
        const page = Math.floor(i / pageSize) + 1;
        sortedData[page] = combinedData.slice(i, i + pageSize);
    }

    if (updateOrder) {
        const newSortColumn = { ...sortColumn };
        newSortColumn[filterParam] = newSortColumn[filterParam] === 'asc' ? 'desc' : 'asc';
        setSortColumn(newSortColumn);
    }
    return sortedData;
}
