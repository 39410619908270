import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImKernel = {
    label: 'Kernel',
    type: 'collect',
    name: 'im_kernel',
    defaultDirectives: `Module      im_kernel`,
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'DeviceFile',
            type: 'string',
            defaultValue: '/dev/klog'
        },
        {
            name: 'PollInterval',
            type: 'number',
            defaultValue: 1
        },
        ...GENERAL_INPUT_DIRECTIVES('im_kernel'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
