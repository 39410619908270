import { useCallback } from 'react';

export default function useSelectRow(
    checkedRows,
    setCheckedRows,
    paginatedTableData,
    toggleRunningProcesses = null
) {
    const handleSelectRow = useCallback(
        (ids, reset = false) => {
            let list = reset ? [] : Array.from(checkedRows.values());
            list = list.filter(({ id }) => id !== '*');
            const selectedIds = list.map(({ id }) => id);
            if (ids.includes('*')) {
                setCheckedRows([
                    { id: '*' },
                    ...list.filter((rec) => ids.includes(rec.id)),
                    ...paginatedTableData.filter(
                        (rec) => ids.includes(rec.id) && !selectedIds.includes(rec.id)
                    )
                ]);
            } else {
                const records = [
                    ...list.filter((rec) => ids.includes(rec.id)),
                    ...paginatedTableData.filter(
                        (rec) => ids.includes(rec.id) && !selectedIds.includes(rec.id)
                    )
                ];
                setCheckedRows(records);
                if (toggleRunningProcesses) {
                    toggleRunningProcesses(!!ids.length);
                }
            }
        },
        [paginatedTableData, setCheckedRows, checkedRows, toggleRunningProcesses]
    );

    return handleSelectRow;
}
