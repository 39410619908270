export const parseCsv = {
    label: 'Parse CSV',
    name: 'parse_csv',
    type: 'action',
    extension: [
        {
            module: 'xm_csv',
            content: `<Extension csv>\n\tModule    xm_csv\n</Extension>`
        }
    ],
    match: (content) => /^\s*parse_csv\s*\(\s*\)\s*;?\s*$/i.test(content),
    inline: true,
    getCode: (action, level) => `${'  '.repeat(level)}parse_csv();`
};

export default null;
