import { createContext, useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Loader } from '@nxlog/common-ui/dist/components/svgs';
import { getTenantId } from '../../api/endpoints/auth';
import { getMinderSubDomain, parseBool } from '../../utils/helpers/functions';

const TenantIdContext = createContext();
const onPremise = parseBool(window?.env?.REACT_APP_ON_PREMISE ?? process.env.REACT_APP_ON_PREMISE);

export function useTenantId() {
    const context = useContext(TenantIdContext);
    if (!context && !onPremise) {
        throw new Error('useTenantId must be used within a TenantIdWrapper');
    }
    return context;
}

function getTenantIdFromUrl() {
    if (onPremise) return '';
    const minderSubDomain = getMinderSubDomain();
    const { hostname } = window.location;
    const chunks = hostname.split('.');

    return `${chunks[0]}`.toLowerCase().trim() === minderSubDomain &&
        chunks.length >= 4 &&
        `${chunks[2]}`.toLowerCase().trim() === 'tenant'
        ? `${chunks[1]}`.trim()
        : 'tenant';
}

export function TenantIdWrapper({ children }) {
    const [tenantId, setTenantId] = useState(getTenantIdFromUrl());
    const [loading, setLoading] = useState(!onPremise);
    useEffect(() => {
        if (onPremise) return;
        const fetchTenantId = async () => {
            try {
                const value = await getTenantId();
                if (value && typeof value === 'string' && value.length > 1) {
                    setTenantId(value);
                }
            } catch {
                /* */
            }
            setLoading(false);
        };
        fetchTenantId();
    }, []);

    if (loading) {
        return (
            <div data-testid="redirector-wrapper1" className="redirector-wrapper">
                <Loader />
            </div>
        );
    }

    return <TenantIdContext.Provider value={tenantId}>{children}</TenantIdContext.Provider>;
}

TenantIdWrapper.defaultProps = {
    children: null
};

TenantIdWrapper.propTypes = {
    children: propTypes.node
};
