import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { Button, Select } from '@nxlog/common-ui/components';
import { Offcanvas } from 'react-bootstrap';
import AddIcon from '@nxlog/common-ui/dist/components/svgs/add';
import CircleXIcon from '@nxlog/common-ui/dist/components/svgs/circle-x';
import TableAssignAgents from '../../../components/tables/tableAssignAgents';
import { useCheckedRecords } from '../../../utils/hooks';
import SelectedAgents from '../../../components/selectedAgents';
import { selectPaginatedAgents, selectItemsPerPageValue } from '../../../redux/reducers/agents';
import timeOut from '../../../utils/helpers/functions';
import AgentsSearch from '../../../components/searchAgents';
import { createFilterString } from '../../../utils/helpers/strings';
import useAgentTable from '../../../utils/hooks/useAgentTable';
import useCreateFilters from '../../../utils/hooks/useCreateFilters';
import { AGENT_SELECTOR } from '../../../utils/constants/ids';
import useRepeatWhenVisible from '../../../utils/hooks/useRepeatWhenVisible';
import './assignLicenses.scss';

const options = [
    {
        value: 'template1',
        label: (
            <div data-testid="select-license-option" className="license-info">
                macOS Endpoint security #210426200 -{' '}
                <span className="warning">Expires in 20 days</span>
            </div>
        )
    },
    {
        value: 'template2',
        label: (
            <div data-testid="select-license-option" className="license-info">
                Scada #827375 - <span className="warning">Expires in 20 days</span>
            </div>
        )
    },
    {
        value: 'template3',
        label: (
            <div data-testid="select-license-option" className="license-info">
                IBM Cybersecurity Analyst #3945956 -{' '}
                <span className="error">Expires in 10 days</span>
            </div>
        )
    },
    {
        value: 'template4',
        label: (
            <div data-testid="select-license-option" className="license-info">
                Certified Information Systems Auditor (CISA) #9284BM93 -{' '}
                <span className="warning">Expires in 23 days</span>
            </div>
        )
    },
    {
        value: 'template5',
        label: (
            <div data-testid="select-license-option" className="license-info">
                SIEM #29459284 - <span className="ok">Expires in 29 days</span>
            </div>
        )
    }
];

export default function AssignLicensesSideBar({ isOpen, onToggle }) {
    const itemsPerPageValue = useSelector(selectItemsPerPageValue);
    const paginatedAgents = useSelector(selectPaginatedAgents);
    const [loading, setLoading] = useState(true);
    const [license, setLicense] = useState(null);

    const {
        agents,
        filteredAgentsCount,
        currentPage,
        appliedFilters,
        tags,
        resetFilters,
        setTags,
        resetAndFetchAgents,
        refetchCurrrentPage,
        applyFilter,
        removeFilter,
        handleChangePage
    } = useAgentTable();

    const handleChangeLicense = (option) => {
        setLicense(option);
    };

    const { filters } = useCreateFilters({
        applyFilter,
        removeFilter,
        appliedFilters
    });

    const {
        checkedRecords,
        handleCheckRecord,
        handleCheckAll,
        handleRemoveRecord,
        handleRemoveAll
    } = useCheckedRecords(new Map(), AGENT_SELECTOR);

    useRepeatWhenVisible(
        () => {
            refetchCurrrentPage();
        },
        10000,
        [appliedFilters, itemsPerPageValue, currentPage]
    );

    const handleCloseAssignLicenses = () => {
        handleRemoveAll();
        onToggle();
    };

    const handleAssignLicenses = (selectedAgents) => {
        const ids = selectedAgents.map((agent) => `${agent.id}`);
        const filterStr = ids.length > 1 ? `${AGENT_SELECTOR} in` : `${AGENT_SELECTOR} =`;
        // eslint-disable-next-line no-console
        console.log(filterStr);
        handleCloseAssignLicenses();
    };

    useEffect(() => {
        resetAndFetchAgents(createFilterString(appliedFilters));
    }, [appliedFilters]);

    const handleSendLicenses = () => {
        handleAssignLicenses(Array.from(checkedRecords.values()));
    };

    const selectedAgentsData = useMemo(() => Array.from(checkedRecords.values()), [checkedRecords]);

    useEffect(() => {
        if (agents.length > 0) setLoading(false);
    }, [agents]);

    useEffect(() => {
        resetAndFetchAgents();
    }, [itemsPerPageValue]);

    timeOut(loading, setLoading);

    return (
        <>
            <Offcanvas
                show={isOpen}
                onHide={handleCloseAssignLicenses}
                placement="end"
                className="assign-licenses-page"
            >
                <Offcanvas.Header className="template-sidebar-header">
                    <Offcanvas.Title>
                        <header className="assign-licenses-page-header">
                            <h1>Assign license</h1>
                            <h2>
                                Fanny pack you probably haven&apos;t heard of them chia selfies
                                squid scenester tbh food truck
                            </h2>
                        </header>
                    </Offcanvas.Title>
                    <Button
                        data-testid="assign-licenses-side-bar-close"
                        icon={<CircleXIcon outlined fill="#072341" />}
                        onClick={handleCloseAssignLicenses}
                    />
                </Offcanvas.Header>
                <Offcanvas.Body
                    data-testid="asdasdadasdad"
                    className="template-sidebar-body removePadding"
                >
                    <div className="form-container">
                        <div data-testid="assign-licenses-side-bar-form" className="form">
                            <div className="select-title">Select license</div>
                            <Select
                                title="Select license"
                                classname="assign-licenses-page-select"
                                placeholder="Select license"
                                dropdownOptions={options}
                                onClick={handleChangeLicense}
                                defaultValue={license}
                            />
                            <div className="table-title">Agents</div>
                            <AgentsSearch
                                tags={tags}
                                setTags={setTags}
                                resetFilters={resetFilters}
                                resetAndFetchAgents={resetAndFetchAgents}
                            />
                            <TableAssignAgents
                                appliedFilters={appliedFilters}
                                applyFilter={applyFilter}
                                removeFilter={removeFilter}
                                allDataAmount={filteredAgentsCount}
                                tableData={paginatedAgents}
                                filters={filters}
                                checkedRows={checkedRecords}
                                setCheckedRows={handleCheckRecord}
                                onCheckAll={handleCheckAll}
                                currentPage={currentPage}
                                setCurrentPage={handleChangePage}
                            />
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas
                show={!!selectedAgentsData.length}
                placement="end"
                className="assign-selected-licenses"
            >
                <Offcanvas.Body className="p-0">
                    <SelectedAgents
                        title="Selected agents to assign"
                        data={selectedAgentsData}
                        onRemoveAgent={handleRemoveRecord}
                        onRemoveAll={handleRemoveAll}
                        footer={
                            <div className="selected-licenses-footer">
                                <Button className="cancel" ghostBtn onClick={handleRemoveAll}>
                                    Cancel
                                </Button>
                                <Button
                                    data-testid="assign-licenses-side-bar-assign"
                                    icon={<AddIcon />}
                                    className="save"
                                    onClick={handleSendLicenses}
                                >
                                    Assign license
                                </Button>
                            </div>
                        }
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

AssignLicensesSideBar.defaultProps = {
    isOpen: false,
    onToggle: () => {}
};

AssignLicensesSideBar.propTypes = {
    isOpen: propTypes.bool,
    onToggle: propTypes.func
};
