import React, { useEffect, useState, useMemo } from 'react';
import propTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Button, Modal } from '@nxlog/common-ui/components';
import { WarningCircle } from '@nxlog/common-ui/dist/components/svgs';
import { DragAndDropContext, structuredClone } from './helper';
import createRoute from './createRoute';
import Loader from '../../common/loader/index';
import { CONFIG_EDITOR_ACTION_TYPE } from './constants';
import {
    addTemplate,
    deleteTemplate,
    getTemplatesById,
    updateTemplate
} from '../../../api/endpoints/templates';
import generateConfigCode, { getModuleName, moduleIsCompleted } from './generateConfigCode';
import { createNotification } from '../../../utils/helpers/functions';
import { DEFAULT_IP_ADDRESS, TOAST_TYPES } from '../../../utils/constants/ids';
import { addNotifications } from '../../../redux/reducers/notifications';
import { getBuilderDataFromText } from './parser';
import useEnrollmentAddress from '../../../utils/hooks/useEnrollmentAddress';
import { useSolutionPacks } from './useSolutionPacks';
import { TEMPLATE_TAGS } from '../../../utils/templates';
import { createXmAdminTemplate } from '../../../utils/templates/extensions';
import CodeBlock from './CodeBlock';

export function ConfigBuilderContextProvider({
    children,
    initialItem,
    initialConfigItem,
    ...props
}) {
    const [loading, setLoading] = useState(props.action === CONFIG_EDITOR_ACTION_TYPE.EDIT);
    const [data, setData] = useState(null);
    const [errors, setErrors] = useState({});
    const [showTextEditor, setShowTextEditor] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showExtensionModal, setShowExtensionModal] = useState(false);
    const [item, setItem] = useState({
        routes: [createRoute()],
        extensions: [],
        isDraft: false,
        ...initialItem
    });
    const [configItem, setConfigItem] = useState(initialConfigItem || null);
    const update = () => setItem((elm) => ({ ...elm }));

    if (item) {
        item.update = () => setItem({ ...item });
    }

    const openConfig = (type, elm, sp) => setConfigItem({ type, elm, sp });
    const closeConfig = () => setConfigItem(null);
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const defaultIp = useEnrollmentAddress();

    const bind = (target, name, options = {}) => ({
        list: (target[name] || '').length ? options?.list ?? null : null,
        value: target[name] || '',
        onChange: (e) => {
            target[name] = e.target.value;
            setErrors({});
            update();
        }
    });

    const onModuleChange = (mdl) => {
        item.routes.forEach((route) => {
            [...route.collect, ...route.sendTo].forEach((_mdl) => {
                if (
                    _mdl.module &&
                    mdl.module &&
                    _mdl.module.type === mdl.module.type &&
                    getModuleName(_mdl) === getModuleName(mdl)
                ) {
                    Object.assign(_mdl, mdl);
                }
            });
        });
    };

    const addNewAction = (actions, _action, index = null) => {
        const action = structuredClone(_action);
        action.key = uuidv4();
        if (action.label.includes('If-Else Condition')) {
            action.condition_op = '==';
            action.then = [];
            action.else = [];
        }
        if (typeof index === 'number') {
            actions.splice(index, 0, action);
        } else {
            actions.push(action);
        }
        item.extensions = getBuilderDataFromText(
            generateConfigCode(item, { defaultIp })
        ).extensions;
        update();
    };
    const handleCancelClick = () => {
        navigate(-1);
    };

    const pushNotification = (type, message, additionalInfo) => {
        const notification = createNotification(type, message, additionalInfo);
        dispatch(addNotifications(notification));
    };

    const getAllModules = () =>
        item.routes
            .map((route) => [...route.collect, ...route.sendTo])
            .reduce((acc, list) => [...acc, ...list], [])
            .concat(item.extensions.map((module) => ({ module })))
            .filter((mdl) => mdl.module);

    const generateCode = (buildDataItem) => generateConfigCode(buildDataItem, { defaultIp });

    const savePart = ({ itemPart, content, suffix = '' }) => {
        let savePartPromise = null;
        const payload = {
            name: (item.name || '') + suffix,
            comment: item.comment || '',
            tags: itemPart.tags || []
        };

        payload.content = content ?? itemPart.content ?? generateCode(itemPart);

        /**
         * for the /templates endpoint
         * POST and PATCH have different param names format,
         * POST requires snake_case and PATCH is in camelCase
         */
        if (props.action === CONFIG_EDITOR_ACTION_TYPE.EDIT && itemPart.templateId) {
            const patchPayload = {
                ...payload,
                builderConfig: itemPart.builderConfig,
                solutionPackData: itemPart.solutionPackData
            };
            savePartPromise = updateTemplate(itemPart.templateId, patchPayload).then(
                () => itemPart.templateId
            );
        } else {
            if (itemPart.solutionPackData) {
                payload.solution_pack_data = itemPart.solutionPackData;
            }
            const postPayload = {
                ...payload,
                builder_config: itemPart.builderConfig
            };
            savePartPromise = addTemplate(postPayload).then((res) => res.data);
        }

        return savePartPromise;
    };

    const saveItem = (parts) => {
        let savePromise = null;
        let tags = Array.isArray(item.tags) ? item.tags : [];
        if (item.isDraft && !tags.includes(TEMPLATE_TAGS.draft)) {
            tags.push(TEMPLATE_TAGS.draft);
        } else if (!item.isDraft && tags.includes(TEMPLATE_TAGS.draft)) {
            tags = tags.filter((tag) => tag !== TEMPLATE_TAGS.draft);
        }
        const payload = {
            name: item.name || '',
            comment: item.comment || '',
            tags
        };

        if (parts) {
            payload.parts = parts;
        } else {
            payload.content = generateCode(item);
        }

        if (props.action === CONFIG_EDITOR_ACTION_TYPE.EDIT) {
            savePromise = updateTemplate(item.id || item.templateId, payload);
        } else if (props.action === CONFIG_EDITOR_ACTION_TYPE.NEW) {
            savePromise = addTemplate(payload);
        }

        return savePromise;
    };

    const saveTemplate = (ignoreErrors = false) => {
        // check modules to be completed
        const uncompletedModules = getAllModules().filter((mdl) => !moduleIsCompleted(mdl.module));

        if (uncompletedModules.length) {
            pushNotification(
                TOAST_TYPES.WARNING,
                i18next.t('notifications_messages.warning.uncompleted_modules')
            );
            return;
        }

        if (!item.content) item.content = generateCode(item);
        if (!ignoreErrors) {
            const { errors: configErrors } = getBuilderDataFromText(
                showTextEditor ? item.content : generateCode(item)
            );
            if (Array.isArray(configErrors) && configErrors.length > 0) {
                setShowErrorModal(configErrors);
                return;
            }
        }

        // check config to have name and comment
        if (!`${item.name || ''}`.trim()) {
            setErrors({
                ...errors,
                name: i18next.t('form_validation.required_field', { name: 'configuration name' })
            });
            pushNotification(
                TOAST_TYPES.WARNING,
                i18next.t('notifications_messages.warning.required_field', {
                    name: 'Template name'
                })
            );
            document.querySelector('.template-name')?.focus();
            return;
        }

        if (!`${item.content || ''}`.trim()) {
            pushNotification(
                TOAST_TYPES.WARNING,
                i18next.t('notifications_messages.warning.required_config_text')
            );
            return;
        }

        // check config to have solution packs in it
        const solutionPacks = item.solutionPacks && Object.keys(item.solutionPacks).length > 0;

        if (solutionPacks) {
            item.tags = [TEMPLATE_TAGS.solutionPack];
        }

        const saveItemPromise = solutionPacks
            ? (() => {
                  // if it has solution packs, save as multipart template
                  const partsPromises = [];
                  Object.values(item.solutionPacks).forEach((sPack) => {
                      const {
                          identifier,
                          version,
                          content,
                          routes,
                          templateId,
                          paramValues,
                          agentResources
                      } = sPack;
                      const itemPart = {
                          templateId,
                          content: content ?? generateConfigCode({ routes }),
                          builderConfig: JSON.stringify(paramValues),
                          solutionPackData: {
                              key: { id: identifier, version },
                              agent_resources: agentResources
                          },
                          tags: [TEMPLATE_TAGS.solutionPack]
                      };
                      const newPromise = savePart({ itemPart, suffix: '_SP_part' });
                      partsPromises.push(newPromise);
                  });
                  return Promise.all(partsPromises).then((resArray) => {
                      const partsIds = resArray;
                      const { name, routes, directives, extensions, templateId } = item;
                      const userItem = {
                          name: `${name}_user_part`,
                          comment: `Created on ${new Date().toISOString()}`,
                          routes,
                          directives,
                          extensions,
                          templateId
                      };
                      return savePart({ itemPart: userItem, suffix: '_user_part' })
                          .then((res) => {
                              partsIds.push(res);
                              return saveItem(partsIds, ignoreErrors);
                          })
                          .catch(() => {
                              if (props.action === CONFIG_EDITOR_ACTION_TYPE.NEW) {
                                  Promise.allSettled(partsIds.map((id) => deleteTemplate(id)));
                                  throw new Error(
                                      'Error saving user template, the temporary parts now are being deleted'
                                  );
                              }
                              throw new Error('Error updating template');
                          });
                  });
              })()
            : // if no solution packs, save as simple template
              saveItem(null, ignoreErrors);

        if (saveItemPromise) {
            setLoading(true);
            saveItemPromise
                .then(() => {
                    pushNotification(
                        TOAST_TYPES.SUCCESS,
                        i18next.t('notifications_messages.success.save_template')
                    );
                    navigate(-1);
                })
                .catch((err) => {
                    pushNotification(
                        TOAST_TYPES.WARNING,
                        i18next.t('notifications_messages.errors.save_template'),
                        err?.message?.message || ''
                    );
                })
                .finally(() => setLoading(false));
        }
    };

    const handleSaveClick = () => {
        item.isDraft = false;
        setItem({ ...item });
        return saveTemplate();
    };

    const handleSaveDraftClick = () => {
        item.isDraft = true;
        setItem({ ...item });
        return saveTemplate();
    };

    const handleSaveAnywayClick = () => {
        setShowErrorModal(false);
        return saveTemplate(true);
    };

    const getExtension = (module, createItIfNotExists = false) => {
        let ext = item.extensions.find(
            (extMdl) => `${extMdl.module}`.trim().toLowerCase() === `${module}`.trim().toLowerCase()
        );
        if (!ext) {
            if (createItIfNotExists) {
                if (typeof createItIfNotExists === 'function') {
                    ext = createItIfNotExists();
                    item.extensions.push(ext);
                } else {
                    ext = {
                        module,
                        name: `${module}_1`
                    };
                    item.extensions.push(ext);
                }
                update();
            } else {
                return null;
            }
        }
        if (`${module}`.trim().toLowerCase() === 'xm_admin') {
            const hostField = ext.fields.find((field) => field.name === 'Host');
            if (!hostField.value || !Array.isArray(hostField.value) || !hostField.value.length) {
                const [host, port] = `${defaultIp || DEFAULT_IP_ADDRESS}`
                    .split(':')
                    .map((v) => `${v}`.trim());
                hostField.changed = true;
                hostField.value = [{ value: { host, port } }];
            }
        }
        ext.edit = () => openConfig('extension', { module: ext, actions: [] });
        return ext;
    };

    const getActionsByDependency = (module) =>
        item.routes
            .map((route) =>
                [...route.collect, ...route.sendTo]
                    .map(({ actions }) => actions)
                    .reduce((acc, actions) => [...acc, ...actions], [])
                    .filter(
                        (action) =>
                            Array.isArray(action.extension) &&
                            action.extension.find((ext) => ext.module === module)
                    )
            )
            .reduce((acc, actions) => [...acc, ...actions], []);

    useEffect(() => {
        if (item) {
            item.extensions = Array.isArray(item.extensions) ? item.extensions : [];
            getExtension('xm_admin', () => {
                const [host, port] = `${defaultIp || DEFAULT_IP_ADDRESS}`
                    .split(':')
                    .map((v) => `${v}`.trim());
                const { extensions } = getBuilderDataFromText(
                    createXmAdminTemplate({
                        name: 'admin',
                        host,
                        port: port || '4041',
                        connectType: 'connect'
                    }).replace(/\n\s*\n/g, '\n')
                );
                return extensions[0];
            });
        }
    }, [item, item?.extensions]);

    useEffect(() => {
        if (showTextEditor) {
            item.content = generateCode(item);
            update();
        }
    }, [showTextEditor]);

    const { spInputs, spFiles, spManifest, selectedSP, setSelectedSP, clearSelectedSP } =
        useSolutionPacks();

    const contextState = {
        data,
        setData,
        item,
        update,
        configItem,
        showTextEditor,
        setShowTextEditor,
        generateCode,
        openConfig,
        closeConfig,
        setConfigItem,
        bind,
        errors,
        setErrors,
        addNewAction,
        handleCancelClick,
        handleSaveClick,
        handleSaveDraftClick,
        getAllModules,
        defaultIp,
        spInputs,
        spFiles,
        spManifest,
        selectedSP,
        setSelectedSP,
        clearSelectedSP,
        getExtension,
        getActionsByDependency,
        isSolutionPack: item.solutionPacks && Object.keys(item.solutionPacks).length > 0,
        showExtensionModal,
        setShowExtensionModal,
        onModuleChange
    };

    useEffect(() => {
        if (props.action === CONFIG_EDITOR_ACTION_TYPE.EDIT && params.id) {
            const getAndParseTemplatePart = ({ id }) =>
                getTemplatesById({ id })
                    .then((result) => result.data)
                    .then((res) => {
                        const elm = getBuilderDataFromText(res?.content || '');
                        elm.templateId = res.id;
                        if (res.solutionPackKeys?.length) {
                            const [packId, version] = res.solutionPackKeys[0].split('/');
                            const paramValues = res.builderConfig
                                ? JSON.parse(res.builderConfig)
                                : undefined;
                            return {
                                solutionPacks: {
                                    [packId]: {
                                        ...elm,
                                        identifier: packId,
                                        version,
                                        name: res.name,
                                        comment: res.comment,
                                        paramValues
                                    }
                                }
                            };
                        }
                        return elm;
                    });

            setLoading(true);
            getTemplatesById({ id: params.id })
                .then((result) => result.data)
                .then((result) => {
                    const isUsed = !!(
                        result.usedByAgents?.length ||
                        result.usedByEnrollRules?.length ||
                        result.usedByTemplates?.length
                    );
                    const newItem = {
                        id: result.id,
                        name: result.name,
                        comment: result.comment,
                        isDraft:
                            !isUsed &&
                            Array.isArray(result.tags) &&
                            result.tags.includes(TEMPLATE_TAGS.draft),
                        isUsed
                    };
                    if (result.parts && result.parts.length > 0) {
                        const promiseArr = result.parts.map((id) =>
                            getAndParseTemplatePart({ id })
                        );
                        Promise.all(promiseArr).then((resArr) => {
                            const composedItem = resArr.reduce((itemAcc, res) => {
                                if (res.solutionPacks && itemAcc.solutionPacks) {
                                    res.solutionPacks = {
                                        ...itemAcc.solutionPacks,
                                        ...res.solutionPacks
                                    };
                                }
                                return { ...itemAcc, ...res };
                            }, newItem);
                            setItem(composedItem);
                            setShowTextEditor(false);
                        });
                    } else {
                        const loadedItem = {
                            ...newItem,
                            ...getBuilderDataFromText(result?.content || '')
                        };
                        loadedItem.id = result.id;
                        loadedItem.name = result.name;
                        loadedItem.comment = result.comment;
                        setItem(loadedItem);
                        setShowTextEditor(false);
                    }
                })
                .catch(() => {
                    navigate(-1);
                })
                .finally(() => setLoading(false));
        }
    }, [props.action]);

    const state = useMemo(() => contextState, Object.values(contextState));

    return (
        <DragAndDropContext.Provider value={state}>
            {loading ? <Loader /> : null}
            {children}
            <Modal
                isShown={!!showErrorModal}
                onClose={() => setShowErrorModal(false)}
                title={
                    <div className="config-error-modal">
                        <WarningCircle fill="#E67500" width={24} height={24} />
                        <span>There is an error in your configuration</span>
                    </div>
                }
            >
                <div>
                    <p>Please review it before proceeding.</p>
                    {Array.isArray(showErrorModal) &&
                        showErrorModal.map((error) => (
                            <div key={error.line.join(',')}>
                                <p>{error.message}</p>
                                <CodeBlock
                                    hideCopy
                                    code={item.content
                                        .split('\n')
                                        .filter((_, i) => error.line.includes(i + 1))
                                        .join('\n')}
                                />
                            </div>
                        ))}
                </div>
                <div className="config-error-modal-actions">
                    <Button ghostBtn onClick={() => setShowErrorModal(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleSaveAnywayClick}>Save anyway</Button>
                </div>
            </Modal>
        </DragAndDropContext.Provider>
    );
}

ConfigBuilderContextProvider.defaultProps = {
    children: null,
    initialItem: null,
    initialConfigItem: null,
    action: 'new'
};

ConfigBuilderContextProvider.propTypes = {
    children: propTypes.node,
    initialItem: propTypes.shape({}),
    initialConfigItem: propTypes.shape({}),
    action: propTypes.string
};

export default null;
