import { isEqual } from 'lodash';

// 10000 - 9850 = 150 ms -- is a time for request to get response
const CACHE_TIMEOUT = 9850;
export const cacheStack = [];

const clearStack = (stack, deadline) => {
    for (let i = 0; stack[i]?.time < deadline; i += 1) stack.shift();
};

const clearStackEntirely = (stack) => {
    while (stack.length > 0) stack.pop();
};

// separates params and the endpoint, spreads params to the object
const separatePathAndParams = (url, baseURL, initialParams) => {
    const { pathname, searchParams } = new URL(url, baseURL);
    const params = {
        ...initialParams,
        ...Array.from(searchParams?.entries()).reduce((obj, [k, v]) => {
            // values go to array as one parameter may appear more than once (see 'filter' in getAgentsMultiCount)
            obj[k] = obj[k] ? [...obj[k], v] : [v];
            return obj;
        }, {})
    };
    return { pathname, params };
};

export const cacheRequestInterceptor = (config) => {
    const { params: initialParams, url, data, method } = config;

    // any data edit operation clears cache entirely
    if (['post', 'put', 'patch', 'delete'].includes(method)) {
        clearStackEntirely(cacheStack);
    }

    if (method !== 'get') {
        return config;
    }

    const time = Date.now();

    const { pathname, params } = separatePathAndParams(url || '', config.baseURL, initialParams);

    const cashed = cacheStack
        .filter((record) => record.url === pathname && time - record.time < CACHE_TIMEOUT)
        .find((record) => isEqual(record.params, params) && isEqual(record.data, data));
    if (cashed) {
        // transforms the response, skips sending request
        config.headers = { ...config.headers, customHeader: 'cached' };
        config.adapter = async () => cashed.response;
    }

    return config;
};

export const cacheResponseInterceptor = (response) => {
    const requestInfo = response.config;

    // this prevents loop
    if (requestInfo?.headers?.customHeader === 'cached') {
        return response;
    }

    // caches get responses only
    if (requestInfo?.method !== 'get') {
        return response;
    }

    const time = Date.now();

    const { pathname, params } = separatePathAndParams(
        requestInfo?.url || '',
        requestInfo?.baseURL,
        requestInfo?.params || {}
    );

    const responseObject = {
        time,
        url: pathname,
        data: requestInfo?.data,
        params,
        response: {
            headers: response.headers,
            status: response.status,
            data: response.data
        }
    };

    // clears stack, if old enough
    clearStack(cacheStack, time - CACHE_TIMEOUT);

    // adds response to the cache array
    cacheStack.push(responseObject);
    return response;
};
