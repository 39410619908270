/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cc from 'classnames';
import { Button } from '@nxlog/common-ui/components';
import { Cog } from '@nxlog/common-ui/dist/components/svgs';
import FormSwitch from '../../common/formSwitch';
import { EditIcon, ExtensionsIcon, IconDelete } from '../utils/Icons';
import RouteEditorModuleList from '../routeEditorModuleList';
import RouteEditorConnector from '../routeEditorConnector';
import { DragAndDropContext } from '../utils/helper';
import { SolutionPackBadge } from '../../common/badges';

function RouteEditor({ route, routeNumber, onDelete, solutionPack, hideControls }) {
    const isSolutionPack = Boolean(solutionPack);
    const { setShowTextEditor, bind, update, setSelectedSP, getExtension, setShowExtensionModal } =
        useContext(DragAndDropContext);
    const [editRouteName, setEditRouteName] = useState(false);
    const ref = useRef(null);

    const handleApplyClick = () => {
        route.name = `${route.name || ''}`.trim().replace(/[^a-zA-Z0-9]+/gi, '_');
        update();
        setEditRouteName(false);
    };

    useEffect(() => {
        if (!route.name && !editRouteName && update) {
            route.name = `${routeNumber}`;
            update();
        }
    }, [route.name, editRouteName]);

    route.collect = route.collect.filter((mdl) => mdl.module || mdl.actions.length);
    route.collect.push({ module: null, actions: [] });
    route.sendTo = route.sendTo.filter((mdl) => mdl.module || mdl.actions.length);
    route.sendTo.push({ module: null, actions: [] });

    return (
        <div className="RouteEditor ConfigEditorSection">
            <div className="ConfigEditorSection-header">
                <div className="ConfigEditorSectionRouteName">
                    {editRouteName ? (
                        <>
                            <b>
                                Route #<input className="RouteNameInput" {...bind(route, 'name')} />
                            </b>
                            <button className="RouteNameSave" onClick={handleApplyClick}>
                                Apply
                            </button>
                        </>
                    ) : (
                        <>
                            <b>Route #{route.name}</b>
                            {!isSolutionPack && <EditIcon onClick={() => setEditRouteName(true)} />}
                        </>
                    )}
                    {isSolutionPack && <SolutionPackBadge />}
                </div>
                {routeNumber === 1 && !hideControls ? (
                    <div className="ConfigEditorSectionRightSection">
                        <div className="ConfigEditorSectionExtensionsListSection" ref={ref}>
                            <Button
                                className="btn-link"
                                icon={<ExtensionsIcon />}
                                onClick={() => setShowExtensionModal(true)}
                            >
                                Extensions
                            </Button>
                        </div>
                        <div className="ConfigEditorSectionExtensionsSection">
                            {getExtension('xm_admin') ? (
                                <Button
                                    className="btn-link"
                                    icon={<Cog />}
                                    onClick={getExtension('xm_admin').edit}
                                >
                                    Agent communication settings
                                </Button>
                            ) : null}
                        </div>
                        <span
                            className={cc({
                                'configuration-text-switch': true,
                                'configuration-text-switch-solution-pack': isSolutionPack
                            })}
                        >
                            <span>View as configuration text</span>
                            <FormSwitch
                                data-testid="config-text-editor-switch-on"
                                onChange={() => setShowTextEditor(true)}
                            />
                        </span>
                    </div>
                ) : (
                    !isSolutionPack && (
                        <IconDelete onClick={onDelete} testid={`route-${routeNumber}-delete-btn`} />
                    )
                )}
            </div>
            <div
                className={cc('RouteEditor-container', isSolutionPack && 'solution-pack-route')}
                data-testid={`route-${routeNumber}${isSolutionPack ? 'SP' : ''}`}
            >
                <RouteEditorModuleList
                    type="collect"
                    list={route.collect}
                    update={update}
                    isSolutionPack={isSolutionPack}
                />
                <RouteEditorConnector route={route} />
                <RouteEditorModuleList
                    type="sendTo"
                    list={route.sendTo}
                    update={update}
                    isSolutionPack={isSolutionPack}
                />
                {isSolutionPack && (
                    <button className="edit-sp-btn" onClick={() => setSelectedSP(solutionPack)}>
                        <Cog />
                        <span className="edit-sp-caption">Configure</span>
                    </button>
                )}
            </div>
        </div>
    );
}

RouteEditor.defaultProps = {
    route: { name: '', collect: [], sendTo: [] },
    update: null,
    onDelete: null,
    routeNumber: 0,
    hideControls: false,
    solutionPack: null
};

RouteEditor.propTypes = {
    route: PropTypes.shape({
        name: PropTypes.string,
        collect: PropTypes.arrayOf(PropTypes.shape()),
        sendTo: PropTypes.arrayOf(PropTypes.shape())
    }),
    update: PropTypes.func,
    onDelete: PropTypes.func,
    routeNumber: PropTypes.number,
    hideControls: PropTypes.bool,
    solutionPack: PropTypes.shape({
        id: PropTypes.string,
        version: PropTypes.string
    })
};

export default RouteEditor;
