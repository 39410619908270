import { createSlice } from '@reduxjs/toolkit';
import { getModuleSummary, getEntitlements } from '../../api/endpoints/agents';
import { DEFAULT_AMOUNT, LICENSES_STATUS } from '../../utils/constants/ids';
import { createControllableAsyncThunk } from '../asyncThunkHelper';

export const getModulesData = createControllableAsyncThunk(
    'licenses/getModulesSummary',
    async () => {
        const response = await getModuleSummary();
        return response.data;
    }
);

export const getEntitlementsData = createControllableAsyncThunk(
    'licenses/getEntitlementsData',
    async () => {
        const response = await getEntitlements();
        return response.data;
    }
);

const initialState = {
    loading: false,
    licensesAmount: 0,
    licensesStatus: LICENSES_STATUS.OK,
    licensesLimit: null,
    isEnrollmentDisabled: false,
    modules: { input: [], output: [], processor: [], extension: [] },
    entitlements: null,
    error: null
};

const licensesSlice = createSlice({
    name: 'licenses',
    initialState,
    reducers: {
        setLicensesStatus: (state, action) => {
            state.licensesStatus = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getModulesData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getModulesData.fulfilled, (state, action) => {
                state.modules = {
                    input: action.payload?.input_modules || [],
                    output: action.payload?.output_modules || [],
                    processor: action.payload?.processor_modules || [],
                    extension: action.payload?.extension_modules || []
                };
                state.loading = false;
            })
            .addCase(getModulesData.rejected, (state, action) => {
                state.error = action.payload?.message;
                state.loading = false;
            })
            .addCase(getEntitlementsData.fulfilled, (state, action) => {
                state.entitlements = action.payload;
                state.isEnrollmentDisabled = action.payload?.is_enrollment_disabled ?? false;
                state.licensesLimit = action.payload?.entitlements?.log_sources ?? DEFAULT_AMOUNT;
                state.licensesAmount = 0;
                if (action.payload && action.payload.log_sources_usage) {
                    Object.values(action.payload.log_sources_usage).forEach((usage) => {
                        if (usage && typeof usage === 'number') {
                            state.licensesAmount += usage;
                        }
                    });
                }
            })
            .addCase(getEntitlementsData.rejected, (state) => {
                state.entitlements = null;
            });
    }
});

export const selectLicensesAmount = (state) => state.licenses.licensesAmount;
export const selectLicensesLimit = (state) => state.licenses.licensesLimit;
export const selectLicensesStatus = (state) => state.licenses.licensesStatus;
export const selectModules = (state) => state.licenses.modules;
export const selectIsEnrollmentDisabled = (state) => state.licenses.isEnrollmentDisabled;
export const { setLicensesStatus } = licensesSlice.actions;

export default licensesSlice.reducer;
