import { apiService, textContentApiService, zipContentApiService } from '../apiService';

export async function getSolutionPacks(filter) {
    const { data } = await apiService.get(
        `/api/v1/solution-packs/*/*${filter ? `?filter=${filter}` : ''}`
    );
    return data;
}

export async function getSolutionPackByIdentifier({ identifier, version }) {
    const { data } = await apiService.get(`/api/v1/solution-packs/${identifier}/${version}/*`);
    return data;
}

export async function getSolutionPackManifest({ identifier, version }) {
    const { data } = await apiService.get(
        `/api/v1/solution-packs/${identifier}/${version}/manifest`
    );
    return data;
}

export async function renderSolutionPack({ identifier, version, body }) {
    const { data } = await apiService.post(
        `/api/v1/solution-packs/${identifier}/${version}/render`,
        body
    );
    return data;
}

export async function getSPFile({ identifier, version, filePath }) {
    const { data } = await textContentApiService.get(
        `/api/v1/solution-packs/${identifier}/${version}/${filePath}`
    );
    return { data };
}

export async function getSPZipFile({ identifier, version, filePath }) {
    const { data } = await zipContentApiService.get(
        `/api/v1/solution-packs/${identifier}/${version}/${filePath}`
    );
    return { data };
}
