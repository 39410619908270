import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import propTypes from 'prop-types';
import './formSwitch.scss';
import { useForm } from 'react-hook-form';

function FormSwitch(props) {
    const { name, title, defaultValue, classname, disabled, onChange } = props;
    const [isToggleOn, toggleSwitch] = useState(defaultValue);
    const { setValue } = useForm();
    const handleClick = () => {
        if (disabled) return;
        toggleSwitch(!isToggleOn);
        setValue(name, !isToggleOn);
        if (onChange) onChange(!isToggleOn);
    };

    useEffect(() => {
        setValue(name, defaultValue);
    }, [defaultValue]);

    return (
        <div
            data-testid="form-switch"
            className={classNames('toggle-form-switch-wrapper', classname)}
        >
            <div data-testid="form-switch-title" className="toggle-title">
                {title}
            </div>
            <div
                data-testid={props['data-testid'] || 'form-switch-control'}
                onClick={handleClick}
                className={classNames(
                    'toggle-form-switch',
                    isToggleOn && 'active',
                    disabled && 'disabled'
                )}
                role="presentation"
            >
                <div className="knob">
                    <FontAwesomeIcon
                        icon={isToggleOn ? faCheck : faXmark}
                        className="switch-icon"
                    />
                </div>
            </div>
        </div>
    );
}

FormSwitch.defaultProps = {
    title: '',
    name: '',
    defaultValue: false,
    disabled: false,
    classname: '',
    'data-testid': '',
    onChange: null
};

FormSwitch.propTypes = {
    title: propTypes.string,
    name: propTypes.string,
    classname: propTypes.string,
    'data-testid': propTypes.string,
    defaultValue: propTypes.bool,
    disabled: propTypes.bool,
    onChange: propTypes.func
};

export default FormSwitch;
