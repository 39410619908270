import { useEffect, useState } from 'react';

const hasDocument = typeof document !== 'undefined';
const vendorEvents = [
    {
        hidden: 'hidden',
        event: 'visibilitychange',
        state: 'visibilityState'
    },
    {
        hidden: 'webkitHidden',
        event: 'webkitvisibilitychange',
        state: 'webkitVisibilityState'
    },
    {
        hidden: 'mozHidden',
        event: 'mozvisibilitychange',
        state: 'mozVisibilityState'
    },
    {
        hidden: 'msHidden',
        event: 'msvisibilitychange',
        state: 'msVisibilityState'
    },
    {
        hidden: 'oHidden',
        event: 'ovisibilitychange',
        state: 'oVisibilityState'
    }
];

const isSupported = hasDocument && Boolean(document.addEventListener);

const visibility = (() => {
    if (!isSupported) {
        return null;
    }
    // returns event or undefined
    return vendorEvents.find((event) => event.hidden in document);
})();

const getHandlerArgs = () => {
    if (!visibility) {
        return [true, 'visible'];
    }
    const { hidden, state } = visibility;
    return [!document[hidden], document[state]];
};

const isSupportedLocal = isSupported && visibility;

/**
 * Checks if page is visible
 * @returns {boolean} isVisible
 */
const usePageVisibility = () => {
    const [initiallyVisible] = getHandlerArgs();

    const [isVisible, setIsVisible] = useState(initiallyVisible);

    useEffect(() => {
        if (isSupportedLocal) {
            const handler = () => {
                const [currentlyVisible] = getHandlerArgs();

                setIsVisible(currentlyVisible);
            };

            document.addEventListener(visibility.event, handler);

            return () => {
                document.removeEventListener(visibility.event, handler);
            };
        }
        return null;
    }, []);

    return isVisible;
};

export default usePageVisibility;
