export const log = {
    label: 'Log',
    name: 'log',
    type: 'action',
    getCode: (action, level) => {
        if (!action.value) return '';
        const logtype = action.logtype || 'log_info';
        return `${'  '.repeat(level)}${logtype}(${action.value});`;
    }
};

export default null;
