import { createSlice, current } from '@reduxjs/toolkit';
import {
    getAgents,
    getAgentModules,
    writeAgentConfig,
    getAgentsId,
    deleteAgent,
    getAgentConfig,
    postEnrollAgent,
    postRestartAgent,
    postStartAgent,
    postSyncAgentData,
    postUpdateConfig,
    postStopAgent,
    postRefreshAgent,
    getExportAgent,
    getAgentLog,
    postModuleCommand,
    postEnrollMultipleAgents,
    putAssignTemplate,
    postEnrollMultipleAgentsUsingCustomFilter,
    deleteMultipleAgentsUsingCustomFilter,
    actionMultipleAgentsUsingCustomFilter,
    getAgentsCount,
    postRenewCertificate,
    writeAgentComment,
    deleteTemplateAssignment,
    deleteTemplateAssignmentWithFilter,
    getDistinctField,
    postEnrollAgentWithTemplate
} from '../../api/endpoints/agents';
import {
    createAsyncThunkWithErrorHandling,
    createControllableAsyncThunk
} from '../asyncThunkHelper';
import { compareAndMergeFilters, fetchAgentsFiltersCounts } from '../../utils/helpers/filters';
import { processMultiAgentResponse } from '../../utils/helpers/functions';

export const getAgentsData = createControllableAsyncThunk(
    'agents/getAgents',
    async ({ params, page }) => {
        const response = await getAgents(params);
        return { res: response.data, page };
    }
);

export const getDistinctFieldData = createControllableAsyncThunk(
    'agents/getDistinctField',
    async (field) => {
        const response = await getDistinctField(field);
        if (response.data && Array.isArray(response.data)) {
            let list = response.data
                .filter((elm) => Object.keys(elm).length)
                .map((elm) => elm[Object.keys(elm)[0]]);
            if (list.filter((elm) => [undefined, null].includes(elm)).length) {
                list = list.filter((elm) => ![undefined, null].includes(elm));
                list = list.map((label) => ({ value: label, label }));
                list.push({ value: null, label: 'Unknown' });
            } else {
                list = list.map((label) => ({ value: label, label }));
            }
            return list;
        }
        return [];
    }
);

export const getAgentModulesData = createControllableAsyncThunk(
    'agents/getAgentModules',
    async () => {
        const response = await getAgentModules();
        return { res: response?.data };
    }
);

export const getAgentLogData = createControllableAsyncThunk(
    'agents/getAgentLog',
    async (agentId) => {
        const response = await getAgentLog(agentId);
        return response.data;
    }
);

export const deleteAgentData = createAsyncThunkWithErrorHandling(
    'agents/deleteAgent',
    async ({ id }) => {
        const response = await deleteAgent(id);
        return response.data;
    }
);

export const getAgentsIdsData = createControllableAsyncThunk(
    'agents/getAgentsIds',
    async (filter) => {
        const response = await getAgentsId(filter);
        return response.data;
    }
);

export const getFilteredAgentsCount = createControllableAsyncThunk(
    'agents/getFilteredAgentsCount',
    async (filter) => {
        const response = await getAgentsCount(filter);
        return response.data;
    }
);

export const getAgentConfigData = createControllableAsyncThunk(
    'agents/getAgentConfig',
    async (id) => {
        const response = await getAgentConfig(id);
        return response.data;
    }
);

export const updateAgentConfig = createAsyncThunkWithErrorHandling(
    'agents/writeAgentConfig',
    async ({ id, data, onSuccess }) => {
        const response = await writeAgentConfig(id, data);
        if (onSuccess) {
            onSuccess();
        }
        return response.data;
    }
);

export const updateAgentComment = createAsyncThunkWithErrorHandling(
    'agents/writeAgentConfig',
    async ({ id, data, onSuccess }) => {
        const response = await writeAgentComment(id, data);
        if (onSuccess) {
            onSuccess();
        }
        return response.data;
    }
);

export const enrollAgent = createAsyncThunkWithErrorHandling(
    'agents/postEnrollAgent',
    async ({ id, ip }) => {
        const data = await postEnrollAgent(id, ip);
        return data;
    }
);

export const enrollMultipleAgents = createAsyncThunkWithErrorHandling(
    'agents/postEnrollMultipleAgents',
    async ({ uidArray, ip }) => {
        const data = await postEnrollMultipleAgents(uidArray, ip);
        return data;
    }
);

export const enrollAgentWithTemplate = createAsyncThunkWithErrorHandling(
    'agents/postEnrollAgentWithTemplate',
    async ({ agentId, templateId }) => {
        const data = await postEnrollAgentWithTemplate(agentId, templateId);
        return data;
    }
);

export const enrollMultipleAgentsUsingCustomFilter = createAsyncThunkWithErrorHandling(
    'agents/postEnrollMultipleAgentsUsingCustomFilter',
    async ({ filter, ...options }) => {
        const { data: skippedCount } = await getAgentsCount(
            `${filter ? `(${filter}) and ` : ''}enrolled=yes`
        );
        let response = null;
        let errorMessage = null;
        try {
            response = await postEnrollMultipleAgentsUsingCustomFilter(
                `${filter ? `(${filter}) and ` : ''}enrolled=no`,
                options
            );
        } catch (err) {
            errorMessage = err?.message?.error || null;
        }
        const { count, errors, invalidJSONResponse } = processMultiAgentResponse(response);

        return {
            count,
            skippedCount,
            errors,
            errorMessage,
            invalidJSONResponse
        };
    }
);

export const deleteMultiAgentsUsingCustomFilter = createAsyncThunkWithErrorHandling(
    'agents/deleteMultipleAgentsUsingCustomFilter',
    async ({ filter }) => {
        const prefix = filter ? `(${filter}) and ` : '';
        const { data: skippedCount } = await getAgentsCount(`${prefix}enrolled=no`);
        const response = await deleteMultipleAgentsUsingCustomFilter(`${prefix}enrolled=yes`);

        const { count, errors, invalidJSONResponse } = processMultiAgentResponse(response);

        return { count, skippedCount, errors, invalidJSONResponse };
    }
);

export const actionMultiAgentsUsingCustomFilter = createAsyncThunkWithErrorHandling(
    'agents/actionMultipleAgentsUsingCustomFilter',
    async ({ operation, filter, skipOffline = false }) => {
        let skippedCount = 0;
        if (skipOffline) {
            const { data } = await getAgentsCount(`online = no${filter ? ` and (${filter})` : ''}`);
            skippedCount = data;
        }
        const endpointFilter = `${
            skipOffline ? `online = yes${filter ? ` and (${filter})` : ''}` : filter
        }`;
        const response = await actionMultipleAgentsUsingCustomFilter(operation, endpointFilter);

        const { count, errors, invalidJSONResponse } = processMultiAgentResponse(response);

        return {
            operation,
            errors,
            skippedCount,
            count,
            invalidJSONResponse
        };
    }
);

export const restartAgent = createAsyncThunkWithErrorHandling(
    'agents/postRestartAgent',
    async (id) => {
        const data = await postRestartAgent(id);
        return data;
    }
);

export const startAgent = createAsyncThunkWithErrorHandling('agents/postStartAgent', async (id) => {
    const data = await postStartAgent(id);
    return data;
});

export const syncAgentData = createAsyncThunkWithErrorHandling(
    'agents/postSyncAgentData',
    async (id) => {
        const data = await postSyncAgentData({ id, filePath: '/*/*' });
        return data;
    }
);

export const syncAgentDataUsingCustomFilter = createAsyncThunkWithErrorHandling(
    'agents/postSyncAgentDataUsingCustomFilter',
    async ({ id, filter = null }) => {
        const data = await postSyncAgentData({ id, filePath: '/*/*', filter });
        return data;
    }
);

export const updateConfigData = createAsyncThunkWithErrorHandling(
    'agents/postUpdateConfig',
    async (id) => {
        const response = await postUpdateConfig(id);
        return response.data;
    }
);

export const updateConfigDataUsingCustomFilter = createAsyncThunkWithErrorHandling(
    'agents/updateConfigDataUsingCustomFilter',
    async ({ id, filter = null }) => {
        const { data: response } = await postUpdateConfig(id, filter);
        const errors = [];

        if (Array.isArray(response)) {
            errors.push(
                ...response
                    .filter((item) => item.status === 'error')
                    .map((item) => item.error)
                    .filter((item) => item)
            );
        }
        return { count: response.length - errors.length, errors };
    }
);

export const stopAgent = createAsyncThunkWithErrorHandling('agents/postStopAgent', async (id) => {
    const data = await postStopAgent(id);
    return data;
});

export const refreshAgent = createAsyncThunkWithErrorHandling(
    'agents/postRefreshAgent',
    async (id) => {
        const data = await postRefreshAgent(id);
        return data;
    }
);

export const renewAgentCertificate = createAsyncThunkWithErrorHandling(
    'agents/postRenewCertificate',
    async (id) => {
        const response = await postRenewCertificate(id);
        return response?.data;
    }
);

export const renewAgentCertificateUsingCustomFilter = createAsyncThunkWithErrorHandling(
    'agents/renewAgentCertificateUsingCustomFilter',
    async (filter) => {
        const response = await postRenewCertificate('*', filter);
        return response?.data;
    }
);

export const exportAgent = createControllableAsyncThunk('agents/getExportAgent', async (id) => {
    const response = await getExportAgent(id);
    return response;
});

export const assignTemplateData = createAsyncThunkWithErrorHandling(
    'agents/assignTemplate',
    async ({ id, filter, agentId = '*' }) => {
        const response = await putAssignTemplate({ id, filter, agentId });
        return response.data;
    }
);

export const unassignTemplate = createAsyncThunkWithErrorHandling(
    'agents/unassignTemplate',
    async ({ agentId }) => {
        const response = await deleteTemplateAssignment({ agentId });
        return response.data;
    }
);

export const unassignTemplateUsingFilter = createAsyncThunkWithErrorHandling(
    'agents/unassignTemplateUsingFilter',
    async ({ filter }) => {
        const response = await deleteTemplateAssignmentWithFilter({ filter });
        return response.data;
    }
);

export const postAgentModulesOperation = createAsyncThunkWithErrorHandling(
    'agents/postAgentModulesOperation',
    async ({ moduleName, body, params }) => {
        const response = await postModuleCommand(moduleName, body, params);
        return response.data;
    }
);

export const getAgentsFiltersCounts = createControllableAsyncThunk(
    'agents/getAgentsFiltersCounts',
    async (newFiltersItems, { getState }) => {
        const refetch = !newFiltersItems;
        const updatedItems = await fetchAgentsFiltersCounts(
            newFiltersItems || getState().agents.filterItems,
            {
                refetch
            }
        );
        return updatedItems;
    }
);

const initialState = {
    agents: [],
    agentModules: [],
    paginatedAgents: {},
    filterItems: [],
    agentsUpdated: null,
    filterDetails: {},
    filteredAgentsIds: [],
    filteredAgentsCount: 0,
    fetchedAgentPages: [],
    agentsStats: [],
    agentsState: [],
    agentConfig: {},
    agentLog: {},
    operationStatus: {},
    processes: [],
    configUpdateInfo: {},
    error: null,
    itemsPerPage: { label: '15 items', value: 15 },
    distinct: {}
};

const agentsSlice = createSlice({
    name: 'agents',
    initialState,
    reducers: {
        setItemsPerPage: (state, action) => {
            state.itemsPerPage = action.payload;
        },
        addAgentsIds: (state, action) => {
            state.filteredAgentsIds = [...state.filteredAgentsIds, ...action.payload];
            state.filteredAgentsCount += action.payload.length ?? 0;
        },
        removeFetchedPage: (state, action) => {
            state.fetchedAgentPages = state.fetchedAgentPages.filter(
                (page) => page !== action.payload
            );
        },
        setFetchedAgentPages: (state, action) => {
            state.fetchedAgentPages = [...state.fetchedAgentPages, ...action.payload];
        },
        updatePaginatedAgentConfiguration: (state, action) => {
            if (state.paginatedAgents[action.payload.page]) {
                state.paginatedAgents[action.payload.page] = current(
                    state.paginatedAgents[action.payload.page]
                ).map((item) => {
                    if (item.id === action.payload.id) {
                        return {
                            ...action.payload.data[0]
                        };
                    }
                    return item;
                });
            }
        },
        resetFetchedPages: (state, action) => {
            state.fetchedAgentPages = [...action.payload];
        },
        resetPagesExcluding: (state, action) => {
            const paginatedAgents = {};
            action.payload.forEach((page) => {
                paginatedAgents[page] = current(state.paginatedAgents)[page];
            });
            state.paginatedAgents = paginatedAgents;
        },
        resetAgents: (state) => {
            state.filteredAgentsIds = [];
            state.filteredAgentsCount = 0;
            state.paginatedAgents = {};
            state.agents = [];
            state.fetchedAgentPages = [];
            state.agentsState = [];
            state.agentsStats = [];
        },
        updatePaginatedAgents: (state, action) => {
            state.paginatedAgents = action.payload;
        },
        deleteOneAgent: (state, action) => {
            const { id, page } = action.payload;
            state.agents = state.agents.filter((agent) => agent.id !== id);
            state.filteredAgentsIds = state.filteredAgentsIds.filter((_id) => _id !== id);
            state.filteredAgentsCount -= 1;
            state.paginatedAgents[page] = state.paginatedAgents[page].filter(
                (agent) => agent.id !== id
            );
        },
        removeOperation: (state, action) => {
            delete state.operationStatus[action.payload];
        },
        removeMultipleOperations: (state, action) => {
            action.payload.forEach((id) => {
                delete state.operationStatus[id];
            });
        },
        addProcess: (state, action) => {
            state.processes = [action.payload, ...state.processes];
        },
        setProcesses: (state, action) => {
            state.processes = action.payload;
        },
        removeIdFromProcesses: (state, action) => {
            const { id, processType } = action.payload;
            state.processes = [
                ...state.processes.map((item) => {
                    if (item.processType === processType) {
                        item.agentIds = item.agentIds.filter((_id) => _id !== id);
                    }
                    return item;
                })
            ];
        },
        setFilterDetails: (state, action) => {
            state.filterDetails = action.payload;
        },
        updateAgentsFilterItems: (state, action) => {
            state.filterItems = compareAndMergeFilters(state.filterItems, action.payload, true);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(postAgentModulesOperation.pending, (state) => {
                state.error = null;
            })
            .addCase(postAgentModulesOperation.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(getAgentsData.fulfilled, (state, action) => {
                if (action.payload.page !== undefined) {
                    state.paginatedAgents[action.payload.page + 1] = action.payload.res;
                    const nextPage =
                        action.payload.res?.slice(
                            state.itemsPerPage.value,
                            2 * state.itemsPerPage.value
                        ) || [];
                    if (nextPage?.length) {
                        state.paginatedAgents[action.payload.page + 2] = nextPage;
                    }
                }
                state.agents = action.payload.res;
            })
            .addCase(getAgentsData.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(getDistinctFieldData.fulfilled, (state, action) => {
                if (action?.meta?.arg && action?.payload && Array.isArray(action?.payload)) {
                    state.distinct[action.meta.arg] = action.payload;
                } else if (action.meta.arg) {
                    state.distinct[action.meta.arg] = [];
                }
            })
            .addCase(getDistinctFieldData.rejected, (state, action) => {
                state.error = action?.payload?.message;
            })
            .addCase(getAgentModulesData.fulfilled, (state, action) => {
                state.agentModules = action?.payload?.res;
            })
            .addCase(getAgentModulesData.rejected, (state, action) => {
                state.error = action?.payload?.message;
            })
            .addCase(getAgentsIdsData.fulfilled, (state, action) => {
                state.filteredAgentsIds = action.payload;
                state.filteredAgentsCount = action.payload?.length ?? 0;
            })
            .addCase(getFilteredAgentsCount.fulfilled, (state, action) => {
                state.filteredAgentsCount = action.payload ?? 0;
            })
            .addCase(getAgentConfigData.fulfilled, (state, action) => {
                if (action?.meta?.arg) {
                    state.agentConfig[action?.meta?.arg] = {
                        id: action?.meta?.arg,
                        data: action.payload
                    };
                }
            })
            .addCase(deleteAgentData.rejected, (state, action) => {
                state.error = action?.error?.message;
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg?.id] = 'error';
                }
                state.agentsUpdated = Date.now();
            })
            .addCase(deleteAgentData.pending, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg?.id] = 'pending';
                }
            })
            .addCase(deleteAgentData.fulfilled, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg?.id] = 'success';
                }
                state.agentsUpdated = Date.now();
            })
            .addCase(enrollAgent.fulfilled, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg?.id] = { enroll: 'success' };
                }
                state.agentsUpdated = Date.now();
            })
            .addCase(enrollAgent.rejected, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg?.id] = { enroll: 'error' };
                }
            })
            .addCase(enrollAgent.pending, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg?.id] = { enroll: 'pending' };
                }
            })
            .addCase(enrollMultipleAgents.fulfilled, (state, action) => {
                if (action?.meta?.arg) {
                    action?.meta?.arg?.uidArray.forEach((id) => {
                        state.operationStatus[id] = { enroll: 'success' };
                    });
                }
                state.agentsUpdated = Date.now();
            })
            .addCase(enrollMultipleAgents.rejected, (state, action) => {
                if (action?.meta?.arg) {
                    action?.meta?.arg?.uidArray.forEach((id) => {
                        state.operationStatus[id] = { enroll: 'error' };
                    });
                }
            })
            .addCase(enrollMultipleAgents.pending, (state, action) => {
                if (action?.meta?.arg) {
                    action?.meta?.arg?.uidArray.forEach((id) => {
                        state.operationStatus[id] = { enroll: 'pending' };
                    });
                }
            })
            .addCase(restartAgent.fulfilled, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'success';
                }
                state.agentsUpdated = Date.now();
            })
            .addCase(restartAgent.rejected, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'error';
                }
            })
            .addCase(restartAgent.pending, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'pending';
                }
            })
            .addCase(startAgent.fulfilled, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'success';
                }
                state.agentsUpdated = Date.now();
            })
            .addCase(startAgent.rejected, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'error';
                }
            })
            .addCase(startAgent.pending, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'pending';
                }
            })
            .addCase(stopAgent.fulfilled, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'success';
                }
                state.agentsUpdated = Date.now();
            })
            .addCase(stopAgent.rejected, (state, action) => {
                state.error = action?.error;
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'error';
                }
            })
            .addCase(stopAgent.pending, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'pending';
                }
            })
            .addCase(enrollMultipleAgentsUsingCustomFilter.fulfilled, (state) => {
                state.agentsUpdated = Date.now();
            })
            .addCase(deleteMultiAgentsUsingCustomFilter.fulfilled, (state) => {
                state.agentsUpdated = Date.now();
            })
            .addCase(actionMultiAgentsUsingCustomFilter.fulfilled, (state) => {
                state.agentsUpdated = Date.now();
            })
            .addCase(assignTemplateData.fulfilled, (state) => {
                state.agentsUpdated = Date.now();
            })
            .addCase(unassignTemplate.fulfilled, (state) => {
                state.agentsUpdated = Date.now();
            })
            .addCase(unassignTemplateUsingFilter.fulfilled, (state) => {
                state.agentsUpdated = Date.now();
            })
            .addCase(syncAgentData.fulfilled, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'success';
                }
            })
            .addCase(syncAgentData.rejected, (state, action) => {
                state.error = action?.error;
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'error';
                }
            })
            .addCase(syncAgentData.pending, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'pending';
                }
            })
            .addCase(refreshAgent.rejected, (state, action) => {
                state.error = action?.payload || action?.error;
            })
            .addCase(renewAgentCertificate.rejected, (state, action) => {
                state.error = action?.payload || action?.error;
            })
            .addCase(exportAgent.rejected, (state, action) => {
                state.error = action?.payload || action?.error;
            })
            .addCase(updateConfigData.fulfilled, (state, action) => {
                const payload = action.payload?.[0];
                state.configUpdateInfo[payload?.id] = payload;
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'success';
                }
                state.agentsUpdated = Date.now();
            })
            .addCase(updateConfigData.rejected, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'error';
                }
            })
            .addCase(updateConfigData.pending, (state, action) => {
                if (action?.meta?.arg) {
                    state.operationStatus[action?.meta?.arg] = 'pending';
                }
            })
            .addCase(updateAgentConfig.fulfilled, (state) => {
                state.agentsUpdated = Date.now();
            })
            .addCase(updateAgentConfig.rejected, (state, action) => {
                state.error = action?.error;
            })
            .addCase(getAgentLogData.fulfilled, (state, action) => {
                state.agentLog[action?.meta?.arg] = action?.payload?.cachedLogs;
            })
            .addCase(getAgentsFiltersCounts.fulfilled, (state, action) => {
                state.filterItems = compareAndMergeFilters(
                    state.filterItems,
                    action.payload,
                    false
                );
            });
    }
});

export const {
    setItemsPerPage,
    addAgentsIds,
    setFetchedAgentPages,
    resetAgents,
    removeFetchedPage,
    resetCurrentAgent,
    updateStartStop,
    deleteOneAgent,
    updatePaginatedAgentConfiguration,
    updatePaginatedAgents,
    removeOperation,
    removeMultipleOperations,
    addProcess,
    setProcesses,
    removeIdFromProcesses,
    resetFetchedPages,
    resetPagesExcluding,
    setFilterDetails,
    updateAgentsFilterItems
} = agentsSlice.actions;

export const selectAgentModulesData = (state) => state.agents.agentModules;
export const selectPaginatedAgents = (state) => state.agents.paginatedAgents;
export const selectAgents = (state) => state.agents.agents;
export const selectAgentsFilterItems = (state) => state.agents.filterItems;
export const selectDistinct = (field) => (state) =>
    state.agents?.distinct ? state.agents.distinct[field] : null;
export const selectFilteredAgentsIds = (state) => state.agents.filteredAgentsIds;
export const selectFilteredAgentsCount = (state) => state.agents.filteredAgentsCount;
export const selectFetchedAgentPages = (state) => state.agents.fetchedAgentPages;
export const selectAgentConfigs = (state) => state.agents.agentConfig;
export const selectItemsPerPage = (state) => state.agents.itemsPerPage;
export const selectItemsPerPageValue = (state) => state.agents.itemsPerPage.value;
export const selectAgentFilterDetails = (state) => state.agents.filterDetails;
export const selectOperationStatus = (state) => state.agents.operationStatus;
export const selectProcesses = (state) => state.agents.processes;
export const selectAgentsUpdated = (state) => state.agents.agentsUpdated;
export default agentsSlice.reducer;
