import { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next';
import { createNotification, mergeArraysBySameField } from '../helpers/functions';
import { addNotifications } from '../../redux/reducers/notifications';
import { getAgentEverConnected, getAgents, getAgentsCount } from '../../api/endpoints/agents';
import { TOAST_TYPES, AGENT_SELECTOR, FILTER_BY_TYPE } from '../constants/ids';
import {
    deleteAgentData,
    enrollAgent,
    getAgentConfigData,
    selectAgentConfigs,
    getAgentLogData,
    getAgentsData,
    restartAgent,
    selectFetchedAgentPages,
    setFetchedAgentPages,
    startAgent,
    stopAgent,
    updatePaginatedAgentConfiguration,
    selectItemsPerPageValue,
    deleteOneAgent,
    selectAgents,
    resetAgents,
    syncAgentData,
    selectProcesses,
    resetFetchedPages,
    resetPagesExcluding,
    enrollMultipleAgentsUsingCustomFilter,
    deleteMultiAgentsUsingCustomFilter,
    actionMultiAgentsUsingCustomFilter,
    removeIdFromProcesses,
    renewAgentCertificate,
    selectFilteredAgentsCount,
    getFilteredAgentsCount,
    syncAgentDataUsingCustomFilter,
    unassignTemplate,
    unassignTemplateUsingFilter,
    renewAgentCertificateUsingCustomFilter,
    exportAgent
} from '../../redux/reducers/agents';
import { createFilterString, createSortString } from '../helpers/strings';
import { createAgentsFilterStrFromTags } from '../helpers/tables';
import useFetch from './useFetch';
import useFilters from './useFilters';
import { AGENT_TABLE_FIELDS } from '../apiFields';
import useSortTable from './useSortTable';
import useEnrollmentAddress from './useEnrollmentAddress';
import { getEntitlementsData, selectIsEnrollmentDisabled } from '../../redux/reducers/licenses';

const useAgentTable = (initialFilters = [], inititalSortFields = []) => {
    const dispatch = useDispatch();
    const itemsPerPageValue = useSelector(selectItemsPerPageValue);
    const ip = useEnrollmentAddress();
    const fetchedPages = useSelector(selectFetchedAgentPages);
    const filteredAgentsCount = useSelector(selectFilteredAgentsCount);
    const configData = useSelector(selectAgentConfigs);
    const agents = useSelector(selectAgents);
    const [generalFilter, setGeneralFilter] = useState('all');
    const [tags, setTags] = useState([]);
    const [agentEverConnected, setAgentEverConnected] = useState(false);
    const isEnrollmentDisabled = useSelector(selectIsEnrollmentDisabled);

    const { appliedFilters, applyFilter, removeFilter, resetFilters } = useFilters(initialFilters);

    const { sortFields, addSortField, removeSortField } = useSortTable(inititalSortFields);

    const { response: agentsCount, refetch: refetchAgentsCount } = useFetch(getAgentsCount);

    const { response: responseAgentEverConnected, refetch: refetchAgentEverConnected } =
        useFetch(getAgentEverConnected);

    useEffect(() => {
        // once agentEverConnected is set to 'true', it stays 'true'
        if (responseAgentEverConnected || agents?.length) {
            setAgentEverConnected(true);
        }
    }, [responseAgentEverConnected, agents]);

    const [modalShow, setModalShow] = useState(false);
    const [logModalShow, setLogModalShow] = useState(false);
    const [currentAgentId, setCurrentAgentId] = useState(null);
    const [currentTemplate, setCurrentTemplate] = useState(null);
    const [isAgentsTemplateModalVisible, setAgentsTemplateModalVisible] = useState(false);
    const [currentRows, setCurrentRows] = useState(null);
    const [currentRowsAction, setCurrentRowsAction] = useState(null);
    const [currentPage, setCurrentPage] = useState({ label: 1, value: 1 });

    const aggregateFilters = (filters, filterTags, singleFilter) => {
        const filterStr = createFilterString(filters);
        const tagsFilterStr = createAgentsFilterStrFromTags(filterTags);
        const general = FILTER_BY_TYPE[singleFilter];
        const filtersArr = [tagsFilterStr, general, filterStr].filter(Boolean);
        const filter = filtersArr.join(' and ');
        return filter;
    };

    // function accepts a string id or an array of ids
    const refetchAgentInformation = (idProp) => {
        refetchAgentsCount();
        let agentData = [];
        // if idProp is a string
        if (typeof idProp !== 'object') {
            Promise.all([
                getAgents({ filter: `${AGENT_SELECTOR} = ${idProp}`, fields: AGENT_TABLE_FIELDS })
            ]).then((res) => {
                res.forEach((item) => {
                    agentData = mergeArraysBySameField(agentData, item.data, AGENT_SELECTOR);
                });
                dispatch(
                    updatePaginatedAgentConfiguration({
                        data: agentData,
                        page: currentPage.value,
                        id: idProp
                    })
                );
            });
        }
        // if idProp is an array of multiple strings
        if (Array.isArray(idProp) && idProp.length > 1) {
            Promise.all([
                getAgents({
                    filter: `${AGENT_SELECTOR} IN (${idProp.join(', ')})`,
                    fields: AGENT_TABLE_FIELDS
                })
            ]).then((res) => {
                res.forEach((item) => {
                    agentData = mergeArraysBySameField(agentData, item.data, AGENT_SELECTOR);
                });
                agentData.forEach((agent, i) =>
                    dispatch(
                        updatePaginatedAgentConfiguration({
                            data: agentData[i],
                            page: currentPage.value,
                            id: agent.id
                        })
                    )
                );
            });
        }
        if (Array.isArray(idProp) && idProp.length === 1) {
            refetchAgentInformation(idProp[0]);
        }
    };

    const processes = useSelector(selectProcesses);

    useEffect(() => {
        const ids = processes.reduce(
            (acc, process) =>
                process.status === 'complete' ? [...new Set([...acc, ...process.agentIds])] : acc,
            []
        );
        refetchAgentInformation(ids);
    }, [processes]);

    const refetchCurrrentPage = () => {
        const pageValue = currentPage.value - 1;
        const filter = aggregateFilters(appliedFilters, tags, generalFilter);
        const sortStr = createSortString(sortFields);
        const params = {
            'sort-by': sortStr,
            fields: AGENT_TABLE_FIELDS,
            offset: pageValue * itemsPerPageValue,
            limit: itemsPerPageValue,
            ...(!!filter && { filter })
        };
        const agentData = {
            params,
            page: pageValue
        };
        dispatch(getEntitlementsData());
        dispatch(getFilteredAgentsCount(filter));
        dispatch(getAgentsData(agentData));
    };

    let enrolled = 0;
    let offline = 0;

    const groupAsyncAction = useCallback(
        async (row, action, operation = null) => {
            const uidArray = row.map((agent) => agent.id);
            const batchSize = 10;

            const enrollAgentAction = operation === 'enrollAgent';
            const enrolledAgents = row.reduce((agentsDic, agent) => {
                if (enrollAgentAction && agent.enrolled) {
                    agentsDic[agent.id] = true;
                    enrolled += 1;
                }
                return agentsDic;
            }, {});

            const restartAgentAction = operation === 'restart';
            const offlineAgents = row.reduce((agentDic, agent) => {
                if (restartAgentAction && !agent.online) {
                    agentDic[agent.id] = true;
                    offline += 1;
                }
                return agentDic;
            }, {});

            const processBatch = async (startIndex) => {
                if (startIndex >= uidArray.length) {
                    refetchCurrrentPage();
                    return;
                }

                const endIndex = Math.min(startIndex + batchSize, uidArray.length);
                const batch = uidArray.slice(startIndex, endIndex);

                await Promise.allSettled(
                    batch.map((id) => {
                        if (enrollAgentAction && enrolledAgents[id]) {
                            return dispatch(removeIdFromProcesses({ id, processType: 'enroll' }));
                        }
                        if (restartAgentAction && offlineAgents[id]) {
                            return dispatch(removeIdFromProcesses({ id, processType: 'restart' }));
                        }
                        const hostnameObj = row.find((object) => object.id === id);
                        const { hostname } = hostnameObj;
                        return enrollAgentAction
                            ? dispatch(action(id, hostname))
                            : dispatch(action(id));
                    })
                ).then(() => {
                    if ((enrollAgentAction && enrolled) || (restartAgentAction && offline)) {
                        const notification = createNotification(
                            TOAST_TYPES.WARNING,
                            i18next.t(
                                `notifications_messages.warning.${
                                    enrollAgentAction ? 'enroll' : 'restart'
                                }`,
                                {
                                    count: enrollAgentAction ? enrolled : offline
                                }
                            ),
                            i18next.t(
                                `notifications_messages.warning.${
                                    enrollAgentAction ? 'enroll_info' : 'restart_info'
                                }`,
                                {
                                    count: enrollAgentAction ? enrolled : offline
                                }
                            )
                        );
                        notification.id = 'enrollAgent.warning';
                        return dispatch(addNotifications(notification));
                    }
                    return null;
                });

                processBatch(startIndex + batchSize);
            };

            await processBatch(0);
        },
        [dispatch, refetchCurrrentPage]
    );

    const AGENT_TABLE_ROW_FUNCTIONS_BY_FILTER = {
        assignTemplate: async (row) => {
            setAgentsTemplateModalVisible((prev) => !prev);
            setCurrentRows(row);
            setCurrentRowsAction('assignTemplate');
        },
        unassignTemplate: ({ filter }) =>
            dispatch(unassignTemplateUsingFilter({ filter })).unwrap(),
        enroll: ({ filter }) => {
            if (isEnrollmentDisabled) return Promise.resolve(false);
            return dispatch(enrollMultipleAgentsUsingCustomFilter({ filter, ip }));
        },
        enrollwithtemplate: async (row) => {
            if (isEnrollmentDisabled) return;
            setAgentsTemplateModalVisible((prev) => !prev);
            setCurrentRows(row);
            setCurrentRowsAction('enrollwithtemplate');
        },
        restart: ({ filter }) =>
            dispatch(
                actionMultiAgentsUsingCustomFilter({
                    filter,
                    operation: 'restart',
                    skipOffline: true
                })
            ),
        start: ({ filter }) =>
            dispatch(
                actionMultiAgentsUsingCustomFilter({
                    filter,
                    operation: 'start',
                    skipOffline: true
                })
            ),
        stop: ({ filter }) =>
            dispatch(
                actionMultiAgentsUsingCustomFilter({
                    filter,
                    operation: 'stop',
                    skipOffline: true
                })
            ),
        updateAndRestart: ({ filter }) =>
            dispatch(syncAgentDataUsingCustomFilter({ filter, id: '*' })),
        delete: ({ filter }) => dispatch(deleteMultiAgentsUsingCustomFilter({ filter })),
        renewCertificate: ({ filter }) => dispatch(renewAgentCertificateUsingCustomFilter(filter))
    };

    const AGENT_TABLE_ROW_FUNCTIONS = {
        assignTemplate: (row) => {
            setAgentsTemplateModalVisible((prev) => !prev);
            setCurrentRows(row);
            setCurrentRowsAction('assignTemplate');
        },
        unassignTemplate: (row) => {
            row.forEach(({ id }) => dispatch(unassignTemplate({ agentId: id })));
        },
        enroll: (row) => {
            if (isEnrollmentDisabled) return;
            const enrollAction = (id, hostname) => enrollAgent({ id, ip, hostname });
            groupAsyncAction(row, enrollAction, 'enrollAgent');
        },
        enrollwithtemplate: (row) => {
            if (isEnrollmentDisabled) return;
            setAgentsTemplateModalVisible((prev) => !prev);
            setCurrentRows(row);
            setCurrentRowsAction('enrollwithtemplate');
        },
        restart: (row) => {
            groupAsyncAction(row, restartAgent, 'restart');
        },
        start: (row) => {
            groupAsyncAction(row, startAgent);
        },
        stop: (row) => {
            groupAsyncAction(row, stopAgent);
        },
        editConfig: (row) => {
            setCurrentAgentId(row[0].id);
            setCurrentTemplate(row[0].templateName);
            dispatch(getAgentConfigData(row[0].id));
            setModalShow(true);
        },
        updateAndRestart: (row) => {
            row.forEach((agent) => {
                dispatch(syncAgentData(agent.id));
            });
        },
        viewLogs: (row) => {
            setCurrentAgentId(row[0].id);
            dispatch(getAgentLogData(row[0].id));
            setLogModalShow(true);
        },
        delete: (row) => {
            Promise.allSettled(
                row.map((agent) =>
                    dispatch(deleteAgentData({ id: agent.id, hostname: agent.hostname })).then(
                        (res) =>
                            dispatch(deleteOneAgent({ id: res.meta.arg, page: currentPage.value }))
                    )
                )
            ).then(() => refetchCurrrentPage());
        },
        renewCertificate: (row) => {
            row.forEach((agent) => {
                dispatch(renewAgentCertificate(agent.id));
            });
        },
        refresh: () => refetchCurrrentPage(),
        export: (rows) => {
            dispatch(exportAgent(rows[0].id))
                .unwrap()
                .then((res) => {
                    const blob = new Blob([res.data], { type: 'application/octet-stream' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    const [, fileName] = res.headers['content-disposition'].split('=');
                    a.href = url;
                    a.download = `${`${fileName || rows[0].id}`.trim()}.zip`;
                    a.click();
                })
                .catch(() => {});
        }
    };

    const handleChangePage = (page) => {
        setCurrentPage(page);
        const filter = aggregateFilters(appliedFilters, tags, generalFilter);
        const sortStr = createSortString(sortFields);
        if (!fetchedPages.includes(page.value)) {
            const pageValue = page.value - 1;
            const params = {
                'sort-by': sortStr,
                fields: AGENT_TABLE_FIELDS,
                offset: pageValue * itemsPerPageValue,
                limit: itemsPerPageValue,
                ...(!!filter && { filter })
            };
            const agentData = {
                params,
                page: pageValue
            };

            dispatch(setFetchedAgentPages([page.value]));
            dispatch(getAgentsData(agentData));
        }
        if (
            !fetchedPages.includes(page.value + 1) &&
            page.value * itemsPerPageValue < filteredAgentsCount
        ) {
            const params = {
                'sort-by': sortStr,
                fields: AGENT_TABLE_FIELDS,
                offset: page.value * itemsPerPageValue,
                limit: itemsPerPageValue,
                ...(!!filter && { filter })
            };
            const agentData = {
                params,
                page: page.value
            };
            dispatch(setFetchedAgentPages([page.value + 1]));
            dispatch(getAgentsData(agentData));
        }
    };

    const resetAndFetchAgents = (filterStr, itemsAmount, generalFilterName, filterTags = null) => {
        const tagsFilterStr = createAgentsFilterStrFromTags(filterTags ?? tags);
        const general = generalFilterName
            ? FILTER_BY_TYPE[generalFilterName]
            : FILTER_BY_TYPE[generalFilter];
        const filtersArr = [tagsFilterStr, general, filterStr].filter(Boolean);
        const sortStr = createSortString(sortFields);
        const filter = filtersArr.join(' and ');
        setCurrentPage({ label: 1, value: 1 });
        dispatch(resetAgents());
        dispatch(getFilteredAgentsCount(filter));
        dispatch(setFetchedAgentPages([1]));
        const params = {
            'sort-by': sortStr,
            fields: AGENT_TABLE_FIELDS,
            offset: 0,
            limit: itemsAmount ?? itemsPerPageValue,
            ...(!!filter && { filter })
        };
        const agentData = {
            params,
            page: 0
        };

        dispatch(getAgentsData(agentData));
    };

    const addNewTag = (filter, isFullTextSearch = false) => {
        applyFilter({ filter, id: `tag-${Date.now()}`, isTag: true, isFullTextSearch });
    };

    useEffect(() => {
        const filterStr = createFilterString(appliedFilters);
        resetAndFetchAgents(filterStr);
    }, []);

    useEffect(() => {
        dispatch(resetFetchedPages([currentPage.value]));
        dispatch(resetPagesExcluding([currentPage.value]));
        refetchCurrrentPage();
    }, [sortFields]);

    return {
        strFilter: aggregateFilters(appliedFilters, tags, generalFilter),
        agents,
        filteredAgentsCount,
        agentsCount,
        agentEverConnected,
        modalShow,
        logModalShow,
        currentPage,
        configData,
        currentRows,
        currentRowsAction,
        AGENT_TABLE_ROW_FUNCTIONS,
        AGENT_TABLE_ROW_FUNCTIONS_BY_FILTER,
        isAgentsTemplateModalVisible,
        currentAgentId,
        currentTemplate,
        appliedFilters,
        generalFilter,
        tags,
        addNewTag,
        processes,
        sortFields,
        addSortField,
        removeSortField,
        setTags,
        setGeneralFilter,
        resetAndFetchAgents,
        applyFilter,
        removeFilter,
        resetFilters,
        refetchCurrrentPage,
        refetchAgentsCount,
        refetchAgentEverConnected,
        setModalShow,
        setLogModalShow,
        setCurrentRows,
        setCurrentRowsAction,
        setCurrentPage,
        setAgentsTemplateModalVisible,
        handleChangePage
    };
};

export default useAgentTable;
