import React from 'react';
import PropTypes from 'prop-types';
import MQLEditor from '.';

// eslint-disable-next-line react/prop-types
function MQLInput({ placeholder, className, value, onChange, 'data-testid': dataTestId }) {
    return (
        <MQLEditor
            placeholder={placeholder}
            hideIcon
            className={className}
            value={value}
            onChange={onChange}
            dataTestId={dataTestId}
        />
    );
}

MQLInput.defaultProps = {
    placeholder: '',
    className: '',
    value: '',
    onChange: () => {},
    'data-testid': ''
};

MQLInput.propTypes = {
    placeholder: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    'data-testid': PropTypes.string
};

export default MQLInput;
