import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmUds = {
    label: 'Unix Domain Sockets',
    type: 'sendTo',
    name: 'om_uds',
    defaultDirectives: 'Module      om_uds',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'UDS',
            type: 'string',
            required: true,
            defaultValue: '/dev/log'
        },
        {
            name: 'Group',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Perms',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UDSType',
            type: 'select',
            options: ['auto', 'dgram', 'stream'],
            defaultValue: 'auto'
        },
        {
            name: 'User',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_uds'),
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
