import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImSalesforce = {
    label: 'Salesforce',
    name: 'im_salesforce',
    type: 'collect',
    defaultDirectives: 'Module      im_salesforce',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ConsumerKey',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'ConsumerSecret',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Domain',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'LoginDomain',
            type: 'string',
            defaultValue: 'login.salesforce.com'
        },
        {
            name: 'Login',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Password',
            type: 'password',
            defaultValue: '',
            required: true
        },
        {
            name: 'ApiVersion',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'EventFilter',
            label: 'EventFilter',
            type: 'block',
            defaultValue: {},
            block: [
                {
                    name: 'Accept',
                    label: 'Accept',
                    type: 'string',
                    defaultValue: [],
                    multiple: true
                },
                {
                    name: 'Exclude',
                    label: 'Exclude',
                    type: 'string',
                    defaultValue: [],
                    multiple: true
                }
            ]
        },

        {
            name: 'PollInterval',
            type: 'number',
            defaultValue: 60
        },
        {
            name: 'ReadFromLast',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'SavePos',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'StartFrom',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_INPUT_DIRECTIVES('im_salesforce'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
