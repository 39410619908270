import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getTemplatesIds,
    addTemplate,
    getTemplatesNames,
    getTemplates,
    updateTemplate,
    getTemplatesCount,
    deleteTemplateAndParts
} from '../../api/endpoints/templates';
import { duplicateTemplateWithParts } from '../../api/operations';
import {
    createAsyncThunkWithErrorHandling,
    createControllableAsyncThunk
} from '../asyncThunkHelper';
import { CONFIG_FILTERS, fetchTemplatesFiltersCounts } from '../../utils/helpers/filters';

export const getTemplatesIdsData = createControllableAsyncThunk(
    'templates/getTempaltesIds',
    async (filter) => {
        const response = await getTemplatesIds(filter);
        return response.data;
    }
);

export const getTemplatesNamesData = createControllableAsyncThunk(
    'templates/getTempaltesNames',
    async (filter) => {
        const response = await getTemplatesNames(filter);
        return response.data;
    }
);

export const getTemplatesData = createControllableAsyncThunk(
    'templates/getTemplates',
    async (params) => {
        const response = await getTemplates(params);
        return response.data;
    }
);

export const getTemplatesItemsCount = createControllableAsyncThunk(
    'templates/getTemplatesCount',
    async (params) => {
        const response = await getTemplatesCount(params);
        return response.data;
    }
);

export const postTemplateData = createAsyncThunkWithErrorHandling(
    'templates/addTempalte',
    async (body) => {
        const response = await addTemplate(body);
        return { ...body, id: response.data };
    }
);

export const duplicateTemplateData = createAsyncThunkWithErrorHandling(
    'templates/duplicateTempalte',
    async ({ id, name }) => {
        const response = await duplicateTemplateWithParts(id, name);
        return response.data;
    }
);

export const deleteTemplateData = createAsyncThunk(
    'templates/deleteTemplate',
    async (templates) => {
        if (Array.isArray(templates)) {
            const promiseArr = templates.map(({ id, name }) =>
                deleteTemplateAndParts(id)
                    .then((r) => ({
                        ...r,
                        id,
                        name,
                        status: 'fulfilled'
                    }))
                    .catch((err) => ({
                        ...err,
                        id,
                        name,
                        status: 'rejected'
                    }))
            );

            const response = await Promise.allSettled(promiseArr);
            const arr = response.map((item) => {
                if (item.value.status === 'rejected') {
                    return {
                        status: item.value.status,
                        value: item.value.id,
                        name: item.value.name,
                        message: item.value.response?.data?.message || ''
                    };
                }
                return {
                    status: item.value.status,
                    value: item.value.id,
                    name: item.value.name
                };
            });
            return arr;
        }
        const res = await deleteTemplateAndParts(templates.id);
        return [res];
    }
);

export const updateTemplateData = createAsyncThunkWithErrorHandling(
    'configs/updateTemplate',
    async ({ id, body }) => {
        const response = await updateTemplate(id, body);
        return response.data;
    }
);

export const getTemplatesFiltersCounts = createControllableAsyncThunk(
    'templates/getTemplateFiltersCounts',
    async (_, { getState }) => {
        const updatedItems = await fetchTemplatesFiltersCounts(getState().templates.filterItems, {
            refetch: true
        });
        return updatedItems;
    }
);

const initialState = {
    loading: false,
    templatesIds: [],
    templatesNames: [],
    templates: null,
    itemsPerPage: 15,
    itemsCount: 0,
    filterItems: CONFIG_FILTERS,
    error: null
};

const templatesSlice = createSlice({
    name: 'templates',
    initialState,
    reducers: {
        setItemsPerPage: (state, action) => {
            state.itemsPerPage = action.payload;
        },
        updatesTemplateDataById: (state, action) => {
            const arr = state.templates.map((template) => {
                if (template.id === action.payload.id) {
                    return action.payload;
                }

                return template;
            });

            state.templates = arr;
        },
        setTemplateFilterItems: (state, action) => {
            state.filterItems = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteTemplateData.fulfilled, (state, action) => {
                const map = new Map();
                action.payload.forEach((payload) => {
                    if (payload.status === 'fulfilled') {
                        map.set(payload.value, payload.value);
                    }
                });

                state.templates = state.templates.filter((template) => !map.get(template.id));
                state.itemsCount -= map.size;
                state.loading = false;
            })
            .addCase(getTemplatesIdsData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getTemplatesIdsData.fulfilled, (state, action) => {
                state.templatesIds = action.payload;
                state.loading = false;
            })
            .addCase(getTemplatesIdsData.rejected, (state, action) => {
                state.error = action.payload?.message;
                state.loading = false;
            })
            .addCase(getTemplatesNamesData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getTemplatesNamesData.fulfilled, (state, action) => {
                state.templatesNames = action.payload;
                state.loading = false;
            })
            .addCase(getTemplatesNamesData.rejected, (state, action) => {
                state.error = action.payload?.message;
                state.loading = false;
            })
            .addCase(postTemplateData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(postTemplateData.fulfilled, (state, action) => {
                if (state.templates === null) state.templates = [];
                state.templatesIds = [action.payload.id, ...state.templatesIds];
                state.templates = [action.payload, ...state.templates];
                state.itemsCount += 1;
                state.loading = false;
            })
            .addCase(postTemplateData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error?.message;
            })
            .addCase(duplicateTemplateData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(duplicateTemplateData.fulfilled, (state, action) => {
                if (state.templates === null) state.templates = [];
                state.templatesIds = [action.payload.id, ...state.templatesIds];
                state.templates = [action.payload, ...state.templates];
                state.itemsCount += 1;
                state.loading = false;
            })
            .addCase(duplicateTemplateData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error?.message;
            })
            .addCase(getTemplatesData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getTemplatesData.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getTemplatesData.fulfilled, (state, action) => {
                state.templates = action.payload;
                state.loading = false;
            })
            .addCase(getTemplatesItemsCount.fulfilled, (state, action) => {
                state.itemsCount = action.payload;
            })
            .addCase(updateTemplateData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateTemplateData.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(updateTemplateData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error?.message;
            })
            .addCase(getTemplatesFiltersCounts.fulfilled, (state, action) => {
                state.filterItems = action.payload;
            });
    }
});

export const { updatesTemplateDataById, setItemsPerPage } = templatesSlice.actions;

export const selectTemplates = (state) => state.templates.templates;
export const selectTemplatesIds = (state) => state.templates.templatesIds;
export const selectTemplatesNames = (state) => state.templates.templatesNames;
export const selectTemplatesError = (state) => state.templates.error;
export const selectTemplatesPerPage = (state) => state.templates.itemsPerPage;
export const selectTemplatesCount = (state) => state.templates.itemsCount;
export const selectTemplatesLoading = (state) => state.templates.loading;
export const selectTemplatesFilterItems = (state) => state.templates.filterItems;

export default templatesSlice.reducer;
