import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Button, Modal } from '@nxlog/common-ui/components';
import './viewLogModal.scss';

export default function ViewLogModal({ onHide, show, logData, currentAgentId }) {
    const [showAll, setShow] = useState(false);
    const handleShow = () => (!showAll ? setShow(true) : setShow(false));
    const currentLog = logData[currentAgentId];

    return (
        <Modal
            isShown={show}
            onClose={onHide}
            classname="view-log modal-xl"
            title={currentLog?.hostname ? `${currentLog?.hostname}'s Log` : 'Loading...'}
            aria-labelledby="view-log"
        >
            <div className="scroll" data-testid="ViewLogModal">
                <div>
                    <pre className="log-text">
                        {!showAll
                            ? currentLog?.slice(0, 3000) ||
                              logData[currentAgentId]?.error.slice(0, 3000)
                            : currentLog || currentLog?.error}
                    </pre>
                    {currentLog?.length > 3000 && (
                        <div className="show-btn">
                            <Button onClick={() => handleShow()}>
                                {!showAll ? 'Show All' : 'Show Less'}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
}

ViewLogModal.defaultProps = {
    onHide: () => {},
    show: false,
    logData: [],
    currentAgentId: ''
};

ViewLogModal.propTypes = {
    onHide: propTypes.func,
    show: propTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    logData: propTypes.object,
    currentAgentId: propTypes.string
};
