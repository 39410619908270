import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import {
    OrganizationContext,
    useLoggedUser,
    useOrganizationProvider
} from '@nxlog/common-ui/hooks';
import { getSubscription } from '../../../api/endpoints/auth';
import { parseBool } from '../../../utils/helpers/functions';

function OrganizationProvider({ children }) {
    const [user] = useLoggedUser();

    // this is supposed to be an API function to get org info
    // instead it gets org info from the user object
    const findOrg = async (id) => {
        const res = Object.entries(user?.orgs || {}).find(([, value]) => value.id === id)?.[1];
        const onPremise = parseBool(
            window?.env?.REACT_APP_ON_PREMISE ?? process.env.REACT_APP_ON_PREMISE
        );
        if (res && !onPremise) {
            res.plan = await getSubscription(id);
        }
        return res;
    };

    const [, org, setOrg] = useOrganizationProvider(findOrg, {
        // eslint-disable-next-line no-console
        onError: (err) => console.error(err)
    });

    const orgState = useMemo(() => [org, setOrg], [org, setOrg]);

    return <OrganizationContext.Provider value={orgState}>{children}</OrganizationContext.Provider>;
}

export default OrganizationProvider;

OrganizationProvider.defaultProps = {
    children: null
};

OrganizationProvider.propTypes = {
    children: propTypes.oneOfType([propTypes.element, propTypes.array])
};
