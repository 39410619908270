import { useState, useCallback } from 'react';
import { changeSortOrder } from '../helpers/strings';

const useSortTable = (initialState = []) => {
    const [sortFields, setSortFields] = useState(initialState);

    const addSortField = useCallback(
        (field) => {
            if (!field) return;
            let updatedFields = [...sortFields];

            const addOneField = (oneField, fieldsArray) => {
                const { name } = oneField;
                const existingField = updatedFields.find((item) => item.name === name);
                const order = existingField
                    ? changeSortOrder(existingField.order)
                    : oneField.order || 'asc';
                return [{ name, order }, ...fieldsArray.filter((value) => value.name !== name)];
            };

            // if there's a single field
            if (typeof field === 'object' && !!field.name) {
                updatedFields = addOneField(field, updatedFields);
            }

            // if there's an array of fields
            if (typeof field === 'object' && !!field[0]?.name) {
                field
                    .slice()
                    .reverse()
                    .forEach((item) => {
                        updatedFields = addOneField(item, updatedFields);
                    });
            }

            setSortFields(updatedFields);
        },
        [sortFields]
    );

    const removeSortField = useCallback((field = {}) => {
        setSortFields((prev) => {
            const filteredFields = prev.filter((item) => item.name !== field.name);
            return filteredFields;
        });
    }, []);

    return {
        sortFields,
        addSortField,
        removeSortField
    };
};

export default useSortTable;
