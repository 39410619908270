import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { Form, ToastNotification } from '@nxlog/common-ui/components';
import { FormProvider } from 'react-hook-form';
import propTypes from 'prop-types';
import { TOAST_TYPES } from '../../../utils/constants/ids';
import ConfigTemplateFormContent from './contents';
import './configTemplateForm.scss';

export default function ConfigTemplateForm(props) {
    const { initialValues, onSubmit, hideName } = props;

    const defaultValues = useMemo(() => {
        const formValues = {
            connectType: 'connect',
            logLevel: 'INFO',
            address: []
        };

        Object.entries(initialValues).forEach(([key, value]) => {
            if (key.match(/^(host|port)-(.+)$/i)) {
                const host = `${initialValues[key.replace(/^port-/i, 'host-')] || ''}:${
                    initialValues[key.replace(/^host-/i, 'port-')] || ''
                }`;
                const index = formValues.address.findIndex((item) => item.host === host);
                if (index >= 0) {
                    formValues.address[index] = { ...formValues.address[index], host };
                } else {
                    formValues.address.push({ host });
                }
            } else {
                formValues[key] = value;
            }
        });

        return formValues;
    }, [initialValues]);

    const handleSubmit = (data) => {
        if (data.name) {
            data.name = data.name?.trim();
        }
        if (data.address?.length === 0) {
            toast.error(
                <ToastNotification
                    type={TOAST_TYPES.ERROR}
                    message={i18next.t('notifications_messages.errors.no_host_address', {
                        name: data.name || ''
                    })}
                    additionalInfo={i18next.t('notifications_messages.errors.no_host_address_info')}
                    extendedPreview
                />
            );
            return;
        }
        onSubmit(data);
    };

    return (
        <Form
            classname="config-template-form"
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            FormProvider={FormProvider}
            mode="onChange"
        >
            <ConfigTemplateFormContent hideName={hideName} />
        </Form>
    );
}

ConfigTemplateForm.defaultProps = {
    initialValues: {},
    onSubmit: () => {},
    hideName: false
};

ConfigTemplateForm.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    initialValues: propTypes.object,
    onSubmit: propTypes.func,
    hideName: propTypes.bool
};
