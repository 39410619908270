const statementNames = {
    COMMENT: 'comment',
    STRING_SINGLE: 'string-single',
    STRING_DOUBLE: 'string-double',
    NUMBER: 'number',
    BOOL: 'bool',
    UNDEF: 'undef',
    FUNCTION: 'function',
    OPEN_PARENTHESIS: 'open-parenthesis',
    CLOSE_PARENTHESIS: 'close-parenthesis',
    SEMICOLON: 'semicolon',
    COMMA: 'comma',
    PLUS: 'plus',
    MINUS: 'minus',
    ASTERISK: 'asterisk',
    SLASH: 'slash',
    EQUAL: 'equal',
    ASSIGNMENT: 'assignment',
    VARIABLE: 'variable',
    GLOBAL_VARIABLE: 'global-variable',
    IF: 'if',
    ELSE: 'else',
    OPEN_BLOCK: 'open-block',
    CLOSE_BLOCK: 'close-block'
};

const statementRegex = [
    {
        regex: /^\s*#.*$/,
        type: statementNames.COMMENT
    },
    {
        regex: /^\s*'([^'])*'/,
        type: statementNames.STRING_SINGLE
    },
    {
        regex: /^\s*"(\\.|[^\\"])*"/,
        type: statementNames.STRING_DOUBLE
    },
    {
        regex: /^\s*(\d+(\.\d+)?|\.\d+)/,
        type: statementNames.NUMBER
    },
    {
        regex: /^\s*(true|false)\b/,
        type: statementNames.BOOL
    },
    {
        regex: /^\s*undef\b/,
        type: statementNames.UNDEF
    },
    {
        regex: /^\s*if\b/i,
        type: statementNames.IF
    },
    {
        regex: /^\s*else\b/i,
        type: statementNames.ELSE
    },
    {
        regex: /^\s*(?:(\w+[a-zA-Z0-9_-]*?)->)*(?<fnName>\w+[a-zA-Z0-9_])\s*\(/,
        type: statementNames.FUNCTION
    },
    {
        regex: /^\s*\(/,
        type: statementNames.OPEN_PARENTHESIS
    },
    {
        regex: /^\s*\)/,
        type: statementNames.CLOSE_PARENTHESIS
    },
    {
        regex: /^\s*;/,
        type: statementNames.SEMICOLON
    },
    {
        regex: /^\s*,/,
        type: statementNames.COMMA
    },
    {
        regex: /^\s*\+/,
        type: statementNames.PLUS
    },
    {
        regex: /^\s*-/,
        type: statementNames.MINUS
    },
    {
        regex: /^\s*\*/,
        type: statementNames.ASTERISK
    },
    {
        regex: /^\s*\//,
        type: statementNames.SLASH
    },
    {
        regex: /^\s*[=]{2,3}/,
        type: statementNames.EQUAL
    },
    {
        regex: /^\s*[=]{1}/,
        type: statementNames.ASSIGNMENT
    },
    {
        regex: /^\s*\$[a-zA-Z_][a-zA-Z0-9_]*/,
        type: statementNames.VARIABLE
    },
    {
        regex: /^\s*%[a-zA-Z_][a-zA-Z0-9_]*%/,
        type: statementNames.GLOBAL_VARIABLE
    },
    {
        regex: /^\s*{/i,
        type: statementNames.OPEN_BLOCK
    },
    {
        regex: /^\s*}/i,
        type: statementNames.CLOSE_BLOCK
    }
];

function getNextToken(i, str) {
    const txt = str.slice(i);
    const token = statementRegex.find((t) => t.regex.test(txt));
    if (!token) return null;
    return {
        ...token,
        index: i,
        match: str.slice(i).match(token.regex)[0]
    };
}

function parseScript(code) {
    const tokens = [];
    let i = 0;
    while (i < code.length) {
        const token = getNextToken(i, code);
        if (token) {
            tokens.push(token);
            i += token.match.length;
        } else {
            return null;
        }
    }
    const getStatements = (_tokens, stopAt = []) => {
        const statements = [];
        while (_tokens.length) {
            const statement = _tokens.shift();
            if (stopAt.includes(statement.type)) {
                _tokens.unshift(statement);
                return statements;
            }
            switch (statement.type) {
                case statementNames.COMMA:
                case statementNames.SEMICOLON:
                    return statements;
                case statementNames.FUNCTION:
                    statement.args = [];
                    while (_tokens[0] && _tokens[0].type !== statementNames.CLOSE_PARENTHESIS) {
                        statement.args.push(
                            getStatements(_tokens, [statementNames.CLOSE_PARENTHESIS])
                        );
                    }
                    if (_tokens[0] && _tokens[0].type === statementNames.CLOSE_PARENTHESIS) {
                        _tokens.shift();
                        statements.push(statement);
                    } else {
                        return false;
                    }

                    break;
                default:
                    statements.push(statement);
                    break;
            }
        }
        return statements;
    };

    const listStatements = [];
    while (tokens.length) {
        const statements = getStatements(tokens);
        if (statements.length) {
            listStatements.push(statements);
        } else {
            break;
        }
    }
    return listStatements;
}

export function isValidQuoteString(str) {
    const statements = parseScript(`${str}`.trim());
    if (!statements) return false;
    let lastTokenIsOperator = true;
    if (statements.length === 1) {
        while (statements[0].length) {
            const token = statements[0].shift();
            if (lastTokenIsOperator) {
                if (
                    [
                        statementNames.STRING_DOUBLE,
                        statementNames.STRING_SINGLE,
                        statementNames.VARIABLE,
                        statementNames.FUNCTION
                    ].includes(token.type)
                ) {
                    lastTokenIsOperator = false;
                } else {
                    return false;
                }
            } else if (token.type === statementNames.PLUS) {
                lastTokenIsOperator = true;
            } else {
                return false;
            }
        }
        return statements[0].length === 0 && !lastTokenIsOperator;
    }
    return false;
}

export default null;
