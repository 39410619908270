import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmChronicle = {
    label: 'Google Chronicle',
    name: 'om_chronicle',
    type: 'sendTo',
    defaultDirectives: 'Module      om_chronicle',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'URL',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'AddHeader',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'ChronicleBatchSize',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.ChronicleVersion === 'v1',
            name: 'ChronicleKey',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'ChronicleMode',
            type: 'select',
            options: ['structured', 'unstructured'],
            defaultValue: 'unstructured'
        },
        {
            name: 'ChronicleRegion',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.ChronicleMode === 'structured',
            name: 'ChronicleSchema',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'ChronicleVersion',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Compression',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.ChronicleVersion === 'v2',
            name: 'CredentialsFile',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => item.ChronicleVersion === 'v2',
            name: 'CustomerId',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPBasicAuthUser',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => !!item.HTTPBasicAuthUser,
            name: 'HTTPBasicAuthPassword',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSAllowExpired',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSAllowUntrusted',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSCADir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCAFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCAThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSearchAllCertStores',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSCertFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCertThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCRLDir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCRLFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSKeyPass',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSSLCompression',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            if: (item) => item.ChronicleMode === 'unstructured',
            name: 'LogType',
            type: 'string',
            defaultValue: '',
            required: (item) => item.ChronicleMode === 'unstructured'
        },
        {
            name: 'Proxy',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Reconnect',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'SchemaDir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SchemaMap',
            type: 'multiline-string-block',
            defaultValue: ''
        },
        {
            name: 'UseCNGCertificates',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'OutputRequestSize',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_chronicle'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
