import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImJava = {
    label: 'Java Language',
    name: 'im_java',
    type: 'collect',
    defaultDirectives: 'Module      im_java',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ClassPath',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'VMOption',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'VMOptions',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'JavaHome',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Run',
            type: 'string',
            defaultValue: '',
            required: true
        },
        ...GENERAL_INPUT_DIRECTIVES('im_java'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
