import React from 'react';
import propTypes from 'prop-types';
import { AxiosInterceptor } from '../../api/apiService';
import OrganizationProvider from './organizationProvider';
import UserProvider from './userProvider';
import { useStoreOrgId } from '../../utils/hooks';
import useStoreTenantId from '../../utils/hooks/useStoreTenantId';

function AuthProvider({ children }) {
    const { orgId } = useStoreOrgId();

    // gets tenantId and sets it into the context
    useStoreTenantId();

    return (
        <AxiosInterceptor>
            <UserProvider orgId={orgId}>
                <OrganizationProvider>{children}</OrganizationProvider>
            </UserProvider>
        </AxiosInterceptor>
    );
}

export default AuthProvider;

AuthProvider.defaultProps = {
    children: null
};

AuthProvider.propTypes = {
    children: propTypes.oneOfType([propTypes.element, propTypes.array])
};
