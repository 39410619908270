import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, WithTooltip } from '@nxlog/common-ui/components';
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { omit } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useToggle } from '@nxlog/common-ui/hooks';
import DownloadIcon from '@nxlog/common-ui/dist/components/svgs/download';
import DocumentIcon from '@nxlog/common-ui/dist/components/svgs/document';
import CircleXIcon from '@nxlog/common-ui/dist/components/svgs/circle-x';
import Loader from '../../components/common/loader';
import { useFetch } from '../../utils/hooks';
import InfoSidebar from '../../components/infoSidebar';
import TabSelector from '../../components/common/tabSelector';
import TableStatusModules from '../../components/tables/tableStatusModules';
import RightSidebar from '../../components/rightSidebar';
import { APP_AGENTS, APP_MODULE } from '../../utils/constants/routes';
import ViewConfigModal from '../../components/modals/viewConfigModal';
import { getAgentById } from '../../api/endpoints/agents';
import ViewLogModal from '../../components/modals/viewLogModal';
import {
    getAgentLogData,
    restartAgent,
    startAgent,
    stopAgent,
    exportAgent,
    deleteAgentData,
    syncAgentData,
    enrollAgent,
    renewAgentCertificate,
    refreshAgent,
    enrollAgentWithTemplate,
    assignTemplateData
} from '../../redux/reducers/agents';
import { createDefaultTemplate } from '../../utils/templates';
import useAgentsTabs from './tabs/agents';
import { getAgentMenuOptions, getSingleAgentSideBarData } from '../../utils/helpers/functions';
import { modules } from '../../resources/static/data';
import Header from '../../components/header';
import NotFoundPage from '../404';
import {
    getEntitlementsData,
    selectIsEnrollmentDisabled,
    selectLicensesStatus
} from '../../redux/reducers/licenses';
import useEnrollmentAddress from '../../utils/hooks/useEnrollmentAddress';
import CreateTemplateFromConfigModal from '../../components/modals/createTemplateFromConfigModal';
import AssignTemplateModal from '../../components/modals/assignTemplateModal';
import { getTemplatesData, selectTemplates } from '../../redux/reducers/templates';
import useRepeatWhenVisible from '../../utils/hooks/useRepeatWhenVisible';
import './singleAgent.scss';

const DOWNLOAD_TITLES = {
    CA: 'agent-ca.pem',
    Certificate: 'agent-cert.pem',
    Key: 'agent-key.pem'
};
const AGENT_FIELDS = [
    'info',
    'stats',
    'state',
    'session',
    'config',
    'modules/*/all',
    'comment',
    'polled-time',
    'started-time',
    'files',
    'template-name',
    'os-release',
    'arch'
];

export default function SingleAgentPage() {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logData = useSelector((state) => state.agents.agentLog);
    const ip = useEnrollmentAddress();
    const licenseStatus = useSelector(selectLicensesStatus);
    const isEnrollmentDisabled = useSelector(selectIsEnrollmentDisabled);
    const configNames = useSelector(selectTemplates);

    const [currentAgentId, setCurrentAgentId] = useState(null);
    const [logModalShow, setLogModalShow] = useState(false);
    const [config, setConfig] = useState({ data: '', status: 'success' });
    const [currentRoute, setCurrentRoute] = useState(null);
    const [isConfigModalVisible, toggleConfigModalVisible] = useToggle();
    const [saveAsTemplateModal, setSaveAsTemplateModal] = useState(null);
    const [isAgentsTemplateModalVisible, setIsAgentsTemplateModalVisible] = useState(false);

    const {
        response: agentResponse,
        refetch: refetchAgentResponse,
        loading,
        error
    } = useFetch(getAgentById, params.agentId, { fields: AGENT_FIELDS.join(',') });

    const [DOWNLOAD_FILES, setDownloadFiles] = useState([]);

    useEffect(() => {
        if (agentResponse && agentResponse.polledTime) {
            agentResponse.outOfDate =
                Math.ceil(
                    (new Date().getTime() - new Date(agentResponse.polledTime).getTime()) / 1000
                ) >=
                60 * 5;
        }
    }, [agentResponse]);

    useEffect(() => {
        dispatch(getTemplatesData({ fields: 'id,name,tags' }));
    }, []);

    useEffect(() => {
        if (!agentResponse?.files?.cert) return;
        setDownloadFiles(
            Object.entries(DOWNLOAD_TITLES).map(([name, fileName]) => {
                const item = {
                    name,
                    fileName,
                    exist: !!agentResponse.files.cert[fileName],
                    download: () => {
                        const blob = new Blob([agentResponse.files.cert[fileName]], {
                            type: 'text/plain'
                        });
                        const a = document.createElement('a');
                        a.href = URL.createObjectURL(blob);
                        a.download = fileName;
                        a.click();
                    }
                };
                return item;
            })
        );
    }, [agentResponse]);

    useRepeatWhenVisible(() => {
        dispatch(getEntitlementsData());
        refetchAgentResponse();
    }, 10000);

    const agentConfig = agentResponse?.config;
    const messages = useMemo(
        () =>
            agentResponse?.status
                ? Object.entries(agentResponse[`${agentResponse?.status}s`] || {}).map(
                      ([id, { message, help, note }]) => ({
                          id,
                          type: agentResponse?.status,
                          message,
                          help,
                          note
                      })
                  )
                : [],
        [agentResponse]
    );
    const sideBarData = useMemo(() => getSingleAgentSideBarData(agentResponse), [agentResponse]);
    const agentStatus = useMemo(
        () =>
            (() => ({
                enrolled: agentResponse?.enrolled,
                online: agentResponse?.online
            }))(),
        [agentResponse]
    );

    const handleGoBack = () => {
        navigate(`/${APP_AGENTS}`);
    };

    const handleModuleClick = (id) => {
        navigate(APP_MODULE(id));
    };

    const getConfigData = (data) => {
        const normalizedConfig = {};
        Object.entries(data).forEach(([key, value]) => {
            const partOfKey = key.split('-')[1];
            if (partOfKey === 'port' || partOfKey === 'host') {
                if (partOfKey === 'host') {
                    normalizedConfig[partOfKey] = normalizedConfig[partOfKey]
                        ? [...normalizedConfig[partOfKey], value]
                        : [value];
                }
            } else {
                normalizedConfig[key] = value;
            }
        });
        return `${createDefaultTemplate(omit(normalizedConfig, ['name', 'comment']))}`;
    };

    const handleViewConfig = (data) => {
        if (data.logLevel) {
            setConfig({
                data: getConfigData(data),
                status: 'success'
            });
        }
        toggleConfigModalVisible();
    };

    const AGENT_DROPDOWN_FUNCTIONS = {
        restart: (agentId) => {
            dispatch(restartAgent(agentId));
        },
        start: (agentId) => {
            dispatch(startAgent(agentId));
        },
        stop: (agentId) => {
            dispatch(stopAgent(agentId));
        },
        viewLogs: (agentId) => {
            setCurrentAgentId(agentId);
            dispatch(getAgentLogData(agentId));
            setLogModalShow(true);
        },
        delete: (agentId, agentName) => {
            dispatch(deleteAgentData({ id: agentId, hostname: agentName }));
            navigate(-1);
        },
        enroll: (agentId, agentName) => {
            dispatch(enrollAgent({ id: agentId, ip, hostname: agentName }))
                .unwrap()
                .then(() => {
                    refetchAgentResponse();
                })
                .catch(() => {});
        },
        enrollwithtemplate: (agentId) => {
            if (isEnrollmentDisabled) return;
            setCurrentAgentId(agentId);
            setIsAgentsTemplateModalVisible('enrollwithtemplate');
        },
        export: (agentId) => {
            dispatch(exportAgent(agentId))
                .unwrap()
                .then((res) => {
                    const blob = new Blob([res.data], { type: 'application/octet-stream' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    const [, fileName] = res.headers['content-disposition'].split('=');
                    a.href = url;
                    a.download = `${`${fileName || agentId}`.trim()}.zip`;
                    a.click();
                })
                .catch(() => {});
        },
        updateAndRestart: (agentId) => {
            dispatch(syncAgentData(agentId));
        },
        refresh: (agentId) => {
            dispatch(refreshAgent(agentId))
                .unwrap()
                .then(() => {
                    refetchAgentResponse();
                })
                .catch(() => {});
        },
        renewCertificate: (agentId) => {
            dispatch(renewAgentCertificate(agentId));
        },
        assignTemplate: (agentId) => {
            setCurrentAgentId(agentId);
            setIsAgentsTemplateModalVisible('assignTemplate');
        }
    };

    const handleSelectOption = useCallback((value, agentId, agentName) => {
        if (!AGENT_DROPDOWN_FUNCTIONS[value]) {
            return;
        }
        AGENT_DROPDOWN_FUNCTIONS[value](agentId, agentName);
    }, []);

    const handleSaveAsTemplateClick = (data) => setSaveAsTemplateModal(getConfigData(data));

    const AgentsTabs = useAgentsTabs({
        comment: agentResponse?.comment || '',
        agentConfig,
        agentStats: agentResponse,
        handleViewConfig,
        handleSaveAsTemplateClick,
        hasResponse: !!agentResponse,
        refetchAgentConfig: refetchAgentResponse,
        templateName: agentResponse?.templateName
    });

    const options = getAgentMenuOptions({
        item: agentResponse,
        licenseStatus,
        isSingleAgent: true,
        isEnrollmentDisabled
    });

    const handleSaveAgentTemplate = (data) => {
        if (isAgentsTemplateModalVisible === 'assignTemplate') {
            dispatch(assignTemplateData({ id: data.value, agentId: agentResponse?.id }))
                .unwrap()
                .then(() => {
                    refetchAgentResponse();
                })
                .catch(() => {});
        } else {
            dispatch(
                enrollAgentWithTemplate({
                    agentId: agentResponse?.id,
                    templateId: data.value,
                    hostname: agentResponse.hostname
                })
            )
                .unwrap()
                .then(() => {
                    refetchAgentResponse();
                })
                .catch(() => {});
        }
        setIsAgentsTemplateModalVisible(false);
    };

    return (
        <>
            {!!error && <NotFoundPage />}
            {!error && loading && !agentResponse && <Loader />}
            {!error && agentResponse && (
                <div className="single-agent-page w-100">
                    <Header
                        title={agentResponse?.hostname ?? 'No agent name'}
                        titleIcon={
                            <div className="d-flex w-100">
                                <button className="agent-name" onClick={handleGoBack}>
                                    Agents
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                        }
                    />
                    <div className="single-agent-page-content">
                        <InfoSidebar
                            title="Agent Details"
                            comment={agentResponse?.comment || ''}
                            messages={agentResponse?.enrolled ? messages : []}
                            options={options}
                            data={sideBarData}
                            agentStatus={agentStatus}
                            onSelectOption={handleSelectOption}
                        >
                            <div className="download-links-container">
                                <div className="download-links-title">Download links:</div>
                                {DOWNLOAD_FILES.map((item) => (
                                    <div
                                        className={`download-links-row${
                                            item.exist ? '' : ' download-links-row-disabled'
                                        }`}
                                        key={item.name}
                                    >
                                        <div className="download-links-row-content">
                                            <DocumentIcon />
                                            {item.name}
                                        </div>
                                        <WithTooltip
                                            message={
                                                item.exist ? null : `The agent has no ${item.name}`
                                            }
                                            placement="right"
                                        >
                                            <Button
                                                disabled={!item.exist}
                                                data-testid={`download-${item.name}`}
                                                icon={
                                                    item.exist ? (
                                                        <DownloadIcon />
                                                    ) : (
                                                        <CircleXIcon outlined fill="#7c7c7c" />
                                                    )
                                                }
                                                onClick={item.download}
                                            />
                                        </WithTooltip>
                                    </div>
                                ))}
                            </div>
                        </InfoSidebar>
                        <div className="form-container">
                            <TabSelector tabs={AgentsTabs} classname="agent-tabs" />
                        </div>
                    </div>
                    <RightSidebar
                        isVisible={!!currentRoute}
                        onClose={() => setCurrentRoute(null)}
                        title="text"
                        classname="single-agent-popup"
                    >
                        <div className="sub-header">
                            <FontAwesomeIcon icon={faXmarkCircle} /> Error
                        </div>
                        <div className="modules">
                            {modules.map((module) => (
                                <TableStatusModules
                                    key={module.title}
                                    id={module.title}
                                    title={module.title}
                                    classname="routes-module-wrapper"
                                    data={module.data}
                                    onNameClick={handleModuleClick}
                                />
                            ))}
                        </div>
                    </RightSidebar>
                    <ViewConfigModal
                        isShown={isConfigModalVisible}
                        onClose={toggleConfigModalVisible}
                        data={config}
                    />
                    <ViewLogModal
                        show={logModalShow}
                        onHide={() => setLogModalShow(false)}
                        logData={logData}
                        currentAgentId={currentAgentId}
                    />
                    <CreateTemplateFromConfigModal
                        config={saveAsTemplateModal}
                        onClose={() => setSaveAsTemplateModal(null)}
                    />
                    <AssignTemplateModal
                        data={configNames}
                        isShown={!!isAgentsTemplateModalVisible}
                        onClose={() => setIsAgentsTemplateModalVisible(false)}
                        onSave={handleSaveAgentTemplate}
                        action={isAgentsTemplateModalVisible}
                    />
                </div>
            )}
        </>
    );
}
