import React from 'react';
import propTypes from 'prop-types';
import { Input } from '@nxlog/common-ui/components';
import { agentTagNormalizer, createFilterString } from '../../utils/helpers/strings';
import { handleAgentApiFilter } from '../../utils/helpers/tables';
import './searchAgents.scss';
import useValidateTag from '../../utils/hooks/useValidateTag';

export default function SearchAgents(props) {
    const { tags, setTags, appliedFilters, resetAndFetchAgents, disabled } = props;
    const validTags = [
        'ip_address',
        'events_per_second',
        'agentGroup',
        'agentTemplate',
        'cpu_load',
        'enrolled',
        'hostname',
        'memory_used',
        'status',
        'template-name',
        'os-release',
        'os',
        'arch',
        'all'
    ];

    const sampleSearchTags = [
        'ip_address:127.0.0.1',
        'events_per_second:0',
        'agentGroup:0',
        'template-name:template1',
        'cpu_load>0.06',
        'deployment:enrolled',
        'hostname:lively',
        'memory<0.01G',
        'arch:x86-64',
        'os-release:CentOS',
        'os:Linux',
        'status:ok'
    ];

    const filterDataBySearchTerm = (appliedTags) => {
        handleAgentApiFilter(appliedTags, resetAndFetchAgents, createFilterString, appliedFilters);
    };

    const { errorMessage, validateTagCallback } = useValidateTag({
        validTags,
        tagNormalizer: agentTagNormalizer
    });

    return (
        <div data-testid="search-agents" className="search-agents">
            <Input
                type="text"
                classname="with-search-icon"
                placeholder="Search or filter using tags e.g: status:ok"
                isSearch
                isDropdown={false}
                tagName="AgentTags"
                tags={tags}
                setTags={setTags}
                filterDataBySearchTerm={filterDataBySearchTerm}
                validTags={validTags}
                validateTag={validateTagCallback}
                sampleSearchTags={sampleSearchTags}
                tagNormalizer={agentTagNormalizer}
                disabled={disabled}
                errorMessage={errorMessage}
            />
        </div>
    );
}

SearchAgents.defaultProps = {
    tags: [],
    setTags: () => {},
    appliedFilters: [],
    resetAndFetchAgents: () => {},
    disabled: false
};

SearchAgents.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    tags: propTypes.array,
    setTags: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    appliedFilters: propTypes.array,
    resetAndFetchAgents: propTypes.func,
    disabled: propTypes.bool
};
