import { useApplicationContext } from '@nxlog/common-ui/hooks';
import AgentsIcon from '@nxlog/common-ui/dist/components/svgs/agents';
import { useNavigate } from 'react-router-dom';
import useEnrollmentAddress from '../../utils/hooks/useEnrollmentAddress';
import useStoreOrgId from '../../utils/hooks/useStoreOrgId';
import tutorialContent from '../../assets/contents/tutorialContent';
import Wizard from '../wizard';
import './welcomeScreen.scss';

export default function WelcomeScreen() {
    const { portalUrl } = useApplicationContext();
    const enrollmentHost = useEnrollmentAddress();
    const { orgId } = useStoreOrgId();

    const navigate = useNavigate();
    const redirect = () => navigate('/agents/configurations');

    return (
        <div className="welcome-wrapper" data-testid="WelcomeScreen">
            <div className="welcome-content">
                <div className="round-icon">
                    <AgentsIcon />
                </div>{' '}
                <Wizard
                    title={`You haven't added any agents yet, let us help you get started!`}
                    content={tutorialContent({ portalUrl, orgId, enrollmentHost })}
                    endCaption="Go to Templates"
                    onEnd={redirect}
                />
            </div>
        </div>
    );
}
