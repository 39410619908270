import { createSlice } from '@reduxjs/toolkit';
import { getSolutionPacks, getSolutionPackByIdentifier } from '../../api/endpoints/solutionPacks';
import { createControllableAsyncThunk } from '../asyncThunkHelper';

export const getSolutionPacksData = createControllableAsyncThunk(
    'solutionPacks/getSolutionPacksData',
    async (filter) => {
        const data = await getSolutionPacks(filter);
        return data;
    }
);

export const getSolutionPackDetails = createControllableAsyncThunk(
    'solutionPacks/getSolutionPackDetails',
    async ({ identifier, version }) => {
        const data = await getSolutionPackByIdentifier({ identifier, version });
        return data;
    }
);

const initialState = {
    loading: false,
    solutionPacks: [],
    error: null
};

const solutionPacksSlice = createSlice({
    name: 'templates',
    initialState,
    reducers: {
        deselectSolutionPack: (state) => {
            state.selectedSolutionPack = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSolutionPacksData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSolutionPacksData.fulfilled, (state, action) => {
                state.solutionPacks = action.payload.map((item) => {
                    const itemCopy = { ...item };
                    itemCopy.type = 'sp';
                    return itemCopy;
                });
                state.loading = false;
            })
            .addCase(getSolutionPacksData.rejected, (state, action) => {
                state.error = action.payload?.message;
                state.loading = false;
            })
            .addCase(getSolutionPackDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSolutionPackDetails.fulfilled, (state, action) => {
                state.selectedSolutionPack = action.payload;
                state.loading = false;
            })
            .addCase(getSolutionPackDetails.rejected, (state, action) => {
                state.error = action.payload?.message;
                state.loading = false;
            });
    }
});

export const { deselectSolutionPack } = solutionPacksSlice.actions;

export const selectSolutionPacks = (state) => state.solutionPacks.solutionPacks;
export const selectSolutionPacksError = (state) => state.solutionPacks.error;
export const selectSolutionPacksLoading = (state) => state.solutionPacks.loading;

export default solutionPacksSlice.reducer;
