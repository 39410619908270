import React from 'react';
import { AgentType } from '../../../../utils/constants/propTypes';
import { formatDateTime, formatMemoryUsage } from '../../../../utils/helpers/functions';
import './tableAgentDetails.scss';

export default function TableAgentDetails(props) {
    const { rowData } = props;

    const version = rowData?.data.version;
    const os = rowData?.data.os;
    const startTime = formatDateTime(rowData?.data.startedTime);
    const pollTime = formatDateTime(rowData?.data.polledTime);
    const modules = rowData?.data.modules;
    const pid = rowData?.data.pid;
    const agentLoad = rowData?.data.cpuLoad;
    const memoryUsed = rowData?.data.memoryUsed;

    const modulesCount = modules ? Object.keys(modules).length : '--';
    const cpuLoad = agentLoad ? Math.round(agentLoad * 100) / 100 : '--';

    return (
        <div data-testid="table-agent-details" className="agent-details">
            <div className="accordion-body expanded">
                <div className="agent-details-info">
                    <div className="details-title">Details</div>
                    <div className="details-info">
                        <div className="single-detail">
                            <div className="title">Started:</div>
                            <div className="value" data-testid="table-agent-details-started-time">
                                {startTime || '--'}
                            </div>
                        </div>
                        <div className="single-detail">
                            <div className="title">Load:</div>
                            <div data-testid="table-agent-details-cpu-load" className="value">
                                {cpuLoad}
                            </div>
                        </div>
                        <div className="single-detail">
                            <div className="title">Pid:</div>
                            <div data-testid="table-agent-details-pid" className="value">
                                {pid || '--'}
                            </div>
                        </div>
                        <div className="single-detail">
                            <div className="title">Mem:</div>
                            <div data-testid="table-agent-details-memory-used" className="value">
                                {`${formatMemoryUsage(memoryUsed)}` || '--'}
                            </div>
                        </div>
                    </div>
                    <div className="details-info">
                        <div className="single-detail">
                            <div className="title">OS:</div>
                            <div data-testid="table-agent-details-os" className="value">
                                {os || '--'}
                            </div>
                        </div>
                        <div className="single-detail">
                            <div className="title">Version:</div>
                            <div data-testid="table-agent-version" className="value">
                                {version || '--'}
                            </div>
                        </div>
                        <div className="single-detail">
                            <div className="title">Servertime:</div>
                            <div data-testid="table-agent-polled-time" className="value">
                                {pollTime || '--'}
                            </div>
                        </div>
                        <div className="single-detail">
                            <div className="title">Module count:</div>
                            <div data-testid="table-agent-modules-count" className="value">
                                {modulesCount}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

TableAgentDetails.defaultProps = {
    rowData: null
};

TableAgentDetails.propTypes = {
    rowData: AgentType
};
