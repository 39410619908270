import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AGENT_FILTERS } from '../helpers/filters';

const LIST_FILTERS = Object.values(AGENT_FILTERS);

function useFiltersFromURL() {
    const [searchParams, setSearchParams] = useSearchParams();
    const getFiltersFromURL = () => {
        if (
            Array.from(searchParams)
                .map(([key]) => key)
                .includes('filter')
        ) {
            const idFilter = Array.from(searchParams).find(([key]) => key === 'filter')[1];
            return LIST_FILTERS.filter(({ id }) => id === idFilter);
        }
        return JSON.parse(localStorage.getItem('appliedFilters')) ?? [];
    };
    const [filters, setFilters] = useState(getFiltersFromURL());

    useEffect(() => {
        if (
            Array.from(searchParams)
                .map(([key]) => key)
                .includes('filter')
        ) {
            const urlFilters = getFiltersFromURL();
            localStorage.setItem('appliedFilters', JSON.stringify(urlFilters));
            setFilters(urlFilters);
            setSearchParams(Array.from(searchParams).filter(([key]) => key !== 'filter'));
        } else {
            setFilters([]);
        }
    }, [searchParams]);

    return filters;
}

export default useFiltersFromURL;
