import { getBaseUrl } from '../../utils/helpers/functions';
import { apiService, portalApiService } from '../apiService';

export async function login() {
    const res = await apiService.post('/api/v1/login', {
        client_id: process.env.REACT_APP_CLIENT_ID,
        redirect_uri: `${window.location.origin}/token`,
        fast: false
    });
    return res;
}

export async function logout() {
    const res = await apiService.post('/api/v1/logout');
    return res;
}

export async function getToken(state, code) {
    const res = await apiService.get('/api/v1/token', {
        params: { state, code, client_id: process.env.REACT_APP_CLIENT_ID }
    });
    return res;
}

export async function getUserInfo() {
    const res = await apiService.get('/api/v1/user-info');
    return res;
}

export async function getTenantId() {
    try {
        const res = await apiService.get('/api/v1/tenant-id');
        if (res && res?.data) return res?.data;
    } catch {
        /* */
    }
    return null;
}

export async function getSubscription(orgId) {
    try {
        const BASE_URL = getBaseUrl() || '';
        const res = await portalApiService.get(
            `${BASE_URL.trim().match(/\/api\/?$/) ? '' : '/api'}/portal/api/v1/orgs/${orgId}/asset`
        );
        if (res && res?.data) return res?.data;
    } catch {
        /* */
    }
    return null;
}
