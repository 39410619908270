import PropTypes from 'prop-types';
import { Modal, ButtonToolbar } from 'react-bootstrap';
import { Button } from '@nxlog/common-ui/components';
import './confirmModal.scss';

function Confirm(props) {
    const { className } = props;

    return (
        <Modal
            className={className ? `confirm-modal ${className}` : 'confirm-modal'}
            show={props.show}
            onClose={props.onCancel}
            size="md"
            centered
        >
            <Modal.Header closeButton onHide={props.onCancel}>
                <Modal.Title data-testid="modal-title" className="confirm-modal-title">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body data-testid="modal-body">{props.body}</Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button data-testid="modal-cancel" ghostBtn onClick={props.onCancel}>
                        {props.cancel}
                    </Button>
                    <Button data-testid="modal-confirm" className="ms-4" onClick={props.onConfirm}>
                        {props.confirm}
                    </Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
}

Confirm.defaultProps = {
    className: null
};

Confirm.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    cancel: PropTypes.string.isRequired,
    confirm: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default Confirm;
