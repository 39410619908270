import { Draft } from '@nxlog/common-ui/dist/components/svgs';
import './badges.scss';

export function DraftBadge() {
    return (
        <span data-testid="badge-draft" className="draft-badge">
            <Draft />
            Draft
        </span>
    );
}

export function SolutionPackBadge() {
    return (
        <span data-testid="badge-solution-pack" className="sp-badge">
            SP
        </span>
    );
}

export function PaidBadge() {
    return (
        <span data-testid="badge-paid" className="paid-badge">
            $
        </span>
    );
}
