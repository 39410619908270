export const extractXml = {
    label: 'Extract XML',
    name: 'extract_xml',
    type: 'action',
    extension: [
        {
            module: 'xm_xml',
            content: `<Extension xml>\n\tModule    xm_xml\n</Extension>`
        }
    ],
    match: (content) => /^\s*extract_xml\s*\(\s*\)\s*;?\s*$/i.test(content),
    inline: true,
    getCode: (action, level) => `${'  '.repeat(level)}extract_xml();`
};

export default null;
