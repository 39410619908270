import { useContext, useState } from 'react';
import propTypes from 'prop-types';
import { Angle, Download, Eye } from '@nxlog/common-ui/dist/components/svgs';
import { ReactComponent as SubdirIcon } from '../../../../assets/img/subdirectory-arrow-right.svg';
import { getSPFile, getSPZipFile } from '../../../../api/endpoints/solutionPacks';
import { saveTextFile, saveZipFile } from '../../../../utils/helpers/files';
import { DragAndDropContext } from '../../utils/helper';
import InstructionsModal from '../instructionsModal';
import './solutionPackFileList.scss';

function TreeView({ name, option, branch }) {
    const [unwrapped, setUnwrapped] = useState(false);
    const { selectedSP } = useContext(DragAndDropContext);
    const disableUnwrap = typeof branch === 'object' && Object.keys(branch).length === 0;

    const downloadSPFile = (path) => {
        if (selectedSP) {
            const { identifier, version } = selectedSP;

            getSPFile({ identifier, version, filePath: path }).then((response) => {
                saveTextFile(response.data, path.split('/').pop());
            });
        }
    };

    return (
        !(Array.isArray(branch) && branch.length === 0) && (
            <>
                <p className="branch-title">
                    {disableUnwrap ? (
                        <span className="branch-name">
                            <span>{name}</span>
                            <span className="no-files">no files</span>
                        </span>
                    ) : (
                        <button
                            className="branch-name"
                            onClick={() => setUnwrapped((u) => !u)}
                            data-testid={unwrapped ? 'wrap-btn' : 'unwrap-btn'}
                        >
                            {name}
                            {unwrapped ? <Angle.Up /> : <Angle.Down />}
                        </button>
                    )}
                    {option}
                </p>
                {unwrapped && (
                    <ul className="file-list">
                        {Array.isArray(branch) &&
                            branch.length > 0 &&
                            branch.map((path) => (
                                <li
                                    key={path}
                                    className="file-list-item"
                                    data-testid="file-list-item"
                                >
                                    <Download className="list-icon" />
                                    <button
                                        type="button"
                                        className="file-download-btn"
                                        onClick={() => downloadSPFile(path)}
                                    >
                                        {path.split('/').pop()}
                                    </button>
                                </li>
                            ))}
                        {!Array.isArray(branch) &&
                            typeof branch === 'object' &&
                            Object.entries(branch).map(([key, subbranch]) => (
                                <li key={key} className="file-list-item">
                                    <TreeView
                                        name={
                                            <>
                                                <div className="list-icon">
                                                    <SubdirIcon />
                                                </div>
                                                <span className="caption">
                                                    {key.split('_').join(' ')}
                                                </span>
                                            </>
                                        }
                                        branch={subbranch}
                                    />
                                </li>
                            ))}
                    </ul>
                )}
            </>
        )
    );
}

TreeView.defaultProps = {
    name: '-',
    option: null
};

TreeView.propTypes = {
    name: propTypes.oneOfType([propTypes.string, propTypes.object]),
    option: propTypes.oneOfType([propTypes.string, propTypes.object]),
    branch: propTypes.oneOfType([propTypes.array, propTypes.object]).isRequired
};

export function SolutionPackFileList({ spFiles }) {
    const { selectedSP, spManifest } = useContext(DragAndDropContext);

    const [instructionPath, setInstructionPath] = useState(null);

    const downloadAllFiles = (path) => {
        if (selectedSP) {
            const { identifier, version } = selectedSP;

            getSPZipFile({ identifier, version, filePath: path }).then((response) => {
                saveZipFile(response.data, `${path.split('/').pop()}.zip`);
            });
        }
    };

    return (
        <>
            <div className="files-wrapper">
                <div className="heading-wrapper">
                    <h3 className="file-list-heading">
                        <Download />
                        File Download
                    </h3>
                </div>
                <div className="file-list-container">
                    {spFiles && typeof spFiles === 'object' && (
                        <>
                            {spFiles.siemContent && (
                                <div className="section">
                                    <TreeView
                                        name={
                                            <>
                                                <b>SIEM Content</b>
                                                {' (Output)'}
                                            </>
                                        }
                                        option={
                                            <>
                                                {!!Object.keys(spFiles.siemContent).length && (
                                                    <button
                                                        className="download-all-btn"
                                                        data-testid="download-all-btn"
                                                        onClick={() =>
                                                            downloadAllFiles('siem-content')
                                                        }
                                                    >
                                                        <Download />
                                                        Download all
                                                    </button>
                                                )}
                                                {spManifest?.instructions?.siem && (
                                                    <button
                                                        className="view-instructions-btn"
                                                        data-testid="view-instructions-btn"
                                                        onClick={() =>
                                                            setInstructionPath(
                                                                spManifest.instructions.siem
                                                            )
                                                        }
                                                    >
                                                        <Eye />
                                                        View instructions
                                                    </button>
                                                )}
                                            </>
                                        }
                                        branch={spFiles.siemContent}
                                    />
                                </div>
                            )}
                            {spFiles.agentContent && (
                                <div className="section">
                                    <TreeView
                                        name={
                                            <>
                                                <b>Agent Content</b>
                                                {' (Input)'}
                                            </>
                                        }
                                        option={
                                            <>
                                                {!!Object.keys(spFiles.agentContent).length && (
                                                    <button
                                                        className="download-all-btn"
                                                        data-testid="download-all-btn"
                                                        onClick={() =>
                                                            downloadAllFiles('agent-content')
                                                        }
                                                    >
                                                        <Download />
                                                        Download all
                                                    </button>
                                                )}
                                                {spManifest?.instructions?.agent && (
                                                    <button
                                                        className="view-instructions-btn"
                                                        data-testid="view-instructions-btn"
                                                        onClick={() =>
                                                            setInstructionPath(
                                                                spManifest.instructions.agent
                                                            )
                                                        }
                                                    >
                                                        <Eye />
                                                        View instructions
                                                    </button>
                                                )}
                                            </>
                                        }
                                        branch={spFiles.agentContent}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {!spFiles && <div className="placeholder">No files to show</div>}
                </div>
            </div>
            {selectedSP && (
                <InstructionsModal
                    identifier={selectedSP.identifier}
                    version={selectedSP.version}
                    path={instructionPath}
                    onClose={() => setInstructionPath(null)}
                />
            )}
        </>
    );
}

SolutionPackFileList.defaultProps = {
    spFiles: null
};

SolutionPackFileList.propTypes = {
    spFiles: propTypes.shape({
        siemContent: propTypes.oneOfType([propTypes.array, propTypes.object]).isRequired,
        agentContent: propTypes.oneOfType([propTypes.array, propTypes.object]).isRequired
    })
};

export default SolutionPackFileList;
