import React from 'react';
import PropTypes from 'prop-types';

function UnassignIcon({ fill = 'currentColor', ...props }) {
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <g clipPath="url(#clip0_24553_31969)">
                <path
                    d="M17.57 14.51L19.17 12.91C19.47 12.61 19.47 12.14 19.17 11.84C18.87 11.54 18.4 11.54 18.1 11.84L16.5 13.44L14.9 11.84C14.6 11.54 14.13 11.54 13.83 11.84C13.53 12.14 13.53 12.61 13.83 12.91L15.43 14.51L13.83 16.11C13.53 16.41 13.53 16.88 13.83 17.18C14.13 17.48 14.6 17.48 14.9 17.18L16.5 15.58L18.1 17.18C18.4 17.48 18.87 17.48 19.17 17.18C19.47 16.88 19.47 16.41 19.17 16.11L17.57 14.51Z"
                    fill={fill}
                />
                <path
                    d="M14.03 9.53C14.17 9.39 14.25 9.2 14.25 9C14.25 8.8 14.17 8.61 14.03 8.47C13.89 8.33 13.7 8.25 13.5 8.25H4.5C4.3 8.25 4.11 8.33 3.97 8.47C3.83 8.61 3.75 8.8 3.75 9C3.75 9.2 3.83 9.39 3.97 9.53C4.11 9.67 4.3 9.75 4.5 9.75H13.5C13.7 9.75 13.89 9.67 14.03 9.53Z"
                    fill={fill}
                />
                <path
                    d="M4.5 11.25C4.3 11.25 4.11 11.33 3.97 11.47C3.83 11.61 3.75 11.8 3.75 12C3.75 12.2 3.83 12.39 3.97 12.53C4.11 12.67 4.3 12.75 4.5 12.75H10.5C10.7 12.75 10.89 12.67 11.03 12.53C11.17 12.39 11.25 12.2 11.25 12C11.25 11.8 11.17 11.61 11.03 11.47C10.89 11.33 10.7 11.25 10.5 11.25H4.5Z"
                    fill={fill}
                />
                <path
                    d="M8.03 2.47C7.93 2.37 7.79 2.29 7.65 2.26C7.5 2.23 7.35 2.25 7.22 2.3C7.08 2.36 6.97 2.45 6.88 2.58C6.8 2.7 6.75 2.85 6.75 3C6.75 3.2 6.83 3.39 6.97 3.53C7.11 3.67 7.3 3.75 7.5 3.75C7.65 3.75 7.79 3.71 7.92 3.62C8.04 3.54 8.14 3.42 8.2 3.28C8.26 3.14 8.27 2.99 8.24 2.85C8.21 2.7 8.14 2.57 8.03 2.47Z"
                    fill={fill}
                />
                <path
                    d="M13.47 15.76L14.23 15H3.75C3.15 15 2.58 14.76 2.16 14.34C1.74 13.92 1.5 13.35 1.5 12.75V6H16.5V12.73L17.75 11.48C17.82 11.41 17.91 11.37 18 11.31V3.75C18 2.76 17.6 1.8 16.9 1.1C16.2 0.4 15.24 0 14.25 0H3.75C2.76 0 1.8 0.4 1.1 1.1C0.4 1.8 0 2.76 0 3.75V12.75C0 13.74 0.4 14.7 1.1 15.4C1.8 16.1 2.76 16.5 3.75 16.5H13.13C13.16 16.23 13.26 15.96 13.47 15.76ZM1.5 3.75C1.5 3.15 1.74 2.58 2.16 2.16C2.58 1.74 3.15 1.5 3.75 1.5H14.25C14.85 1.5 15.42 1.74 15.84 2.16C16.26 2.58 16.5 3.15 16.5 3.75V4.5H1.5V3.75Z"
                    fill={fill}
                />
                <path
                    d="M3.53 2.47C3.43 2.37 3.29 2.29 3.15 2.26C3 2.23 2.85 2.25 2.72 2.3C2.58 2.36 2.47 2.45 2.38 2.58C2.3 2.7 2.25 2.85 2.25 3C2.25 3.2 2.33 3.39 2.47 3.53C2.61 3.67 2.8 3.75 3 3.75C3.15 3.75 3.29 3.71 3.42 3.62C3.54 3.54 3.64 3.42 3.7 3.28C3.76 3.14 3.77 2.99 3.74 2.85C3.71 2.7 3.64 2.57 3.53 2.47Z"
                    fill={fill}
                />
                <path
                    d="M5.78 2.47C5.68 2.37 5.54 2.29 5.4 2.26C5.25 2.23 5.1 2.25 4.97 2.3C4.83 2.36 4.72 2.45 4.63 2.58C4.55 2.7 4.5 2.85 4.5 3C4.5 3.2 4.58 3.39 4.72 3.53C4.86 3.67 5.05 3.75 5.25 3.75C5.4 3.75 5.54 3.71 5.67 3.62C5.79 3.54 5.89 3.42 5.95 3.28C6.01 3.14 6.02 2.99 5.99 2.85C5.96 2.7 5.89 2.57 5.78 2.47Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_24553_31969">
                    <rect width="19.39" height="17.4" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

UnassignIcon.defaultProps = {
    fill: 'currentColor'
};

UnassignIcon.propTypes = {
    fill: PropTypes.string
};

export default UnassignIcon;
