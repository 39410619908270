import Loader from '@nxlog/common-ui/dist/components/svgs/loader';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getToken } from '../../api/endpoints/auth';
import { ROOT } from '../../utils/constants/routes';
import './authRedirector.scss';

export default function AuthRedirectorPage() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const state = searchParams.get('state');
        const code = searchParams.get('code');

        getToken(state, code).then(() => {
            navigate(ROOT, { replace: true });
        });
    }, []);

    return (
        <div data-testid="auth-redirector-page" className="redirector-wrapper">
            <Loader />
        </div>
    );
}
