export const ROOT = '/';

export const APP_LOGIN = `${ROOT}login`;
export const APP_TOKEN = `${ROOT}token`;
export const APP_AGENTS = 'agents';
export const APP_NOTIFICATIONS = 'notifications';
export const APP_LICENSES = 'licenses';
export const APP_AUTO_ENROLL = 'auto-enroll';
export const APP_SWAGGER = 'api';
export const APP_SETTINGS = 'settings';
export const APP_AGENT_MANAGER_SETTING = 'agent-manager';
export const APP_API_SECURITY = 'api-security';
export const APP_AUTENTICATION_SETTINGS = 'authentication-settings';
export const APP_USER_MANAGEMENT = 'user-management';
export const APP_CLUSTER = 'cluster';
export const APP_WHAT_IS_NEW = 'what-is-new';
export const APP_CONFIGURATION = 'configuration';
export const APP_AGENT_CONFIGS = 'configurations';
export const APP_AGENT_CONFIGS_EDIT = `edit/:id`;
export const APP_AGENT_CONFIGS_NEW = 'new';
export const APP_PRODUCT_DOWNLOAD = 'product-download';
export const APP_SUPPORT = 'support';
export const APP_DASHBOARD = 'dashboard';
export const NOT_FOUND = 'not-found';
export const LOGOUT = 'logout';

export const APP_AGENT = (agentId) => `agent/${agentId || ':agentId'}`;
export const APP_EXTENSION = (extensionId) => `extension/${extensionId || ':extensionId'}`;
export const APP_MODULE = (moduleId) => `module/${moduleId || ':moduleId'}`;

export const ROUTE_PERMISSIONS = new Map([
    [ROOT, { agents: 'read' }],
    [APP_AGENTS, { agents: 'read' }],
    ['agent', { agents: 'read' }],
    ['extension', { agents: 'read' }],
    ['module', { agents: 'read' }],
    [APP_DASHBOARD, { agents: 'read' }],
    [APP_AGENT_CONFIGS, { agents: 'read' }],
    [APP_CONFIGURATION, { agents: 'read' }],
    [APP_NOTIFICATIONS, { agents: 'read' }],
    [APP_SETTINGS, { agents: 'read' }],
    [APP_AGENT_MANAGER_SETTING, { agents: 'read' }],
    [APP_CLUSTER, { agents: 'read' }],
    [APP_LICENSES, { licenses: 'read' }],
    [APP_USER_MANAGEMENT, { users: 'read' }],
    [APP_PRODUCT_DOWNLOAD, { products: 'read' }]
]);
