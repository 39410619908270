import { useEffect, useState } from 'react';
import { AGENT_TABLE_FIELDS } from '../apiFields';
import { getAgents, getAgentsCount } from '../../api/endpoints/agents';
import { createExcludedIdsFilter } from '../helpers/strings';

export default function useCustomAgentsList({ strFilter, checkedRecords }) {
    const [rows, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [loading, setLoading] = useState(false);
    const ids = checkedRecords ? Array.from(checkedRecords).map((item) => item.id) : [];
    const usingFilter = ids.includes('*');

    const getFilter = (idField) => createExcludedIdsFilter(ids, strFilter, idField);

    const loadAgents = () => {
        if (!usingFilter) {
            setData(
                Array.from(checkedRecords || []).slice(
                    (page - 1) * itemsPerPage,
                    page * itemsPerPage
                )
            );
            setCount((checkedRecords || []).length);
            return null;
        }

        const params = {
            fields: AGENT_TABLE_FIELDS,
            offset: (page - 1) * itemsPerPage,
            limit: itemsPerPage,
            filter: getFilter('id')
        };

        setLoading(true);
        const requests = [];
        requests.push(
            getAgentsCount(getFilter('id'))
                .then((res) => res.data)
                .then((result) => setCount(result))
                .catch(() => {})
        );
        requests.push(
            getAgents(params)
                .then((res) => res.data)
                .then((result) => setData(result))
                .catch(() => {})
        );
        return Promise.allSettled(requests).finally(() => setLoading(false));
    };

    useEffect(() => {
        loadAgents();
    }, [strFilter, ids.sort().join(';'), page, itemsPerPage]);

    return {
        rows,
        usingFilter,
        getFilter,
        itemsPerPage,
        setItemsPerPage,
        page,
        setPage,
        count,
        loading,
        setLoading
    };
}
