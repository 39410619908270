/* eslint-disable import/no-cycle */
import { addField } from './actions/addField';
import { condition } from './actions/condition';
import { drop } from './actions/drop';
import { exec } from './actions/exec';
import { log } from './actions/log';
import { toJson } from './actions/toJson';
import { parseSyslog } from './actions/parseSyslog';
import { parseSyslogBsd } from './actions/parseSyslogBsd';
import { parseSyslogIetf } from './actions/parseSyslogIetf';
import { toSnare } from './actions/toSnare';
import { toSyslogBsd } from './actions/toSyslogBsd';
import { toSyslogIetf } from './actions/toSyslogIetf';
import { toSyslogSnare } from './actions/toSyslogSnare';
import { extractXml } from './actions/extractXml';
import { toXml } from './actions/toXml';
import { parseWindowsEventlogXml } from './actions/parseWindowsEventlogXml';
import { parseXml } from './actions/parseXml';
import { extractJson } from './actions/extractJson';
import { parseJson } from './actions/parseJson';
import { parseKvp } from './actions/parseKvp';
import { toKvp } from './actions/toKvp';
import { parseCef } from './actions/parseCef';
import { toCef } from './actions/toCef';
import { parseLeef } from './actions/parseLeef';
import { toLeef } from './actions/toLeef';
import { parseCsv } from './actions/parseCsv';
import { toCsv } from './actions/toCsv';
import { ImAcct } from './input/im_acct';
import { ImAixaudit } from './input/im_aixaudit';
import { ImAmazons3 } from './input/im_amazons3';
import { ImAzure } from './input/im_azure';
import { ImBatchcompress } from './input/im_batchcompress';
import { ImBsm } from './input/im_bsm';
import { ImCheckpoint } from './input/im_checkpoint';
import { ImDbi } from './input/im_dbi';
import { ImEtw } from './input/im_etw';
import { ImExec } from './input/im_exec';
import { ImFim } from './input/im_fim';
import { ImGo } from './input/im_go';
import { ImGooglelogging } from './input/im_googlelogging';
import { ImGooglepubsub } from './input/im_googlepubsub';
import { ImHttp } from './input/im_http';
import { ImInternal } from './input/im_internal';
import { ImJava } from './input/im_java';
import { ImKafka } from './input/im_kafka';
import { ImKernel } from './input/im_kernel';
import { ImLinuxaudit } from './input/im_linuxaudit';
import { ImMaces } from './input/im_maces';
import { ImMaculs } from './input/im_maculs';
import { ImMark } from './input/im_mark';
import { ImMs365 } from './input/im_ms365';
import { ImMseventlog } from './input/im_mseventlog';
import { ImMsvistalog } from './input/im_msvistalog';
import { ImNull } from './input/im_null';
import { ImOdbc } from './input/im_odbc';
import { ImPcap } from './input/im_pcap';
import { ImPerl } from './input/im_perl';
import { ImPipe } from './input/im_pipe';
import { ImPython } from './input/im_python';
import { ImRedis } from './input/im_redis';
import { ImRegmon } from './input/im_regmon';
import { ImRuby } from './input/im_ruby';
import { ImSalesforce } from './input/im_salesforce';
import { ImSsl } from './input/im_ssl';
import { ImSystemd } from './input/im_systemd';
import { ImTcp } from './input/im_tcp';
import { ImTestgen } from './input/im_testgen';
import { ImUdp } from './input/im_udp';
import { ImUds } from './input/im_uds';
import { ImWinperfcount } from './input/im_winperfcount';
import { ImWseventing } from './input/im_wseventing';
import { ImZmq } from './input/im_zmq';
import { OmAmazons3 } from './output/om_amazons3';
import { OmAzure } from './output/om_azure';
import { OmAzuremonitor } from './output/om_azuremonitor';
import { OmBatchcompress } from './output/om_batchcompress';
import { OmBatchcompressNXLogPlatform } from './output/om_batchcompress-nxlog-platform';
import { OmBlocker } from './output/om_blocker';
import { OmChronicle } from './output/om_chronicle';
import { OmDbi } from './output/om_dbi';
import { OmElasticsearch } from './output/om_elasticsearch';
import { OmEventdb } from './output/om_eventdb';
import { OmExec } from './output/om_exec';
import { OmFile } from './output/om_file';
import { OmGo } from './output/om_go';
import { OmGooglelogging } from './output/om_googlelogging';
import { OmGooglepubsub } from './output/om_googlepubsub';
import { OmHttp } from './output/om_http';
import { OmJava } from './output/om_java';
import { OmKafka } from './output/om_kafka';
import { OmNull } from './output/om_null';
import { OmOdbc } from './output/om_odbc';
import { OmPerl } from './output/om_perl';
import { OmPipe } from './output/om_pipe';
import { OmPython } from './output/om_python';
import { OmRaijin } from './output/om_raijin';
import { OmRedis } from './output/om_redis';
import { OmRuby } from './output/om_ruby';
import { OmSsl } from './output/om_ssl';
import { OmTcp } from './output/om_tcp';
import { OmUdp } from './output/om_udp';
import { OmUdpspoof } from './output/om_udpspoof';
import { OmUds } from './output/om_uds';
import { OmWebhdfs } from './output/om_webhdfs';
import { OmZmq } from './output/om_zmq';
import { ImFile } from './input/im_file';
import { structuredClone } from '../utils/helper';
import { XmAdmin } from './extensions/xm_admin';
import { ImTcpSyslog } from './input/im_tcp-syslog';
import { ImUdpSyslog } from './input/im_udp-syslog';
import { XmJson } from './extensions/xm_json';
import { XmSyslog } from './extensions/xm_syslog';
import { XmKvp } from './extensions/xm_kvp';
import { XmCef } from './extensions/xm_cef';
import { XmLeef } from './extensions/xm_leef';
import { XmCsv } from './extensions/xm_csv';
import { XmXml } from './extensions/xm_xml';

function initializeModule(module) {
    const moduleName = module.defaultDirectives.trim().split(/\s+/i)[1].trim();
    module.fields.unshift({
        name: 'Module',
        type: 'string',
        required: true,
        requiredInConfigText: true,
        defaultValue: moduleName
    });
    module.moduleName = moduleName;
    module.defaultDirectives = null;
    return module;
}

export const collectModules = [
    ImAcct,
    ImAixaudit,
    ImAmazons3,
    ImAzure,
    ImBatchcompress,
    ImBsm,
    ImCheckpoint,
    ImDbi,
    ImEtw,
    ImExec,
    ImFile,
    ImFim,
    ImGo,
    ImGooglelogging,
    ImGooglepubsub,
    ImHttp,
    ImInternal,
    ImJava,
    ImKafka,
    ImKernel,
    ImLinuxaudit,
    ImMaces,
    ImMaculs,
    ImMark,
    ImMs365,
    ImMseventlog,
    ImMsvistalog,
    ImNull,
    ImOdbc,
    ImPcap,
    ImPerl,
    ImPipe,
    ImPython,
    ImRedis,
    ImRegmon,
    ImRuby,
    ImSalesforce,
    ImSsl,
    ImSystemd,
    ImTcp,
    ImTcpSyslog,
    ImTestgen,
    ImUdp,
    ImUdpSyslog,
    ImUds,
    ImWinperfcount,
    ImWseventing,
    ImZmq
].map(initializeModule);

export const sendToModules = [
    OmAmazons3,
    OmAzure,
    OmAzuremonitor,
    OmBatchcompress,
    OmBatchcompressNXLogPlatform,
    OmBlocker,
    OmChronicle,
    OmDbi,
    OmElasticsearch,
    OmEventdb,
    OmExec,
    OmFile,
    OmGo,
    OmGooglelogging,
    OmGooglepubsub,
    OmHttp,
    OmJava,
    OmKafka,
    OmNull,
    OmOdbc,
    OmPerl,
    OmPipe,
    OmPython,
    OmRaijin,
    OmRedis,
    OmRuby,
    OmSsl,
    OmTcp,
    OmUdp,
    OmUdpspoof,
    OmUds,
    OmWebhdfs,
    OmZmq
].map(initializeModule);

export const actions = [
    condition,
    addField,
    drop,
    log,
    exec,
    parseSyslog,
    parseSyslogBsd,
    parseSyslogIetf,
    toSnare,
    toSyslogBsd,
    toSyslogIetf,
    toSyslogSnare,
    extractXml,
    toXml,
    parseWindowsEventlogXml,
    parseXml,
    extractJson,
    parseJson,
    toJson,
    parseKvp,
    toKvp,
    parseCef,
    toCef,
    parseLeef,
    toLeef,
    parseCsv,
    toCsv
];

export const extensions = [XmAdmin, XmJson, XmSyslog, XmKvp, XmCef, XmLeef, XmCsv, XmXml].map(
    initializeModule
);

export const solutionPacks = [];

export function getModuleByName(moduleName, name) {
    let mdl = [...collectModules, ...sendToModules, ...extensions].filter(
        (elm) => `${elm.moduleName}`.trim().toLowerCase() === `${moduleName}`.trim().toLowerCase()
    );
    if (mdl.length > 1 && name) {
        const refModuleOfSameName = mdl.filter(
            (elm) => `${elm.name}`.trim().toLowerCase() === `${name}`.trim().toLowerCase()
        );
        if (refModuleOfSameName.length) {
            mdl = refModuleOfSameName;
        }
    }
    if (mdl.length) {
        const cloneObj = structuredClone(mdl[0]);
        if (Array.isArray(mdl[0].fields)) {
            cloneObj.fields = mdl[0].fields
                .map((field) => [field, structuredClone(field)])
                .map(([field, clone]) => {
                    Object.entries(field).forEach(([key, value]) => {
                        if (typeof value === 'function') {
                            clone[key] = field[key];
                        }
                    });
                    return clone;
                });
        }
        return cloneObj;
    }
    return null;
}

export function getActionByName(name) {
    const action = actions.find(
        (elm) => `${elm.name}`.trim().toLowerCase() === `${name}`.trim().toLowerCase()
    );
    return action ? JSON.parse(JSON.stringify(action)) : null;
}

export function getActionByContentMatch(content, defaultAction = 'exec') {
    const action = actions.find((elm) => elm.match && elm.match(content));
    return action ? JSON.parse(JSON.stringify(action)) : getActionByName(defaultAction);
}

export default null;
