import { useEffect } from 'react';
import usePageVisibility from './usePageVisibility';

/**
 * Sets interval to repeat callback if the page is visible,
 * optional dependecies are added to trigger the hook
 * @param {function} callback
 * @param {number} timeout
 * @param {Array} dependencies
 * @param {boolean} triggerOnStart
 */
const useRepeatWhenVisible = (callback, timeout, dependencies = [], triggerOnStart = false) => {
    const isVisible = usePageVisibility();

    useEffect(() => {
        if (isVisible) {
            if (triggerOnStart) {
                callback();
            }

            const handler = setInterval(callback, timeout);
            return () => {
                clearInterval(handler);
            };
        }
        return () => {};
    }, [callback, timeout, isVisible, ...dependencies]);
};

export default useRepeatWhenVisible;
