import React from 'react';
import propTypes from 'prop-types';
import { get } from 'lodash';
import { Modal } from '@nxlog/common-ui/components';
import { CONFIG_ANSWER_TYPES } from '../../../utils/constants/ids';
import './viewConfigModal.scss';

function ViewConfigModal(props) {
    const { data, isShown, onClose } = props;

    const path = CONFIG_ANSWER_TYPES[data?.status];

    return (
        <Modal
            data-testid="view-config-modal"
            isShown={isShown}
            onClose={onClose}
            classname="view-config-modal"
            title={<h1 className="view-config-header">View config</h1>}
        >
            <div className="view-config-content" data-testid="view-config-modal-content">
                {data?.status === 'failure' ? get(data, path) : <pre>{get(data, path)}</pre>}
            </div>
        </Modal>
    );
}

ViewConfigModal.defaultProps = {
    data: null
};

ViewConfigModal.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: propTypes.object,
    isShown: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired
};

export default ViewConfigModal;
