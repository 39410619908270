import PropTypes from 'prop-types';

function CircleExclamation(props) {
    return (
        <svg
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            color={props.color}
        >
            <path
                d="M10 15c.283 0 .52-.096.713-.287A.968.968 0 0 0 11 14a.968.968 0 0 0-.287-.713A.968.968 0 0 0 10 13a.967.967 0 0 0-.713.287A.968.968 0 0 0 9 14c0 .283.096.52.287.713.192.191.43.287.713.287Zm0-4c.283 0 .52-.096.713-.287A.968.968 0 0 0 11 10V6a.967.967 0 0 0-.287-.713A.968.968 0 0 0 10 5a.968.968 0 0 0-.713.287A.968.968 0 0 0 9 6v4c0 .283.096.52.287.713.192.191.43.287.713.287Zm0 9a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 0 10c0-1.383.263-2.683.787-3.9a10.099 10.099 0 0 1 2.138-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 10 0c1.383 0 2.683.263 3.9.787a10.098 10.098 0 0 1 3.175 2.138c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 20 10a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 10 20Zm0-2c2.233 0 4.125-.775 5.675-2.325C17.225 14.125 18 12.233 18 10c0-2.233-.775-4.125-2.325-5.675C14.125 2.775 12.233 2 10 2c-2.233 0-4.125.775-5.675 2.325C2.775 5.875 2 7.767 2 10c0 2.233.775 4.125 2.325 5.675C5.875 17.225 7.767 18 10 18Z"
                fill="currentColor"
            />
        </svg>
    );
}

CircleExclamation.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired
};

export default CircleExclamation;
