import React, { useEffect, useState, useContext } from 'react';
import { cloneDeep } from 'lodash';
import { FormProvider, useFormContext } from 'react-hook-form';
import Button from '@nxlog/common-ui/dist/components/button';
import Form from '@nxlog/common-ui/dist/components/form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CircleX from '@nxlog/common-ui/dist/components/svgs/circle-x';
import { Angle } from '@nxlog/common-ui/dist/components/svgs';
import PropTypes from 'prop-types';
import { DragAndDropContext } from '../utils/helper';
import ConfigInput from '../configInput';
import { getModuleFieldsAsObject } from '../utils/generateConfigCode';
import { getModuleByName } from '../modules';
import { ArrowLeftIcon } from '../utils/Icons';

function ConfigModuleDetails() {
    const [item, setItem] = useState(null);
    const { configItem, closeConfig, update, setShowExtensionModal, onModuleChange } =
        useContext(DragAndDropContext);

    useEffect(() => {
        setItem(
            configItem && configItem.elm && configItem.type !== 'action'
                ? cloneDeep(configItem.elm)
                : null
        );
    }, [configItem]);

    const onSubmit = (data) => {
        if (configItem && configItem.elm && Array.isArray(item?.module?.fields)) {
            item.module.fields.forEach((field) => {
                const defaultValue =
                    (typeof field.defaultValue === 'function'
                        ? field.defaultValue(data)
                        : field.defaultValue) || null;
                field.value = data[field.name];
                if (field.type === 'block') {
                    if (field.multiple) {
                        field.changed = true;
                    } else {
                        field.changed =
                            Object.entries(field.value || {}).filter(
                                ([key, value]) =>
                                    value &&
                                    `${value}`.trim().toUpperCase() !==
                                        `${defaultValue[key]}`.trim().toUpperCase()
                            ).length > 0;
                    }
                } else if (
                    (field.multiple && Array.isArray(field.value) && field.value.length) ||
                    (!field.multiple &&
                        field.value &&
                        `${field.value}`.trim().toUpperCase() !==
                            `${defaultValue}`.trim().toUpperCase())
                ) {
                    field.changed = true;
                } else {
                    field.changed = false;
                }
            });
            configItem.elm.module.fields = item.module.fields;
            onModuleChange(configItem.elm);
            update();
            closeConfig();
            if (item?.module?.type === 'extension') {
                setShowExtensionModal(false);
            }
        }
    };

    const onHide = (isCancelButton) => () => {
        closeConfig();
        if (item?.module?.type === 'extension' && isCancelButton) {
            setShowExtensionModal(false);
        }
    };

    return (
        <Offcanvas show={!!item} onHide={onHide()} placement="end" className="configeditor-sidebar">
            <Offcanvas.Body className="configeditor-sidebar-body">
                <Form
                    onSubmit={onSubmit}
                    FormProvider={FormProvider}
                    defaultValues={{ ...getModuleFieldsAsObject(item?.module?.fields) }}
                    mode="onChange"
                >
                    <div className="configeditor-form-container">
                        <div className="configeditor-sidebar-header">
                            <Offcanvas.Title>{item?.module?.label}</Offcanvas.Title>
                            <Button
                                icon={<CircleX outlined fill="#072341" />}
                                onClick={closeConfig}
                                data-testid="configeditor-close-btn"
                            />
                        </div>
                        <div className="scroll">
                            <ConfigForm item={item} />
                        </div>
                        <div className="configeditor-sidebar-actions">
                            {item?.module?.type === 'extension' ? (
                                <Button ghostBtn onClick={closeConfig}>
                                    <ArrowLeftIcon />
                                    Back
                                </Button>
                            ) : null}
                            <Button ghostBtn onClick={onHide(true)}>
                                Cancel
                            </Button>
                            <Button type="submit" data-testid="configeditor-save-btn">
                                {item?.module?.type === 'extension' ? 'Save and Close' : 'Save'}
                            </Button>
                        </div>
                    </div>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

function ConfigForm({ item }) {
    const [showMore, setShowMore] = useState(false);
    const { watch } = useFormContext();

    useEffect(() => {
        setShowMore(false);
    }, [item]);

    if (!Array.isArray(item?.module?.fields)) return null;

    const isRequired = (_field) => {
        const mdl = getModuleByName(item.module.moduleName);
        if (!mdl) return false;
        const field = mdl.fields.find((f) => f.name === _field.name);
        if (field && field.required) {
            if (typeof field.required === 'function') {
                return field.required(watch());
            }
            return true;
        }
        return false;
    };

    const isNotRequired = (field) => !isRequired(field);
    const countOfOptionalFields = item.module.fields.filter(isNotRequired).length;

    const isNotModuleField = (field) => `${field.name}`.trim().toLowerCase() !== 'module';
    item.module.fields.sort((a, b) => {
        if (`${a.name}`.trim().toLowerCase() === 'module') return -1;
        if (`${b.name}`.trim().toLowerCase() === 'module') return 1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    return (
        <div className="configeditor-form" data-testid={`form body ${item?.module?.label}`}>
            {item.module.fields
                .filter(isRequired)
                .filter(isNotModuleField)
                .map((field) => (
                    <ConfigInput key={field.name} module={item?.module} field={field} />
                ))}
            {showMore && countOfOptionalFields
                ? item.module.fields
                      .filter(isNotRequired)
                      .filter(isNotModuleField)
                      .map((field) => (
                          <ConfigInput key={field.name} module={item?.module} field={field} />
                      ))
                : null}
            {countOfOptionalFields ? (
                <button
                    type="button"
                    className="cb-btn"
                    onClick={() => setShowMore((value) => !value)}
                    data-testid={`show-${showMore ? 'less' : 'more'}`}
                >
                    Show {showMore ? 'less' : 'more'} options{' '}
                    {showMore ? <Angle.Up /> : <Angle.Down />}
                </button>
            ) : null}
        </div>
    );
}

ConfigForm.defaultProps = {
    item: null
};

ConfigForm.propTypes = {
    item: PropTypes.shape()
};

export default ConfigModuleDetails;
