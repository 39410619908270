import React from 'react';
import propTypes from 'prop-types';
import { Checkbox, Dropdown } from '@nxlog/common-ui/components';
import classNames from 'classnames';
import Dots from '@nxlog/common-ui/dist/components/svgs/dots';
import Success from '@nxlog/common-ui/dist/components/svgs/success';
import CircleX from '@nxlog/common-ui/dist/components/svgs/circle-x';
import {
    USER_MANAGEMENT_TABLE_ROW_OPTIONS,
    USER_MANAGEMENT_TABLE_ROW_ACCOUNT_TYPES
} from '../../../../utils/constants/options';

export default function TableRow(props) {
    const { rowData, isChecked, onCheck } = props;

    const handleSelect = () => {
        onCheck(rowData);
    };

    return (
        <div className="table-rows">
            <div className="users-row">
                <div data-testid="table-row-checkbox" className="row-check">
                    <Checkbox isNoText checked={isChecked} onChange={handleSelect} />
                </div>
                <div className="row-block name-row">
                    <span data-testid="table-row-name" className="filter-value">
                        {rowData.name}
                    </span>
                </div>
                <div data-testid="table-row-type" className={classNames('row-block type-row')}>
                    <Dropdown
                        options={USER_MANAGEMENT_TABLE_ROW_ACCOUNT_TYPES}
                        title={rowData.type}
                        withIcon
                        className="dropdown-actions"
                    />
                </div>
                <div data-testid="table-row-access" className="row-block access-row">
                    <Dropdown
                        options={USER_MANAGEMENT_TABLE_ROW_ACCOUNT_TYPES}
                        title={rowData.access}
                        withIcon
                        className="dropdown-actions"
                    />
                </div>
                <div className={classNames('row-block active-row')}>
                    {rowData.active === 'Yes' ? (
                        <Success outlined fill="228D3A" />
                    ) : (
                        <CircleX outlined fill="#E03520" />
                    )}
                    <span data-testid="table-row-active" className="filter-value">
                        {rowData.active}
                    </span>
                </div>
                <div className="row-block account-row">
                    <span data-testid="table-row-account" className="filter-value">
                        {rowData.account}
                    </span>
                </div>
                <div className="row-block password-row">
                    <span data-testid="table-row-password" className="filter-value">
                        {rowData.password}
                    </span>
                </div>
                <div className="row-block login-row">
                    <span data-testid="table-row-login" className="filter-value">
                        {rowData.login}
                    </span>
                </div>
                <div className="row-block actions-row">
                    <Dropdown
                        options={USER_MANAGEMENT_TABLE_ROW_OPTIONS}
                        icon={<Dots fill="#0081C1" />}
                        withIcon
                        className="dropdown-actions"
                    />
                </div>
            </div>
        </div>
    );
}

TableRow.defaultProps = {
    rowData: null,
    isChecked: false,
    onCheck: () => {}
};

TableRow.propTypes = {
    rowData: propTypes.shape({
        id: propTypes.number,
        name: propTypes.string,
        type: propTypes.string,
        access: propTypes.string,
        active: propTypes.string,
        account: propTypes.string,
        password: propTypes.string,
        login: propTypes.string
    }),
    isChecked: propTypes.bool,
    onCheck: propTypes.func
};
