import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmBlocker = {
    label: 'Blocker',
    type: 'sendTo',
    name: 'om_blocker',
    defaultDirectives: 'Module      om_blocker',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_blocker'),
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
