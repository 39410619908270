import React from 'react';
import propTypes from 'prop-types';
import ConfigModuleDetails from './configModuleDetails';
import ConfigModuleActions from './configModuleActions';
import LeftMenu from './leftMenu';
import ConfigEditorItem from './configEditorItem';
import { ConfigBuilderContextProvider } from './utils/ConfigBuilderContextProvider';
import ExtensionModals from './extensionModals';
import './configEditor.scss';

function ConfigEditor({ action }) {
    return (
        <ConfigBuilderContextProvider action={action}>
            <div className="ConfigEditor">
                <LeftMenu />
                <ConfigEditorItem />
            </div>
            <ConfigModuleActions />
            <ConfigModuleDetails />
            <ExtensionModals />
        </ConfigBuilderContextProvider>
    );
}

export default ConfigEditor;

ConfigEditor.defaultProps = {
    action: 'new'
};

ConfigEditor.propTypes = {
    action: propTypes.string
};
