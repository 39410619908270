import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { LoggedUserContext, useApplicationContext } from '@nxlog/common-ui/hooks';
import { OptimizeLoggedUser } from '@nxlog/common-ui/utils';
import Loader from '@nxlog/common-ui/dist/components/svgs/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    checkRoutePerms,
    ensureFetchedUserHasOrgId,
    parseBool
} from '../../../utils/helpers/functions';
import { airGappedUser, sampleUser } from '../../../resources/static/data';
import { getUserInfo } from '../../../api/endpoints/auth';
import { APP_LOGIN } from '../../../utils/constants/routes';

const withInterceptor = parseBool(
    window?.env?.REACT_APP_WITH_RESPONSE_INTERCEPTORS ??
        process.env.REACT_APP_WITH_RESPONSE_INTERCEPTORS
);
const withSampleUser = parseBool(
    window?.env?.REACT_APP_WITH_NO_USER ?? process.env.REACT_APP_WITH_NO_USER
);
const airGapped = parseBool(window?.env?.REACT_APP_AIR_GAPPED ?? process.env.REACT_APP_AIR_GAPPED);
const onPremise = parseBool(window?.env?.REACT_APP_ON_PREMISE ?? process.env.REACT_APP_ON_PREMISE);

function UserProvider({ orgId, children }) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const { portalUrl } = useApplicationContext();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    // this effect checks user permissions to access pages
    useEffect(() => {
        if (!user || user.id === sampleUser.id) {
            return;
        }
        if (onPremise && !user.orgs?.[orgId]) {
            return;
        }

        const routes = pathname === '/' ? ['/'] : pathname.split('/');
        const access = routes.reduce(
            (checkResult, route) => checkRoutePerms(route, user, orgId) && checkResult,
            true
        );
        if (!loading && !access && withInterceptor) {
            navigate(APP_LOGIN, {
                state: {
                    error: true,
                    message: `Access to ${pathname} denied for ${user.firstname || 'the user'} ${
                        user.lastname
                    }`,
                    goBack: true,
                    id: 'error_access_denied'
                }
            });
        }
    }, [user, pathname, orgId, navigate, portalUrl, loading]);

    // this effect fetches user info and redirects if there is no response
    // it also sets onPremise user orgId, if user's orgs have no orgId
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await getUserInfo();
                setLoading(false);
                let fetchedUser = response?.data;
                if (fetchedUser) {
                    // TODO: remove this after backend provides id in orgs
                    if (onPremise) {
                        fetchedUser = ensureFetchedUserHasOrgId(fetchedUser, orgId);
                    }

                    const mappedUser = OptimizeLoggedUser(fetchedUser);
                    setUser(mappedUser);
                }
            } catch (err) {
                if (withInterceptor && !airGapped) {
                    navigate(APP_LOGIN, {
                        state: {
                            error: true,
                            message: 'Error fetching user information',
                            id: 'error_userinfo'
                        }
                    });
                }
                setLoading(false);
                if (airGapped) {
                    setUser(airGappedUser);
                    return;
                }
                if (withSampleUser) {
                    setUser(sampleUser);
                    return;
                }
                setUser(null);
            }
        };

        fetchUserData();
    }, [orgId, getUserInfo, withInterceptor, withSampleUser, airGapped]);

    return (
        <>
            {loading && (
                <div className="redirector-wrapper" data-testid="redirector-wrapper">
                    <Loader />
                </div>
            )}
            {!loading && (
                <LoggedUserContext.Provider value={user}>{children}</LoggedUserContext.Provider>
            )}
        </>
    );
}

export default UserProvider;

UserProvider.defaultProps = {
    orgId: '',
    children: null
};

UserProvider.propTypes = {
    orgId: propTypes.string,
    children: propTypes.oneOfType([propTypes.element, propTypes.array])
};
