import { useState, useCallback } from 'react';

const useCheckedRecords = (initialState = new Map(), checkedKey = 'id') => {
    const [checkedRecords, setCheckedRecords] = useState(initialState);

    const handleSetRecords = useCallback((recordArray) => {
        setCheckedRecords(new Map(recordArray.map((r) => [r[checkedKey], r])));
    });

    const handleCheckRecord = useCallback(
        (record) => {
            setCheckedRecords((prev) => {
                const newMap = new Map(prev);
                if (newMap.has(record[checkedKey])) {
                    newMap.delete(record[checkedKey]);
                } else {
                    newMap.set(record[checkedKey], record);
                }
                return newMap;
            });
        },
        [checkedKey]
    );

    const handleCheckRecords = useCallback(
        (param) => {
            if (!param) return;
            if (Array.isArray(param)) {
                param.map((record) => handleCheckRecord(record));
            } else {
                handleCheckRecord(param);
            }
        },
        [checkedKey]
    );

    const handleRemoveRecord = useCallback((id) => {
        setCheckedRecords((prev) => {
            const newMap = new Map(prev);
            newMap.delete(id);
            return newMap;
        });
    }, []);

    const handleRemoveRecords = useCallback((ids) => {
        setCheckedRecords((prev) => {
            const newMap = new Map(prev);
            ids.forEach((id) => {
                newMap.delete(id);
            });
            return newMap;
        });
    }, []);

    const handleCheckAll = useCallback(
        (records) => {
            const newMap = new Map();
            records.forEach((item) => {
                newMap.set(item[checkedKey], item);
            });
            setCheckedRecords(newMap);
        },
        [checkedKey]
    );

    const handleRemoveAll = useCallback(() => {
        const newMap = new Map();
        setCheckedRecords(newMap);
    }, []);

    return {
        checkedRecords,
        handleCheckRecord,
        handleCheckRecords,
        handleCheckAll,
        handleRemoveRecord,
        handleRemoveRecords,
        handleRemoveAll,
        handleSetRecords
    };
};

export default useCheckedRecords;
