import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImInternal = {
    label: 'NXLog Agent Internal Logs',
    type: 'collect',
    name: 'im_internal',
    defaultDirectives: `Module      im_internal`,
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'LogqueueSize',
            type: 'number',
            defaultValue: ''
        },
        ...GENERAL_INPUT_DIRECTIVES('im_internal'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
