import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import EditModuleForm from '../../../components/forms/editModuleForm';
import InfoSidebar from '../../../components/infoSidebar';
import Header from '../../../components/header';
import './editModule.scss';

export default function EditModulePage() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const sideBarData = [
        {
            value: 'TCP 127.0.0.1:1514',
            name: 'Address'
        },
        {
            value: '171965',
            name: 'Evt-recvd'
        },
        {
            value: '0',
            name: 'Evt-drop'
        },
        {
            value: '171965',
            name: 'Evt-fwd'
        },
        {
            value: '0',
            name: 'Queue size'
        },
        {
            value: '0',
            name: 'Queue limit'
        },
        {
            value: '50',
            name: 'Batch size'
        }
    ];

    const message = {
        type: 'error',
        text: 'Alert message text box lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor!'
    };

    return (
        <div className="edit-module-page w-100">
            <Header
                title=""
                titleIcon={null}
                button={
                    <button className="module-name" onClick={handleGoBack}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                        internal
                        <span>(module)</span>
                    </button>
                }
            />
            <div className="edit-module-page-content">
                <InfoSidebar title="Module details" data={sideBarData} message={message} />
                <div className="form-container">
                    <div className="form">
                        <EditModuleForm />
                    </div>
                </div>
            </div>
        </div>
    );
}
