/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useRef, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@nxlog/common-ui/components';
import { Angle, CircleX, WarningCircle } from '@nxlog/common-ui/dist/components/svgs';
import classNames from 'classnames';
import FormSwitch from '../../common/formSwitch';
import { DragAndDropContext } from '../utils/helper';
import { getBuilderDataFromText, preFormatText } from '../utils/parser';

function ConfigTextEditor({ readOnly }) {
    const { setShowTextEditor, item, update } = useContext(DragAndDropContext);

    const [showSPText, setShowSPText] = useState(false);

    const toggleSPWrapper = () => setShowSPText((t) => !t);

    const handleChange = (e) => {
        const currentCursor = e.target.selectionStart;
        const code = preFormatText(e.target.value);
        Object.assign(item, getBuilderDataFromText(code));
        item.content = code;
        update();
        if (e.target.value !== item.content) {
            // wait for React to update the state
            setTimeout(() => {
                if (e.target && e.target.setSelectionRange) {
                    e.target.setSelectionRange(currentCursor + 1, currentCursor + 1);
                }
            }, 0);
        }
    };

    return (
        <div
            className="ConfigTextEditor"
            data-testid={`${readOnly ? 'readOnly' : ''}ConfigTextEditor`}
        >
            <div className="ConfigEditorSection-header">
                <b>Configuration text</b>
                <span className="configuration-text-switch">
                    <span>View as configuration text</span>
                    <FormSwitch
                        data-testid="config-text-editor-switch-off"
                        defaultValue
                        onChange={setShowTextEditor}
                    />
                </span>
            </div>
            <div className="CodeEditor">
                {item.solutionPacks &&
                    Object.values(item.solutionPacks).map(({ id, version, content }) => (
                        <Fragment key={`${id}/${version}`}>
                            <button
                                type="button"
                                className="code-delimiter"
                                onClick={toggleSPWrapper}
                            >
                                {showSPText ? <Angle.Down /> : <Angle.Right />}Solution pack
                                template part
                            </button>
                            <div
                                className={classNames(
                                    'solution-pack-code-wrapper',
                                    !showSPText ? 'wrapped' : null
                                )}
                            >
                                <CodeEditor
                                    key={id + version}
                                    value={content}
                                    testid="generatedSPConfigCode"
                                />
                            </div>
                            <span className="code-delimiter">User-generated template part</span>
                        </Fragment>
                    ))}
                <CodeEditor
                    value={item.content}
                    onChange={handleChange}
                    testid="generatedConfigCode"
                />
            </div>
        </div>
    );
}

ConfigTextEditor.defaultProps = {
    readOnly: false
};

ConfigTextEditor.propTypes = {
    readOnly: PropTypes.bool
};

export function CodeEditor(props) {
    const { value, onChange, testid } = props;
    const textarea = useRef();
    const state = useRef(null);
    const [lineNumbers, setLineNumbers] = useState((value || '').split('\n').length);
    const [errors, setErrors] = useState([]);
    const [activeLine, setActiveLine] = useState(null);

    useEffect(() => {
        const code = typeof value === 'string' ? value : '';
        if (state.current) {
            state.current = null;
            if (onChange) onChange({ target: { value: code } });
        }
        setLineNumbers(code.split('\n').length);
        const item = getBuilderDataFromText(
            code,
            onChange && ((txt) => onChange({ target: { value: txt } }))
        );
        setErrors(item.errors);
    }, [value]);

    const keyup = (event) => {
        setLineNumbers(event.target.value.split('\n').length);
    };

    const keydown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            const start = textarea.current.selectionStart;
            const end = textarea.current.selectionEnd;
            textarea.current.value = `${textarea.current.value.substring(
                0,
                start
            )}\t${textarea.current.value.substring(end)}`;
            textarea.current.selectionStart = start + 1;
            textarea.current.selectionEnd = start + 1;
            textarea.current.focus();
            if (onChange) onChange({ target: textarea.current });
        }
    };

    useEffect(() => {
        const currentTextarea = textarea.current;
        const emitter = currentTextarea.parentElement?.parentElement?.parentElement;
        if (!emitter) return;

        const handleFocus = () => {
            emitter.removeAttribute('draggable');
        };

        const handleBlur = () => {
            emitter.setAttribute('draggable', 'true');
        };

        currentTextarea.addEventListener('focus', handleFocus);
        currentTextarea.addEventListener('blur', handleBlur);

        // eslint-disable-next-line consistent-return
        return () => {
            currentTextarea.removeEventListener('focus', handleFocus);
            currentTextarea.removeEventListener('blur', handleBlur);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.configeditor-error-wrapper')) {
                setActiveLine(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    const handleInput = () => {
        state.current = 'onPaste';
        setErrors([]);
    };

    const handleChange = (e) => {
        if (onChange) onChange(e);
    };

    return (
        <div className="code-editor">
            <div className="line-numbers">
                {[...new Array(lineNumbers)]
                    .map((k, i) => i)
                    .map((key) => (
                        <LineNumber
                            key={key}
                            n={key}
                            errors={errors}
                            mouseEnter={setActiveLine}
                            activeNbr={activeLine}
                        />
                    ))}
            </div>
            <textarea
                ref={textarea}
                style={{ minHeight: ((lineNumbers || 0) + 1) * 21 }}
                {...props}
                onDragStart={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
                onChange={handleChange}
                data-testid={testid}
                onKeyUp={keyup}
                onKeyDown={keydown}
                onPaste={handleInput}
                spellCheck="false"
            />
        </div>
    );
}

CodeEditor.defaultProps = {
    value: '',
    onChange: null,
    testid: ''
};

CodeEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    testid: PropTypes.string
};

function LineNumber({ errors, n, mouseEnter, activeNbr }) {
    const [activeLine, setActiveLine] = useState(null);
    const lineErr = errors.filter((err) => err.line.includes(n + 1));

    useEffect(() => {
        setActiveLine(activeNbr === n);
    }, [activeNbr, n]);

    return (
        <span
            className={`configeditor-error-wrapper ${lineErr.length ? 'error' : ''}`}
            onMouseEnter={() => mouseEnter(n)}
            onDragStart={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            draggable="false"
        >
            {lineErr.length ? (
                <div className={`configeditor-error-popover ${activeLine ? 'active' : ''}`}>
                    <div className="configeditor-error-popover-header">
                        <WarningCircle width={24} height={24} fill="#E67500" />
                        <span className="configeditor-error-popover-header-text">Warning</span>
                        <Button
                            className="configeditor-error-popover-close-btn"
                            icon={<CircleX outlined fill="#072341" />}
                            onClick={() => mouseEnter(null)}
                        />
                    </div>
                    {lineErr.map((err, i) =>
                        Array.isArray(err.message) ? (
                            err.message.map((message, j) => (
                                <div
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${n}-${i}-${j}`}
                                    className="configeditor-error-popover-message"
                                >
                                    {message}
                                </div>
                            ))
                        ) : (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={`${n}-${i}`}>{err.message}</div>
                        )
                    )}
                </div>
            ) : null}
        </span>
    );
}

LineNumber.defaultProps = {
    errors: [],
    n: null,
    mouseEnter: null,
    activeNbr: null
};

LineNumber.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.array,
    n: PropTypes.number,
    mouseEnter: PropTypes.func,
    activeNbr: PropTypes.number
};

export default ConfigTextEditor;
