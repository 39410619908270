import { GENERAL_EXTENSION_DIRECTIVES, GENERAL_DIRECTIVES } from '../generalDirectives';

export const XmKvp = {
    label: 'KVP',
    type: 'extension',
    name: 'xm_kvp',
    module: 'xm_kvp',
    defaultDirectives: `Module      xm_kvp`,
    functions: [
        {
            name: 'to_kvp'
        },
        {
            name: 'parse_kvp'
        },
        {
            name: 'reset_kvp'
        }
    ],
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'DetectNumericValues',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'EscapeChar',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'EscapeControl',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'IncludeHiddenFields',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'KeyQuoteChar',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'KVDelimiter',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'KVPDelimiter',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'QuoteMethod',
            type: 'select',
            options: ['All', 'Delimiter', 'None'],
            defaultValue: ''
        },
        {
            name: 'ValueQuoteChar',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_EXTENSION_DIRECTIVES('xm_kvp'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
