export const toSyslogIetf = {
    label: 'Convert to Syslog IETF',
    name: 'to_syslog_ietf',
    type: 'action',
    extension: [
        {
            module: 'xm_syslog',
            content: `<Extension syslog>\n\tModule    xm_syslog\n</Extension>`
        }
    ],
    match: (content) => /^\s*to_syslog_ietf\s*\(\s*\)\s*;?\s*$/i.test(content),
    inline: true,
    getCode: (action, level) => `${'  '.repeat(level)}syslog->to_syslog_ietf();`
};

export default null;
