import React from 'react';
import BrowserIcon from '@nxlog/common-ui/dist/components/svgs/browser';
import CogIcon from '@nxlog/common-ui/dist/components/svgs/cog';
import EditIcon from '@nxlog/common-ui/dist/components/svgs/edit';
import RestartIcon from '@nxlog/common-ui/dist/components/svgs/restart';
import PlayAltIcon from '@nxlog/common-ui/dist/components/svgs/playalt';
import TrashIcon from '@nxlog/common-ui/dist/components/svgs/trash';
import { Copy } from '@nxlog/common-ui/dist/components/svgs';

const API_SECURITY_TABLE_ROW_OPTIONS = [
    {
        value: 'revoke',
        label: 'Revoke',
        icon: <TrashIcon />,
        className: 'delete'
    }
];

const CLUSTERS_TABLE_ROW_OPTIONS = [{ value: 'open', label: 'Open', icon: <PlayAltIcon /> }];

const CONFIG_TEMPLATE_TABLE_ROW_OPTIONS = [
    {
        value: 'edit',
        label: 'Edit template',
        icon: <EditIcon />,
        className: 'config-template-action'
    },
    {
        value: 'assignAgents',
        label: 'Assign agent',
        icon: <BrowserIcon />,
        className: 'config-template-action'
    },
    {
        value: 'delete',
        label: 'Delete',
        icon: <TrashIcon />,
        className: 'delete config-template-action'
    }
];

const CONFIG_TABLE_ROW_OPTIONS = [
    {
        value: 'edit',
        label: 'Edit configuration',
        icon: <EditIcon />,
        className: 'config-template-action'
    },
    {
        value: 'assignAgents',
        label: 'Assign agent',
        icon: <BrowserIcon />,
        className: 'config-template-action'
    },
    {
        value: 'duplicate',
        label: 'Duplicate',
        icon: <Copy />,
        className: 'config-template-action'
    },
    {
        value: 'delete',
        label: 'Delete',
        icon: <TrashIcon />,
        className: 'delete config-template-action'
    }
];

const EXTENSION_TABLE_ROW_OPTIONS = [
    { value: 'restart', label: 'Restart', icon: <RestartIcon fill="#072341" /> }
];

const NOTIFICATIONS_TABLE_ROW_OPTIONS = [
    {
        value: 'delete',
        label: 'Delete',
        icon: <TrashIcon />,
        className: 'delete'
    }
];

const ROUTES_PRIORITY_OPTIONS = Array.from(Array(100).keys()).map((item) => ({
    value: `${item + 1}`,
    label: `${item + 1}`
}));

const ROUTES_TABLE_ROW_OPTIONS = [
    { value: 'restart', label: 'Restart', icon: <RestartIcon fill="#072341" /> },
    { value: 'editRoute', label: 'Edit Route', icon: <CogIcon /> },
    {
        value: 'delete',
        label: 'Delete',
        icon: <TrashIcon />,
        classname: 'delete'
    }
];

const USER_MANAGEMENT_TABLE_ROW_ACCOUNT_TYPES = [
    { value: 'admin', label: 'Admin', classname: 'account_type_dropdown' },
    { value: 'basicUser', label: 'Basic User', className: 'account_type_dropdown' },
    { value: 'manager', label: 'Manager', className: 'account_type_dropdown' },
    { value: 'superUser', label: 'Super User', className: 'account_type_dropdown' },
    { value: 'user', label: 'User', className: 'account_type_dropdown' }
];

const USER_MANAGEMENT_TABLE_ROW_OPTIONS = [
    {
        value: 'delete',
        label: 'Delete',
        icon: <TrashIcon />,
        className: 'delete'
    }
];

export {
    EXTENSION_TABLE_ROW_OPTIONS,
    ROUTES_PRIORITY_OPTIONS,
    CLUSTERS_TABLE_ROW_OPTIONS,
    NOTIFICATIONS_TABLE_ROW_OPTIONS,
    USER_MANAGEMENT_TABLE_ROW_OPTIONS,
    USER_MANAGEMENT_TABLE_ROW_ACCOUNT_TYPES,
    CONFIG_TEMPLATE_TABLE_ROW_OPTIONS,
    CONFIG_TABLE_ROW_OPTIONS,
    ROUTES_TABLE_ROW_OPTIONS,
    API_SECURITY_TABLE_ROW_OPTIONS
};
