import { GENERAL_EXTENSION_DIRECTIVES, GENERAL_DIRECTIVES } from '../generalDirectives';

export const XmCsv = {
    label: 'CSV',
    type: 'extension',
    name: 'xm_csv',
    module: 'xm_csv',
    defaultDirectives: `Module      xm_csv`,
    functions: [
        {
            name: 'to_csv'
        },
        {
            name: 'parse_csv'
        }
    ],
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Fields',
            type: 'string',
            multiple: true,
            required: true,
            separator: ', ',
            defaultValue: []
        },
        {
            name: 'Delimiter',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'EscapeChar',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'EscapeControl',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'FieldTypes',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'QuoteChar',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'QuoteMethod',
            type: 'select',
            options: ['', 'All', 'None', 'String'],
            defaultValue: ''
        },
        {
            name: 'QuoteOptional',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'StrictMode',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        {
            name: 'UndefValue',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_EXTENSION_DIRECTIVES('xm_csv'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
