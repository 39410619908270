import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmGooglelogging = {
    label: 'Google Cloud Logging',
    name: 'om_googlelogging',
    type: 'sendTo',
    defaultDirectives: 'Module      om_googlelogging',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'CredentialsFile',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'LogName',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Resources',
            type: 'multiline-string-block',
            defaultValue: '',
            required: true
        },
        {
            name: 'Connections',
            type: 'number',
            defaultValue: 1
        },
        {
            name: 'GoogleLoggingBatchSize',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Labels',
            type: 'key-value',
            defaultValue: [],
            multiple: true
        },
        {
            name: 'Reconnect',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'OutputRequestSize',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_googlelogging'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
