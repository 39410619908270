export const parseLeef = {
    label: 'Parse LEEF',
    name: 'parse_leef',
    type: 'action',
    extension: [
        {
            module: 'xm_leef',
            content: `<Extension leef>\n\tModule    xm_leef\n</Extension>`
        }
    ],
    match: (content) => /^\s*parse_leef\s*\(\s*\)\s*;?\s*$/i.test(content),
    inline: true,
    getCode: (action, level) => `${'  '.repeat(level)}parse_leef();`
};

export default null;
