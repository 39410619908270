import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmRuby = {
    label: 'Ruby Language',
    name: 'om_ruby',
    type: 'sendTo',
    defaultDirectives: 'Module      om_ruby',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'RubyCode',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Call',
            type: 'string',
            defaultValue: 'write_data'
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_ruby'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
