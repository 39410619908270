/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button } from '@nxlog/common-ui/components';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import AddIcon from '@nxlog/common-ui/dist/components/svgs/add';
import TrashIcon from '@nxlog/common-ui/dist/components/svgs/trash';
import VerticalDotsIcon from '@nxlog/common-ui/dist/components/svgs/vertical_dots';
import FormInput from '../../../common/formInput';
import useEnrollmentAddress from '../../../../utils/hooks/useEnrollmentAddress';
import { validateHostPort } from '../../../../utils/helpers/functions';

export default function AddressesInputs({ name }) {
    const enrollmentAddress = useEnrollmentAddress();

    const { fields, append, remove, move } = useFieldArray({ name });

    const handleAdd = () => {
        append({ host: enrollmentAddress });
    };

    const onDragEnd = (result) => {
        if (result?.source && result?.destination) {
            move(result.source.index, result.destination.index);
        }
    };

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="dnd-item">
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                className={classNames(
                                    'draggable-container',
                                    snapshot.isDraggingOver && 'dragging'
                                )}
                                {...provided.droppableProps}
                            >
                                {fields?.map((address, index) => (
                                    <Draggable
                                        key={address.id}
                                        draggableId={address.id}
                                        index={index}
                                        isDragDisabled={fields.length === 1}
                                    >
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="draggable-item"
                                            >
                                                <div
                                                    className="config-template-form-row"
                                                    key={address.id}
                                                    data-testid={`address-row-${index}`}
                                                >
                                                    {fields.length !== 1 && (
                                                        <div
                                                            className="dots"
                                                            data-testid={`drag-handle-${index}`}
                                                        >
                                                            <VerticalDotsIcon fill="#8195A9" />
                                                        </div>
                                                    )}
                                                    <FormInput
                                                        classname="ipAddress"
                                                        name={`${name}.${index}.host`}
                                                        label="Address"
                                                        placeholder={enrollmentAddress}
                                                        required
                                                        validationProps={{
                                                            validate: validateHostPort
                                                        }}
                                                        popoverMessage
                                                    />
                                                    {fields.length !== 1 && (
                                                        <Button
                                                            ghostBtn
                                                            className="dlt-btn"
                                                            data-testid="delete-btn"
                                                            onClick={() => remove(index)}
                                                            icon={<TrashIcon />}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
            <Button
                data-testid="addresses-inputs-append"
                className="add-btn"
                icon={<AddIcon />}
                onClick={handleAdd}
            >
                Add new address
            </Button>
        </>
    );
}

AddressesInputs.defaultProps = {
    name: 'address'
};

AddressesInputs.propTypes = {
    name: propTypes.string
};
