import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Popover } from 'react-bootstrap';
import { Input, Button, Modal } from '@nxlog/common-ui/components';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import CopyIcon from '@nxlog/common-ui/dist/components/svgs/copy';
import DateIcon from '@nxlog/common-ui/dist/components/svgs/date';
import ExclamationIcon from '@nxlog/common-ui/dist/components/svgs/exclamation';
import Success from '@nxlog/common-ui/dist/components/svgs/success';
import KeyIcon from '@nxlog/common-ui/dist/components/svgs/key';
import CirclePlusIcon from '@nxlog/common-ui/dist/components/svgs/circle-plus';
import AngleIcon from '@nxlog/common-ui/dist/components/svgs/angle-icon';
import Header from '../../../components/header';
import TableApiSecurity from '../../../components/tables/tableApiSecurity';
import Loader from '../../../components/common/loader';
import timeOut from '../../../utils/helpers/functions';
import { ApiSecurityData } from '../../../resources/static/data';
import './apiSecurity.scss';

const ShowGeneratedToken = React.forwardRef((props, ref) => {
    const [token] = useState('T5wrhCkVnPTaNLwrTufHk2FGZb6g3jpcNSEJjcmr');
    const [copied, setCopied] = useState(false);
    const popover = <Popover className="token-popover">Copied!</Popover>;
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(token);
            setCopied(true);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log('Failed to copy text: ', err);
        }
    };
    return (
        <div className="display-token">
            <p>
                <strong>Your new personal access token</strong>
            </p>
            <p>
                Token:
                <span className="token"> {token}</span>
                <OverlayTrigger
                    target={ref.current}
                    onClick={handleCopy}
                    show={copied}
                    placement="right"
                    overlay={popover}
                >
                    <span
                        className="copy-wrapper"
                        role="presentation"
                        ref={ref}
                        onClick={handleCopy}
                    >
                        <CopyIcon fill="#0081C1" />
                    </span>
                </OverlayTrigger>
            </p>
            <p>
                <ExclamationIcon fillValue="red" className="info-icon" />
                <span> Make sure you save it - you won’t be able to access it again.</span>
            </p>
        </div>
    );
});

export default function ApiSecurity() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const allData = ApiSecurityData;
    const [tableData, setTableData] = useState(ApiSecurityData);
    const [currentPage, setCurrentPage] = useState({ label: 1, value: 1 });
    const [checkedRows, setCheckedRows] = useState(new Map());
    const [show, setShow] = useState(false);
    const [showGeneratedToken, setShowGeneratedToken] = useState(false);
    const target = useRef(null);

    const handleBack = () => {
        navigate('/settings');
    };

    const handleSetCheckedRows = useCallback(
        (recordArray) => {
            setCheckedRows(new Map(recordArray.map((r) => [r.id, r])));
        },
        [setCheckedRows]
    );

    useEffect(() => {
        if (allData.length > 0) setLoading(false);
    }, [allData]);

    const handleClose = () => {
        setShow(false);
    };
    const handleAddToken = () => setShow(true);

    const handleSave = (e) => {
        e.preventDefault();
        handleClose();
        setShowGeneratedToken(true);
    };

    timeOut(loading, setLoading);

    return (
        <div className="api-security w-100">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Header
                        title="Api Security"
                        backIcon={<AngleIcon.Left fill="#072341" width="14.02px" height="7.18px" />}
                        handleBackClick={handleBack}
                        titleIcon={<KeyIcon fill="#0086C5" />}
                    />
                    <div className="token-search">
                        <Input
                            type="text"
                            classname="with-search-icon"
                            placeholder="Search token"
                            isSearch
                            isDropdown
                        />
                        <Button
                            onClick={handleAddToken}
                            className="new-agent-btn"
                            type="submit"
                            icon={<CirclePlusIcon fill="#fff" />}
                        >
                            Add new token
                        </Button>
                    </div>
                    {showGeneratedToken && <ShowGeneratedToken ref={target} />}
                    <TableApiSecurity
                        allData={allData}
                        tableData={tableData}
                        checkedRows={checkedRows}
                        setCheckedRows={handleSetCheckedRows}
                        setTableData={setTableData}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                    {showGeneratedToken && (
                        <div className="token-success">
                            <Success fill="#228D3A" />
                            <span> Your new personal access token has been created.</span>
                        </div>
                    )}
                </>
            )}
            <Modal
                isShown={show}
                centered={false}
                classname="modal-api-security"
                contentClassName="modal-right-md"
                onClose={handleClose}
                title="Add New token"
            >
                <Form className="removePadding add-token">
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Token name</Form.Label>
                        <Form.Control className="token-input" type="name" placeholder="KVP" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicDate">
                        <Form.Label>Expiry date</Form.Label>
                        <span className="date-input">
                            <Form.Control className="token-input" type="date" required />
                            <span className="date-input-icon">
                                <DateIcon />
                            </span>
                        </span>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" id="checkbox1" label="Optional checkbox" />
                        <Form.Check type="checkbox" id="checkbox2" label="Optional checkbox" />
                        <Form.Check type="checkbox" id="checkbox3" label="Optional checkbox" />
                    </Form.Group>
                    <Form.Group className="add-token-btn">
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSave}>Save</Button>
                    </Form.Group>
                </Form>
            </Modal>
        </div>
    );
}
