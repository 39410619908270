/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import WithTooltip from '@nxlog/common-ui/dist/components/with_tooltip';
import { InfoCircle } from '@nxlog/common-ui/dist/components/svgs';
import { DeleteIcon, IconMove } from '../utils/Icons';
import { getFieldReference } from '../utils/generateConfigCode';
import { isValidQuoteString } from '../utils/execScriptParser';
import { validateHost, validateHostPort, validatePort } from '../../../utils/helpers/functions';
import InputErrorMessagePopover from '../../common/inputErrorMessagePopover';

function ConfigInput({ field, module }) {
    const { t } = useTranslation();
    const defaultValue =
        field.value ||
        (typeof field.defaultValue === 'function' ? field.defaultValue({}) : field.defaultValue) ||
        null;

    const {
        formState: { errors },
        watch
    } = useFormContext();
    const filedPath = field.name
        .split('.')
        .filter((v) => !v.match(/^[0-9]+$/))
        .map((name) => ({ name }));
    const refField = getFieldReference(module, filedPath);
    if (!refField || (refField && refField.if && !refField.if(watch()))) return null;
    if (refField) field.required = refField.required;
    const label = refField.label || field.name;
    if (field.multiple) {
        const required =
            typeof refField.required === 'function'
                ? refField.required(watch())
                : !!refField.required;

        let leftIcon = null;
        if (field.type === 'host-port-in-same-input') {
            leftIcon = (
                <WithTooltip placement="left" message={t('form_input.host_tooltip_message')}>
                    <InfoCircle />
                </WithTooltip>
            );
        }
        return (
            <ConfigInputLabel
                label={label}
                leftIcon={leftIcon}
                error={errors?.[field.name]}
                required={required}
            >
                <ConfigList
                    defaultValue={defaultValue}
                    addButtonText={`Add ${label.trim().toLowerCase()}`}
                    name={field.name}
                    required={required}
                >
                    {(item, i) => (
                        <ConfigInputControl
                            field={{
                                ...field,
                                value: item.value,
                                name:
                                    field.type === 'block'
                                        ? `${field.name}.${i}`
                                        : `${field.name}.${i}.value`,
                                label
                            }}
                            hideLabel
                            module={module}
                            errors={errors}
                        />
                    )}
                </ConfigList>
            </ConfigInputLabel>
        );
    }

    return <ConfigInputControl field={field} errors={errors} module={module} />;
}
ConfigInput.defaultProps = {
    field: {},
    module: {}
};

ConfigInput.propTypes = {
    field: PropTypes.shape(),
    module: PropTypes.shape()
};

function ConfigInputControl({ field, errors, hideLabel, module }) {
    const { t } = useTranslation();
    const defaultValue =
        field.value ||
        (typeof field.defaultValue === 'function' ? field.defaultValue({}) : field.defaultValue) ||
        null;
    const { register, watch } = useFormContext();
    const data = watch();
    const required = typeof field.required === 'function' ? field.required(data) : !!field.required;
    const registerField = register(field.name, {
        value: defaultValue,
        required,
        setValueAs: field.setValueAs
    });

    const label = hideLabel ? null : field.label || field.name;
    let quoteError = null;
    if (field.quoting) {
        if (!isValidQuoteString(data[field.name] || '')) {
            quoteError = { message: 'Invalid quoting' };
        }
    }

    switch (`${field.type}`.trim().toLowerCase()) {
        case 'select':
            return (
                <ConfigInputLabel label={label} error={errors?.[field.name]} required={required}>
                    <select {...registerField} data-testid={`field:${field.name}`}>
                        {field.options.map((option) => (
                            <option key={option}>{option}</option>
                        ))}
                    </select>
                </ConfigInputLabel>
            );
        case 'number':
            return (
                <ConfigInputLabel label={label} error={errors?.[field.name]} required={required}>
                    <input
                        type="number"
                        step={field.step || 'any'}
                        {...registerField}
                        data-testid={`field:${field.name}`}
                    />
                </ConfigInputLabel>
            );
        case 'block':
            return (
                <ConfigInputLabel label={label} error={errors?.[field.name]} required={required}>
                    <div className="ConfigInputControlBlock">
                        {field.block.map((elm) => (
                            <ConfigInput
                                key={elm.name}
                                module={module}
                                field={{
                                    ...elm,
                                    name: `${field.name}.${elm.name}`,
                                    label: elm.name
                                }}
                            />
                        ))}
                    </div>
                </ConfigInputLabel>
            );
        case 'key-value': {
            const registerFieldKey = register(`${field.name}.key`, { required });
            const registerFieldValue = register(`${field.name}.value`, { required });
            return (
                <ConfigInputLabel label={label} error={errors?.[field.name]} required={required}>
                    <div className="ConfigInputControlKeyValue" data-testid={`field:${field.name}`}>
                        <input
                            {...registerFieldKey}
                            placeholder="Key"
                            data-testid={`field:${field.name}.key`}
                        />
                        <input
                            {...registerFieldValue}
                            placeholder="Value"
                            data-testid={`field:${field.name}.value`}
                        />
                    </div>
                </ConfigInputLabel>
            );
        }
        case 'host-port': {
            const registerFieldHost = register(`${field.name}.host`, {
                required,
                validate: validateHost
            });
            const registerFieldPort = register(`${field.name}.port`, {
                required: !`${get(data, `${field.name}.host`, '')}`
                    .trim()
                    .match(/^%[a-zA-Z0-9_-]+%$/),
                validate: validatePort(get(data, `${field.name}.host`, ''))
            });
            const hostError = get(errors, `${field.name}.host`, null);
            const portError = get(errors, `${field.name}.port`, null);
            return (
                <ConfigInputLabel label={label} error={errors?.[field.name]} required={required}>
                    <div className="ConfigInputControlHostPort" data-testid={`field:${field.name}`}>
                        <input
                            {...registerFieldHost}
                            className={hostError ? 'host has-error' : 'host'}
                            placeholder="Host"
                            data-testid={`field:${field.name}.host`}
                        />
                        <span>:</span>
                        <input
                            {...registerFieldPort}
                            className={portError ? 'port has-error' : 'port'}
                            placeholder="Port"
                            data-testid={`field:${field.name}.port`}
                        />
                    </div>
                </ConfigInputLabel>
            );
        }
        case 'host-port-in-same-input': {
            const registerFieldHost = register(`${field.name}.value`, {
                required: t('form_validation.required'),
                validate: validateHostPort
            });
            const hostError = get(errors, `${field.name}.value`, null);
            const input = (
                <input
                    {...registerFieldHost}
                    className={hostError ? 'host has-error' : 'host'}
                    placeholder="Host:Port"
                    data-testid={`field:${field.name}`}
                />
            );
            return (
                <ConfigInputLabel label={label} error={errors?.[field.name]} required={required}>
                    <div className="ConfigInputControlHostPort" data-testid={`field:${field.name}`}>
                        <InputErrorMessagePopover message={hostError?.message}>
                            {input}
                        </InputErrorMessagePopover>
                    </div>
                </ConfigInputLabel>
            );
        }
        case 'textarea':
        case 'multiline-string':
        case 'multiline-string-block':
            return (
                <ConfigInputLabel label={label} error={errors?.[field.name]} required={required}>
                    <textarea
                        className="detail-form"
                        rows={3}
                        {...registerField}
                        data-testid={`field:${field.name}`}
                    />
                </ConfigInputLabel>
            );
        case 'password':
            return (
                <ConfigInputLabel label={label} error={errors?.[field.name]} required={required}>
                    <input {...registerField} type="password" data-testid={`field:${field.name}`} />
                </ConfigInputLabel>
            );
        default:
            return (
                <ConfigInputLabel
                    label={label}
                    error={errors?.[field.name] || quoteError}
                    required={required}
                >
                    <input
                        list={`${field.name}-datalist`}
                        {...registerField}
                        data-testid={`field:${field.name}`}
                    />
                    {Array.isArray(field.options) && field.options.length ? (
                        <datalist id={`${field.name}-datalist`}>
                            {field.options.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </datalist>
                    ) : null}
                </ConfigInputLabel>
            );
    }
}

ConfigInputControl.defaultProps = {
    errors: {},
    field: { name: '', type: '' },
    module: {},
    register: {},
    hideLabel: false
};

ConfigInputControl.propTypes = {
    errors: PropTypes.shape(),
    field: PropTypes.shape(),
    register: PropTypes.shape(),
    module: PropTypes.shape(),
    hideLabel: PropTypes.bool
};

function ConfigInputLabel({ label, leftIcon, error, required, children }) {
    return (
        <div className={`ConfigInput ${error ? 'has-error' : ''}`}>
            {label ? (
                <div className="ConfigInputLabel">
                    <label>
                        {label} {required ? <small>(Required)</small> : ''}
                    </label>
                    {leftIcon}
                </div>
            ) : null}
            <div>{children}</div>
        </div>
    );
}

ConfigInputLabel.defaultProps = {
    label: '',
    leftIcon: null,
    error: null,
    required: false,
    children: null
};

ConfigInputLabel.propTypes = {
    label: PropTypes.string,
    leftIcon: PropTypes.node,
    required: PropTypes.bool,
    error: PropTypes.shape(),
    children: PropTypes.node
};

function ConfigList({ defaultValue, children, addButtonText = 'Add', name, required }) {
    const { fields, append, remove, move } = useFieldArray({ name });

    if (typeof children !== 'function') return null;

    const createNewItem = () => {
        const item =
            Array.isArray(defaultValue) && defaultValue.length
                ? { ...defaultValue[0] }
                : { value: '' };
        append(item);
    };

    const onDragEnd = (result) => {
        if (result?.source && result?.destination) {
            move(result.source.index, result.destination.index);
        }
    };

    return (
        <div className="ConfigList">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {fields.map((item, i) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={i}
                                    isDragDisabled={fields.length === 1}
                                >
                                    {(fieldProvided) => (
                                        <div
                                            key={item.id}
                                            ref={fieldProvided.innerRef}
                                            {...fieldProvided.draggableProps}
                                            {...fieldProvided.dragHandleProps}
                                        >
                                            <span>
                                                {fields.length > 1 ? (
                                                    <>
                                                        <span className="config-list-item-nbr">
                                                            {i + 1}
                                                        </span>
                                                        <IconMove />
                                                    </>
                                                ) : (
                                                    <span>{i + 1}</span>
                                                )}
                                            </span>
                                            <div>{children(item, i)}</div>
                                            {required && fields.length === 1 ? null : (
                                                <DeleteIcon
                                                    onClick={() => remove(i)}
                                                    testid={`delete-field:${name}.${i}`}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <button type="button" onClick={createNewItem} data-testid={`add-field:${name}`}>
                {addButtonText}
            </button>
        </div>
    );
}

ConfigList.defaultProps = {
    children: null,
    name: null,
    defaultValue: [],
    addButtonText: 'Add',
    required: false
};

ConfigList.propTypes = {
    children: PropTypes.func,
    name: PropTypes.string,
    defaultValue: PropTypes.arrayOf(PropTypes.shape()),
    addButtonText: PropTypes.node,
    required: PropTypes.bool
};

export default ConfigInput;
