import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import propTypes from 'prop-types';
import { Button, Modal } from '@nxlog/common-ui/components';
import { updateAgentConfig } from '../../../redux/reducers/agents';
import Confirm from '../confirmModal';
import AlertHeading from '../../common/alertHeading';
import CreateTemplateFromConfigModal from '../createTemplateFromConfigModal';
import './editConfigModal.scss';

export default function EditConfigModal(props) {
    const { onHide, show, configData, currentAgentId, templateName } = props;

    const dispatch = useDispatch();
    const [confirm, setConfirm] = useState(false);
    const [saveAsTemplateModal, setSaveAsTemplateModal] = useState(null);
    const initialData = configData[currentAgentId]?.configData;
    const [config, setConfig] = useState(initialData);
    const configTextRef = useRef();
    const onConfigChange = (event) => {
        setConfig(event.target.value);
    };

    useEffect(() => {
        setConfig(configData[currentAgentId]?.data);
    }, [initialData, configData]);

    const save = () => {
        setConfirm(false);
        dispatch(
            updateAgentConfig({
                id: currentAgentId,
                data: configTextRef.current.value
            })
        );
        onHide();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (templateName) {
            setConfirm(true);
        } else {
            save();
        }
    };

    const onCancelConfirm = () => {
        setConfirm(false);
    };

    const handleConfirm = () => {
        save();
        setConfirm(false);
    };

    const confirmTitle = (
        <AlertHeading>
            {i18next.t('alert_messages.confirm.unassign_template_heading', {
                count: 1
            })}
        </AlertHeading>
    );

    return (
        <>
            <Modal
                isShown={show && !saveAsTemplateModal}
                onClose={onHide}
                title="Edit Config"
                classname="edit-config"
                aria-labelledby="edit-config"
            >
                <>
                    <div className="modal-content">
                        <textarea
                            ref={configTextRef}
                            value={config}
                            onChange={onConfigChange}
                            data-testid="edit-config-textarea"
                        >
                            {config}
                        </textarea>
                    </div>
                    <div className="modal-footer">
                        <Button ghostBtn onClick={() => setSaveAsTemplateModal(config)}>
                            Create a template based on this config
                        </Button>
                        <Button onClick={handleSubmit}>Update</Button>
                    </div>
                </>
            </Modal>
            <CreateTemplateFromConfigModal
                config={saveAsTemplateModal}
                onClose={() => setSaveAsTemplateModal(null)}
            />
            <Confirm
                className="shadowed-modal"
                show={Boolean(confirm)}
                title={confirmTitle}
                body={i18next.t('alert_messages.confirm.edit_assigned_config', {
                    template: templateName || ''
                })}
                cancel="Cancel"
                confirm="Unassign Template"
                onCancel={onCancelConfirm}
                onConfirm={handleConfirm}
            />
        </>
    );
}

EditConfigModal.defaultProps = {
    onHide: () => {},
    show: false,
    configData: [],
    currentAgentId: '',
    templateName: ''
};

EditConfigModal.propTypes = {
    onHide: propTypes.func,
    show: propTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    configData: propTypes.object,
    currentAgentId: propTypes.string,
    templateName: propTypes.string
};
