import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImMsvistalog = {
    label: 'Windows 2008/Vista and Later Event Logging',
    type: 'collect',
    name: 'im_msvistalog',
    defaultDirectives: 'Module      im_msvistalog',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'AddPrefix',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'ReadBatchSize',
            type: 'number',
            defaultValue: 31
        },
        {
            name: 'CaptureEventXML',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'Channel',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'File',
            type: 'string',
            defaultValue: [{ value: '' }],
            multiple: true
        },
        {
            name: 'Language',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'PollInterval',
            type: 'number',
            defaultValue: 1
        },
        {
            name: 'Query',
            type: 'multiline-string',
            defaultValue: ''
        },
        {
            name: 'QueryXML',
            type: 'multiline-string-block',
            defaultValue: ''
        },
        {
            name: 'ReadFromLast',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'RemoteAuthMethod',
            type: 'select',
            options: ['Default', 'Negotiate', 'Kerberos', 'NTLM'],
            defaultValue: 'Default'
        },
        {
            name: 'RemoteDomain',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'RemotePassword',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'RemoteServer',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'RemoteUser',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'ResolveGUID',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'ResolveSID',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'SavePos',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'TolerateQueryErrors',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        ...GENERAL_INPUT_DIRECTIVES('im_msvistalog'),
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
