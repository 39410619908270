/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import propTypes from 'prop-types';

function Tabs({ list, defaultSelect = 0 }) {
    const [tab, setTab] = useState(defaultSelect);
    return (
        <div className="ConfigEditorTabs" data-testid="ConfigEditorTabs">
            <div className="tabs-titles">
                {list.map((item, i) => (
                    <span
                        onClick={() => setTab(i)}
                        key={item.label}
                        data-testid={`tab-${item.label}`}
                        className={`${i === tab ? 'active' : ''}`}
                    >
                        {item.label}
                    </span>
                ))}
            </div>
            <div className="tab-content" key={`tab-${tab}`}>
                {list[tab]?.content}
            </div>
        </div>
    );
}

Tabs.defaultProps = {
    defaultSelect: 0,
    list: []
};

Tabs.propTypes = {
    defaultSelect: propTypes.number,
    list: propTypes.arrayOf(propTypes.shape())
};

export default Tabs;
