import React from 'react';
import AgentsIcon from '@nxlog/common-ui/dist/components/svgs/agents';
import SettingsIcon from '@nxlog/common-ui/dist/components/svgs/settings';
import ClusterIcon from '@nxlog/common-ui/dist/components/svgs/cluster';
import Settings from '../../components/settings';
import Header from '../../components/header';
import './settings.scss';

export default function SettingsPage() {
    const settings = [
        {
            name: 'Agent Manager Settings',
            icon: <AgentsIcon fill="#05043E" />,
            route: 'agent-manager'
        },
        {
            name: 'Cluster Information',
            icon: <ClusterIcon fill="#05043E" />,
            route: 'cluster'
        }
    ];

    return (
        <div className="settings-page w-100">
            <Header title="Settings" titleIcon={<SettingsIcon fill="#0086C5" />} />
            <div className="all-settings">
                {settings.map((setting) => (
                    <Settings
                        key={setting.name}
                        name={setting.name}
                        icon={setting.icon}
                        route={setting.route}
                    />
                ))}
            </div>
        </div>
    );
}
