import React, { useCallback, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Copy } from '@nxlog/common-ui/dist/components/svgs';
import propTypes from 'prop-types';
import './index.scss';

function CmdLine({ cmd }) {
    const [tooltip, setTooltip] = useState(null);
    const copyToClipboard = useCallback(() => {
        if (navigator?.clipboard) {
            navigator.clipboard
                .writeText(cmd)
                .then(() => {
                    setTooltip('Copied!');
                })
                .catch(() => {
                    setTooltip('Error copy to clipboard');
                });
        } else {
            setTooltip('Clipboard unavailable');
        }
    }, [cmd]);
    const renderTooltip = useCallback(
        (popoverProps) => (
            <Popover
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...popoverProps}
                data-testid="code-snippet-popover"
                className={tooltip ? 'snippet-popover' : 'hidden'}
            >
                {tooltip}
            </Popover>
        ),
        [tooltip]
    );
    return (
        <div className="cmd-line-wrapper">
            <mark>{cmd}</mark>
            <OverlayTrigger trigger="click" rootClose placement="right" overlay={renderTooltip}>
                <button
                    data-testid="cmd-line-copy"
                    onClick={copyToClipboard}
                    className="copy-button"
                    aria-label="Copy"
                >
                    <Copy />
                </button>
            </OverlayTrigger>
        </div>
    );
}

CmdLine.defaultProps = {
    cmd: ''
};

CmdLine.propTypes = {
    cmd: propTypes.string
};

export default CmdLine;
