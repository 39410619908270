import { useCallback, useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Angle, Copy, Download } from '@nxlog/common-ui/dist/components/svgs';
import './codeSnippet.scss';

export default function CodeSnippet({ filename, code, minLines }) {
    const codeRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [result, setResult] = useState(null);

    const renderTooltip = useCallback(
        (popoverProps) => (
            <Popover
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...popoverProps}
                data-testid="code-snippet-popover"
                className={result ? 'snippet-popover' : 'hidden'}
            >
                {result}
            </Popover>
        ),
        [result]
    );

    useEffect(() => {
        if (codeRef.current && minLines) {
            codeRef.current.style.setProperty(
                '--line-height',
                expanded ? 'unset' : `${minLines * 1.7 + 2.5}em`
            );
        }
    }, [minLines, expanded]);

    const downloadFile = useCallback(() => {
        const element = document.createElement('a');
        const file = new Blob([code], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = filename;
        element.click();
        setResult('Downloaded!');
    }, [filename, code]);

    const copyToClipboard = useCallback(() => {
        if (navigator?.clipboard) {
            navigator.clipboard
                .writeText(code)
                .then(() => {
                    setResult('Copied!');
                })
                .catch(() => {
                    setResult('Error copy to clipboard');
                });
        } else {
            setResult(
                <>
                    Clipboard unavailable
                    <br />
                    Please, copy manually
                    <br />
                    or download the file
                </>
            );
        }
    }, [code]);

    const toggleExpand = () => {
        setExpanded((ex) => !ex);
    };

    return (
        <div className="snippet-wrapper">
            <h3 data-testid="code-snippet-filename" className="snippet-title">
                {filename}
            </h3>
            <pre ref={codeRef} className={minLines ? 'limited-height' : ''}>
                <code data-testid="code-snippet-code">
                    {code.split('\n').map((line, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <span key={index}>
                            <span className="line-number">{index + 1}</span>
                            {line}
                            {'\n'}
                        </span>
                    ))}
                </code>
            </pre>
            <div className="snippet-footer">
                {minLines && (
                    <button
                        data-testid="code-snippet-show-more"
                        onClick={toggleExpand}
                        className="expand-button"
                    >
                        {expanded ? (
                            <>
                                Show less
                                <Angle.Up />
                            </>
                        ) : (
                            <>
                                Show more
                                <Angle.Down />
                            </>
                        )}
                    </button>
                )}
                <div className="snippet-controls">
                    <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="right"
                        overlay={renderTooltip}
                    >
                        <button
                            data-testid="code-snippet-download"
                            onClick={downloadFile}
                            className="download-button"
                            aria-label="Download"
                        >
                            <Download />
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="right"
                        overlay={renderTooltip}
                    >
                        <button
                            data-testid="code-snippet-copy"
                            onClick={copyToClipboard}
                            className="copy-button"
                            aria-label="Copy"
                        >
                            <Copy />
                        </button>
                    </OverlayTrigger>
                </div>
            </div>
        </div>
    );
}

CodeSnippet.defaultProps = {
    filename: '',
    code: '',
    minLines: null
};

CodeSnippet.propTypes = {
    filename: propTypes.string,
    code: propTypes.string,
    minLines: propTypes.number
};
