import { CONNECT_TYPES } from '../constants/ids';

const createXmAdminTemplate = ({
    name,
    host,
    port,
    connectType,
    execRules,
    Config
}) => `<Extension ${name}>
    Module      xm_admin
    ${CONNECT_TYPES[connectType]}        ${host}:${port}
    SocketType  SSL
    CAFile      %CERTDIR%/agent-ca.pem
    CertFile    %CERTDIR%/agent-cert.pem
    CertKeyFile %CERTDIR%/agent-key.pem
    <ACL conf>
        Directory   %CONFDIR%
        AllowRead   TRUE
        AllowWrite  TRUE
    </ACL>

    <ACL cert>
        Directory   %CERTDIR%
        AllowRead   TRUE
        AllowWrite  TRUE
    </ACL>
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmAixauditTemplate = ({
    name,
    eventsConfigFile,
    execRules,
    Config
}) => `<Extension ${name}>
    Module      xm_aixaudit
    ${eventsConfigFile ? `EventsConfigFile   ${eventsConfigFile}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmAslTemplate = ({ name, execRules, Config }) => `<Extension ${name}>
    Module      xm_asl
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmBsmTemplate = ({ name, eventFile, execRules, Config }) => `<Extension ${name}>
    Module      xm_bsm
    ${eventFile ? `EventFile   ${eventFile}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmCefTemplate = ({
    name,
    includeHiddenFields,
    execRules,
    Config
}) => `<Extension ${name}>
    Module      xm_cef
    ${includeHiddenFields ? `IncludeHiddenFields   ${includeHiddenFields}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmCarnovTemplate = ({
    name,
    autodetectCharsets,
    bigEndian,
    charBytes,
    lineReader,
    Config,
    execRules
}) => `<Extension ${name}>
    Module      xm_charconv
    ${autodetectCharsets ? `AutodetectCharsets   ${autodetectCharsets}` : ''}
    ${bigEndian ? `BigEndian   ${bigEndian}` : ''}
    ${charBytes ? `CharBytes   ${charBytes}` : ''}
    ${lineReader ? `LineReader   ${lineReader}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmCSVTemplate = ({
    name,
    fields,
    delimiter,
    escapeChar,
    escapeControl,
    fieldTypes,
    quoteMethod,
    quoteChar,
    Config,
    quoteOptional,
    strictMode,
    undefValue,
    execRules
}) => `<Extension ${name}>
    Module      xm_csv
    ${fields ? `Fields   ${fields}` : ''}
    ${delimiter ? `Delimiter   ${delimiter}` : ''}
    ${escapeChar ? `EscapeChar   ${escapeChar}` : ''}
    ${escapeControl ? `EscapeControl   ${escapeControl}` : ''}
    ${fieldTypes ? `FieldTypes   ${fieldTypes}` : ''}
    ${quoteMethod ? `QuoteMethod   ${quoteMethod}` : ''}
    ${quoteChar ? `QuoteChar   ${quoteChar}` : ''}
    ${quoteOptional ? `QuoteOptional   ${quoteOptional}` : ''}
    ${strictMode ? `StrictMode   ${strictMode}` : ''}
    ${undefValue ? `UndefValue   ${undefValue}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmCryptoTemplate = ({
    name,
    password,
    passwordFile,
    Config,
    useSalt,
    iter,
    execRules
}) => `<Extension ${name}>
    Module      xm_crypto
    ${password ? `Password   ${password}` : ''}
    ${passwordFile ? `PasswordFile   ${passwordFile}` : ''}
    ${useSalt ? `UseSalt   ${useSalt}` : ''}
    ${iter ? `Iter   ${iter}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmExecTemplate = ({ name, execRules, Config }) => `<Extension ${name}>
    Module      xm_exec
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmFilelistTemplate = ({
    name,
    file,
    Config,
    checkInterval,
    execRules
}) => `<Extension ${name}>
    Module      xm_filelist
    ${file ? `File   ${file}` : ''}
    ${checkInterval ? `CheckInterval   ${checkInterval}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmFileopTemplate = ({ name, execRules, Config }) => `<Extension ${name}>
    Module      xm_fileop
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmGelfTemplate = ({
    name,
    includeHiddenFields,
    shortMessageLength,
    useNullDelimiter,
    execRules,
    Config
}) => `<Extension ${name}>
    Module      xm_gelf
    ${includeHiddenFields ? `IncludeHiddenFields   ${includeHiddenFields}` : ''}
    ${shortMessageLength ? `ShortMessageLength   ${shortMessageLength}` : ''}
    ${useNullDelimiter ? `UseNullDelimiter   ${useNullDelimiter}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmGoTemplate = ({ name, Config, importLib, execRules }) => `<Extension ${name}>
    Module      xm_go
    ${importLib ? `ImportLib   ${importLib}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmGrokTemplate = ({ name, Config, pattern, execRules }) => `<Extension ${name}>
    Module      xm_grok
    ${pattern ? `Pattern   ${pattern}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmJavaTemplate = ({
    name,
    classPath,
    VMOption,
    VMOptions,
    Config,
    javaHome,
    execRules
}) => `<Extension ${name}>
    Module      xm_java
    ${classPath ? `ClassPath   ${classPath}` : ''}
    ${VMOption ? `VMOption   ${VMOption}` : ''}
    ${VMOptions ? `VMOptions   ${VMOptions}` : ''}
    ${javaHome ? `JavaHome   ${javaHome}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmJsonTemplate = ({
    name,
    dateFormat,
    detectNestedJSON,
    flatten,
    forceUTF8,
    includeHiddenFields,
    parseDate,
    Config,
    prettyPrint,
    execRules,
    unFlatten
}) => `<Extension ${name}>
    Module      xm_json
    ${dateFormat ? `DateFormat   ${dateFormat}` : ''}
    ${detectNestedJSON ? `DetectNestedJSON   ${detectNestedJSON}` : ''}
    ${flatten ? `Flatten   ${flatten}` : ''}
    ${forceUTF8 ? `ForceUTF8   ${forceUTF8}` : ''}
    ${includeHiddenFields ? `IncludeHiddenFields   ${includeHiddenFields}` : ''}
    ${parseDate ? `ParseDate   ${parseDate}` : ''}
    ${prettyPrint ? `PrettyPrint   ${prettyPrint}` : ''}
    ${unFlatten ? `UnFlatten   ${unFlatten}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmKvpTemplate = ({
    name,
    detectNumericValues,
    Config,
    escapeChar,
    escapeControl,
    keyQuoteChar,
    includeHiddenFields,
    KVDelimiter,
    KVPDelimiter,
    quoteMethod,
    valueQuoteChar,
    execRules
}) => `<Extension ${name}>
    Module      xm_kvp
    ${detectNumericValues ? `DetectNumericValues   ${detectNumericValues}` : ''}
    ${escapeChar ? `EscapeChar   ${escapeChar}` : ''}
    ${escapeControl ? `EscapeControl   ${escapeControl}` : ''}
    ${includeHiddenFields ? `IncludeHiddenFields   ${includeHiddenFields}` : ''}
    ${keyQuoteChar ? `KeyQuoteChar   ${keyQuoteChar}` : ''}
    ${KVDelimiter ? `KVDelimiter   ${KVDelimiter}` : ''}
    ${KVPDelimiter ? `KVPDelimiter   ${KVPDelimiter}` : ''}
    ${quoteMethod ? `QuoteMethod   ${quoteMethod}` : ''}
    ${valueQuoteChar ? `ValueQuoteChar   ${valueQuoteChar}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmLeefTemplate = ({
    name,
    addSyslogHeader,
    Config,
    LEEFHeader,
    execRules,
    includeHiddenFields
}) => `<Extension ${name}>
    Module      xm_leef
    ${addSyslogHeader ? `AddSyslogHeader   ${addSyslogHeader}` : ''}
    ${includeHiddenFields ? `IncludeHiddenFields   ${includeHiddenFields}` : ''}
    ${LEEFHeader ? `LEEFHeader   ${LEEFHeader}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmMsdnsTemplate = ({
    name,
    dateFormat,
    eventLine,
    noteLine,
    execRules,
    packetLine,
    Config
}) => `<Extension ${name}>
    Module      xm_msdns
    ${dateFormat ? `DateFormat   ${dateFormat}` : ''}
    ${eventLine ? `EventLine   ${eventLine}` : ''}
    ${noteLine ? `NoteLine   ${noteLine}` : ''}
    ${packetLine ? `PacketLine   ${packetLine}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmMultilineTemplate = ({
    name,
    fixedLineCount,
    Config,
    autoFlush,
    headerLine,
    endLine,
    execRules
}) => `<Extension ${name}>
    Module      xm_multiline
    ${fixedLineCount ? `FixedLineCount   ${fixedLineCount}` : ''}
    ${headerLine ? `HeaderLine   ${headerLine}` : ''}
    ${autoFlush ? `AutoFlush   ${autoFlush}` : ''}
    ${endLine ? `EndLine   ${endLine}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmNetflowTemplate = ({
    name,
    Config,
    ipfixElementDefinition,
    execRules
}) => `<Extension ${name}>
    Module      xm_netflow
    ${ipfixElementDefinition ? `IpfixElementDefinition   ${ipfixElementDefinition}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmNpsTemplate = ({ name, execRules, Config }) => `<Extension ${name}>
    Module      xm_nps
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmPatternTemplate = ({ name, patternFile, Config, execRules }) => `<Extension ${name}>
    Module      xm_pattern
    ${patternFile ? `PatternFile   ${patternFile}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmPerlTemplate = ({ name, config, Config, perlCode, execRules }) => `<Extension ${name}>
    Module      xm_perl
    ${perlCode ? `PerlCode   ${perlCode}` : ''}
    ${config ? `Config   ${config}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmPythonTemplate = ({ name, Config, execRules, pythonCode }) => `<Extension ${name}>
    Module      xm_python
    ${pythonCode ? `PythonCode   ${pythonCode}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmResolverTemplate = ({
    name,
    cacheExpiry,
    cacheLimit,
    execRules,
    Config,
    negativeCacheExpiry
}) => `<Extension ${name}>
    Module      xm_resolver
    ${cacheExpiry ? `CacheExpiry   ${cacheExpiry}` : ''}
    ${cacheLimit ? `CacheLimit   ${cacheLimit}` : ''}
    ${negativeCacheExpiry ? `NegativeCacheExpiry   ${negativeCacheExpiry}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmRewriteTemplate = ({
    name,
    execRules,
    Config,
    keep,
    rename,
    deleteField
}) => `<Extension ${name}>
    Module      xm_rewrite
    ${deleteField ? `Delete   ${deleteField}` : ''}
    ${keep ? `Keep   ${keep}` : ''}
    ${rename ? `Rename   ${rename}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmRubyTemplate = ({ name, rubyCode, execRules, Config }) => `<Extension ${name}>
    Module      xm_ruby
    ${rubyCode ? `RubyCode   ${rubyCode}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmSapTemplate = ({ name, execRules, Config }) => `<Extension ${name}>
    Module      xm_sap
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmSnmpTemplate = ({
    name,
    allowAuthenticatedOnly,
    MIBDir,
    Config,
    execRules
}) => `<Extension ${name}>
    Module      xm_snmp
    ${allowAuthenticatedOnly ? `AllowAuthenticatedOnly   ${allowAuthenticatedOnly}` : ''}
    ${MIBDir ? `MIBDir   ${MIBDir}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmSyslogTemplate = ({
    name,
    execRules,
    IETFTimestampInGMT,
    replaceLineBreaks,
    Config,
    snareDelimiter,
    snareReplacement,
    UTCTimestamp
}) => `<Extension ${name}>
    Module      xm_syslog
    ${IETFTimestampInGMT ? `IETFTimestampInGMT   ${IETFTimestampInGMT}` : ''}
    ${replaceLineBreaks ? `ReplaceLineBreaks   ${replaceLineBreaks}` : ''}
    ${snareDelimiter ? `SnareDelimiter   ${snareDelimiter}` : ''}
    ${snareReplacement ? `SnareReplacement   ${snareReplacement}` : ''}
    ${UTCTimestamp ? `UTCTimestamp   ${UTCTimestamp}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmW3CTemplate = ({
    name,
    fieldType,
    delimiter,
    Config,
    execRules
}) => `<Extension ${name}>
    Module      xm_w3c
    ${delimiter ? `Delimiter   ${delimiter}` : ''}
    ${fieldType ? `FieldType   ${fieldType}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmWtmpTemplate = ({ name, execRules, Config }) => `<Extension ${name}>
    Module      xm_wtmp
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmXmlTemplate = ({
    name,
    ignoreRootTag,
    includeHiddenFields,
    parseAttributes,
    rootTag,
    prefixWinEvent,
    execRules,
    Config
}) => `<Extension ${name}>
    Module      xm_xml
    ${ignoreRootTag ? `IgnoreRootTag   ${ignoreRootTag}` : ''}
    ${includeHiddenFields ? `IncludeHiddenFields   ${includeHiddenFields}` : ''}
    ${parseAttributes ? `ParseAttributes   ${parseAttributes}` : ''}
    ${rootTag ? `RootTag   ${rootTag}` : ''}
    ${prefixWinEvent ? `PrefixWinEvent   ${prefixWinEvent}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

const createXmZlibTemplate = ({
    name,
    format,
    compressionLevel,
    compBufSize,
    decompBufSize,
    Config,
    execRules,
    dataType,
    memoryLevel
}) => `<Extension ${name}>
    Module      xm_zlib
    ${format ? `Format   ${format}` : ''}
    ${compressionLevel ? `CompressionLevel   ${compressionLevel}` : ''}
    ${compBufSize ? `CompBufSize   ${compBufSize}` : ''}
    ${decompBufSize ? `DecompBufSize   ${decompBufSize}` : ''}
    ${dataType ? `DataType   ${dataType}` : ''}
    ${memoryLevel ? `MemoryLevel   ${memoryLevel}` : ''}
    ${
        execRules
            ? `<Exec>
      ${execRules.join('\n      ')}
    </Exec>`
            : ''
    }
    ${Config ? `${Config.split('\n').join('\n    ')}` : ''}
</Extension>`;

export {
    createXmZlibTemplate,
    createXmXmlTemplate,
    createXmWtmpTemplate,
    createXmW3CTemplate,
    createXmSyslogTemplate,
    createXmSnmpTemplate,
    createXmSapTemplate,
    createXmRubyTemplate,
    createXmRewriteTemplate,
    createXmResolverTemplate,
    createXmPythonTemplate,
    createXmPerlTemplate,
    createXmPatternTemplate,
    createXmNpsTemplate,
    createXmNetflowTemplate,
    createXmMultilineTemplate,
    createXmMsdnsTemplate,
    createXmLeefTemplate,
    createXmKvpTemplate,
    createXmJsonTemplate,
    createXmAdminTemplate,
    createXmAixauditTemplate,
    createXmAslTemplate,
    createXmBsmTemplate,
    createXmCefTemplate,
    createXmCarnovTemplate,
    createXmCSVTemplate,
    createXmCryptoTemplate,
    createXmExecTemplate,
    createXmFilelistTemplate,
    createXmFileopTemplate,
    createXmGelfTemplate,
    createXmGoTemplate,
    createXmGrokTemplate,
    createXmJavaTemplate
};
