/* eslint-disable no-useless-escape */
import { getDistinctField } from '../../../api/endpoints/agents';

export const RegexSplitMQLKeywords =
    /[^\s\"\'\(\)\=\!\<\>\,]+|"(\\.|[^\\])*?"|'(\\.|[^\\])*?'|(==|!=|\<\>|\<=|\>=|\<|>|IN|LIKE|IS|REGEX|HAS|NOT|,)|\(|\)/gm;
export const EXPECTED_TOKEN = {
    field: 'field',
    notOperators: 'notOperators',
    operator: 'operator',
    value: 'value',
    groupOperators: 'groupOperators',
    comma: 'comma',
    closeParenthesis: 'closeParenthesis'
};
export const defaultOperators = ['==', '!=', '<', '<=', '>', '>=', 'IN', 'IN ( ... )'];
export const defaultOptionalOperators = ['HAS', 'IS'];
export const defaultStringOperators = ['REGEX', 'LIKE'];
export const booleanValues = ['YES', 'NO', 'TRUE', 'FALSE'];
export const nullValues = ['NONE', 'NULL', 'EMPTY'];
export const operators = {
    uuid: [...defaultOperators],
    enum: [...defaultOperators],
    string: [...defaultOperators, ...defaultStringOperators],
    version: [...defaultOperators, ...defaultStringOperators],
    integer: [...defaultOperators],
    float: [...defaultOperators],
    address: [...defaultOperators]
};
export const groupOperators = ['AND', 'OR'];
export const notOperators = ['NOT', 'NOT ( ... )'];
export const allOperators = [
    ...defaultOperators,
    ...defaultOptionalOperators,
    ...defaultStringOperators
];
export const getDistinctFieldValues = async (field, filter, limit = 10, defaultValues = []) => {
    try {
        const { data } = await getDistinctField(field, null, limit).catch(() => null);
        const toCamelCase = (str) =>
            str.replace(/([-_][a-z])/gi, (match) =>
                match.toUpperCase().replace('-', '').replace('_', '')
            );
        return Array.isArray(data)
            ? data
                  .filter((item) => item[toCamelCase(field)])
                  .map((item) => `"${item[toCamelCase(field)]}"`)
                  .concat(defaultValues)
            : [...defaultValues];
    } catch (error) {
        return [...defaultValues];
    }
};
export const fields = [
    {
        text: 'id',
        type: 'uuid',
        values: (filter) => getDistinctFieldValues('id', filter, 100)
    },
    {
        text: 'status',
        type: 'enum',
        values: ['ok', 'warning', 'warn', 'error', 'err']
    },
    {
        text: 'hostname',
        type: 'string',
        values: (filter) => getDistinctFieldValues('name', filter, 100)
    },
    {
        text: 'host',
        type: 'string',
        values: (filter) => getDistinctFieldValues('name', filter, 100)
    },
    {
        text: 'name',
        type: 'string',
        values: (filter) => getDistinctFieldValues('name', filter, 100)
    },
    {
        text: 'os',
        type: 'string',
        values: (filter) => getDistinctFieldValues('os', filter, 10000)
    },
    {
        text: 'os-release',
        type: 'string',
        values: (filter) => getDistinctFieldValues('os-release', filter, 1000)
    },
    {
        text: 'arch',
        type: 'string',
        values: (filter) => getDistinctFieldValues('arch', filter, 1000)
    },
    {
        text: 'version',
        type: 'version',
        values: (filter) => getDistinctFieldValues('version', filter, 1000)
    },
    {
        text: 'online',
        type: 'boolean',
        values: [...booleanValues]
    },
    {
        text: 'persisted',
        type: 'boolean',
        values: [...booleanValues]
    },
    {
        text: 'enrolled',
        type: 'boolean',
        values: [...booleanValues]
    },
    {
        text: 'configured',
        type: 'boolean',
        values: [...booleanValues]
    },
    {
        text: 'template-id',
        type: 'uuid',
        optional: true,
        values: [...nullValues]
    },
    {
        text: 'template-name',
        type: 'string',
        optional: true,
        values: [...nullValues]
    },
    {
        text: 'synced',
        type: 'boolean',
        values: [...booleanValues]
    },
    {
        text: 'files-synced',
        type: 'boolean',
        values: [...booleanValues]
    },
    {
        text: 'config-synced-with-template',
        type: 'boolean',
        optional: true,
        values: [...booleanValues, ...nullValues]
    },
    {
        text: 'pid',
        type: 'integer',
        optional: true,
        values: [...nullValues]
    },
    {
        text: 'memory-used',
        type: 'integer',
        values: (value) => {
            const values = [];
            if (value === '') {
                values.push(`1`, `1K`, `1M`, `1G`);
            } else if (value.endsWith('K')) {
                values.push(`${value}`, `${value.replace('K', '')}M`, `${value.replace('K', '')}G`);
            } else if (value.endsWith('M')) {
                values.push(`${value}`, `${value.replace('M', '')}K`, `${value.replace('M', '')}G`);
            } else if (value.endsWith('G')) {
                values.push(`${value}`, `${value.replace('G', '')}K`, `${value.replace('G', '')}M`);
            } else {
                values.push(`${value}`, `${value}K`, `${value}M`, `${value}G`);
            }
            return values;
        }
    },
    {
        text: 'cpu-load',
        type: 'float',
        values: (value) => {
            const values = [];
            if (value === '') {
                values.push(`1`, `1%`);
            } else if (value.endsWith('%')) {
                values.push(`${value}`);
            } else {
                values.push(`${value}`, `${value}%`);
            }
            return values;
        }
    },
    {
        text: 'cpu-usage',
        type: 'integer',
        values: []
    },
    {
        text: 'thread-count',
        type: 'integer',
        values: []
    },
    {
        text: 'fd-count',
        type: 'integer',
        values: []
    },
    {
        text: 'events-per-second',
        type: 'float',
        values: []
    },
    {
        text: 'eps',
        type: 'float',
        values: []
    },
    {
        text: 'address',
        type: 'address',
        optional: true,
        values: (filter) => getDistinctFieldValues('address', filter, 1000, [...nullValues])
    },
    {
        text: 'manager-address',
        type: 'address',
        optional: true,
        values: (filter) => getDistinctFieldValues('manager-address', filter, 1000, [...nullValues])
    },
    {
        text: 'comment',
        type: 'string',
        values: []
    },
    {
        text: 'added-time',
        type: 'time',
        values: [`${new Date().toISOString().split('.')[0]}Z`]
    },
    {
        text: 'started-time',
        type: 'time',
        values: [`${new Date().toISOString().split('.')[0]}Z`]
    },
    {
        text: 'connected-time',
        type: 'time',
        optional: true,
        values: [`${new Date().toISOString().split('.')[0]}Z`, ...nullValues]
    },
    {
        text: 'persisted-time',
        type: 'time',
        optional: true,
        values: [`${new Date().toISOString().split('.')[0]}Z`, ...nullValues]
    },
    {
        text: 'enrolled-time',
        type: 'time',
        optional: true,
        values: [`${new Date().toISOString().split('.')[0]}Z`, ...nullValues]
    },
    {
        text: 'polled-time',
        type: 'time',
        values: [`${new Date().toISOString().split('.')[0]}Z`]
    }
].map((item) => {
    item.operators = operators[item.type] || defaultOperators;
    if (item.optional) {
        item.operators = [...item.operators, ...defaultOptionalOperators];
    }
    return item;
});

export const fieldsText = fields.map((field) => `${field.text}`.trim().toLowerCase());
