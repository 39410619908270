import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import propTypes from 'prop-types';
import { Button, Dropdown, Pagination } from '@nxlog/common-ui/components';
import Loader from '../common/loader';
import './selectedTemplates.scss';

export default function SelectedTemplates(props) {
    const {
        loading,
        count,
        onSelectAllChange,
        actions,
        handleSelectOption,
        rows,
        itemsPerPage,
        setItemsPerPage,
        setPage,
        page,
        onCheckedChanged
    } = props.selectedTemplates;
    return (
        <div
            data-testid="selected-templates"
            id="selected-templates"
            className="selected-templates"
        >
            {!!loading && <Loader />}
            <div className="selected-templates-title">
                <div>
                    <h4>
                        Selected templates
                        <span className="amount" data-testid="selected-templates-amount">
                            ({count})
                        </span>
                    </h4>
                    <Button
                        data-testid="selected-templates-remove-all"
                        className="clear-button"
                        onClick={() => onSelectAllChange('none')}
                    >
                        clear all
                    </Button>
                </div>

                {!!actions && (
                    <Dropdown
                        options={actions}
                        title="Operations"
                        className="selected-dropdown"
                        icon={<FontAwesomeIcon icon={faChevronDown} />}
                        onClick={handleSelectOption}
                        disabled={!count}
                        withIcon
                        closeMenuOnSelect
                    />
                )}
            </div>
            <div className="selected-templates-items">
                {!!rows &&
                    rows.map((item) => (
                        <div key={item.id}>
                            <b>{item.name}</b>
                            <Button
                                data-testid="selected-templates-remove"
                                ghostBtn
                                className="template-action close-button"
                                icon={<FontAwesomeIcon icon={faXmark} />}
                                onClick={() => onCheckedChanged(item.id, false)}
                            />
                        </div>
                    ))}
            </div>
            {count > itemsPerPage ? (
                <div className="table-footer">
                    <Pagination
                        className="selected-templates-pagination"
                        onPageSizeChange={setItemsPerPage}
                        itemsAmount={count}
                        itemsPerPage={itemsPerPage}
                        currentIndex={((page ?? 1) - 1) * itemsPerPage}
                        onPageChange={setPage}
                    />
                </div>
            ) : null}
        </div>
    );
}

SelectedTemplates.defaultProps = {};

SelectedTemplates.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    selectedTemplates: propTypes.object.isRequired
};
