import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getBaseUrl, parseBool } from '../utils/helpers/functions';
import { cacheRequestInterceptor, cacheResponseInterceptor } from './cacheInterceptor';
import { APP_LOGIN } from '../utils/constants/routes';

const BASE_URL = getBaseUrl();

const userName = window?.env?.REACT_APP_USERNAME ?? process.env.REACT_APP_USERNAME;
const userPass = window?.env?.REACT_APP_USERPASS ?? process.env.REACT_APP_USERPASS;

if (!!userName && !!userPass) {
    axios.defaults.headers.common.Authorization = `Basic ${btoa(`${userName}:${userPass}`)}`;
}

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
};

const textContentHeader = {
    Accept: 'text/plain',
    'Content-Type': 'text/plain'
};

const zipContentHeader = {
    Accept: 'application/zip',
    'Content-Type': 'application/zip'
};

export const apiService = axios.create({
    baseURL: BASE_URL,
    headers
});

export const portalApiService = axios.create({
    baseURL: BASE_URL,
    headers
});

export const textContentApiService = axios.create({
    baseURL: BASE_URL,
    textContentHeader
});

export const zipContentApiService = axios.create({
    baseURL: BASE_URL,
    headers: zipContentHeader,
    responseType: 'arraybuffer'
});

// request interceptor
const reqInterceptor = (config) => {
    config.baseURL = getBaseUrl();
    return config;
};

export const apiServiceWithCustomHeaders = (customHeaders) => {
    const customApiService = axios.create({ baseURL: getBaseUrl(), headers: customHeaders });
    customApiService.interceptors.request.use(reqInterceptor, Promise.reject);
    return customApiService;
};

apiService.interceptors.request.use(reqInterceptor, Promise.reject);

textContentApiService.interceptors.request.use(reqInterceptor, Promise.reject);

zipContentApiService.interceptors.request.use(reqInterceptor, Promise.reject);

// cache interceptors
const NO_CACHE = parseBool(
    window?.env?.REACT_APP_WITH_NO_CACHE ?? process.env.REACT_APP_WITH_NO_CACHE,
    false
);

if (!NO_CACHE) {
    apiService.interceptors.request.use(cacheRequestInterceptor, Promise.reject);

    apiService.interceptors.response.use(cacheResponseInterceptor);

    textContentApiService.interceptors.request.use(cacheRequestInterceptor, Promise.reject);

    textContentApiService.interceptors.response.use(cacheResponseInterceptor);

    zipContentApiService.interceptors.request.use(cacheRequestInterceptor, Promise.reject);

    zipContentApiService.interceptors.response.use(cacheResponseInterceptor);
}

// COMPONENT WRAPPER TO SET AXIOS INTERCEPTORS
export const AxiosInterceptor = ({ children }) => {
    const navigate = useNavigate();
    const [params] = useSearchParams();

    useEffect(() => {
        const resInterceptor = (response) => response;

        const errInterceptor = (error) => {
            const withInterceptor = parseBool(
                window?.env?.REACT_APP_WITH_RESPONSE_INTERCEPTORS ??
                    process.env.REACT_APP_WITH_RESPONSE_INTERCEPTORS
            );

            const airGapped = parseBool(
                window?.env?.REACT_APP_AIR_GAPPED ?? process.env.REACT_APP_AIR_GAPPED
            );

            // Redirect to /login page on 401 error status
            if (error.response?.status === 401 && withInterceptor && !airGapped) {
                navigate(APP_LOGIN, {
                    state: {
                        error: true,
                        message: 'You are not logged in',
                        id: 'error_login'
                    }
                });
            }

            // Send 'Not found' message if error status code 404
            if (error.response?.status === 404) {
                error.response.data = { message: 'No server response' };
            }

            // Modify the error response to include the server error message
            if (error.response?.data) {
                error.message = error.response.data;
            }

            return Promise.reject(error);
        };

        const apiServiceReqInterceptor = apiService.interceptors.request.use(
            reqInterceptor,
            Promise.reject
        );

        const textContentApiServiceReqInterceptor = textContentApiService.interceptors.request.use(
            reqInterceptor,
            Promise.reject
        );

        const zipContentApiServiceReqInterceptor = zipContentApiService.interceptors.request.use(
            reqInterceptor,
            Promise.reject
        );

        const apiServiceResInterceptor = apiService.interceptors.response.use(
            resInterceptor,
            errInterceptor
        );
        const textContentApiServiceResInterceptor = textContentApiService.interceptors.response.use(
            resInterceptor,
            errInterceptor
        );
        const zipContentApiServiceResInterceptor = zipContentApiService.interceptors.response.use(
            resInterceptor,
            errInterceptor
        );

        return () => {
            apiService.interceptors.request.eject(apiServiceReqInterceptor);
            textContentApiService.interceptors.request.eject(textContentApiServiceReqInterceptor);
            zipContentApiService.interceptors.request.eject(zipContentApiServiceReqInterceptor);
            apiService.interceptors.response.eject(apiServiceResInterceptor);
            textContentApiService.interceptors.response.eject(textContentApiServiceResInterceptor);
            zipContentApiService.interceptors.response.eject(zipContentApiServiceResInterceptor);
        };
    }, [navigate, params, apiService, textContentApiService, zipContentApiService, reqInterceptor]);

    return children;
};

export const { get, post } = apiService;
