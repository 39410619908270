import { v4 as uuidv4 } from 'uuid';

export default function createRoute() {
    return {
        key: uuidv4(),
        name: '',
        comment: '',
        processors: [],
        collect: [
            {
                module: null,
                actions: []
            }
        ],
        sendTo: [
            {
                module: null,
                actions: []
            }
        ]
    };
}
