/* eslint-disable prefer-destructuring */
/* eslint-disable no-cond-assign */
import { validateHost, validateHostPort, validatePort } from '../../../utils/helpers/functions';
import { getActionByContentMatch, getModuleByName, extensions as extensionsList } from '../modules';
import CodeBlock from './CodeBlock';
import { CONTENT_TAGS, ROOT_TAGS } from './constants';
import createRoute from './createRoute';
import { isValidQuoteString } from './execScriptParser';
import { getModuleFieldsAsObject } from './generateConfigCode';

/**
 * This function moves XML-style comments to a new line
 * if they are not separated with \n
 * Function skips <Exec></Exec> blocks
 * */
export function preFormatText(text) {
    const execBlockPattern = /<!--[\s\S]*?-->|<exec>[\s\S]*?<\/exec>/gim;
    return text.replace(execBlockPattern, (match, index) => {
        if (match.match(/^<!--/)) {
            const str = [];
            if (
                text
                    .substring(0, index)
                    .split('\n')
                    .pop()
                    .match(/[^\s]+/i)
            ) {
                str.push('\n');
            }
            str.push(match);
            if (
                text
                    .substring(index + match.length)
                    .split('\n')[0]
                    .match(/[^\s]+/i)
            ) {
                str.push('\n');
            }
            return str.join('');
        }
        return match;
    });
}

// This function parses each line and builds a tree that represents the components of the config text.
function parseConfigLines(list, parents, firstParent) {
    const result = [];
    const parent = parents.length ? `${parents[0]}`.trim().toLowerCase() : '';
    while (list.length) {
        const { line, index } = list.shift();
        if (CONTENT_TAGS.includes(parent)) {
            let r = null;
            if (
                (r = line.match(
                    /^\s*<\/?(?<tag>[a-zA-Z0-9_]+)(\s+(?<name>[a-zA-Z0-9_]+)?|\s*)>\s*$/i
                ))
            ) {
                if (ROOT_TAGS.includes(`${r.groups.tag}`.trim().toLowerCase())) {
                    list.unshift({ line, index });
                    if (firstParent) firstParent.unclosedTag = true;
                    return result;
                }
            }
            if (line.match(new RegExp(`^\\s*</${parent}\\s*>`, 'i'))) {
                return result;
            }
            result.push({
                type: 'content',
                line,
                index
            });
        } else {
            let r = null;
            if (line.match(/^\s*<!--/i)) {
                list.unshift({ line, index });
                const endOfComment = list.findIndex((el) => el.line.match(/-->\s*$/i));
                if (endOfComment < 0) {
                    result.push({
                        type: 'comment',
                        line,
                        index,
                        error: <b>Unclosed comment tag</b>
                    });
                    list.shift();
                } else {
                    for (let i = 0; i <= endOfComment; i += 1) {
                        const { line: comLine, index: comIndex } = list.shift();
                        result.push({
                            type: 'comment',
                            line: comLine,
                            index: comIndex
                        });
                    }
                }
            } else if (line.match(/^\s*#[\s\S]*$/i)) {
                result.push({
                    type: 'comment',
                    line,
                    index
                });
            } else if (line.match(/^\s*$/i)) {
                result.push({
                    type: 'empty-line',
                    line,
                    index
                });
            } else if (
                (r = line.match(/^\s*(?<flag>[a-zA-Z]+[a-zA-Z0-9_]*)\s*(?<value>[\s\S]*)$/i))
            ) {
                result.push({
                    type: 'flag',
                    flag: r.groups.flag,
                    value: `${r.groups.value || ''}`.trimEnd(),
                    line,
                    index
                });
            } else if (
                (r = line.match(/^\s*<(?<tag>[a-zA-Z0-9_]+)(\s+(?<name>[a-zA-Z0-9_]+)?|\s*)>/i))
            ) {
                if (ROOT_TAGS.includes(`${r.groups.tag}`.trim().toLowerCase())) {
                    if (firstParent) {
                        for (let i = firstParent.index[0] + 1; i <= index[0] - 1; i += 1) {
                            firstParent.index.push(i);
                            firstParent.unclosedTag = true;
                        }
                        list.unshift({ line, index });
                        return result;
                    }
                }
                const elm = {
                    type: 'tag',
                    tag: r.groups.tag,
                    name: r.groups.name,
                    children: [],
                    line,
                    index
                };
                elm.children = parseConfigLines(list, [r[1], ...parents], elm);
                elm.index = index;
                result.push(elm);
            } else if ((r = line.match(/^\s*<\/(?<tag>[a-zA-Z0-9_]+)\s*[^>]*>\s*$/i))) {
                if (`${parents[0]}`.trim().toLowerCase() === `${r[1]}`.trim().toLowerCase()) {
                    if (firstParent) {
                        for (let i = firstParent.index[0] + 1; i <= index[0]; i += 1) {
                            firstParent.index.push(i);
                        }
                    }
                    return result;
                }
            } else {
                result.push({
                    type: 'content',
                    line,
                    index
                });
            }
        }
    }

    if (firstParent) {
        firstParent.unclosedTag = true;
    }

    return result;
}

// This function parses config text and builds a tree that represents the components of the config text.
export function parseConfig(txt) {
    const lines = txt.split('\n');
    const listLines = [];
    let i = -1;
    let j = 0;
    while (lines.length) {
        i += 1;
        j += 1;
        const line = lines.shift();
        if (!listLines[i]) listLines[i] = [];
        listLines[i].push({ line, i: j });
        if (line.match(/\\$/)) i -= 1;
    }
    return parseConfigLines(
        listLines.map((list) =>
            list.reduce(
                (acc, item, k) => {
                    if (k > 0) {
                        acc.line += `${acc.line ? '\n' : ''}${item.line
                            .trimLeft()
                            .replace(/[\\]+$/, '')}`;
                    } else {
                        acc.line += `${acc.line ? '\n' : ''}${item.line.replace(/[\\]+$/, '')}`;
                    }
                    acc.index.push(item.i);
                    return acc;
                },
                { line: '', index: [] }
            )
        ),
        []
    );
}

export const getChildren = (children, filter = {}) =>
    children.filter((elm) =>
        Object.entries(filter).every(
            ([key, value]) => `${elm[key]}`.trim().toLowerCase() === `${value}`.trim().toLowerCase()
        )
    );

export const getChild = (children, filter = {}) => {
    const l = getChildren(children, filter);
    if (l.length) return l[0];
    return null;
};

// This function parses config text and builds a JSON object to be used for graphical representation of the config.
export function getBuilderDataFromText(code, onChange) {
    const list = parseConfig(code);
    const tags = list.filter((elm) => elm.type === 'tag');
    const directives = list.filter((elm) => elm.type === 'flag');
    const comments = list.filter((elm) => elm.type === 'comment');
    const emptyLines = list.filter((elm) => elm.type === 'empty-line');
    const routes = tags.filter((elm) => `${elm.tag}`.trim().toLowerCase() === 'route');
    const extensions = tags.filter((elm) => `${elm.tag}`.trim().toLowerCase() === 'extension');
    const processors = tags.filter((elm) => `${elm.tag}`.trim().toLowerCase() === 'processor');
    const inputs = tags.filter((elm) => `${elm.tag}`.trim().toLowerCase() === 'input');
    const outputs = tags.filter((elm) => `${elm.tag}`.trim().toLowerCase() === 'output');
    const errors = {};

    const addError = (line, message) => {
        if (!errors[`Line ${line}`]) {
            errors[`Line ${line}`] = {
                line,
                message: []
            };
        }
        errors[`Line ${line}`].message.push(message);
    };

    const collectParserErrors = (lines) => {
        lines.forEach((elm) => {
            if (elm.error) {
                addError(elm.index, elm.error);
            }
            if (elm.children) {
                collectParserErrors(elm.children);
            }
        });
    };

    collectParserErrors(list);

    inputs.forEach((mTag, i) => {
        if (!mTag.name) {
            addError(mTag.index, <b>Module name required.</b>);
            const module = getChild(mTag.children, { type: 'flag', flag: 'module' });
            const mdl = getModuleByName(module?.value);
            if (mdl) {
                mTag.name = mdl.name;
            } else {
                mTag.name = `im_${i + 1}`;
            }
        }
    });

    outputs.forEach((mTag, i) => {
        if (!mTag.name) {
            addError(mTag.index, <b>Module name required.</b>);
            const module = getChild(mTag.children, { type: 'flag', flag: 'module' });
            const mdl = getModuleByName(module?.value);
            if (mdl) {
                mTag.name = mdl.name;
            } else {
                mTag.name = `om_${i + 1}`;
            }
        }
    });

    routes.forEach((mTag, i) => {
        if (!mTag.name) {
            mTag.name = `route_${i + 1}`;
        }
    });

    const checkParsedValueWithTheExpectedValue = (field, parsedData) => {
        if (Array.isArray(field.options)) {
            const options = field.options.map((op) => `${op}`.trim().toLowerCase());
            if (field.multiple) {
                if (field.separator) {
                    let listValue = [];
                    if (typeof field.value === 'string') {
                        listValue = field.value.split(`${field.separator}`.trim());
                    } else if (Array.isArray(field.value)) {
                        listValue = field.value.map((elm) => elm.value);
                    }
                    const wrongValues = listValue
                        .filter((value) => !`${value}`.trim().match(/^%/))
                        .filter((value) => !options.includes(`${value}`.trim().toLowerCase()));

                    if (wrongValues.length)
                        addError(
                            parsedData.index,
                            <b>{`Unexpected Value${
                                wrongValues.length > 1 ? 's' : ''
                            } in the list: ${wrongValues.join(', ')}`}</b>
                        );
                } else if (
                    typeof field.value === 'string' &&
                    !`${field.value}`.trim().match(/^%/) &&
                    !options.includes(`${field.value}`.trim().toLowerCase())
                ) {
                    addError(parsedData.index, <b>Unexpected Value</b>);
                }
            } else if (
                typeof field.value === 'string' &&
                !`${field.value}`.trim().match(/^%/) &&
                !options.includes(`${field.value}`.trim().toLowerCase())
            ) {
                addError(parsedData.index, <b>Unexpected Value</b>);
            }
        }
    };

    function insertAtLine(lineNbr, txt) {
        const lines = code.split('\n');
        lines.splice(lineNbr, 0, txt);
        onChange(lines.join('\n'));
    }

    function parseActionContent(content, lineNbr, moduleLineNbr) {
        `${content}`.split('\n').forEach((line, i) => {
            line.replace(
                /^\s*#.*$|"(\\".|[^\\"])*"|'(\\'.|[^\\'])*'|(?:(?<mdlName>\w+[a-zA-Z0-9_-]*?)->)?(?<fnName>\w+[a-zA-Z0-9_])\s*\(/gim,
                (...args) => {
                    if (['"', "'", '#'].includes(args[0])) return '';
                    const { mdlName, fnName } = args[args.length - 1];
                    const xm = extensionsList.find(
                        (x) =>
                            Array.isArray(x.functions) &&
                            x.functions.find(
                                (f) =>
                                    `${f.name}`.trim().toLowerCase() ===
                                    `${fnName}`.trim().toLowerCase()
                            )
                    );
                    if (xm) {
                        const parsedXmWithAnyName = extensions.filter((x) => {
                            const module = getChild(x.children, { type: 'flag', flag: 'module' });
                            return (
                                module &&
                                `${module.value}`.trim().toLowerCase() ===
                                    `${xm.module}`.trim().toLowerCase()
                            );
                        });
                        const parsedXm = parsedXmWithAnyName.filter(
                            (x) =>
                                !mdlName ||
                                `${x.name}`.trim().toLowerCase() ===
                                    `${mdlName}`.trim().toLowerCase()
                        );
                        if (!parsedXm.length) {
                            const message = (
                                <>
                                    <p>
                                        <b>
                                            The <mark>{fnName}</mark> function requires the{' '}
                                            <mark>{xm.module}</mark> extension module to be loaded
                                            {mdlName ? (
                                                <>
                                                    {' '}
                                                    with the name <mark>{mdlName}</mark>.
                                                </>
                                            ) : (
                                                '.'
                                            )}
                                        </b>
                                        <br />
                                        Please ensure you have the following extension
                                        configuration:
                                        <br />
                                    </p>
                                    <CodeBlock
                                        code={`<Extension ${
                                            mdlName || xm.module.split('xm_')[1]
                                        }>\n\tModule      ${xm.module}\n</Extension>`}
                                        onInsert={
                                            onChange &&
                                            ((txt) => insertAtLine(moduleLineNbr - 1, `${txt}\n`))
                                        }
                                    />
                                    {parsedXmWithAnyName.length ? (
                                        <p>
                                            Note: The &apos;{xm.module}&apos; extension module is
                                            already loaded by the name &apos;
                                            {parsedXmWithAnyName[0].name}&apos; at line{' '}
                                            {parsedXmWithAnyName[0].index[0]}.
                                        </p>
                                    ) : null}
                                </>
                            );
                            addError([lineNbr + i + 1], message);
                        }
                    }
                    return '';
                }
            );
        });
        return content;
    }

    function convertTagToModule(tag, mdlList) {
        if (!tag) return { module: null, actions: [] };
        const alreadyExists = mdlList.find(
            (m) =>
                m.module?.fields?.find(({ name }) => `${name}`.trim().toLowerCase() === 'name')
                    ?.value === tag.name
        );
        if (alreadyExists) {
            return alreadyExists;
        }
        const module = getChild(tag.children, { type: 'flag', flag: 'module' });
        const mdl = {
            tag: tag.tag,
            module: getModuleByName(module?.value, `${tag.name || ''}`.replace(/_[0-9]+$/i, '')),
            actions: [],
            lineNbr: tag.index[0],
            endLineNbr: tag.index[tag.index.length - 1]
        };

        if (module && !mdl.module) {
            addError(module.index, <b>Unrecognized module name &quot;{module?.value}&quot;.</b>);
        } else if (!module) {
            addError(tag.index, <b>Missing required directive: Module.</b>);
        }

        if (!mdl.module) {
            mdl.module = {
                label: `${module?.value || 'unknown module'}`,
                unknownModule: true,
                name: module?.value,
                type: `${tag.tag}`.toLowerCase().trim(),
                defaultDirectives: `Module      ${module?.value}`,
                fields: [
                    {
                        name: 'Name',
                        type: 'string',
                        required: true,
                        defaultValue: ''
                    }
                ]
            };
        }

        if (mdl.module) {
            // eslint-disable-next-line prefer-destructuring
            mdl.module.lineNbr = tag.index[0];
            mdl.module.endLineNbr = tag.index[tag.index.length - 1];
        }

        if (tag.unclosedTag) {
            addError(tag.index, <b>Close tag missing.</b>);
        }

        const getModuleName = () => {
            if (!tag.name) {
                addError(tag.index, <b>Module name required.</b>);
                if (mdl.module) tag.name = mdl.module.name;
            }
            return tag.name;
        };

        if (mdl.module) {
            mdl.module.unknownDirectives = mdl.module.unknownDirectives || [];
            let commentNbr = 1;
            const definedDirectives = ['Name'];
            const fieldName = mdl.module.fields.find(
                ({ name }) => `${name}`.trim().toLowerCase() === 'name'
            );
            if (fieldName) {
                fieldName.value = getModuleName();
                fieldName.changed = true;
            }
            tag.children.forEach((child) => {
                if (child.type === 'flag' && child.flag.trim().toLowerCase() !== 'exec') {
                    const field = mdl.module.fields.find(
                        ({ name }) =>
                            `${name}`.trim().toLowerCase() === `${child.flag}`.trim().toLowerCase()
                    );
                    if (field) {
                        definedDirectives.push(field.name);
                        field.lineNbr = child.index[0];
                        if (field.multiple) {
                            if (field.separator) {
                                field.value = `${child.value || ''}`
                                    .split(field.separator.trim())
                                    .map((value) => value.trim())
                                    .filter((value) => value)
                                    .map((value) => ({ value, $lineNbr: field.lineNbr }));
                            } else if (field.type === 'host-port-in-same-input') {
                                if (!Array.isArray(field.value)) field.value = [];
                                const hostError = validateHostPort(child.value);
                                if (hostError !== true) {
                                    addError(child.index, <b>{hostError}</b>);
                                }
                                field.value.push({
                                    value: { value: `${child.value || ''}` },
                                    $lineNbr: field.lineNbr
                                });
                            } else if (field.type === 'host-port') {
                                if (!Array.isArray(field.value)) field.value = [];
                                const parsedHost = `${child.value || ''}`
                                    .trim()
                                    .split(':')
                                    .map((v) => v.trim());
                                const port = parsedHost.length > 1 ? parsedHost.pop() : '';
                                const host = parsedHost.join(':');
                                if (!validateHost(host)) {
                                    addError(child.index, <b>Invalid host format.</b>);
                                }
                                if (!validatePort(host)(port)) {
                                    addError(child.index, <b>Invalid port number.</b>);
                                }
                                field.value.push({
                                    value: { host: host || '', port: port || '' },
                                    $lineNbr: field.lineNbr
                                });
                            } else if (field.type === 'block') {
                                if (field.defaultField) {
                                    if (!Array.isArray(field.value)) field.value = [];
                                    field.value.push({ [field.defaultField]: child.value });
                                } else {
                                    addError(
                                        child.index,
                                        <b>
                                            Invalid {child.flag} directive format in{' '}
                                            {mdl.module.name} module.
                                        </b>
                                    );
                                }
                            } else {
                                if (!Array.isArray(field.value)) field.value = [];
                                field.value.push({ value: child.value, $lineNbr: field.lineNbr });
                            }
                        } else {
                            if (field.quoting) {
                                if (
                                    !`${child.value}`.trim().match(/^%[^%]*%/i) &&
                                    !isValidQuoteString(child.value)
                                ) {
                                    addError(
                                        child.index,
                                        <b>{`Quoting for ${child.flag} directive value is not correctly respected.`}</b>
                                    );
                                }
                            }
                            field.value = child.value;
                        }
                        field.changed = true;
                        checkParsedValueWithTheExpectedValue(field, child);
                    } else if (`${child.flag}`.trim().toLowerCase() !== 'module') {
                        mdl.module.unknownDirectives.push(child);
                        addError(
                            child.index,
                            <b>
                                Unexpected {child.flag} directive in {mdl.module.name} module.
                            </b>
                        );
                    }
                } else if (child.type === 'tag' && child.tag.trim().toLowerCase() !== 'exec') {
                    const field = mdl.module.fields.find(
                        ({ name }) =>
                            `${name}`.trim().toLowerCase() === `${child.tag}`.trim().toLowerCase()
                    );
                    if (field) {
                        definedDirectives.push(field.name);
                        field.lineNbr = child.index[0];
                        if (Array.isArray(field?.block) && field?.block?.length) {
                            const values = {
                                $order: []
                            };
                            field.block.forEach((subField) => {
                                if (subField.isName) {
                                    subField.value = child.name;
                                    subField.changed = true;
                                    values[subField.name] = subField.value;
                                } else {
                                    switch (subField.type) {
                                        case 'multiline-string-block':
                                            {
                                                let subChild = getChild(child.children, {
                                                    type: 'tag',
                                                    tag: subField.name
                                                });
                                                if (subChild) {
                                                    values.$order.push({
                                                        name: subField.name,
                                                        lineNbr: subChild.index[0]
                                                    });
                                                    const listContents = getChildren(
                                                        subChild.children,
                                                        { type: 'content' }
                                                    );
                                                    subField.value = listContents
                                                        .map(({ line }) => line)
                                                        .join('\n');
                                                    subField.changed = true;
                                                    values[subField.name] = subField.value;
                                                    checkParsedValueWithTheExpectedValue(
                                                        subField,
                                                        subChild
                                                    );
                                                } else if (
                                                    (subChild = getChild(child.children, {
                                                        type: 'flag',
                                                        flag: subField.name
                                                    }))
                                                ) {
                                                    values.$order.push({
                                                        name: subField.name,
                                                        lineNbr: subChild.index[0]
                                                    });
                                                    subField.value = subChild.value;
                                                    values[subField.name] = subField.value;
                                                    subField.changed = true;
                                                    checkParsedValueWithTheExpectedValue(
                                                        subField,
                                                        subChild
                                                    );
                                                } else if (subField.required) {
                                                    addError(
                                                        child.index,
                                                        <b>{`${subField.name} directive is missing.`}</b>
                                                    );
                                                }
                                            }
                                            break;
                                        default:
                                            {
                                                const subChild = getChild(child.children, {
                                                    type: 'flag',
                                                    flag: subField.name
                                                });
                                                if (subChild) {
                                                    values.$order.push({
                                                        name: subField.name,
                                                        lineNbr: subChild.index[0]
                                                    });
                                                    if (subField.multiple) {
                                                        subField.value = [];
                                                        const subChildren = getChildren(
                                                            child.children,
                                                            { type: 'flag', flag: subField.name }
                                                        );
                                                        subChildren.forEach((elm) => {
                                                            subField.value.push({
                                                                value: elm.value
                                                            });
                                                        });
                                                    } else {
                                                        subField.value = subChild.value;
                                                    }
                                                    values[subField.name] = subField.value;
                                                    subField.changed = true;
                                                    checkParsedValueWithTheExpectedValue(
                                                        subField,
                                                        subChild
                                                    );
                                                } else if (subField.required) {
                                                    addError(
                                                        child.index,
                                                        <b>{`${subField.name} directive is missing.`}</b>
                                                    );
                                                }
                                            }
                                            break;
                                    }
                                }
                            });
                            const listComments = getChildren(child.children, { type: 'comment' });
                            listComments.forEach((elm, i) => {
                                values[`comment_${i}`] = elm.line;
                                values.$order.push({ name: `comment_${i}`, lineNbr: elm.index[0] });
                            });

                            const listEmptyLines = getChildren(child.children, {
                                type: 'empty-line'
                            });
                            listEmptyLines.forEach((elm) => {
                                values.$order.push({ name: `empty-line`, lineNbr: elm.index[0] });
                            });

                            if (field.multiple) {
                                field.value = Array.isArray(field.value) ? field.value : [];
                                values.$lineNbr = field.lineNbr;
                                field.value.push(values);
                            }
                            field.changed = true;
                        } else if (field.type === 'multiline-string-block') {
                            const listContents = getChildren(child.children, { type: 'content' });
                            field.value = listContents.map(({ line }) => line).join('\n');
                            field.changed = true;
                            checkParsedValueWithTheExpectedValue(field, child);
                        } else if (field.type === 'key-value') {
                            field.value = (Array.isArray(child.children) ? child.children : [])
                                .filter((elm) => elm.type === 'flag')
                                .map((elm) => ({ value: { key: elm.flag, value: elm.value } }));
                            field.changed = true;
                        } else {
                            addError(child.index, <b>Invalid {child.tag} directive format.</b>);
                        }
                    } else {
                        mdl.module.unknownDirectives.push({
                            ...child,
                            line: code
                                .split('\n')
                                .filter((_, i) => child.index.includes(i + 1))
                                .join('\n')
                        });
                        addError(
                            child.index,
                            <b>
                                Unexpected {child.tag} directive in {mdl.module.name} module.
                            </b>
                        );
                    }
                } else if (child.type === 'flag' && child.flag.trim().toLowerCase() === 'exec') {
                    const action = getActionByContentMatch(`${child.value}`, 'exec');
                    if (action) {
                        action.code = parseActionContent(
                            `${child.value}`,
                            child.index[0] - 1,
                            tag.index[0]
                        );
                        action.isFlag = true;
                        mdl.actions.push(action);
                    }
                } else if (child.type === 'tag' && child.tag.trim().toLowerCase() === 'exec') {
                    const actionContent = child.children.map((elm) => elm.line).join('\n');
                    const action = getActionByContentMatch(actionContent, 'exec');
                    if (action) {
                        action.code = parseActionContent(
                            child.children.map((elm) => elm.line).join('\n'),
                            child.index[0],
                            tag.index[0]
                        );
                        mdl.actions.push(action);
                    }
                } else if (child.type === 'comment') {
                    mdl.module.fields.push({
                        name: `comment_${commentNbr}`,
                        type: 'comment',
                        value: child.line,
                        changed: true,
                        lineNbr: child.index[0]
                    });
                    commentNbr += 1;
                } else if (child.type === 'empty-line') {
                    mdl.module.fields.push({
                        name: `empty-line`,
                        type: 'empty-line',
                        value: '',
                        changed: true,
                        lineNbr: child.index[0]
                    });
                }

                if (child.type === 'tag' && child.unclosedTag) {
                    addError(child.index, <b>Close tag missing.</b>);
                }
            });

            const data = getModuleFieldsAsObject(mdl.module.fields);
            const missingDirectives = mdl.module.fields
                .filter((field) => !definedDirectives.includes(field.name) && !field.isNotDirective)
                .filter((field) => {
                    if (field.if && !field.if(data)) return false;
                    if (field.required && typeof field.required === 'function')
                        return field.required(data);
                    return !!field.required;
                })
                .map((field) => field.name);
            if (missingDirectives.length)
                addError(
                    tag.index,
                    <b>{`Missing required directive${
                        missingDirectives.length > 1 ? 's' : ''
                    }: ${missingDirectives.join(', ')}.`}</b>
                );
        }
        return mdl;
    }

    const getCloseTag = (line) => {
        let name = line.split(/\s+[^<>]+>/)[0];
        name = name.replace('<', '</');
        if (!name.includes('>')) name = `${name}>`;
        return name;
    };

    const getContentFromParsedTag = (tag) =>
        tag.children
            .map((elm) => {
                const lines = [elm.line.replace(/\t/gi, '  ').replace(/[\\]*\n/gi, '\\\n')];
                if (elm.type === 'tag') {
                    lines.push(getContentFromParsedTag(elm));
                    lines.push(
                        getCloseTag(elm.line.replace(/\t/gi, '  ').replace(/[\\]*\n/gi, '\\\n'))
                    );
                }
                return lines.join('\n');
            })
            .join('\n');

    const item = {
        routes: [],
        directives: [],
        extensions: [],
        processors: [],
        content: code,
        errors: [],
        comments: [],
        emptyLines: []
    };

    comments.forEach((p) => {
        item.comments.push({
            lineNbr: p.index[0],
            content: p.line
        });
    });

    emptyLines.forEach((p) => {
        item.emptyLines.push({
            lineNbr: p.index[0],
            content: ''
        });
    });

    extensions.forEach((ext) => {
        const moduleFlag = getChild(ext.children, { type: 'flag', flag: 'module' });
        if (moduleFlag && moduleFlag.value && `${moduleFlag.value}`.trim().toLowerCase()) {
            if (getModuleByName(`${moduleFlag.value}`.trim().toLowerCase())) {
                const PExt = convertTagToModule(ext, item.extensions);
                if (PExt && PExt.module) {
                    item.extensions.push(PExt.module);
                }
            } else {
                item.extensions.push({
                    tag: ext.tag,
                    module: moduleFlag.value,
                    name: ext.name,
                    lineNbr: ext.index[0],
                    endLineNbr: ext.index[ext.index.length - 1],
                    content: getContentFromParsedTag(ext)
                });
            }
        } else {
            addError(ext.index, <b>Missing required directive: Module.</b>);
        }
    });

    processors.forEach((p) => {
        const moduleFlag = getChild(p.children, { type: 'flag', flag: 'module' });
        if (moduleFlag && moduleFlag.value) {
            item.processors.push({
                tag: p.tag,
                module: moduleFlag.value,
                name: p.name,
                lineNbr: p.index[0],
                endLineNbr: p.index[p.index.length - 1],
                content: getContentFromParsedTag(p)
            });
        }
    });

    directives.forEach((p) => {
        item.directives.push({
            name: p.flag,
            lineNbr: p.index[0],
            content: p.value
        });
    });

    if (!routes.length) {
        const pathvalue = `${inputs.map((inp) => inp.name).join(', ')} => ${outputs
            .map((outp) => outp.name)
            .join(', ')}`;
        routes.push({
            name: 'default_route',
            index: [list.length ? list[list.length - 1].index[0] + 1 : 1],
            children: [
                {
                    type: 'flag',
                    flag: 'path',
                    value: pathvalue,
                    line: `    Path ${pathvalue}`
                }
            ]
        });
    }

    const inputsAdded = [];
    const outputsAdded = [];

    const createRouteFromTag = (tag) => {
        const route = createRoute();
        route.name = tag.name;
        route.lineNbr = Array.isArray(tag.index) && tag.index.length ? tag.index[0] : null;
        route.content = tag.children.filter((elm) =>
            ['flag', 'comment', 'empty-line'].includes(elm.type)
        );
        route.tag = tag.tag;
        const path = getChild(tag.children, { type: 'flag', flag: 'path' });
        if (!path) {
            addError(tag.index, <b>Missing required directive: Path.</b>);
        }
        if (path?.value) {
            const mdlNamesList = path.value.split('=>');
            let [inputList, ...processorsList] = mdlNamesList;
            processorsList = Array.isArray(processorsList) ? processorsList : [];
            let outputlist = processorsList.pop();

            if (!inputList.length) {
                addError(path.index, <b>Input modules are missing in the path directive.</b>);
            }

            if (!outputlist.length) {
                addError(path.index, <b>Output modules are missing in the path directive.</b>);
            }

            route.processors = processorsList
                .map((listP) =>
                    listP
                        .split(',')
                        .map((v) => v.trim())
                        .filter((v) => v)
                        .join(', ')
                )
                .filter((listP) => listP);
            inputList = inputList || '';
            outputlist = outputlist || '';
            inputList = inputList
                .split(',')
                .map((v) => v.trim())
                .filter((v) => v);
            outputlist = outputlist
                .split(',')
                .map((v) => v.trim())
                .filter((v) => v);
            const listCollect = item.routes
                .map((r) => r.collect)
                .reduce((acc, listMdl) => [...acc, ...listMdl], []);
            const listSendTo = item.routes
                .map((r) => r.sendTo)
                .reduce((acc, listMdl) => [...acc, ...listMdl], []);

            inputList
                .map((name) => {
                    const InP = getChild(inputs, { name });
                    return { name, InP };
                })
                .filter(({ name, InP }) => {
                    if (!InP) {
                        addError(path.index, <b>{`Module '${name}' not found`}</b>);
                    }
                    return !!InP;
                })
                .forEach(({ name, InP }, i) => {
                    inputsAdded.push(`${name}`.trim());
                    route.collect[i] = convertTagToModule(InP, listCollect);
                });

            outputlist
                .map((name) => {
                    const OutP = getChild(outputs, { name });
                    return { name, OutP };
                })
                .filter(({ name, OutP }) => {
                    if (!OutP) {
                        addError(path.index, <b>{`Module '${name}' not found`}</b>);
                    }
                    return !!OutP;
                })
                .forEach(({ name, OutP }, i) => {
                    outputsAdded.push(`${name}`.trim());
                    route.sendTo[i] = convertTagToModule(OutP, listSendTo);
                });

            item.routes.push(route);
        }
    };

    routes.forEach(createRouteFromTag);

    const inputsNotRouted = inputs
        .map((tag) => tag.name)
        .filter((name) => !inputsAdded.includes(`${name}`.trim()));
    const outputsNotRouted = outputs
        .map((tag) => tag.name)
        .filter((name) => !outputsAdded.includes(`${name}`.trim()));

    if (inputsNotRouted.length || outputsNotRouted.length) {
        createRouteFromTag({
            name: 'default_route',
            children: [
                {
                    type: 'flag',
                    flag: 'path',
                    value: `${inputsNotRouted.join(', ')} => ${outputsNotRouted.join(', ')}`
                }
            ]
        });
    }

    item.errors = Object.values(errors);
    item.errors.sort((a, b) => a.line - b.line);
    return item;
}

export function selectDirectiveFromConfig(selector, config) {
    const list = selector.trim().split('.');
    let result = [{ children: parseConfig(config) }];
    while (list.length) {
        const item = list.shift();
        const isDirective = list.length === 0;
        if (isDirective) {
            result = result
                .map((elm) =>
                    getChild(elm.children, { type: 'flag', flag: item.trim().toLowerCase() })
                )
                .filter((flag) => flag);
        } else {
            const newResult = [];
            result.forEach((elm) => {
                const tags = getChildren(elm.children, { type: 'tag' });
                newResult.push(
                    ...tags.filter((tag) => {
                        const module = getChild(tag.children, { type: 'flag', flag: 'module' });
                        return (
                            module &&
                            `${module.value}`.trim().toLowerCase() === item.trim().toLowerCase()
                        );
                    })
                );
            });
            result = newResult;
        }
    }
    return result;
}

export function updateDirectiveValueInConfig(selector, config, value) {
    let content = config;
    selectDirectiveFromConfig(selector, config).forEach((elm) => {
        content = content
            .split('\n')
            .map((line, index) => {
                if (elm.index.includes(index + 1)) {
                    return line.replace(
                        elm.value,
                        typeof value === 'function' ? value(elm) : value
                    );
                }
                return line;
            })
            .join('\n');
    });
    return content;
}

export default null;
