import React from 'react';
import propTypes from 'prop-types';
import { Checkbox } from '@nxlog/common-ui/components';
import SortIcon from '@nxlog/common-ui/dist/components/svgs/sort';

export default function TableHeader(props) {
    const { data, onCheckAll, isChecked, onFilterTable } = props;

    const handleFilter = (filterParam) => () => {
        onFilterTable(filterParam);
    };

    return (
        <div className="table-header">
            <div data-testid="table-header-check-all" className="header-check">
                <Checkbox isNoText onChange={onCheckAll} checked={isChecked} />
            </div>
            {Object.keys(data).map((item) => (
                <div
                    className="single-filter flex-grow-0 flex-shrink-1"
                    style={{ flexBasis: data[item].size }}
                    key={item}
                >
                    <button
                        data-testid="table-header-button"
                        className="filter-button"
                        onClick={handleFilter(item)}
                    >
                        <span className="filter-name">{data[item].name}</span>
                        <SortIcon fill="#8195A9" />
                    </button>
                </div>
            ))}
            <div className="actions-label">Actions</div>
        </div>
    );
}

TableHeader.defaultProps = {
    isChecked: false,
    data: {},
    onCheckAll: () => {},
    onFilterTable: () => {}
};

TableHeader.propTypes = {
    isChecked: propTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    data: propTypes.object,
    onCheckAll: propTypes.func,
    onFilterTable: propTypes.func
};
