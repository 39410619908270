import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Pagination } from '@nxlog/common-ui/components';
import TableRow from './tableRow';
import TableHeader from './tableHeader';
import sort from '../../../utils/helpers/tables';
import { selectItemsPerPageValue, setItemsPerPage } from '../../../redux/reducers/agents';
import './tableUsers.scss';

const HEADER_COLUMNS = {
    name: { name: 'Account name', size: '14%' },
    type: { name: 'Account type', size: '14%' },
    access: { name: 'Access', size: '11%' },
    active: { name: 'Active', size: '10%' },
    account: { name: 'Account expires', size: '15%' },
    password: { name: 'Password expires', size: '14%' },
    login: { name: 'Last login', size: '16%' }
};

/* ****************************************************************
 *  this component uses obsolete style of table rendering,        *
 *  it needs implementing the @nxlog/common-ui Table component    *
 **************************************************************** */
export default function TableUsers(props) {
    const dispatch = useDispatch();
    const {
        checkedRows,
        tableData,
        setTableData,
        currentPage,
        setCurrentPage,
        allData,
        onCheckRow,
        onCheckAll
    } = props;
    const itemsPerPage = useSelector(selectItemsPerPageValue);
    const isFirstPage = currentPage.value === 1;
    const paginationIndex = isFirstPage ? 0 : itemsPerPage * (currentPage.value - 1);
    const clonedArray = tableData.slice();
    const paginatedTableData = clonedArray.splice(paginationIndex, itemsPerPage);
    const [sortColumn, setSortColumn] = useState({
        name: 'asc',
        type: 'asc',
        access: 'asc',
        active: 'asc',
        account: 'asc',
        password: 'asc',
        login: 'asc'
    });

    const handleChangeItemsAmount = (value) => {
        dispatch(setItemsPerPage({ label: value, value }));
    };

    const filterTable = (filterParam) => {
        sort(filterParam, sortColumn, allData, setSortColumn, setTableData);
    };

    const handleCheckAll = (value) => {
        if (value) {
            onCheckAll(paginatedTableData);
            return;
        }
        onCheckAll([]);
    };

    return (
        <div className="table-users-wrapper">
            <div className="table-users">
                <div className="main-table">
                    <TableHeader
                        data={HEADER_COLUMNS}
                        onCheckAll={handleCheckAll}
                        isChecked={paginatedTableData.length === checkedRows.size}
                        onFilterTable={filterTable}
                    />
                    {paginatedTableData.map((item) => (
                        <TableRow
                            key={item.id}
                            rowData={item}
                            isChecked={checkedRows.has(item.id)}
                            onCheck={onCheckRow}
                        />
                    ))}
                    <div data-testid="table-users-pagination" className="table-footer">
                        <Pagination
                            className="table-pagination"
                            onPageSizeChange={handleChangeItemsAmount}
                            itemsAmount={tableData.length}
                            itemsPerPage={itemsPerPage}
                            currentIndex={(currentPage.value - 1) * itemsPerPage}
                            onPageChange={(pageIndex) => setCurrentPage({ value: pageIndex })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

TableUsers.defaultProps = {
    tableData: null,
    setTableData: () => {},
    currentPage: null,
    setCurrentPage: () => {},
    allData: null,
    checkedRows: new Map(),
    onCheckRow: () => {},
    onCheckAll: () => {}
};

TableUsers.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    tableData: propTypes.array,
    setTableData: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    currentPage: propTypes.object,
    setCurrentPage: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    allData: propTypes.array,
    checkedRows: propTypes.instanceOf(Map),
    onCheckRow: propTypes.func,
    onCheckAll: propTypes.func
};
