import { collectModules, sendToModules } from '../../components/configEditor/modules';
import { getBuilderDataFromText } from '../../components/configEditor/utils/parser';
import { CONNECT_TYPES } from '../constants/ids';
import { ipv6Regex } from '../constants/regex';
import {
    createXmAdminTemplate,
    createXmAixauditTemplate,
    createXmAslTemplate,
    createXmBsmTemplate,
    createXmCarnovTemplate,
    createXmCefTemplate,
    createXmCryptoTemplate,
    createXmCSVTemplate,
    createXmExecTemplate,
    createXmFilelistTemplate,
    createXmFileopTemplate,
    createXmGelfTemplate,
    createXmGoTemplate,
    createXmGrokTemplate,
    createXmJavaTemplate,
    createXmJsonTemplate,
    createXmKvpTemplate,
    createXmLeefTemplate,
    createXmMsdnsTemplate,
    createXmMultilineTemplate,
    createXmNetflowTemplate,
    createXmNpsTemplate,
    createXmPatternTemplate,
    createXmPerlTemplate,
    createXmPythonTemplate,
    createXmResolverTemplate,
    createXmRewriteTemplate,
    createXmRubyTemplate,
    createXmSapTemplate,
    createXmSnmpTemplate,
    createXmSyslogTemplate,
    createXmW3CTemplate,
    createXmWtmpTemplate,
    createXmXmlTemplate,
    createXmZlibTemplate
} from './extensions';
import {
    createImFileTemplate,
    createImHttpTemplate,
    createImAcctTemplate,
    createImTcpTemplate,
    createImAixauditTemplate,
    createImBatchCompressTemplate,
    createImBsmTemplate,
    createImCheckpointTemplate,
    createImDbiTemplate,
    createImEtwTemplate,
    createImExecTemplate,
    createImFimTemplate,
    createImGoTemplate,
    createImInternalTemplate,
    createImJavaTemplate,
    createImKafkaTemplate,
    createImKernelTemplate,
    createImLinuxAuditTemplate,
    createImMacesTemplate,
    createImMaculsTemplate,
    createImMarkTemplate,
    createImMseventlogTemplate,
    createImODBCTemplate,
    createImPcapTemplate,
    createImPerlTemplate,
    createImPipeTemplate,
    createImPythonTemplate,
    createImRedisTemplate,
    createImRegmonTemplate,
    createImRubyTemplate,
    createImSystemDTemplate,
    createImTestgenTemplate,
    createImUdpTemplate,
    createImUdsTemplate,
    createImWinperfcountTemplate,
    createImZmqTemplate,
    createImAzureTemplate,
    createImNullTemplate,
    createImSSLTemplate,
    createImMsvistalogTemplate
} from './inputs';

import {
    createOmFileTemplate,
    createOmHttpTemplate,
    createOmDbiTemplate,
    createOmTcpTemplate,
    createOmBatchcompressTemplate,
    createOmElasticsearchTemplate,
    createOmEventDBTemplate,
    createOmExecTemplate,
    createOmGoTemplate,
    createOmChronicleTemplate,
    createOmJavaTemplate,
    createOmKafkaTemplate,
    createOmODBCTemplate,
    createOmPerlTemplate,
    createOmPipeTemplate,
    createOmPythonTemplate,
    createOmRaijinTemplate,
    createOmRedisTemplate,
    createOmRubyTemplate,
    createOmSSLTemplate,
    createOmUdpTemplate,
    createOmUdpSpoofTemplate,
    createOmUdsTemplate,
    createOmWebhdfsTemplate,
    createOmZmqTemplate,
    createOmAzureTemplate,
    createOmBlockerTemplate,
    createOmNullTemplate
} from './outputs';
import {
    createPmBlockerTemplate,
    createPmBufferTemplate,
    createPmEvcorTemplate,
    createPmFilterTemplate,
    createPmHmacCheckTemplate,
    createPmHmacTemplate,
    createPmNorepeatTemplate,
    createPmNullTemplate,
    createPmPatternTemplate,
    createPmTranformerTemplate,
    createPmTsTemplate
} from './processors';

const INPUT_TEMPLATES = {
    im_file: createImFileTemplate,
    im_http: createImHttpTemplate,
    im_acct: createImAcctTemplate,
    im_tcp: createImTcpTemplate,
    im_aixaudit: createImAixauditTemplate,
    im_batchcompress: createImBatchCompressTemplate,
    Im_msvistalog: createImMsvistalogTemplate,
    im_bsm: createImBsmTemplate,
    im_checkpoint: createImCheckpointTemplate,
    im_dbi: createImDbiTemplate,
    im_etw: createImEtwTemplate,
    im_exec: createImExecTemplate,
    im_fim: createImFimTemplate,
    im_go: createImGoTemplate,
    im_internal: createImInternalTemplate,
    im_java: createImJavaTemplate,
    im_kafka: createImKafkaTemplate,
    im_kernel: createImKernelTemplate,
    im_linuxaudit: createImLinuxAuditTemplate,
    im_maces: createImMacesTemplate,
    im_maculs: createImMaculsTemplate,
    im_mark: createImMarkTemplate,
    im_mseventlog: createImMseventlogTemplate,
    im_odbc: createImODBCTemplate,
    im_pcap: createImPcapTemplate,
    im_perl: createImPerlTemplate,
    im_pipe: createImPipeTemplate,
    im_python: createImPythonTemplate,
    im_redis: createImRedisTemplate,
    im_regmon: createImRegmonTemplate,
    im_ruby: createImRubyTemplate,
    im_systemd: createImSystemDTemplate,
    im_testgen: createImTestgenTemplate,
    im_udp: createImUdpTemplate,
    im_uds: createImUdsTemplate,
    im_winperfcount: createImWinperfcountTemplate,
    im_zmq: createImZmqTemplate,
    im_azure: createImAzureTemplate,
    im_null: createImNullTemplate,
    im_ssl: createImSSLTemplate
};

const PROCESSOR_TEMPLATES = {
    pm_filter: createPmFilterTemplate,
    pm_pattern: createPmPatternTemplate,
    pm_buffer: createPmBufferTemplate,
    pm_evcorr: createPmEvcorTemplate,
    pm_hmac: createPmHmacTemplate,
    pm_hmac_check: createPmHmacCheckTemplate,
    pm_norepeat: createPmNorepeatTemplate,
    pm_transformer: createPmTranformerTemplate,
    pm_ts: createPmTsTemplate,
    pm_blocker: createPmBlockerTemplate,
    pm_null: createPmNullTemplate
};

const OUTPUT_TEMPLATES = {
    om_file: createOmFileTemplate,
    om_http: createOmHttpTemplate,
    om_tcp: createOmTcpTemplate,
    om_dbi: createOmDbiTemplate,
    om_batchcompress: createOmBatchcompressTemplate,
    om_elasticsearch: createOmElasticsearchTemplate,
    om_eventdb: createOmEventDBTemplate,
    om_exec: createOmExecTemplate,
    om_go: createOmGoTemplate,
    om_chronicle: createOmChronicleTemplate,
    om_java: createOmJavaTemplate,
    om_kafka: createOmKafkaTemplate,
    om_odbc: createOmODBCTemplate,
    om_perl: createOmPerlTemplate,
    om_pipe: createOmPipeTemplate,
    om_python: createOmPythonTemplate,
    om_raijin: createOmRaijinTemplate,
    om_redis: createOmRedisTemplate,
    om_ruby: createOmRubyTemplate,
    om_ssl: createOmSSLTemplate,
    om_udp: createOmUdpTemplate,
    om_udpspoof: createOmUdpSpoofTemplate,
    om_uds: createOmUdsTemplate,
    om_webhdfs: createOmWebhdfsTemplate,
    om_zmq: createOmZmqTemplate,
    om_azure: createOmAzureTemplate,
    om_blocker: createOmBlockerTemplate,
    om_null: createOmNullTemplate
};

const EXTENSION_TEMPLATES = {
    xm_admin: createXmAdminTemplate,
    xm_aixaudit: createXmAixauditTemplate,
    xm_asl: createXmAslTemplate,
    xm_bsm: createXmBsmTemplate,
    xm_cef: createXmCefTemplate,
    xm_charconv: createXmCarnovTemplate,
    xm_csv: createXmCSVTemplate,
    xm_crypto: createXmCryptoTemplate,
    xm_exec: createXmExecTemplate,
    xm_filelist: createXmFilelistTemplate,
    xm_fileop: createXmFileopTemplate,
    xm_gelf: createXmGelfTemplate,
    xm_go: createXmGoTemplate,
    xm_grok: createXmGrokTemplate,
    xm_java: createXmJavaTemplate,
    xm_json: createXmJsonTemplate,
    xm_kvp: createXmKvpTemplate,
    xm_leef: createXmLeefTemplate,
    xm_msdns: createXmMsdnsTemplate,
    xm_multiline: createXmMultilineTemplate,
    xm_netflow: createXmNetflowTemplate,
    xm_nps: createXmNpsTemplate,
    xm_pattern: createXmPatternTemplate,
    xm_perl: createXmPerlTemplate,
    xm_python: createXmPythonTemplate,
    xm_resolver: createXmResolverTemplate,
    xm_rewrite: createXmRewriteTemplate,
    xm_ruby: createXmRubyTemplate,
    xm_sap: createXmSapTemplate,
    xm_snmp: createXmSnmpTemplate,
    xm_syslog: createXmSyslogTemplate,
    xm_w3c: createXmW3CTemplate,
    xm_wtmp: createXmWtmpTemplate,
    xm_xml: createXmXmlTemplate,
    xm_zlib: createXmZlibTemplate
};

const EXTENSION_TEMPLATES_OPTIONS = [
    { value: 'xm_admin', label: 'Remote Agent Management' },
    { value: 'xm_aixaudit', label: 'AIX Auditing (xm_aixaudit)' },
    { value: 'xm_asl', label: 'Apple System Logs (xm_asl)' },
    { value: 'xm_bsm', label: 'Basic Security Module Auditing (xm_bsm)' },
    { value: 'xm_cef', label: 'Common Event Format (xm_cef)' },
    { value: 'xm_charconv', label: 'Character Set Conversion (xm_charconv)' },
    { value: 'xm_csv', label: 'Delimiter-Separated Values (xm_csv)' },
    { value: 'xm_crypto', label: 'Encryption (xm_crypto)' },
    { value: 'xm_exec', label: 'External Programs (xm_exec)' },
    { value: 'xm_filelist', label: 'File Lists (xm_filelist)' },
    { value: 'xm_fileop', label: 'File Operations (xm_fileop)' },
    { value: 'xm_gelf', label: 'GELF (xm_gelf)' },
    { value: 'xm_go', label: 'Go (xm_go)' },
    { value: 'xm_grok', label: 'Grok (xm_grok)' },
    { value: 'xm_java', label: 'Java (xm_java)' },
    { value: 'xm_json', label: 'JSON (xm_json)' },
    { value: 'xm_kvp', label: 'Key-Value Pairs (xm_kvp)' },
    { value: 'xm_leef', label: 'LEEF (xm_leef)' },
    { value: 'xm_msdns', label: 'Microsoft DNS Server (xm_msdns)' },
    { value: 'xm_multiline', label: 'Multiline Parser (xm_multiline)' },
    { value: 'xm_netflow', label: 'NetFlow (xm_netflow)' },
    { value: 'xm_nps', label: 'Microsoft Network Policy Server (xm_nps)' },
    { value: 'xm_pattern', label: 'Pattern Matcher (xm_pattern)' },
    { value: 'xm_perl', label: 'Perl (xm_perl)' },
    { value: 'xm_python', label: 'Python (xm_python)' },
    { value: 'xm_resolver', label: 'Resolver (xm_resolver)' },
    { value: 'xm_rewrite', label: 'Rewrite (xm_rewrite)' },
    { value: 'xm_ruby', label: 'Ruby (xm_ruby)' },
    { value: 'xm_sap', label: 'SAP (xm_sap)' },
    { value: 'xm_snmp', label: 'SNMP Traps (xm_snmp)' },
    { value: 'xm_syslog', label: 'Syslog (xm_syslog)' },
    { value: 'xm_w3c', label: 'W3C (xm_w3c)' },
    { value: 'xm_wtmp', label: 'WTMP (xm_wtmp)' },
    { value: 'xm_xml', label: 'XML (xm_xml)' },
    { value: 'xm_zlib', label: 'Compression (xm_zlib)' }
];

const INPUT_TEMPLATES_OPTIONS = collectModules.map(({ moduleName, label }) => ({
    value: moduleName,
    label
}));
const OUTPUT_TEMPLATES_OPTIONS = sendToModules.map(({ moduleName, label }) => ({
    value: moduleName,
    label
}));

const PROCESSOR_TEMPLATES_OPTIONS = [
    { value: 'pm_filter', label: 'Filter (pm_filter)' },
    { value: 'pm_pattern', label: 'Pattern Matcher (pm_pattern)' },
    { value: 'pm_buffer', label: 'Buffer (pm_buffer)' },
    { value: 'pm_evcorr', label: 'Event Correlator (pm_evcorr)' },
    { value: 'pm_hmac', label: 'HMAC Message Integrity (pm_hmac)' },
    { value: 'pm_hmac_check', label: 'HMAC Message Integrity Checker (pm_hmac_check)' },
    { value: 'pm_norepeat', label: 'De-Duplicator (pm_norepeat)' },
    { value: 'pm_transformer', label: 'Format Converter (pm_transformer)' },
    { value: 'pm_ts', label: 'Timestamping (pm_ts)' },
    { value: 'pm_blocker', label: 'Blocker (pm_blocker)' },
    { value: 'pm_null', label: 'Null (pm_null)' }
];

const remapModules = ({ value, label }) => ({ type: value, name: label });

const MODULE_LIST = [
    ...INPUT_TEMPLATES_OPTIONS.map(remapModules),
    ...OUTPUT_TEMPLATES_OPTIONS.map(remapModules),
    ...PROCESSOR_TEMPLATES_OPTIONS.map(remapModules),
    ...EXTENSION_TEMPLATES_OPTIONS.map(remapModules)
];

const TEMPLATE_TYPES = {
    input: INPUT_TEMPLATES,
    output: OUTPUT_TEMPLATES,
    processor: PROCESSOR_TEMPLATES,
    extension: EXTENSION_TEMPLATES
};

const TEMPLATE_OPTIONS_TYPES = {
    input: INPUT_TEMPLATES_OPTIONS,
    output: OUTPUT_TEMPLATES_OPTIONS,
    processor: PROCESSOR_TEMPLATES_OPTIONS,
    extension: EXTENSION_TEMPLATES_OPTIONS
};

const TEMPLATE_TAGS = { draft: 'draft', paid: 'paid', solutionPack: 'solution pack' };
const createHosts = (address, connectType) => {
    let result = '';
    [...address].forEach((item) => {
        const correctHost = ipv6Regex.test(item.host) ? `[${item.host}]` : item.host;
        result += `    ${CONNECT_TYPES[connectType]}        ${correctHost}${
            `${item.port || ''}`.trim() ? `:${item.port}` : ''
        }\n`;
    });

    return result;
};

const createDefaultTemplate = ({ logLevel, address, connectType, verbatimConfig }) => {
    // Remove xm_admin, LogLevel and LogFile from verbatimConfig
    const { extensions, directives } = getBuilderDataFromText(`${verbatimConfig || ''}`.trim());
    const listXmAdmin = extensions.filter(
        (extMdl) => `${extMdl.module}`.trim().toLowerCase() === 'xm_admin'
    );
    const listDirectives = directives.filter((dir) =>
        ['loglevel', 'logfile'].includes(`${dir.name}`.trim().toLowerCase())
    );
    verbatimConfig = `${verbatimConfig || ''}`
        .trim()
        .split('\n')
        .filter((l, i) => {
            if (listDirectives.find((dir) => dir.lineNbr === i + 1)) return false;
            return !listXmAdmin.find(
                (xmAdmin) => i + 1 >= xmAdmin.lineNbr && i + 1 <= xmAdmin.endLineNbr
            );
        })
        .join('\n');

    return `LogLevel    ${logLevel?.toUpperCase()}
LogFile     %MYLOGFILE%

<Extension admin>
    Module      xm_admin
${createHosts(address, connectType)}
    SocketType  SSL
    CAFile      %CERTDIR%/agent-ca.pem
    CertFile    %CERTDIR%/agent-cert.pem
    CertKeyFile %CERTDIR%/agent-key.pem
    <ACL conf>
        Directory   %CONFDIR%
        AllowRead   TRUE
        AllowWrite  TRUE
    </ACL>

    <ACL cert>
        Directory   %CERTDIR%
        AllowRead   TRUE
        AllowWrite  TRUE
    </ACL>
</Extension>

${verbatimConfig}`.trim();
};

const removeEmptyLines = (str) =>
    str
        .split('\n')
        .map((value) => value.trimEnd())
        .filter(Boolean)
        .join('\n');

const createTemplate = (templateName, type, data) =>
    removeEmptyLines(TEMPLATE_TYPES[type][templateName](data));

export {
    createTemplate,
    createDefaultTemplate,
    INPUT_TEMPLATES,
    OUTPUT_TEMPLATES,
    TEMPLATE_TYPES,
    TEMPLATE_OPTIONS_TYPES,
    INPUT_TEMPLATES_OPTIONS,
    OUTPUT_TEMPLATES_OPTIONS,
    MODULE_LIST,
    TEMPLATE_TAGS
};
