import DownloadIcon from '@nxlog/common-ui/dist/components/svgs/download';
import { GeneratePortalDownloadURL } from '@nxlog/common-ui/utils';
import { SharedAppPath } from '@nxlog/common-ui/app';
import CodeSnippet from '../../components/codeSnippet';
import sampleTemplate from './sampleTemplate';
import { parseBool } from '../../utils/helpers/functions';
import CmdLine from '../../components/cmdLine';

const ON_PREMISE = parseBool(
    window?.env?.REACT_APP_ON_PREMISE ?? process.env.REACT_APP_ON_PREMISE,
    false
);

const AIR_GAPPED = parseBool(window?.env?.REACT_APP_AIR_GAPPED ?? process.env.REACT_APP_AIR_GAPPED);

function getDownloadURL(portalUrl, orgId) {
    if (AIR_GAPPED) {
        return 'https://nxlog.co/downloads/nxlog-ee';
    }

    /**
     * If orgId=null content will display URL as /product-download/agents?orgId=null
     * To avoid such behaviour set fallback value as an empty string result is without params /product-download/agents
     */
    const ensureOrgId = orgId || '';

    /**
     * Source file nxlog-platform-ui-shared/src/app/constants.js:
     *
     * const SharedAppPath = {
     *     ...
     *     AgentsDownload: (ON_PREMISE ? CLOUD_PLATFORM_URL : '') +  '/product-download/agents',
     *     ...
     * };
     */
    return ON_PREMISE
        ? GeneratePortalDownloadURL('', SharedAppPath.AgentsDownload, ensureOrgId)
        : GeneratePortalDownloadURL(portalUrl || '', SharedAppPath.AgentsDownload, ensureOrgId);
}

function Content(props) {
    const { portalUrl, orgId, enrollmentHost = '0.0.0.0:4041' } = props;
    const downloadURL = getDownloadURL(portalUrl, orgId);
    const installURL = 'https://docs.nxlog.co/agent/current/install/';

    return [
        {
            name: 'Download Agent',
            step: 'Download the Agent',
            markup: (
                <div>
                    <p>You need to install the NXLog agent to start ingesting logs.</p>
                    <p>Download the agent for your platform from</p>
                    <p>
                        <a href={downloadURL} target="_blank" rel="noreferrer nofollow noopener">
                            <DownloadIcon /> {downloadURL}
                        </a>
                    </p>
                </div>
            )
        },
        {
            name: 'Install Agent',
            step: 'Install',
            markup: (
                <div>
                    <p>
                        Execute the installer and follow the prompts to install the NXLog agent on
                        Windows and macOS.
                    </p>
                    <p>
                        Use the package manager for your OS, e.g., APT or DNF/YUM, to install on
                        Linux.
                    </p>
                    <p>
                        See{' '}
                        <a href={installURL} target="_blank" rel="noreferrer nofollow noopener">
                            {installURL}
                        </a>{' '}
                        for details.
                    </p>
                </div>
            )
        },
        {
            name: 'Configure Agent',
            step: 'Configure',
            markup: (
                <div>
                    <p>
                        Next, configure the agent to connect to NXLog Platform. Here is a basic
                        configuration to initiate the first contact.
                    </p>
                    <CodeSnippet
                        filename="managed.conf"
                        code={sampleTemplate(enrollmentHost)}
                        minLines={3}
                    />
                    <p className="side-note">
                        Copy this configuration to your <b>managed.conf</b> file in{' '}
                        <b>/opt/nxlog/etc/nxlog.d</b> on Linux, Unix, and macOS or{' '}
                        <b>C:\Program Files\nxlog\conf\nxlog.d</b> on Windows.
                    </p>
                </div>
            )
        },
        {
            name: 'Restart Agent',
            step: 'Restart the Agent',
            markup: (
                <div>
                    <p>Restart the NXLog service to apply your changes.</p>
                    <div className="options-list">
                        <div className="options-list-item">
                            <div className="list-title">Windows:</div>
                            <div className="list-text">
                                Open <b>Service Manager</b> (services.msc), select <b>nxlog</b>,
                                choose <b>restart</b>
                            </div>
                        </div>
                        <div className="options-list-item">
                            <div className="list-title">Linux:</div>
                            <div className="list-text">
                                <CmdLine cmd="sudo systemctl restart nxlog" />
                            </div>
                        </div>
                        <div className="options-list-item">
                            <div className="list-title">Apple macOS:</div>
                            <div className="list-text">
                                <CmdLine
                                    cmd={`sudo launchctl unload -w /Library/LaunchDaemons/com.nxlog.plist;\nsudo launchctl load -w /Library/LaunchDaemons/com.nxlog.plist`}
                                />
                            </div>
                        </div>
                    </div>
                    <p>
                        Once the agent restarts, you will see it on the <b>Agents</b> list in NXLog
                        Platform. If not, check the agent log file to troubleshoot the issue.
                    </p>
                    <div className="options-list">
                        <div className="options-list-item">
                            <div className="list-title">Windows:</div>
                            <div className="list-text">
                                <CmdLine cmd="C:\Program Files\nxlog\data\nxlog.log" />
                            </div>
                        </div>
                        <div className="options-list-item">
                            <div className="list-title">Linux:</div>
                            <div className="list-text">
                                <CmdLine cmd="/opt/nxlog/var/log/nxlog/nxlog.log" />
                            </div>
                        </div>
                        <div className="options-list-item">
                            <div className="list-title">Apple macOS:</div>
                            <div className="list-text">
                                <CmdLine cmd="/opt/nxlog/var/log/nxlog/nxlog.log" />
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
        {
            name: 'Enroll Agent',
            step: 'Enroll the Agent',
            markup: (
                <div>
                    <p>
                        Enroll your agents to begin managing and configuring them. Enrolling an
                        agent creates a digital certificate for secure communications and ensures
                        the agent reconnects to NXLog Platform when it restarts.
                    </p>
                    <h4>Enrolling a single agent</h4>
                    <ul>
                        <li>
                            Select an agent in the <b>Agents</b> tab
                        </li>
                        <li>
                            Choose <b>Enroll</b> from the actions menu (...)
                        </li>
                    </ul>
                    <h4>Enrolling multiple agents</h4>
                    <ul>
                        <li>
                            Search and filter the agents list as needed{' '}
                            <li>Select the agents you want to enroll</li>
                        </li>
                        <li>
                            Use the <b>Operations</b> drop-down in the <b>Selected Agents</b> panel
                            to select <b>Enroll</b>
                        </li>
                    </ul>
                </div>
            )
        },
        {
            name: 'Send Logs',
            step: 'Send Logs to Platform',
            markup: (
                <div>
                    <p>
                        You can now start sending logs to NXLog Platform.
                        <br />
                        See the default <b>Agent logs to Platform</b> template for an example.
                    </p>
                </div>
            )
        }
    ];
}

export default Content;
