import React from 'react';
import PropTypes from 'prop-types';
import ReactECharts from 'echarts-for-react';
import AngleIcon from '@nxlog/common-ui/dist/components/svgs/angle-icon';
import './dataSources.scss';

function DataSources(props) {
    const { title, value, max, available, onClick } = props;

    // https://nxlogdev.atlassian.net/browse/MIND-1999
    if (!max) {
        return (
            <div className="data-sources">
                <div className="data-sources-title">{title}</div>
                <div className="data-sources-content" style={{ justifyContent: 'center' }}>
                    <div>
                        <div className="data-sources-content-infos flex-center">
                            <span style={{ padding: 10 }} data-testid="data-sources-info">
                                Total number of log sources used: {value}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const calculatePercentage = () => {
        if (max === 0) return 0;
        const validMax = Math.max(1, max || 1);
        const validValue = value || 0;
        let percentage = 100 - (validValue / validMax) * 100;
        percentage = Math.max(Math.min(percentage, 100), 0);
        if (percentage === 100) return percentage;
        return percentage.toFixed(2);
    };

    const percentage = calculatePercentage(value, max);
    const option = {
        color: ['#E03520', '#ECF2F6'],
        height: '136px',
        tooltip: 'none',
        emphasis: {
            disabled: true
        },
        series: [
            {
                name: title,
                type: 'pie',
                radius: ['55px', '63px'],
                height: '136px',
                avoidLabelOverlap: true,
                itemStyle: {
                    borderRadius: 8
                },
                label: {
                    show: true,
                    position: 'center',
                    color: '#072341',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    formatter: '{a|{b}}\n{b|remaining}',
                    rich: {
                        a: {
                            fontSize: 32,
                            lineHeight: 32,
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            color: '#072341'
                        },
                        b: {
                            fontFamily: 'Roboto',
                            fontSize: 14,
                            lineHeight: 14
                        }
                    }
                },
                emphasis: {
                    label: {
                        show: false
                    },
                    scale: false
                },
                labelLine: {
                    show: true
                },
                data: [
                    { value: 100 - percentage, name: ` ${percentage}%` },
                    { value: percentage, name: `${percentage}% ` }
                ]
            }
        ]
    };
    return (
        <div className="data-sources">
            <div className="data-sources-title">{title}</div>
            <div className="data-sources-content">
                <ReactECharts option={option} className="data-sources-echarts" />
                <div>
                    <div className="data-sources-content-infos">
                        <DataSourcesInfo title="sources used" value={`${value} / ${max}`} />
                        <span className="data-sources-sep" />
                        <DataSourcesInfo title="available" value={available} />
                    </div>
                    <button
                        data-testid="data-sources-manage-licenses"
                        className="btn-manage-licenses"
                        onClick={onClick}
                    >
                        <span>Manage licenses</span>
                        <AngleIcon.Right fill="#0081c1" />
                    </button>
                </div>
            </div>
        </div>
    );
}

DataSources.defaultProps = {
    onClick: () => null
};

DataSources.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    available: PropTypes.number.isRequired,
    onClick: PropTypes.func
};

function DataSourcesInfo({ title, value }) {
    return (
        <div className="data-sources-info" data-testid="data-sources-info">
            <b>{value}</b>
            <span>{title}</span>
        </div>
    );
}

DataSourcesInfo.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default DataSources;
