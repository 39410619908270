import React, { useState } from 'react';
import Tabs from '../tabs';
import { SnippetsList, SolutionPacks } from '../configEditorList';

function LeftMenu() {
    const [q, setQ] = useState('');
    return (
        <div className="LeftMenu">
            <div className="input-form-container searchinput">
                <input
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                    data-testid="search"
                    className="input-form"
                    placeholder="Search..."
                />
            </div>
            <Tabs
                defaultSelect={1}
                list={[
                    {
                        label: 'Solution Packs',
                        content: <SolutionPacks q={q} />
                    },
                    {
                        label: 'Snippets',
                        content: <SnippetsList q={q} />
                    }
                ]}
            />
        </div>
    );
}

export default LeftMenu;
