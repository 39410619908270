import { useRef } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { ToastNotification } from '@nxlog/common-ui/components';
import { duplicateTemplateData } from '../../../redux/reducers/templates';

const useDuplicateConfig = ({ setLoading = () => {} }) => {
    const dispatch = useDispatch();

    const templateTimeoutId = useRef(null);

    const createError = () => {
        setLoading(false);
        toast.error(
            <ToastNotification
                type="error"
                message="Add new template aborted due to long response"
            />
        );
    };

    const handleCopyConfig = (configData) => {
        const { id, name } = configData;

        const waitTime = 10000;

        setLoading(true);

        const postTemplatePromise = dispatch(
            duplicateTemplateData({ id, name: `${name || 'new'} copy` })
        ).unwrap();
        templateTimeoutId.current = setTimeout(() => {
            if (postTemplatePromise.abort) {
                postTemplatePromise.abort();
            }

            createError();
        }, waitTime);

        postTemplatePromise
            .then(() => {
                if (templateTimeoutId.current) {
                    clearTimeout(templateTimeoutId.current);
                }
            })
            .catch(() => {
                toast.error(<ToastNotification type="error" message="Error: Add new template" />);
            })
            .finally(() => {
                clearTimeout(templateTimeoutId.current);
                setLoading(false);
            });
    };

    return { handleCopyConfig };
};

export default useDuplicateConfig;
