import { parseBool, getMinderSubDomain } from '../helpers/functions';
import { useTenantId } from '../../components/tenantId';

const onPremise = parseBool(window?.env?.REACT_APP_ON_PREMISE ?? process.env.REACT_APP_ON_PREMISE);

export default function useStoreTenantId() {
    const minderSubDomain = getMinderSubDomain();
    const tenantId = useTenantId();
    const { protocol, hostname, port, pathname } = window.location;
    const domainParts = hostname.split(':')[0].split('.');
    const domain =
        onPremise && domainParts[0] === minderSubDomain
            ? domainParts.slice(1).join('.')
            : domainParts.join('.');
    return { tenantId, domain, protocol, port, pathname, hostname };
}
