import React, { useState, useMemo } from 'react';
import propTypes from 'prop-types';
import { Button, Select, Modal } from '@nxlog/common-ui/components';
import { TEMPLATE_TAGS } from '../../../utils/templates';
import './assignTemplate.scss';

function AssignTemplateModal(props) {
    const { data, isShown, onClose, onSave, action } = props;

    const [selected, setSelected] = useState(null);

    const configsNamesOptions = useMemo(() => {
        const options = data
            ?.filter((config) => !config?.tags?.includes(TEMPLATE_TAGS.draft))
            .map((config) => ({
                value: config.id,
                label: config.name
            }));
        return options;
    }, [data]);

    const handleSelect = (selectedItem) => {
        setSelected(selectedItem);
    };

    const handleSave = () => {
        onSave(selected);
    };

    const handleClose = () => {
        setSelected(null);
        onClose();
    };

    return (
        <Modal
            isShown={isShown}
            onClose={handleClose}
            classname="assign-template-modal"
            title={
                <h1 className="assign-template-header">
                    {action === 'enrollwithtemplate' ? 'Enroll with template' : 'Assign template'}{' '}
                    <span>({data?.length || 0})</span>
                </h1>
            }
        >
            <div className="assign-template-dropdown" data-testid="assign-template-modal-select">
                <h4 className="assign-template-dropdown-title">Select config template</h4>
                <Select
                    defaultValue={selected}
                    dropdownOptions={configsNamesOptions}
                    classname="assign-template-dropdown-select"
                    placeholder=""
                    onClick={handleSelect}
                />
            </div>
            <div className="assign-template-buttons">
                <Button ghostBtn className="assign-template-button" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    data-testid="assign-template-modal-confirm"
                    className="assign-template-button"
                    onClick={handleSave}
                    disabled={!selected}
                >
                    {action === 'enrollwithtemplate' ? 'Enroll' : 'Save'}
                </Button>
            </div>
        </Modal>
    );
}

AssignTemplateModal.defaultProps = {
    data: [],
    action: ''
};

AssignTemplateModal.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: propTypes.array,
    isShown: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    onSave: propTypes.func.isRequired,
    action: propTypes.string
};

export default AssignTemplateModal;
