import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import EditExtensionForm from '../../../components/forms/editExtensionForm';
import './editExtension.scss';
import Header from '../../../components/header';

export default function EditExtensionPage() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className="edit-extension-page w-100">
            <Header
                title=""
                titleIcon={null}
                button={
                    <button className="extension-name" onClick={handleGoBack}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                        KVP
                        <span>(extension)</span>
                    </button>
                }
            />
            <div className="edit-extension-page-content">
                <div className="form-container">
                    <div className="form">
                        <EditExtensionForm />
                    </div>
                </div>
            </div>
        </div>
    );
}
