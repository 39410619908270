import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@nxlog/common-ui/components';
import HomeIcon from '@nxlog/common-ui/dist/components/svgs/home';
import Angle from '@nxlog/common-ui/dist/components/svgs/angle-icon';
import Header from '../../components/header';
import { ReactComponent as Illustration404 } from '../../assets/img/page404.svg';
import './404.scss';

function NotFoundPage() {
    const navigate = useNavigate();

    return (
        <div className="not-found-page w-100">
            <Header
                title={
                    <button
                        data-testid="not-found-btn-to-home"
                        onClick={() => navigate('/')}
                        className="header-button"
                    >
                        Home
                    </button>
                }
                titleIcon={
                    <button
                        data-testid="not-found-icon-to-home"
                        onClick={() => navigate('/')}
                        className="header-button"
                        aria-label="Navigate"
                    >
                        <HomeIcon fill="#0086C5" />
                    </button>
                }
            />
            <div className="not-found">
                <Illustration404 className="image-404" />
                <h1>Lost in the Digital Wilderness: Page Not Found!</h1>
                <p>
                    {`Oops! It appears the page you're searching for has vanished into the digital
                wilderness. But don't worry, we're here to help you find your way back!`}
                </p>
                <Button
                    data-testid="not-found-link-to-home"
                    key={3}
                    onClick={() => navigate('/')}
                    icon={<Angle.Left className="btn-icon" />}
                    className="button-home"
                >
                    Go Home
                </Button>
            </div>
        </div>
    );
}

export default NotFoundPage;
