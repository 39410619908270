import React from 'react';
import propTypes from 'prop-types';
import AgentsIcon from '@nxlog/common-ui/dist/components/svgs/agents';
import DocumentIcon from '@nxlog/common-ui/dist/components/svgs/document';
import PollTimeIcon from '@nxlog/common-ui/dist/components/svgs/poll_time';
import ClusterIcon from '@nxlog/common-ui/dist/components/svgs/cluster';
import InfoIcon from '@nxlog/common-ui/dist/components/svgs/info';
import { WithTooltip } from '@nxlog/common-ui/components';
import moment from 'moment';
import { formatDateTime, formatMemoryUsage } from '../../utils/helpers/functions';
import { capitalizeFirstLetter, decapitalizeFirstLetter } from '../../utils/helpers/strings';
import './statistics.scss';

function Statistics(props) {
    const { statisticsData } = props;

    const cpuLoad = `${Number(statisticsData?.cpuLoad)?.toFixed(2)} %`;
    const eventsPerSecond = `${Number(statisticsData?.eventsPerSecond).toFixed(2)}`;
    const memoryUsed = formatMemoryUsage(statisticsData?.memoryUsed || 0);
    const pollTimeDate = formatDateTime(statisticsData?.polledTime, 'date.std');
    const pollTimeHours = formatDateTime(statisticsData?.polledTime, 'date.time');
    const modules = statisticsData?.modules ?? {};

    return (
        <div
            className={`statistics-wrapper${
                statisticsData && statisticsData.outOfDate ? ' statistics-outofdate' : ''
            }`}
            data-testid="Statistics"
        >
            <div className="statistics">
                <div className="statistics-title">
                    Poll time
                    {statisticsData && statisticsData.outOfDate ? (
                        <small>
                            <span>Data out of date</span>
                            <WithTooltip
                                message={`Last poll ${moment(
                                    statisticsData?.polledTime
                                ).fromNow()}`}
                                placement="bottom"
                            >
                                <InfoIcon fill="#8195A9" />
                            </WithTooltip>
                        </small>
                    ) : null}
                </div>
                <div className="statistics-info">
                    <div className="statistics-icon">
                        <PollTimeIcon fill="#072341" />
                    </div>
                    <div className="statistics-content">
                        <span>{pollTimeDate}</span>
                        <span className="hours">{pollTimeHours}</span>
                    </div>
                </div>
            </div>
            <div className="statistics">
                <div className="statistics-title">Events per second (EPS)</div>
                <div className="statistics-info">
                    <div className="statistics-icon">
                        <DocumentIcon />
                    </div>
                    <div className="statistics-content">
                        <span>{eventsPerSecond}</span>
                    </div>
                </div>
            </div>
            <div className="statistics">
                <div className="statistics-title">Memory usage</div>
                <div className="statistics-info">
                    <div className="statistics-icon">
                        <ClusterIcon fill="#072341" />
                    </div>
                    <div className="statistics-content">
                        <span>{memoryUsed}</span>
                    </div>
                </div>
            </div>
            <div className="statistics">
                <div className="statistics-title">CPU load</div>
                <div className="statistics-info">
                    <div className="statistics-icon">
                        <AgentsIcon fill="#072341" />
                    </div>
                    <div className="statistics-content">
                        <span>{cpuLoad}</span>
                    </div>
                </div>
            </div>
            {Object.keys(modules).map((key) => {
                const [name, type] = key.split('_');
                const currentModule = modules[key];
                return currentModule.kind !== 'extension' ? (
                    <div className="statistics" key={key}>
                        <div className="statistics-title">
                            {capitalizeFirstLetter(name)} {type}
                        </div>
                        <div className="statistics-sub-info">
                            {Object.keys(currentModule)
                                .filter(
                                    (moduleKey) =>
                                        typeof currentModule[moduleKey] !== 'object' ||
                                        currentModule[moduleKey] === null
                                )
                                .map((moduleKey) => {
                                    const [first, ...rest] = moduleKey.split(/(?=[A-Z])/);
                                    const propertyName = `${capitalizeFirstLetter(first)} ${rest
                                        .map((el) => decapitalizeFirstLetter(el))
                                        .join(' ')}`;
                                    const propertyValue =
                                        moduleKey === 'eventsPerSecond'
                                            ? Number(currentModule[moduleKey]).toFixed(2)
                                            : currentModule[moduleKey];
                                    return (
                                        <div
                                            className="statistics-sub-info-content"
                                            key={moduleKey}
                                        >
                                            <span>{propertyName}</span>
                                            <span className="value">{propertyValue ?? '-'}</span>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                ) : null;
            })}
        </div>
    );
}

Statistics.defaultProps = {
    statisticsData: {}
};

Statistics.propTypes = {
    statisticsData: propTypes.shape({
        cpuLoad: propTypes.number,
        eventsPerSecond: propTypes.number,
        memoryUsed: propTypes.number,
        polledTime: propTypes.string,
        outOfDate: propTypes.bool,
        // eslint-disable-next-line react/forbid-prop-types
        modules: propTypes.object
    })
};

export default Statistics;
