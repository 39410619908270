export function getConditionArray(conditionsString) {
    return conditionsString.match(/(?:[a-zA-Z0-9_=!].+?(?:&&|\|\||$))/g);
}

export default function spParamConditionCheck(obj, conditions) {
    if (!conditions) {
        return true;
    }

    const checkCondition = (currentArray) => {
        if (!currentArray?.length) {
            return false;
        }
        let string = currentArray[0];
        let logicOperator = null;
        if (['&&', '||'].includes(string.slice(-2))) {
            logicOperator = string.slice(-2);
            string = string.slice(0, -2);
        }

        const inversion = string[0] === '!';
        if (inversion) {
            string = string.slice(1);
        }
        const opIndex = string.indexOf('==') > -1 ? string.indexOf('==') : string.indexOf('!=');
        const propName = opIndex > -1 ? string.slice(0, opIndex) : string;
        const operator = opIndex > -1 ? string.slice(opIndex, opIndex + 2) : null;
        const value = operator ? string.slice(opIndex + 2) : '';

        let currentCheck =
            propName &&
            // eslint-disable-next-line eqeqeq
            ((operator === '==' && obj[propName] == value.trim()) ||
                // eslint-disable-next-line eqeqeq
                (operator === '!=' && obj[propName.trim()] != value.trim()) ||
                (!operator && !!obj[propName.trim()]));
        if (inversion) {
            currentCheck = !currentCheck;
        }

        if ((currentCheck && logicOperator === '&&') || (!currentCheck && logicOperator === '||')) {
            return checkCondition(currentArray.slice(1));
        }
        return currentCheck;
    };

    const conditionArray = getConditionArray(conditions);

    return checkCondition(conditionArray);
}

export function getDependencies(paramProps) {
    if (!paramProps?.enabled) {
        return [];
    }
    const conditionArray = getConditionArray(paramProps.enabled);
    const dependencies = conditionArray.map((c) => {
        const name = c.split('==')[0].split('!=')[0];
        return name[0] === '!' ? name.slice(1).trim() : name.trim();
    });
    return dependencies;
}

export function reorderSpParams(obj) {
    const newArray = [...Object.entries(obj)];
    for (let i = 0; i < newArray.length; i += 1) {
        const element = newArray[i];
        const dependencies = getDependencies(element[1]);
        const lastDepIndex = newArray.findLastIndex(([name]) => dependencies.includes(name));
        if (lastDepIndex > i) {
            newArray.splice(lastDepIndex + 1, 0, element);
            newArray.splice(i, 1);
            i -= 1;
        }
    }
    return newArray;
}

export function trimStringsAndArrays(obj) {
    Object.keys(obj).forEach((key) => {
        if (Array.isArray(obj[key])) {
            obj[key] = obj[key].filter((value) => typeof value !== 'string' || value.trim() !== '');

            obj[key].forEach((value) => {
                if (typeof value === 'object') {
                    trimStringsAndArrays(value);
                }

                if (typeof value === 'string') {
                    value = value.trim();
                }
            });
        }

        if (typeof obj[key] === 'object') {
            trimStringsAndArrays(obj[key]);
        }

        if (typeof obj[key] === 'string') {
            obj[key] = obj[key].trim();
        }
    });
}

export function getAgentResources(agentResources, paramData) {
    if (!agentResources) {
        return null;
    }
    const fileNames = Object.keys(agentResources);
    return fileNames.reduce(
        (acc, fileName) =>
            paramData[fileName]
                ? { ...acc, [fileName]: paramData[fileName] }
                : { ...acc, [fileName]: '' },
        {}
    );
}
