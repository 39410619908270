import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Pagination } from '@nxlog/common-ui/components';
import TableRow from './tableRow';
import {
    selectItemsPerPageValue,
    selectAgentFilterDetails,
    setFilterDetails,
    setItemsPerPage
} from '../../../redux/reducers/agents';
import Loader from '../../common/loader';
import { sortAgentTable } from '../../../utils/helpers/tables';
import TableHeader from './tableHeader';
import './tableAgentsWithLicenses.scss';

const HEADER_COLUMNS = {
    name: { name: 'Status', size: '8.5%' },
    hostname: { name: 'Agent name', size: '11%' },
    address: { name: 'IP address', size: '11%' },
    events_per_second: { name: 'EPS', size: '8.5%' },
    typesOfUsage: { name: 'Types of usage', size: '14%' },
    item: { name: 'Item', size: '38%' }
};

/* ****************************************************************
 *  this component uses obsolete style of table rendering,        *
 *  it needs implementing the @nxlog/common-ui Table component    *
 **************************************************************** */
export default function TableAgentsWithLicenses(props) {
    const dispatch = useDispatch();
    const { allDataAmount, tableData, currentPage, setCurrentPage } = props;
    const [loading, setLoading] = useState(true);
    const [sortColumn, setSortColumn] = useState({
        status: 'asc',
        hostname: 'asc',
        address: 'asc',
        events_per_second: 'asc',
        memory_used: 'asc',
        cpu_load: 'asc',
        enrolled: 'asc',
        agentGroup: 'asc',
        agentTemplate: 'asc'
    });
    const [paginatedTableData, setPaginatedTableData] = useState([]);
    const [sortedData, setSortedData] = useState(tableData);
    const itemsPerPageValue = useSelector(selectItemsPerPageValue);
    const filterDetails = useSelector(selectAgentFilterDetails);
    const [dataSorted, setDataSorted] = useState(false);

    const handleChangeItemsAmount = (value) => {
        dispatch(setItemsPerPage({ label: value, value }));
    };

    useEffect(() => {
        if (dataSorted) {
            const { filterParam } = filterDetails;
            const { order } = filterDetails;
            const sortData = sortAgentTable(
                filterParam,
                sortColumn,
                tableData,
                setSortColumn,
                itemsPerPageValue,
                false,
                order
            );
            setSortedData(sortData);
        } else {
            setSortedData(tableData);
        }
    }, [tableData]);

    useEffect(() => {
        setPaginatedTableData(sortedData[currentPage.value] ?? []);
    }, [tableData, currentPage, sortedData]);

    const handleSort = (filterParam) => {
        setDataSorted(true);
        const sortData = sortAgentTable(
            filterParam,
            sortColumn,
            sortedData,
            setSortColumn,
            itemsPerPageValue,
            true,
            false
        );
        setSortedData(sortData);
        const sortDetails = {
            filterParam,
            order: sortColumn[filterParam]
        };
        dispatch(setFilterDetails(sortDetails));
    };

    useEffect(() => {
        if (paginatedTableData.length > 0) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [paginatedTableData]);

    useEffect(() => {
        const id = setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => {
            clearTimeout(id);
        };
    }, [paginatedTableData]);

    return (
        <div className="table-agents-licenses-wrapper">
            <div className="table-agents-licenses">
                <div data-testid="table-agents-with-licenses-pagination-top" className="table-top">
                    <Pagination
                        className="table-pagination"
                        onPageSizeChange={handleChangeItemsAmount}
                        itemsAmount={allDataAmount}
                        itemsPerPage={itemsPerPageValue}
                        currentIndex={(currentPage.value - 1) * itemsPerPageValue}
                        onPageChange={(pageIndex) =>
                            setCurrentPage({ label: pageIndex, value: pageIndex })
                        }
                    />
                </div>

                <div className="main-table">
                    <TableHeader data={HEADER_COLUMNS} onFilterTable={handleSort} />
                    {loading ? (
                        <Loader />
                    ) : (
                        paginatedTableData?.map((item) => <TableRow key={item.id} rowData={item} />)
                    )}
                    <div
                        data-testid="table-agents-with-licenses-pagination-bottom"
                        className="table-footer"
                    >
                        <Pagination
                            className="table-pagination"
                            onPageSizeChange={handleChangeItemsAmount}
                            itemsAmount={allDataAmount}
                            itemsPerPage={itemsPerPageValue}
                            currentIndex={(currentPage.value - 1) * itemsPerPageValue}
                            onPageChange={(pageIndex) => setCurrentPage({ value: pageIndex })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

TableAgentsWithLicenses.defaultProps = {
    allDataAmount: 0,
    tableData: {},
    currentPage: null,
    setCurrentPage: () => {}
};

TableAgentsWithLicenses.propTypes = {
    allDataAmount: propTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    tableData: propTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    currentPage: propTypes.object,
    setCurrentPage: propTypes.func
};
