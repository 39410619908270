import {
    apiService,
    apiServiceWithCustomHeaders,
    textContentApiService,
    zipContentApiService
} from '../apiService';

export async function getAgents(params) {
    const fullParams = {
        ...params,
        'sort-by': params['sort-by'] || 'id'
    };
    const data = await apiService.get('/api/v1/agents/*', { params: fullParams });
    return data;
}

export async function getDistinctField(field, filter = null, limit = null) {
    const data = await apiService.get(
        `/api/v1/agents/*?distinct&fields=${field}${filter ? `&filter=${filter}` : ''}${
            limit ? `&limit=${limit}` : ''
        }`
    );
    return data;
}

export async function getAgentModules() {
    const data = await apiService.get('/api/v1/agents/*?fields=modules');
    return data;
}

export async function getAgentById(id, params = {}) {
    const queryParams = {
        fields: 'default,arch,os-release',
        ...params
    };
    const data = await apiService.get(`/api/v1/agents/${id}`, { params: queryParams });
    return data;
}

export async function postModuleCommand(moduleName, body, params) {
    const data = await apiService.post(`/api/v1/agents/modules/:${moduleName}`, body, { params });
    return data;
}

export async function getAgentConfig(id) {
    const data = await apiServiceWithCustomHeaders({ Accept: 'text/plain' }).get(
        `/api/v1/agents/${id}/config`
    );
    return data;
}

export async function writeAgentConfig(id, data) {
    const res = await textContentApiService.put(`/api/v1/agents/${id}/config`, data);
    return res;
}

export async function writeAgentComment(id, data) {
    const res = await textContentApiService.put(`/api/v1/agents/${id}/comment`, data);
    return res;
}

export async function getAgentsId(filter) {
    const params = {
        filter: filter || undefined
    };
    const data = await apiService.get('/api/v1/agents/*/id', { params });
    return data;
}

export async function getAgentsCount(filter) {
    const data = await apiService.get(
        `/api/v1/agents/count${filter ? `?filter=${encodeURIComponent(filter)}` : ''}`
    );
    return data;
}

export async function getAgentsMultiCount([...filters]) {
    const data = await apiService.get(
        `/api/v1/agents/count${filters.length ? `?filter=${filters.join('&filter=')}` : ''}`
    );
    return data;
}

export async function putAssignTemplate({ id, filter, agentId = '*' }) {
    const data = await textContentApiService.put(
        `/api/v1/agents/${agentId}/template-id${filter ? `?filter=${filter}` : ''}`,
        id
    );
    return data;
}

export async function deleteTemplateAssignment({ agentId }) {
    const data = await textContentApiService.delete(`/api/v1/agents/${agentId}/template-id`);
    return data;
}

export async function deleteTemplateAssignmentWithFilter({ filter }) {
    const data = await textContentApiService.delete(
        `/api/v1/agents/*/template-id${filter ? `?filter=${filter}` : ''}`
    );
    return data;
}

export async function postEnrollAgent(id, defaultIp) {
    const body = {
        connection: {
            mode: 'connect',
            address: defaultIp
        }
    };
    const res = await apiService.post(`/api/v1/agents/${id}/enroll`, body);
    return res.data;
}

export async function postEnrollMultipleAgents(idArray, defaultIp) {
    const body = {
        connection: {
            mode: 'connect',
            address: defaultIp
        }
    };
    const res = await apiService.post(
        `/api/v1/agents/*/enroll?filter=(id in ${idArray.join(', ')})`,
        body
    );
    return res.data;
}

export async function postEnrollAgentWithTemplate(agentId, templateId) {
    const res = await apiService.post(`/api/v1/agents/${agentId}/enroll`, { templateId });
    return res.data;
}

export async function postEnrollMultipleAgentsUsingCustomFilter(filter, options) {
    const body = {};

    if (options.ip) {
        body.connection = {
            mode: 'connect',
            address: options.ip
        };
    }

    if (options.templateId) {
        body.templateId = options.templateId;
    }

    filter = filter ? `?filter=${filter}` : '';
    const res = await apiService.post(`/api/v1/agents/*/enroll${filter}`, body);
    return res.data;
}

export async function deleteMultipleAgentsUsingCustomFilter(filter) {
    filter = filter ? `?filter=${filter}` : '';
    const res = await apiService.put(`/api/v1/agents/*/persisted${filter}`, false);
    return res.data;
}

export async function actionMultipleAgentsUsingCustomFilter(operation, filter) {
    filter = filter ? `?filter=${filter}` : '';
    const res = await apiService.post(`/api/v1/agents/*/modules/*/${operation}${filter}`);
    return res.data;
}

export async function postRestartAgent(id) {
    const res = await apiService.post(`/api/v1/agents/${id}/modules/*/restart`);
    return res.data;
}

export async function postStartAgent(id) {
    const res = await apiService.post(`/api/v1/agents/${id}/modules/*/start`);
    return res.data;
}

export async function postStopAgent(id) {
    const res = await apiService.post(`/api/v1/agents/${id}/modules/*/stop`);
    return res.data;
}

export async function postRefreshAgent(id) {
    const res = await apiService.post(`/api/v1/agents/${id}/refresh`);
    return res.data;
}

export async function postRenewCertificate(id, filter = '') {
    const res = await apiService.post(
        `/api/v1/agents/${id}/certificate/renew${filter ? `?filter=${filter}` : ''}`
    );
    return res;
}

export async function getExportAgent(id) {
    const data = await zipContentApiService.get(`/api/v1/agents/${id}/export`);
    return data;
}

export async function postUpdateConfig(id, filter) {
    const res = await apiService.post(
        `/api/v1/agents/${id}/update-config-from-template${filter ? `?filter=${filter}` : ''}`
    );
    return res;
}

export async function postSyncAgentData({
    id,
    filePath = '/conf/managed.conf',
    sync = 'use-manager-file-version',
    filter
}) {
    const res = await apiService.post(
        `/api/v1/agents/${id}/files-sync${filePath}/${sync}${filter ? `?filter=${filter}` : ''}`
    );
    return res.data;
}

export async function deleteAgent(id) {
    const data = await apiService.put(`/api/v1/agents/${id}/persisted`, false);
    return data;
}

export async function getAgentLog(id) {
    const data = await apiService.get(`/api/v1/agents/${id}?fields=cached-logs`);
    return data;
}

export async function getModuleSummary() {
    const data = await apiService.get('/api/v1/stats/module-summary');
    return data;
}

export async function getEntitlements() {
    try {
        const data = await apiService.get('/api/v1/entitlements');
        return data;
    } catch (err) {
        return null;
    }
}

export async function getAgentEverConnected() {
    const data = await apiService.get('/api/v1/stats/agent-ever-connected');
    return data;
}

export async function getAutoEnrollRules() {
    const data = await apiService.get('/api/v1/enroll-rules/*?fields=all');
    return data;
}

export async function saveAutoEnrollRule(rule) {
    try {
        if (rule.id) {
            const { id } = rule;
            delete rule.id;
            const data = await apiService.patch(`/api/v1/enroll-rules/${id}`, rule);
            return data;
        }
        const data = await apiService.post('/api/v1/enroll-rules', rule);
        return data;
    } catch (error) {
        throw new Error(error?.message?.message);
    }
}

export async function deleteAutoEnrollRule(id) {
    const data = await apiService.delete(`/api/v1/enroll-rules/${id}`);
    return data;
}
