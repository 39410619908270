import omit from 'lodash/omit';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import ConfigTemplateForm from '../../../../components/forms/configTemplateForm';
import Statistics from '../../../../components/statistics';
import { updateAgentComment, updateAgentConfig } from '../../../../redux/reducers/agents';
import TableModuleContext from '../../../../utils/context/tableModuleContext';
import {
    createAdditionalConfigString,
    createFormTemplateConfig
} from '../../../../utils/helpers/strings';
import { createDefaultTemplate } from '../../../../utils/templates';
import Confirm from '../../../../components/modals/confirmModal';
import AlertHeading from '../../../../components/common/alertHeading';
import { normalizeConfig } from '../../../../utils/helpers/functions';

const useAgentsTabs = ({
    comment,
    handleViewConfig,
    handleSaveAsTemplateClick,
    agentConfig,
    agentStats,
    hasResponse,
    refetchAgentConfig,
    templateName
}) => {
    const dispatch = useDispatch();
    const params = useParams();
    const [confirm, setConfirm] = useState(null);
    const [data, setData] = useState(null);

    const defaultValues = useMemo(() => {
        let str = agentConfig || '';
        if (str?.startsWith('"')) {
            str = str.slice(1, -1);
        }
        const { globalConfig } = createFormTemplateConfig(str);
        const additionalConfig = str ? createAdditionalConfigString(str) : null;

        return {
            ...globalConfig,
            verbatimConfig: additionalConfig?.trim(),
            comment
        };
    }, [agentConfig, comment]);

    const formKey = useMemo(() => new Date(), [agentConfig, comment]);

    const submitConfig = useCallback(
        (submittedData) => {
            const normalizedConfig = normalizeConfig(submittedData);
            const body = {
                contents: `${createDefaultTemplate(omit(normalizedConfig, ['name', 'comment']))}`
            };
            const onSuccess = () => {
                if (submittedData.comment !== comment) {
                    dispatch(
                        updateAgentComment({
                            id: params.agentId,
                            data: submittedData.comment,
                            onSuccess: refetchAgentConfig
                        })
                    );
                } else refetchAgentConfig();
            };
            dispatch(
                updateAgentConfig({
                    id: params.agentId,
                    data: body.contents,
                    onSuccess
                })
            );
        },
        [dispatch, comment, refetchAgentConfig, templateName, setConfirm]
    );

    const handleSubmitConfig = (dataToSubmit) => {
        if (templateName) {
            setConfirm(true);
            setData(dataToSubmit);
        } else {
            submitConfig(dataToSubmit);
        }
    };

    const onCancelConfirm = () => {
        setConfirm(false);
        setData(null);
    };

    const handleConfirm = () => {
        submitConfig(data);
        setConfirm(false);
        setData(null);
    };

    const moduleContext = useMemo(
        () => ({
            toggleConfig: handleViewConfig,
            handleSaveAsTemplateClick
        }),
        []
    );

    const confirmTitle = (
        <AlertHeading>
            {i18next.t('alert_messages.confirm.unassign_template_heading', {
                count: 1
            })}
        </AlertHeading>
    );

    return [
        {
            title: 'Configure',
            tabName: 'Configure',
            tabContent: (
                <>
                    <div className="form">
                        <TableModuleContext.Provider value={moduleContext}>
                            {hasResponse ? (
                                <ConfigTemplateForm
                                    key={formKey}
                                    initialValues={defaultValues}
                                    onSubmit={handleSubmitConfig}
                                    hideName
                                />
                            ) : null}
                        </TableModuleContext.Provider>
                    </div>
                    <Confirm
                        show={Boolean(confirm)}
                        title={confirmTitle}
                        body={i18next.t('alert_messages.confirm.edit_assigned_config', {
                            template: templateName || ''
                        })}
                        cancel="Cancel"
                        confirm="Unassign Template"
                        onCancel={onCancelConfirm}
                        onConfirm={handleConfirm}
                    />
                </>
            )
        },
        {
            title: 'Statistics',
            tabName: 'Statistics',
            tabContent: <Statistics statisticsData={agentStats} />
        }
    ];
};

export default useAgentsTabs;
