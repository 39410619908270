import React from 'react';
import { Outlet } from 'react-router-dom';
import HomeIcon from '@nxlog/common-ui/dist/components/svgs/home';
import { SubMenuTabs } from '@nxlog/common-ui/components';
import Header from '../header';

function App() {
    const tabs = [{ tabName: 'Overview', tabPath: '/' }];

    return (
        <div className="w-100">
            <Header title="Home" titleIcon={<HomeIcon fill="#0086C5" />} />
            <SubMenuTabs tabs={tabs} />
            <Outlet />
        </div>
    );
}

export default App;
