import { useCallback, useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { Button, WithTooltip } from '@nxlog/common-ui/components';
import { Interrogation } from '@nxlog/common-ui/dist/components/svgs';
import { AddIcon, DeleteIcon } from '../../utils/Icons';
import {
    VALIDATE_INTEGER_PATTERN,
    VALIDATE_IP_PATTERN,
    VALIDATE_NUMBER_PATTERN,
    VALIDATE_PATH_PATTERN
} from '../../../../utils/constants/formValidation';
import { populateFileInput } from '../../../../utils/helpers/files';
import { EMPTY_FILE_INPUT_CAPTION, MAX_SP_PARAM_FILE_SIZE } from '../../utils/constants';

const getInitialValue = (type, variants) => {
    let initialValue;

    switch (type) {
        case 'boolean':
            initialValue = false;
            break;
        case 'integer':
        case 'number':
            initialValue = 0;
            break;
        case 'object':
            initialValue = {};
            break;
        case 'enum':
            initialValue = variants ? variants[0].value : '';
            break;
        default:
            initialValue = '';
    }

    return initialValue;
};

function ObjectField({ schema, value, setValue, disabled }) {
    return (
        <div className="group-wrapper">
            {Object.entries(schema).map(([subParamName, subParamProps]) => (
                <div key={subParamName}>
                    <ParamInput
                        key={subParamName}
                        paramName={subParamName}
                        paramProps={subParamProps}
                        formState={value}
                        setFormState={(callback) => setValue(callback(value))}
                        disabled={disabled}
                    />
                </div>
            ))}
        </div>
    );
}

ObjectField.defaultProps = {
    value: undefined,
    disabled: false
};

ObjectField.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    schema: propTypes.object.isRequired,
    value: propTypes.oneOfType([
        propTypes.string,
        propTypes.number,
        propTypes.bool,
        propTypes.object
    ]),
    setValue: propTypes.func.isRequired,
    disabled: propTypes.bool
};

// TODO: add a multiselect for enum
export function SingleField({ paramName, paramProps, label, value, setValue, disabled }) {
    const { type, variants, schema, multiple, min, max, optional } = paramProps;

    const validation = useRef();
    const fileInputRef = useRef();
    const fileName = useRef('uploaded');

    const handleChange = (e) => {
        if (type === 'boolean') {
            setValue(e.target.checked);
        } else {
            const val = e.target.value;
            setValue(val === 'true' || val === 'false' ? JSON.parse(val) : val);
        }

        if (validation.current) {
            const test = validation.current.value.test(e.target.value);
            if (!test) {
                e.target.setCustomValidity(validation.current.message);
            } else {
                e.target.setCustomValidity('');
            }
        }
    };

    const uploadFile = (input) => {
        const file = input.files?.[0];

        if (!file) {
            if (value) {
                populateFileInput(input, value, fileName.current);
            }
            return;
        }

        if (file.size > MAX_SP_PARAM_FILE_SIZE) {
            input.setCustomValidity(
                `The file exceeds size limit of ${MAX_SP_PARAM_FILE_SIZE} bytes`
            );
        } else {
            input.setCustomValidity('');
        }

        fileName.current = file.name;

        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = (e) => {
            setValue(e.target.result);
        };

        reader.onerror = () => {
            input.value = null;
            setValue(undefined);
        };
    };

    // if the initial value contains the file content
    useEffect(() => {
        if (
            (type === 'file' && fileInputRef.current?.files?.length === 0 && value) ||
            (fileInputRef.current?.files?.length > 0 && !value)
        ) {
            populateFileInput(
                fileInputRef.current,
                value,
                value ? fileName.current : EMPTY_FILE_INPUT_CAPTION
            );
        }
    }, [fileInputRef.current, value, type, fileName]);

    switch (type) {
        case 'boolean':
            return (
                <div className="checkbox-wrapper">
                    <input
                        type="checkbox"
                        id={paramName}
                        name={paramName}
                        checked={value}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                    <label htmlFor={paramName}>{label}</label>
                </div>
            );

        case 'integer':
        case 'number':
            validation.current =
                // eslint-disable-next-line no-nested-ternary
                type === 'integer' ? VALIDATE_INTEGER_PATTERN : VALIDATE_NUMBER_PATTERN;
            return (
                <input
                    type="number"
                    id={paramName}
                    name={paramName}
                    {...{ min: min ?? undefined, max: max ?? undefined }}
                    step={type === 'integer' ? 1 : 0.001}
                    required={!(optional || disabled)}
                    value={value}
                    onChange={handleChange}
                    disabled={disabled}
                />
            );

        case 'enum':
            if (variants)
                return (
                    <select
                        id={paramName}
                        name={paramName}
                        multiple={!!multiple}
                        required={!(optional || disabled)}
                        value={value}
                        onChange={handleChange}
                        disabled={disabled}
                    >
                        {variants.map(
                            ({ value: val, title: optTitle, description: optDescription }) => (
                                <option key={val} value={val}>
                                    {optTitle || optDescription || val}
                                </option>
                            )
                        )}
                    </select>
                );
            break;

        case 'object':
            if (schema) return <ObjectField schema={schema} value={value} setValue={setValue} />;
            break;

        case 'file':
            return (
                <input
                    ref={fileInputRef}
                    type="file"
                    id={paramName}
                    name={paramName}
                    required={!(optional || disabled)}
                    onChange={(e) => {
                        uploadFile(e.target);
                    }}
                    disabled={disabled}
                    data-testid="file-input"
                />
            );

        case 'string':
        case 'path':
        case 'url':
        case 'ip':
        default:
            validation.current =
                // eslint-disable-next-line no-nested-ternary
                type === 'path'
                    ? VALIDATE_PATH_PATTERN
                    : type === 'ip'
                    ? VALIDATE_IP_PATTERN
                    : undefined;
            return (
                <input
                    type={type === 'path' || type === 'ip' ? 'string' : type}
                    id={paramName}
                    name={paramName}
                    {...{ min: min ?? undefined, max: max ?? undefined }}
                    required={!(optional || disabled)}
                    value={value}
                    onChange={handleChange}
                    disabled={disabled}
                />
            );
    }
    return null;
}

SingleField.defaultProps = {
    value: undefined,
    disabled: false
};

SingleField.propTypes = {
    paramName: propTypes.string.isRequired,
    paramProps: propTypes.shape({
        type: propTypes.string.isRequired,
        variants: propTypes.arrayOf(
            propTypes.shape({
                value: propTypes.oneOfType([propTypes.string, propTypes.number, propTypes.bool])
                    .isRequired,
                title: propTypes.string,
                description: propTypes.string
            })
        ),
        // eslint-disable-next-line react/forbid-prop-types
        schema: propTypes.object,
        multiple: propTypes.bool,
        min: propTypes.number,
        max: propTypes.number,
        optional: propTypes.bool,
        title: propTypes.string,
        description: propTypes.string
    }).isRequired,
    label: propTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: propTypes.oneOfType([
        propTypes.string,
        propTypes.number,
        propTypes.bool,
        propTypes.object
    ]),
    setValue: propTypes.func.isRequired,
    disabled: propTypes.bool
};

export function ParamInput({ paramName, paramProps, formState, setFormState, disabled }) {
    const {
        type,
        multiple,
        variants,
        size,
        title,
        description,
        default: defaultValue
    } = paramProps;

    const [clearValue, setClearValue] = useState(false);
    const [quantity, setQuantity] = useState(
        (multiple && (size || (Array.isArray(defaultValue) && defaultValue.length))) || 1
    );

    const increase = () => {
        setFormState((prevValue) => ({
            ...prevValue,
            [paramName]: [...prevValue[paramName], getInitialValue(type, variants)]
        }));
    };

    const remove = (i) => {
        setFormState((prevValue) => ({
            ...prevValue,
            [paramName]: prevValue[paramName].filter((_, j) => j !== i)
        }));
    };

    const label = title ?? paramName.split('_').join(' ');

    const setFieldValue = useCallback(
        (val, i) => {
            setFormState((prevValue) => {
                if (multiple) {
                    prevValue[paramName][i] = val;
                    return { ...prevValue };
                }
                prevValue[paramName] = val;
                return { ...prevValue };
            });
        },
        [setFormState]
    );

    useEffect(() => {
        if (Array.isArray(formState[paramName])) {
            setQuantity(formState[paramName].length);
        }
    }, [formState]);

    useEffect(() => {
        if (disabled) {
            setClearValue(true);
        }
    }, [disabled, setClearValue]);

    useEffect(() => {
        if ((setFormState && (!formState || formState[paramName] === undefined)) || clearValue) {
            let def = defaultValue;
            const initialValue = getInitialValue(type, variants);

            if (multiple && !Array.isArray(defaultValue)) {
                def = [defaultValue ?? initialValue];
            }

            setFormState((prevValue) => ({
                ...prevValue,
                [paramName]: multiple ? def || [] : def ?? initialValue
            }));
            setClearValue(false);
        }
    }, [formState, type, paramName, setFormState, clearValue, setClearValue]);

    if (!formState || formState[paramName] === undefined) {
        return null;
    }

    return (
        <div className="field-wrapper" data-testid={`sp-param-${paramName}`}>
            {type !== 'boolean' && (
                <label className="input-label" htmlFor={paramName}>
                    {label}
                    {description && (
                        <div className="info-icon">
                            <WithTooltip
                                message={description}
                                placement="top"
                                className="field-tooltip"
                            >
                                <Interrogation />
                            </WithTooltip>
                        </div>
                    )}
                </label>
            )}
            {Array(quantity)
                .fill(true)
                .map((_, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="input-wrapper" key={i}>
                        <SingleField
                            paramName={paramName}
                            paramProps={paramProps}
                            label={label}
                            value={
                                (Array.isArray(formState?.[paramName])
                                    ? formState[paramName][i]
                                    : formState?.[paramName]) ?? defaultValue
                            }
                            setValue={(v) => setFieldValue(v, i)}
                            disabled={disabled}
                        />
                        {multiple && quantity > 1 && (
                            <DeleteIcon
                                className="delete-btn"
                                testid="delete-btn"
                                onClick={() => remove(i)}
                            />
                        )}
                    </div>
                ))}
            {multiple && type !== 'enum' && (
                <Button className="add-btn" testid="add-btn" icon={<AddIcon />} onClick={increase}>
                    Add new value
                </Button>
            )}
        </div>
    );
}

ParamInput.defaultProps = {
    disabled: false
};

ParamInput.propTypes = {
    paramName: propTypes.string.isRequired,
    paramProps: propTypes.shape({
        type: propTypes.string.isRequired,
        variants: propTypes.arrayOf(
            propTypes.shape({
                value: propTypes.oneOfType([propTypes.string, propTypes.number, propTypes.bool])
                    .isRequired,
                title: propTypes.string,
                description: propTypes.string
            })
        ),
        // eslint-disable-next-line react/forbid-prop-types
        schema: propTypes.object,
        multiple: propTypes.bool,
        size: propTypes.number,
        min: propTypes.number,
        max: propTypes.number,
        default: propTypes.oneOfType([
            propTypes.string,
            propTypes.number,
            propTypes.bool,
            propTypes.arrayOf(
                propTypes.oneOfType([propTypes.string, propTypes.number, propTypes.bool])
            )
        ]),
        optional: propTypes.bool,
        title: propTypes.string,
        description: propTypes.string
    }).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    formState: propTypes.object.isRequired,
    setFormState: propTypes.func.isRequired,
    disabled: propTypes.bool
};

export default ParamInput;
