import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmPipe = {
    label: 'Named Pipe',
    name: 'om_pipe',
    type: 'sendTo',
    defaultDirectives: 'Module      om_pipe',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Pipe',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Group',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Perms',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'User',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_pipe'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
