import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmUdpspoof = {
    label: 'UDP Transport with IP Spoofing',
    name: 'om_udpspoof',
    type: 'sendTo',
    defaultDirectives: 'Module      om_udpspoof',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Host',
            type: 'string',
            defaultValue: [],
            multiple: true
        },
        {
            name: 'Port',
            type: 'number',
            defaultValue: 514
        },
        {
            name: 'LocalPort',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'MTU',
            type: 'number',
            defaultValue: 1500
        },
        {
            name: 'Reconnect',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SockBufSize',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SpoofAddress',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_udpspoof'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
