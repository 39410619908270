import { cloneDeep } from 'lodash';
import { getAgentsMultiCount } from '../../../api/endpoints/agents';
import { createFilterString } from '../strings';
import { getTemplatesMultiCount } from '../../../api/endpoints/templates';
import { TEMPLATE_TAGS } from '../../templates';

const CONFIG_FILTERS = [
    {
        id: 'assigned',
        filter: 'used-by-agents is not empty',
        label: 'Assigned',
        itemsCount: null
    },
    {
        id: 'unassigned',
        filter: 'used-by-agents is empty',
        label: 'Unassigned',
        itemsCount: null
    },
    {
        id: 'draft',
        filter: `any tags == "${TEMPLATE_TAGS.draft}"`,
        label: 'Draft',
        itemsCount: null
    },
    {
        id: 'solution_pack',
        filter: `any tags == "${TEMPLATE_TAGS.solutionPack}"`,
        label: 'Solution packs',
        itemsCount: null
    }
];

const AGENT_FILTERS = {
    warning: {
        id: 'warning',
        filterGroupId: 'status',
        filter: { status: 'warning', online: 'yes' },
        label: 'Warning'
    },
    error: {
        id: 'error',
        filterGroupId: 'status',
        filter: { status: 'error', online: 'yes' },
        label: 'Error',
        dropdown: [
            {
                id: 'errors/persisted-id-not-found',
                label: 'Persisted ID not found'
            },
            {
                id: 'errors/non-unique-id',
                label: 'Non-unique ID'
            },
            {
                id: 'errors/invalid-id-signature-found',
                label: 'Invalid ID signature found'
            },
            {
                id: 'errors/expected-id-signature-not-found',
                label: 'Expected ID signature not found'
            },
            {
                id: 'errors/certificate-negative-duration',
                label: 'Certificate negative duration'
            },
            {
                id: 'errors/certificate-not-yet-valid',
                label: 'Certificate not yet valid'
            },
            {
                id: 'errors/certificate-expired',
                label: 'Certificate expired'
            },
            {
                id: 'errors/modules-failed-to-start',
                label: 'Modules failed to start'
            },
            {
                id: 'errors/files-modified-on-agent',
                label: 'Files modified on agent'
            },
            {
                id: 'errors/files-modified-on-manager-and-agent',
                label: 'Files modified on manager and agent'
            },
            {
                id: 'errors/config-not-synced-with-template',
                label: 'Config not synced with template'
            },
            {
                id: 'errors/non-auto-priority-enroll-rules-found',
                label: 'Non-auto priority enroll rules found'
            },
            {
                id: 'errors/auto-enroll-certificate-generation-failed',
                label: 'Auto enroll certificate generation failed'
            }
        ].map((elm) => ({
            ...elm,
            filterGroupId: 'status',
            isSubFilter: 'error',
            filter: `${elm.id} is some`
        }))
    },
    ok: {
        id: 'ok',
        filterGroupId: 'status',
        filter: { status: 'ok', online: 'yes' },
        label: 'OK'
    },
    offline: {
        id: 'offline',
        filterGroupId: 'status',
        filter: { online: 'no' },
        label: 'Offline'
    },
    new: {
        id: 'new',
        filterGroupId: 'deployment',
        filter: { online: 'yes', enrolled: 'no' },
        label: 'New'
    },
    enrolled: {
        id: 'enrolled',
        filterGroupId: 'deployment',
        filter: { enrolled: 'yes', configured: 'no' },
        label: 'Enrolled'
    },
    configured: {
        id: 'configured',
        filterGroupId: 'deployment',
        filter: { configured: 'yes', enrolled: 'yes' },
        label: 'Configured'
    },
    linux: {
        id: 'Linux',
        filterGroupId: 'os',
        filter: { os: 'Linux' },
        label: 'Linux'
    },
    windows: {
        id: 'Windows',
        filterGroupId: 'os',
        filter: { os: 'Windows' },
        label: 'Windows'
    },
    macOs: {
        id: '"macOS"',
        filterGroupId: 'os',
        filter: { os: '"macOS"' },
        label: 'MacOs'
    },
    otherOs: {
        id: 'Other',
        filterGroupId: 'os',
        filter: { os: 'Other' },
        label: 'Other'
    }
};

const createAgentsTableFilter = ({
    appliedFilters,
    removeFilter,
    newAgentsCount,
    enrolledAgentsCount,
    configuredAgentsCount,
    okAgentsCount,
    offlineAgentsCount,
    errorAgentsCount,
    warningAgentsCount,
    osLinuxCount,
    osWindowsCount,
    osMacCount,
    osOtherCount,
    versions,
    modules,
    osReleaseList,
    archList
}) => {
    const versionData = [];
    const allModules = Array.from(modules.entries()).map(([type, count]) => ({
        id: type,
        path: { 'data.modules.type': type },
        filter: { 'any modules/*/type': `${type}` },
        label: type,
        itemsCount: count ?? null
    }));

    if (versions) {
        versions.forEach((key) => {
            versionData.push({
                id: key,
                path: { 'data.version': `${key}` },
                filter: { version: `"${key}"` },
                label: key
            });
        });
    }

    if (versionData.length > 0) {
        versionData.push({
            // 'OtherVersions' has a unique id for every versions set
            id: `OtherVersions${versions.reduce((string, key) => string + key, '')}`,
            path: { 'data.version': 'Other' },
            filter: {
                version:
                    versionData.length === 1
                        ? `not = "${versions[0]}"`
                        : `not in ("${versions.join('", "')}")`
            },
            label: 'Other'
        });
    }

    const filters = [
        {
            name: 'Status',
            data: [
                {
                    ...AGENT_FILTERS.warning,
                    itemsCount: warningAgentsCount ?? null
                },
                {
                    ...AGENT_FILTERS.error,
                    itemsCount: errorAgentsCount ?? null
                },
                {
                    ...AGENT_FILTERS.ok,
                    itemsCount: okAgentsCount ?? null
                },
                {
                    ...AGENT_FILTERS.offline,
                    itemsCount: offlineAgentsCount ?? null
                }
            ]
        },
        {
            name: 'Deployment state',
            data: [
                {
                    ...AGENT_FILTERS.new,
                    itemsCount: newAgentsCount ?? null
                },
                {
                    ...AGENT_FILTERS.enrolled,
                    itemsCount: enrolledAgentsCount ?? null
                },
                {
                    ...AGENT_FILTERS.configured,
                    itemsCount: configuredAgentsCount ?? null
                }
            ]
        },
        {
            name: 'OS',
            data: [
                {
                    ...AGENT_FILTERS.linux,
                    itemsCount: osLinuxCount ?? null
                },
                {
                    ...AGENT_FILTERS.windows,
                    itemsCount: osWindowsCount ?? null
                },
                {
                    ...AGENT_FILTERS.macOs,
                    itemsCount: osMacCount ?? null
                },
                {
                    ...AGENT_FILTERS.otherOs,
                    itemsCount: osOtherCount ?? null
                }
            ]
        },
        {
            name: 'Version',
            data: versionData
        },
        {
            name: 'Module',
            data: allModules
        }
    ];

    if (osReleaseList && osReleaseList.length) {
        filters.push({
            name: 'OS-Release',
            data: osReleaseList.map((elm) => ({
                id: `os-release-${elm.label}`,
                path: { 'data.os-release': elm.value ? `"${elm.value}"` : 'is null' },
                filter: { 'os-release': elm.value ? `"${elm.value}"` : 'is null' },
                label: elm.label
            }))
        });
    }

    if (archList && archList.length) {
        filters.push({
            name: 'Arch',
            data: archList.map((elm) => ({
                id: `arch-${elm.label}`,
                path: { 'data.arch': elm.value ? `"${elm.value}"` : 'is null' },
                filter: { arch: elm.value ? `"${elm.value}"` : 'is null' },
                label: elm.label
            }))
        });
    }

    // check if all the appliedFilters exist in filters list
    if (appliedFilters && removeFilter) {
        appliedFilters.forEach((applFilter) => {
            if (
                applFilter.id &&
                !applFilter.isSubFilter &&
                !applFilter.isTag &&
                !filters.find((group) => group.data?.find(({ id }) => id === applFilter.id))
            ) {
                removeFilter(applFilter);
            }
        });
    }

    return filters;
};

// modifies filter object to fill up filter counts
const fetchFiltersCounts = async (filters, { refetch }, fetchService) => {
    const filteredArray = filters.filter(
        (fil) => (!fil.itemsCount && fil.itemsCount !== 0) || fil.itemsCount === '' || refetch
    );
    const { data: countsArray } = await fetchService(
        filteredArray.map((fil) => createFilterString([fil]))
    );
    filteredArray.forEach((fil, i) => {
        fil.itemsCount = countsArray?.[i] ?? 0;
    });
};

// returns a copy of agent filters filled up with counts
// refetch = true forces updating of non-empty counts
const fetchAgentsFiltersCounts = async (filters, { refetch = false }) => {
    const updatedFilters = cloneDeep(filters);
    const flatFilters = updatedFilters
        .reduce((acc, el) => (el.data ? [...acc, ...el.data] : acc), [])
        .reduce((acc, el) => (el.dropdown ? [...acc, el, ...el.dropdown] : [...acc, el]), []);
    await fetchFiltersCounts(flatFilters, { refetch }, getAgentsMultiCount);
    return updatedFilters;
};

const fetchTemplatesFiltersCounts = async (filters, { refetch = false }) => {
    const updatedFilters = cloneDeep(filters);
    await fetchFiltersCounts(updatedFilters, { refetch }, getTemplatesMultiCount);
    return updatedFilters;
};

const compareAndMergeFilters = (existingFilters, newFilters, deleteRudiments = false) => {
    const filters = cloneDeep(existingFilters);
    newFilters.forEach(({ name, data: newData }) => {
        const existingGroup = filters.find((g) => g.name === name);
        if (!existingGroup) {
            filters.push({ name, data: newData });
        } else {
            newData.forEach((item) => {
                const filter = existingGroup.data?.find((f) => f.id === item.id);
                if (!filter) {
                    existingGroup.data?.push(item);
                } else {
                    filter.itemsCount = item.itemsCount ?? filter.itemsCount ?? null;
                    filter.dropdown = item.dropdown ?? filter.dropdown ?? null;
                }
            });
            if (deleteRudiments) {
                existingGroup.data = existingGroup.data.filter((existingItem) =>
                    newData.find((f) => f.id === existingItem.id)
                );
            }
        }
    });
    const filterOrder = [
        'Status',
        'Deployment state',
        'OS',
        'OS-Release',
        'Arch',
        'Version',
        'Module'
    ];
    filterOrder.push(
        ...filters.map((elm) => elm.name).filter((name) => !filterOrder.includes(name))
    );
    filters.sort((a, b) => filterOrder.indexOf(a.name) - filterOrder.indexOf(b.name));
    return filters;
};

export {
    CONFIG_FILTERS,
    AGENT_FILTERS,
    createAgentsTableFilter,
    compareAndMergeFilters,
    fetchAgentsFiltersCounts,
    fetchTemplatesFiltersCounts
};
