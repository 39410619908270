import { assignTemplateData } from '../reducers/agents';
import {
    deleteTemplateData,
    duplicateTemplateData,
    getTemplatesData,
    getTemplatesFiltersCounts,
    postTemplateData,
    updateTemplateData
} from '../reducers/templates';

const filterCountsUpdatingTypes = [
    getTemplatesData.fulfilled.type,
    postTemplateData.fulfilled.type,
    duplicateTemplateData.fulfilled.type,
    deleteTemplateData.fulfilled.type,
    updateTemplateData.fulfilled.type,
    assignTemplateData.fulfilled.type
];

// this middleware triggers template filter counts updates
// on any template stats changing operation from the list
const updateTemplateFilterCountsMiddleware = (store) => (next) => (action) => {
    const res = next(action);
    if (filterCountsUpdatingTypes.includes(action.type)) {
        store.dispatch(getTemplatesFiltersCounts());
    }
    return res;
};

export default updateTemplateFilterCountsMiddleware;
