import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from '@nxlog/common-ui/dist/components/svgs';
import './login.scss';
import { useApplicationContext } from '@nxlog/common-ui/hooks';
import { appendCurrentPortToUrl } from '@nxlog/common-ui/dist/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ToastNotification } from '@nxlog/common-ui/components';
import { TOAST_TYPES } from '../../utils/constants/ids';

export default function LoginPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    useEffect(() => {
        let click = false;

        const action = () => {
            if (click) return;
            click = true;
            if (state?.goBack) {
                navigate(-2);
            } else {
                const { portalUrl } = useApplicationContext();
                const url = new URL(portalUrl);
                window.location.href = appendCurrentPortToUrl(url.toString());
            }
        };

        const timeOut = setTimeout(() => {
            action();
        }, 3000);

        if (state?.error) {
            const message = (
                <span data-testid="login-page-toast-message" className="notification-caption">
                    {state.message || 'Access error'}
                </span>
            );

            toast(
                <ToastNotification
                    type={TOAST_TYPES.ERROR}
                    message={message}
                    additionalInfo={state.info || null}
                    extendedPreview
                />,
                {
                    type: TOAST_TYPES.ERROR,
                    toastId: state.id,
                    onClose: () => {
                        clearTimeout(timeOut);
                        action();
                    }
                }
            );
        }
    }, [state, navigate, dispatch]);

    return (
        <>
            <div className="redirector-wrapper">
                <Loader />
            </div>
            <ToastContainer
                className="toast-message"
                position="bottom-right"
                hideProgressBar
                pauseOnHover
                limit={10}
                closeOnClick={false}
                autoClose={5000}
            />
        </>
    );
}
