import React, { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';

function RouteEditorConnector({ route }) {
    const elm = useRef();
    const [ready, setReady] = useState(false);
    const [, setRender] = useState(false);
    const leftCount = route.collect.filter((e) => e.module).length;
    const rightCount = route.sendTo.filter((e) => e.module).length;
    let left = route.collect;
    let right = route.sendTo;
    const step = 95;
    const top = 53;

    left = left.map((e, i) => ({
        active: !!e.module,
        key: `left-${i}`,
        index: i + 1,
        top: i * step + top
    }));
    right = right.map((e, i) => ({
        active: !!e.module,
        key: `right-${i}`,
        index: i + 1,
        top: i * step + top
    }));

    const tLeft = left.filter((e) => e.active);
    const tRight = right.filter((e) => e.active);
    const minLeft = tLeft.length ? tLeft[0].index - 1 : 10;
    const minRight = tRight.length ? tRight[0].index - 1 : 10;
    const maxLeft = tLeft.length ? tLeft[tLeft.length - 1].index - 1 : 0;
    const maxRight = tRight.length ? tRight[tRight.length - 1].index - 1 : 0;
    const minI = Math.min(minLeft, minRight);
    const maxI = Math.max(maxLeft, maxRight);
    const width = ready ? elm.current.offsetWidth : 200;
    setTimeout(() => {
        if (width !== (ready ? elm?.current?.offsetWidth : 200)) {
            setRender((r) => !r);
        }
    }, 10);
    const cx = width / 2;
    const cy = top + 17 / 2 + minI * step + ((maxI - minI) * step) / 2;
    const arrowWidth = 6;
    const ax = cx;
    const ay = cy - arrowWidth;
    useEffect(() => {
        if (elm.current) setReady(true);
        const handleResize = () => setRender((r) => !r);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [elm.current]);
    return (
        <div ref={elm} className="RouteEditorConnector" data-testid="RouteEditorConnector">
            {right.length
                ? left.map(
                      (e) =>
                          e.active && (
                              <span key={e.key} className="left" style={{ top: e.top }}>
                                  OUT
                              </span>
                          )
                  )
                : null}
            {left.length
                ? right.map(
                      (e) =>
                          e.active && (
                              <span key={e.key} className="right" style={{ top: e.top }}>
                                  IN
                              </span>
                          )
                  )
                : null}
            <svg xmlns="http://www.w3.org/2000/svg">
                {leftCount && rightCount ? (
                    <path
                        d={`M ${ax} ${ay} L ${ax + arrowWidth} ${ay + arrowWidth} L ${ax} ${
                            ay + arrowWidth * 2
                        } L ${ax} ${ay}`}
                        fill="#0081C1"
                    />
                ) : null}
                {tRight.length
                    ? left.map(
                          (e) =>
                              e.active && (
                                  <path
                                      key={e.key}
                                      className="RouteEditorConnectorpath"
                                      d={`M 30,${e.top + 8} C ${cx / 2 + 15},${e.top + 8} ${
                                          cx / 2 + 15
                                      },${cy} ${cx},${cy}`}
                                      fill="none"
                                      stroke="#0081C1"
                                      strokeDasharray="4 4"
                                      strokeWidth="2"
                                  />
                              )
                      )
                    : null}
                {tLeft.length
                    ? right.map(
                          (e) =>
                              e.active && (
                                  <path
                                      key={e.key}
                                      className="RouteEditorConnectorpath"
                                      d={`M ${cx},${cy} C ${(cx * 3) / 2 + 15},${cy} ${
                                          (cx * 3) / 2 + 15
                                      },${e.top + 8} ${width - 30},${e.top + 8}`}
                                      fill="none"
                                      stroke="#0081C1"
                                      strokeDasharray="4 4"
                                      strokeWidth="2"
                                  />
                              )
                      )
                    : null}
            </svg>
        </div>
    );
}

RouteEditorConnector.defaultProps = {
    route: { collect: [], sendTo: [] }
};

RouteEditorConnector.propTypes = {
    route: propTypes.shape({
        collect: propTypes.arrayOf(propTypes.shape()),
        sendTo: propTypes.arrayOf(propTypes.shape())
    })
};

export default RouteEditorConnector;
