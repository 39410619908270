export const GENERAL_INPUT_DIRECTIVES = (mdl) => {
    const directives = [
        {
            name: 'BatchFlushInterval',
            type: 'number',
            defaultValue: 0.1
        }
    ];
    if (['im_exec', 'im_ssl', 'im_tcp', 'im_udp', 'im_uds', 'im_pipe'].includes(mdl)) {
        directives.push({
            name: 'InputType',
            type: 'select',
            options: ['', 'Binary', 'Dgram', 'LineBased'],
            defaultValue: ''
        });
    }

    if (['im_file'].includes(mdl)) {
        directives.push({
            name: 'InputType',
            type: 'string',
            multiple: true,
            separator: ', ',
            options: ['Binary', 'Dgram', 'LineBased'],
            defaultValue: []
        });
    }

    return directives;
};

export const GENERAL_OUTPUT_DIRECTIVES = (mdl) => {
    const directives = [
        {
            name: 'LogqueueDir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'LogqueueSize',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'PersistLogqueue',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'SyncLogqueue',
            type: 'select',
            options: ['', 'TRUE', 'FALSE'],
            defaultValue: ''
        }
    ];
    if (
        [
            'om_exec',
            'om_pipe',
            'om_redis',
            'om_ssl',
            'om_tcp',
            'om_udp',
            'om_udpspoof',
            'om_uds',
            'om_zmq'
        ].includes(mdl)
    ) {
        directives.push({
            name: 'OutputType',
            type: 'select',
            options: ['', 'Binary', 'Dgram', 'LineBased', 'LineBased_CRLF', 'LineBased_LF'],
            defaultValue: ''
        });
    }
    if (['om_file'].includes(mdl)) {
        directives.push({
            name: 'OutputType',
            type: 'string',
            multiple: true,
            separator: ', ',
            options: ['Binary', 'Dgram', 'LineBased', 'LineBased_CRLF', 'LineBased_LF'],
            defaultValue: []
        });
    }
    return directives;
};

export const GENERAL_EXTENSION_DIRECTIVES = () => [];

export const GENERAL_DIRECTIVES = [
    {
        name: 'Define',
        type: 'string',
        multiple: true,
        defaultValue: [],
        emptyByDefault: true
    },
    {
        name: 'BatchSize',
        type: 'number',
        defaultValue: 50
    },
    {
        name: 'BufferSize',
        type: 'string',
        defaultValue: ''
    },
    {
        name: 'FlowControl',
        type: 'select',
        options: ['', 'TRUE', 'FALSE'],
        defaultValue: ''
    },
    {
        name: 'LogLevel',
        type: 'select',
        options: ['', 'CRITICAL', 'ERROR', 'WARNING', 'INFO', 'DEBUG'],
        defaultValue: ''
    },
    {
        name: 'Schedule',
        type: 'block',
        defaultValue: [],
        multiple: true,
        emptyByDefault: true,
        block: [
            {
                name: 'Every',
                type: 'string',
                defaultValue: ''
            },
            {
                name: 'First',
                type: 'string',
                defaultValue: ''
            },
            {
                name: 'RunCount',
                type: 'string',
                defaultValue: ''
            },
            {
                name: 'When',
                type: 'string',
                defaultValue: ''
            },
            {
                name: 'Exec',
                type: 'multiline-string-block',
                defaultValue: '',
                required: true
            }
        ]
    },
    {
        name: 'include_stdout',
        type: 'string',
        defaultValue: ''
    }
];

export default GENERAL_DIRECTIVES;
