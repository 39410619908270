import _ from 'lodash';
import {
    deleteAgentData,
    enrollAgent,
    enrollMultipleAgents,
    removeMultipleOperations,
    renewAgentCertificate,
    restartAgent,
    setProcesses,
    startAgent,
    stopAgent,
    syncAgentData,
    updateConfigData
} from '../reducers/agents';

const operationsChangerTypes = [
    deleteAgentData.fulfilled.type,
    deleteAgentData.rejected.type,
    deleteAgentData.pending.type,
    enrollAgent.fulfilled.type,
    enrollAgent.rejected.type,
    enrollAgent.pending.type,
    enrollMultipleAgents.fulfilled.type,
    enrollMultipleAgents.rejected.type,
    enrollMultipleAgents.pending.type,
    restartAgent.fulfilled.type,
    restartAgent.rejected.type,
    restartAgent.pending.type,
    startAgent.fulfilled.type,
    startAgent.rejected.type,
    startAgent.pending.type,
    stopAgent.fulfilled.type,
    stopAgent.rejected.type,
    stopAgent.pending.type,
    syncAgentData.fulfilled.type,
    syncAgentData.rejected.type,
    syncAgentData.pending.type,
    updateConfigData.fulfilled.type,
    updateConfigData.rejected.type,
    updateConfigData.pending.type,
    renewAgentCertificate.fulfilled.type,
    renewAgentCertificate.rejected.type,
    renewAgentCertificate.pending.type
];

const watchProcessesMiddleware = (store) => (next) => (action) => {
    const res = next(action);
    if (operationsChangerTypes.includes(action.type)) {
        const { operationStatus, processes } = store.getState().agents;
        const processesCopy = _.cloneDeep(processes);
        if (processesCopy?.length || Object.keys(operationStatus)?.length) {
            let idsToRemove = [];
            const updatedProcesses = processesCopy?.map((process) => {
                let error = process?.error || false;
                if (process.status === 'complete') {
                    return process;
                }
                const ids = process.agentIds;
                const completed = ids.reduce((acc, id) => {
                    if (
                        operationStatus[id] === 'success' ||
                        operationStatus[id]?.enroll === 'success'
                    ) {
                        return acc + 1;
                    }
                    if (
                        operationStatus[id] === 'error' ||
                        operationStatus[id]?.enroll === 'error'
                    ) {
                        error = true;
                        return acc + 1;
                    }
                    return acc;
                }, 0);
                const status = completed === ids.length ? 'complete' : process.status;
                if (completed === ids.length) {
                    idsToRemove = [...new Set([...idsToRemove, ...process.agentIds])];
                }
                return {
                    ...process,
                    error,
                    status
                };
            });
            // check for changes for no excessive changes to processes
            if (!_.isEqual(processes, updatedProcesses)) {
                store.dispatch(setProcesses(updatedProcesses));
            }
            if (idsToRemove.length) {
                store.dispatch(removeMultipleOperations(idsToRemove));
            }
        }
    }
    return res;
};

export default watchProcessesMiddleware;
