import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { SharedAppPath } from '@nxlog/common-ui/dist/app/constants';
import App from './components/main';
import configureStore from './redux/store';
import LoginPage from './pages/login';
import AgentsPage from './pages/agents';
import NotificationsPage from './pages/notifications';
import SettingsPage from './pages/settings';
import AgentManagerSettings from './pages/settings/agentManagerSettings';
import ApiSecurity from './pages/settings/apiSecurity';
import AuthenticationSettings from './pages/settings/authenticationSettings';
import UserManagement from './pages/settings/userManagement';
import WhatNewPage from './pages/new';
import ProductDownloadPage from './pages/productDownload';
import SupportPage from './pages/support';
import Layout from './components/layout';
import SingleAgentPage from './pages/singleAgent';
import EditExtensionPage from './pages/singleAgent/editExtension';
import EditModulePage from './pages/singleAgent/editModule';
import NotFoundPage from './pages/404';
import PageChangeOrganization from './pages/PageChangeOrganization';
import {
    APP_AGENTS,
    ROOT,
    APP_LICENSES,
    APP_LOGIN,
    APP_NOTIFICATIONS,
    APP_SETTINGS,
    APP_AGENT_MANAGER_SETTING,
    APP_API_SECURITY,
    APP_AUTENTICATION_SETTINGS,
    APP_USER_MANAGEMENT,
    APP_PRODUCT_DOWNLOAD,
    APP_SUPPORT,
    APP_WHAT_IS_NEW,
    APP_AGENT,
    APP_EXTENSION,
    APP_MODULE,
    APP_TOKEN,
    NOT_FOUND,
    LOGOUT,
    APP_AGENT_CONFIGS,
    APP_AGENT_CONFIGS_NEW,
    APP_AGENT_CONFIGS_EDIT,
    APP_AUTO_ENROLL,
    APP_SWAGGER,
    APP_CLUSTER
} from './utils/constants/routes';
import logEnvironment from './utils/environment/log';
import AuthRedirectorPage from './pages/authRedirector';
import LicensesPage from './pages/licenses';
import i18n from './resources/locales';
import LogoutPage from './pages/logout';
import AgentsTabs from './components/agentsTabs';
import ConfigsPage from './pages/configurations';
import Swagger from './pages/swagger';
import ConfigEditor from './components/configEditor';
import { CONFIG_EDITOR_ACTION_TYPE } from './components/configEditor/utils/constants';
import { TenantIdWrapper } from './components/tenantId';
import AutoEnrollPage from './pages/autoEnroll';
import ClusterPage from './pages/cluster';
import './assets/scss/common.scss';

logEnvironment();

const queryClient = new QueryClient();

localStorage.setItem('SSO_URL', window?.env?.REACT_APP_SSO_URL ?? process.env.REACT_APP_SSO_URL);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <>
        <Provider store={configureStore}>
            <QueryClientProvider client={queryClient}>
                <TenantIdWrapper>
                    <I18nextProvider i18n={i18n}>
                        <BrowserRouter>
                            <Routes>
                                <Route path={ROOT} element={<Layout />}>
                                    <Route path={APP_AGENTS}>
                                        <Route path={APP_AGENT()}>
                                            <Route
                                                path={APP_EXTENSION()}
                                                element={<EditExtensionPage />}
                                            />
                                            <Route
                                                path={APP_MODULE()}
                                                element={<EditModulePage />}
                                            />
                                            <Route index element={<SingleAgentPage />} />
                                        </Route>
                                        <Route path={APP_AGENT_CONFIGS}>
                                            <Route
                                                path={APP_AGENT_CONFIGS_NEW}
                                                element={<AgentsTabs hideTabs />}
                                            >
                                                <Route
                                                    path=""
                                                    element={
                                                        <ConfigEditor
                                                            action={CONFIG_EDITOR_ACTION_TYPE.NEW}
                                                        />
                                                    }
                                                />
                                            </Route>
                                            <Route
                                                path={APP_AGENT_CONFIGS_EDIT}
                                                element={<AgentsTabs hideTabs />}
                                            >
                                                <Route
                                                    path=""
                                                    element={
                                                        <ConfigEditor
                                                            action={CONFIG_EDITOR_ACTION_TYPE.EDIT}
                                                        />
                                                    }
                                                />
                                            </Route>
                                            <Route path="" element={<AgentsTabs />}>
                                                <Route path="" element={<ConfigsPage />} />
                                            </Route>
                                        </Route>
                                        <Route path="" element={<AgentsTabs />}>
                                            <Route path={APP_LICENSES} element={<LicensesPage />} />
                                            <Route
                                                path={APP_AUTO_ENROLL}
                                                element={<AutoEnrollPage />}
                                            />
                                            <Route index element={<AgentsPage />} />
                                            <Route
                                                path={APP_NOTIFICATIONS}
                                                element={<NotificationsPage />}
                                            />
                                            <Route path={APP_SWAGGER} element={<Swagger />} />
                                        </Route>
                                    </Route>
                                    <Route path={APP_LICENSES} element={<LicensesPage />} />
                                    <Route path={APP_SETTINGS}>
                                        <Route index element={<SettingsPage />} />
                                        <Route
                                            path={APP_AGENT_MANAGER_SETTING}
                                            element={<AgentManagerSettings />}
                                        />
                                        <Route path={APP_API_SECURITY} element={<ApiSecurity />} />
                                        <Route
                                            path={APP_AUTENTICATION_SETTINGS}
                                            element={<AuthenticationSettings />}
                                        />
                                        <Route
                                            path={APP_USER_MANAGEMENT}
                                            element={<UserManagement />}
                                        />
                                        <Route path={APP_CLUSTER} element={<ClusterPage />} />
                                    </Route>
                                    <Route path={APP_WHAT_IS_NEW} element={<WhatNewPage />} />
                                    <Route
                                        path={APP_PRODUCT_DOWNLOAD}
                                        element={<ProductDownloadPage />}
                                    />
                                    <Route path={APP_SUPPORT} element={<SupportPage />} />
                                    <Route path={ROOT} element={<App />}>
                                        <Route
                                            index
                                            element={
                                                <Navigate
                                                    to={`${APP_AGENTS}/${APP_AGENT_CONFIGS}`}
                                                    replace
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path={NOT_FOUND} element={<NotFoundPage />} />
                                </Route>
                                <Route path={APP_LOGIN} element={<LoginPage />} />
                                <Route path={LOGOUT} element={<LogoutPage />} />
                                <Route path={APP_TOKEN} element={<AuthRedirectorPage />} />
                                <Route
                                    path={SharedAppPath.OrgChange}
                                    element={<PageChangeOrganization />}
                                />
                                <Route
                                    path="*"
                                    element={<Navigate to={`${ROOT}${NOT_FOUND}`} replace />}
                                />
                            </Routes>
                        </BrowserRouter>
                    </I18nextProvider>
                </TenantIdWrapper>
            </QueryClientProvider>
        </Provider>
        <ToastContainer
            className="toast-message"
            position="bottom-right"
            hideProgressBar
            pauseOnHover
            limit={10}
            closeOnClick={false}
            autoClose={5000}
        />
    </>
);
