import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { parseBool } from '../helpers/functions';

// stores current organization id
export default function useStoreOrgId() {
    const [searchParams] = useSearchParams();
    const orgIdParam = searchParams.get('orgId');
    const [orgId, setOrgId] = useState(orgIdParam);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (orgIdParam) {
            sessionStorage.setItem('orgId', orgId);
            setOrgId(orgIdParam);
        }

        // keeps orgId in URL search params to let common-ui org selector change orgId
        if (
            !orgIdParam &&
            orgId &&
            !parseBool(window?.env?.REACT_APP_ON_PREMISE ?? process.env.REACT_APP_ON_PREMISE)
        ) {
            const to = {
                location: location.pathname,
                search: location.search ? `${location.search}&orgId=${orgId}` : `?orgId=${orgId}`
            };
            navigate(to, { replace: true });
        }

        if (!orgIdParam && !orgId) {
            const storedOrgId = sessionStorage.getItem('orgId');
            if (storedOrgId) setOrgId(storedOrgId);
        }
    }, [orgIdParam, orgId]);

    return { orgId };
}
