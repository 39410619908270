export const toJson = {
    label: 'Convert to JSON',
    name: 'to_json',
    type: 'action',
    extension: [
        {
            module: 'xm_json',
            content: `<Extension json>\n\tModule    xm_json\n</Extension>`
        }
    ],
    match: (content) => /^\s*to_json\s*\(\s*\)\s*;?\s*$/i.test(content),
    inline: true,
    getCode: (action, level) => `${'  '.repeat(level)}to_json();`
};

export default null;
