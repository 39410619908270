import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmPython = {
    label: 'Python Language',
    type: 'sendTo',
    name: 'om_python',
    defaultDirectives: 'Module      om_python',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'PythonCode',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Call',
            type: 'string',
            defaultValue: 'write_data'
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_python'),
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
