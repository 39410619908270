import { ImUdp } from './im_udp';

export const ImUdpSyslog = {
    label: 'Syslog UDP',
    type: 'collect',
    name: 'im_udp',
    defaultDirectives: 'Module      im_udp',
    extension: [
        {
            module: 'xm_syslog',
            content: `<Extension syslog>\n\tModule    xm_syslog\n</Extension>`
        }
    ],
    actions: ['parse_syslog'],
    fields: JSON.parse(JSON.stringify(ImUdp.fields))
};
export default null;
