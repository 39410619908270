import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImZmq = {
    label: 'ZeroMQ',
    name: 'im_zmq',
    type: 'collect',
    defaultDirectives: 'Module      im_zmq',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Address',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'ConnectionType',
            type: 'select',
            options: ['TCP', 'PGM', 'EPGM'],
            defaultValue: '',
            required: true
        },
        {
            name: 'Port',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'SocketType',
            type: 'select',
            options: ['REP', 'DEALER', 'SUB', 'XSUB', 'PULL'],
            defaultValue: '',
            required: true
        },
        {
            name: 'Connect',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'Interface',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SockOpt',
            type: 'string',
            defaultValue: [],
            multiple: true
        },
        ...GENERAL_INPUT_DIRECTIVES('im_zmq'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
