/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@nxlog/common-ui/dist/components/svgs/search';
import useMQLAutoComplete from './utils/useMQLAutoComplete';
import AutocompleteList from './autocompleteList/autocompleteList';
import './index.scss';

function MQLEditor({
    label,
    value,
    onChange,
    onCreate,
    placeholder,
    hideIcon,
    className,
    dataTestId
}) {
    const {
        cursorPosition,
        mqlEditorRef,
        autocomleteListVisible,
        filteredAutoCompleteList,
        selectedIndex,
        handleBlur,
        handleClickItem,
        handleInput,
        handleKeyDown,
        handleKeyUp,
        handlePaste,
        handleFocus,
        isPlaceholderVisible
    } = useMQLAutoComplete({ onChange, onCreate, value });

    const getEditorPosition = () => ({
        top: mqlEditorRef.current?.offsetTop,
        left: mqlEditorRef.current?.offsetLeft,
        width: mqlEditorRef.current?.offsetWidth,
        height: mqlEditorRef.current?.offsetHeight
    });

    const isEmpty = mqlEditorRef.current?.textContent.trim() === '';

    return (
        <div
            className={`mql-editor-container ${
                className
                    ? `${className
                          .split(' ')
                          .filter((c) => c)
                          .map((c) => `${c}-container`)
                          .join(' ')}`
                    : ''
            }`}
        >
            {label ? <label htmlFor={label}>{label}</label> : null}
            <div className="mql-editor-wrapper">
                {hideIcon ? null : (
                    <span className="mql-editor-search-icon">
                        <SearchIcon />
                    </span>
                )}
                <div
                    spellCheck={false}
                    className={`mql-editor ${
                        className ? ` ${className} `.replace(/ has-error /, '') : ''
                    } ${hideIcon ? 'mql-editor-hide-icon' : ''} ${
                        isEmpty ? 'mql-editor-empty' : ''
                    }`}
                    contentEditable
                    ref={mqlEditorRef}
                    onInput={handleInput}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                    onClick={handleInput}
                    onFocus={handleFocus}
                    onPaste={handlePaste}
                    data-testid={dataTestId}
                />
                {isPlaceholderVisible && (
                    <span
                        className="mql-editor-placeholder"
                        onClick={() => mqlEditorRef.current?.focus()}
                        style={getEditorPosition()}
                    >
                        {placeholder}
                    </span>
                )}
                <AutocompleteList
                    cursorPosition={cursorPosition}
                    autocomleteListVisible={autocomleteListVisible}
                    filteredAutoCompleteList={filteredAutoCompleteList}
                    selectedIndex={selectedIndex}
                    handleClickItem={handleClickItem}
                />
            </div>
        </div>
    );
}

MQLEditor.defaultProps = {
    label: '',
    onChange: null,
    onCreate: null,
    hideIcon: false,
    placeholder: 'Search or filter using MQL e.g: status == ok',
    className: '',
    value: null,
    dataTestId: ''
};

MQLEditor.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    onCreate: PropTypes.func,
    placeholder: PropTypes.string,
    hideIcon: PropTypes.bool,
    className: PropTypes.string,
    value: PropTypes.string,
    dataTestId: PropTypes.string
};

export default MQLEditor;
