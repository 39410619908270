import { useState } from 'react';
import { convertSearchTerms } from '../helpers/strings';

export default function useValidateTag({ validTags, tagNormalizer }) {
    const [errorMessage, setErrorMessage] = useState(null);

    const validateTagCallback = async (newTagValue) => {
        if (!newTagValue.trim()) {
            setErrorMessage(null);
            return false;
        }

        const { tag, searchText } = convertSearchTerms(newTagValue.trim());

        const tagIsValid = validTags.indexOf(tagNormalizer(tag?.toLowerCase())) !== -1;

        const errSymbols = searchText.match(/[";]/);
        setErrorMessage(
            searchText && errSymbols
                ? `Symbol ${errSymbols[0]} is not allowed in a search tag`
                : null
        );

        const textIsValid = !errSymbols;

        return tagIsValid && textIsValid;
    };

    return {
        errorMessage,
        validateTagCallback
    };
}
