import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import classNames from 'classnames';
import { getCookie, setCookie } from '../../../utils/helpers/cookies';
import './tabSelector.scss';

function TabContent3() {
    return (
        <>
            <p>TabContent3</p>
            <p>Component Test</p>
        </>
    );
}

function TabSelector(props) {
    const { classname, tabs, defaultKey, id } = props;

    const defaultSavedTab = getCookie('activeAgentsTab');
    const [defaultTab, setDefaultTab] = useState(defaultSavedTab);

    useEffect(() => {
        if (defaultSavedTab) {
            setDefaultTab(defaultSavedTab);
        }
    }, [defaultTab]);

    const setTabInCookies = (elem) => {
        const tabId = elem.target?.id.substring('common-tab-selector-tab-'.length);
        setCookie('activeAgentsTab', tabId, 1);
    };

    const defaultKeyValue = defaultTab || tabs?.[0]?.tabName || defaultKey;

    return (
        <div className={classNames('tab-selector-wrapper', classname)}>
            <Tabs
                data-testid="tab-selector-tabs"
                defaultActiveKey={defaultKeyValue}
                transition={false}
                id={id}
                className={classNames('noHover', 'mb-3')}
                onClick={setTabInCookies}
            >
                {tabs?.map((tab) => (
                    <Tab
                        data-testid="tab-selector-tab"
                        key={tab.tabName}
                        eventKey={tab.tabName}
                        title={tab.title}
                    >
                        {tab.tabContent}
                    </Tab>
                ))}
            </Tabs>
        </div>
    );
}

TabSelector.defaultProps = {
    classname: '',
    id: 'common-tab-selector',
    defaultKey: 'Option 1',
    tabs: [
        { tabName: 'Option 1', title: 'Option 1', tabContent: 'TabContent1' },
        { tabName: 'Option 2', title: 'Option 2', tabContent: 'TabContent2' },
        { tabName: 'Option 3', title: 'Option 3', tabContent: <TabContent3 /> }
    ]
};

TabSelector.propTypes = {
    classname: PropTypes.string,
    id: PropTypes.string,
    defaultKey: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            tabName: PropTypes.string.isRequired,
            tabContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        })
    )
};

export default TabSelector;
