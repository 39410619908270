import React from 'react';
import propTypes from 'prop-types';
import SortIcon from '@nxlog/common-ui/dist/components/svgs/sort';

export default function TableHeader(props) {
    const { data, onFilterTable } = props;

    const handleFilter = (filterParam) => () => {
        onFilterTable(filterParam);
    };

    return (
        <div className="table-header">
            {Object.keys(data)?.map((item) => (
                <div
                    className="single-filter flex-grow-0 flex-shrink-0"
                    style={{ flexBasis: data[item].size }}
                    key={item}
                >
                    <button
                        data-testid="table-header-button"
                        className="filter-button"
                        onClick={handleFilter(item)}
                    >
                        <span className="filter-name">{data[item].name}</span>
                        <SortIcon fill="#8195A9" />
                    </button>
                </div>
            ))}
            <div className="actions-label" style={{ flexBasis: '17%' }}>
                Actions
            </div>
        </div>
    );
}

TableHeader.defaultProps = {
    data: {},
    onFilterTable: () => {}
};

TableHeader.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: propTypes.object,
    onFilterTable: propTypes.func
};
