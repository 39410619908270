import React from 'react';
import propTypes from 'prop-types';
import { Agents } from '@nxlog/common-ui/dist/components/svgs';
import './itemTile.scss';

export default function ItemTile(props) {
    const { heading, value, label, icon } = props;

    return (
        <div className="tile-wrapper">
            <h3 data-testid="item-tile-heading" className="tile-heading">
                {heading}
            </h3>
            <div className="tile-info-block">
                <div className="tile-icon">{icon}</div>
                <div className="tile-info">
                    <p data-testid="item-tile-value" className="info-value">
                        {value}
                    </p>
                    <p data-testid="item-tile-label" className="info-label">
                        {label}
                    </p>
                </div>
            </div>
        </div>
    );
}

ItemTile.defaultProps = {
    heading: '',
    value: '',
    label: '',
    icon: <Agents />
};

ItemTile.propTypes = {
    heading: propTypes.string,
    value: propTypes.oneOfType([propTypes.string, propTypes.number]),
    label: propTypes.string,
    icon: propTypes.element
};
