import { ImTcp } from './im_tcp';

export const ImTcpSyslog = {
    label: 'Syslog TCP',
    type: 'collect',
    name: 'im_tcp',
    defaultDirectives: 'Module      im_tcp',
    extension: [
        {
            module: 'xm_syslog',
            content: `<Extension syslog>\n\tModule    xm_syslog\n</Extension>`
        }
    ],
    actions: ['parse_syslog'],
    fields: JSON.parse(JSON.stringify(ImTcp.fields))
};
export default null;
