import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImKafka = {
    label: 'Kafka',
    name: 'im_kafka',
    type: 'collect',
    defaultDirectives: 'Module      im_kafka',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'BrokerList',
            type: 'string',
            multiple: true,
            required: true,
            separator: ', ',
            defaultValue: [{ value: '' }]
        },
        {
            name: 'Assign',
            type: 'string',
            defaultValue: '',
            required: (item) => !item.Subscribe && !item.Topic
        },
        {
            name: 'Subscribe',
            type: 'string',
            defaultValue: '',
            required: (item) => !item.Assign && !item.Topic
        },
        {
            name: 'Topic',
            type: 'string',
            defaultValue: '',
            required: (item) => !item.Assign && !item.Subscribe
        },
        {
            name: 'CAFile',
            type: 'string',
            defaultValue: '',
            required: (item) => ['ssl', 'sasl_ssl'].includes(item.Protocol)
        },
        {
            name: 'CertFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'GroupID',
            type: 'string',
            defaultValue: '',
            required: (item) => !!item.Subscribe || item.SavePos === 'broker'
        },
        {
            name: 'KeyPass',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Option',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => !!item.Topic,
            name: 'Partition',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'Protocol',
            type: 'select',
            options: ['plaintext', 'ssl', 'sasl_plaintext', 'sasl_ssl'],
            defaultValue: 'plaintext'
        },
        {
            name: 'ReadFromLast',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'SASLKerberosServiceName',
            type: 'string',
            defaultValue: '',
            required: (item) =>
                ['ssl', 'sasl_ssl'].includes(item.Protocol) && item.SASLMechanism === 'GSSAPI'
        },
        {
            name: 'SASLKerberosPrincipal',
            type: 'string',
            defaultValue: '',
            required: (item) =>
                ['ssl', 'sasl_ssl'].includes(item.Protocol) && item.SASLMechanism === 'GSSAPI'
        },
        {
            name: 'SASLKerberosKeytab',
            type: 'string',
            defaultValue: '',
            required: (item) =>
                ['ssl', 'sasl_ssl'].includes(item.Protocol) && item.SASLMechanism === 'GSSAPI'
        },
        {
            if: (item) => ['ssl', 'sasl_ssl'].includes(item.Protocol),
            name: 'SASLMechanism',
            type: 'select',
            options: ['GSSAPI', 'OAUTHBEARER'],
            defaultValue: 'GSSAPI'
        },
        {
            name: 'SASLOAuthBearerClientID',
            type: 'string',
            defaultValue: '',
            required: (item) =>
                ['ssl', 'sasl_ssl'].includes(item.Protocol) && item.SASLMechanism === 'OAUTHBEARER'
        },
        {
            name: 'SASLOAuthBearerClientSecret',
            type: 'string',
            defaultValue: '',
            required: (item) =>
                ['ssl', 'sasl_ssl'].includes(item.Protocol) && item.SASLMechanism === 'OAUTHBEARER'
        },
        {
            name: 'SASLOAuthBearerEndpointURL',
            type: 'string',
            defaultValue: '',
            required: (item) =>
                ['ssl', 'sasl_ssl'].includes(item.Protocol) && item.SASLMechanism === 'OAUTHBEARER'
        },
        {
            if: (item) =>
                ['ssl', 'sasl_ssl'].includes(item.Protocol) && item.SASLMechanism === 'OAUTHBEARER',
            name: 'SASLOAuthBearerExtensions',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) =>
                ['ssl', 'sasl_ssl'].includes(item.Protocol) && item.SASLMechanism === 'OAUTHBEARER',
            name: 'SASLOAuthBearerScope',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SavePos',
            type: 'select',
            options: ['TRUE', 'FALSE', 'cache', 'broker'],
            defaultValue: 'TRUE'
        },
        ...GENERAL_INPUT_DIRECTIVES('im_kafka'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
