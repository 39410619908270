import { ALL_FIELDS } from '../../utils/apiFields';
import { apiService, textContentApiService } from '../apiService';

const excludePartsFilter = '(used-by-templates is empty)';

export async function getTemplatesIds(filter) {
    const data = await apiService.get(
        `/api/v1/templates/ids${
            filter ? `?filter=${filter} and ${excludePartsFilter}` : excludePartsFilter
        }`
    );
    return data;
}

export async function getTemplatesNames(filter) {
    const data = await apiService.get(
        `/api/v1/templates/names${
            filter ? `?filter=${filter} and ${excludePartsFilter}` : excludePartsFilter
        }`
    );
    return data;
}

export async function getTemplates(params = {}) {
    const fullParams = {
        ...params,
        'sort-by': params['sort-by'] || 'id',
        fields: params.fields || ALL_FIELDS,
        filter: params.filter ? `${params.filter} and ${excludePartsFilter}` : excludePartsFilter
    };
    const data = await apiService.get('/api/v1/templates/*', { params: fullParams });
    return data;
}

export async function getTemplatesCount(params = {}) {
    const modifiedParams = {
        ...params,
        filter: params.filter ? `${params.filter} and ${excludePartsFilter}` : excludePartsFilter
    };
    const data = await apiService.get('/api/v1/templates/count', { params: modifiedParams });
    return data;
}

export async function getTemplatesMultiCount([...filters]) {
    const data = await apiService.get(
        `/api/v1/templates/count${
            filters.length
                ? `?filter=${filters.join(`&filter=${excludePartsFilter} and `)}`
                : excludePartsFilter
        }`
    );
    return data;
}

export async function getTemplatesById({ params = {}, id }) {
    const fullParams = {
        ...params,
        'sort-by': params['sort-by'] || 'id',
        fields: params.fields || ALL_FIELDS
    };
    const data = await apiService.get(`/api/v1/templates/${id}`, { params: fullParams });
    return data;
}

export async function updateTemplate(id, body) {
    const data = await textContentApiService.patch(`/api/v1/templates/${id}`, body);
    return data;
}

export async function deleteTemplate(id, filter = null) {
    const data = await apiService.delete(
        `/api/v1/templates/${id}${filter ? `?filter=${filter}` : ''}`
    );
    return data;
}

export async function deleteTemplateAndParts(id) {
    const res = await apiService.get(`/api/v1/templates/${id}?fields=parts`);
    const parts = res.data?.parts;
    const data = await deleteTemplate(id);
    if (Array.isArray(parts) && parts.length > 0) {
        const promiseArray = parts.map((partId) => deleteTemplateAndParts(partId));
        await Promise.all(promiseArray);
    }
    return data;
}

export async function addTemplate(body) {
    const data = await apiService.post('/api/v1/templates', body);
    return data;
}
