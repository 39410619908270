import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import propTypes from 'prop-types';
import AgentsIcon from '@nxlog/common-ui/dist/components/svgs/agents';
import { SubMenuTabs } from '@nxlog/common-ui/components';
import { selectNotificationsCount } from '../../redux/reducers/notifications';
import Header from '../header';

function AgentsTabs({ hideTabs }) {
    const notificationsAll = useSelector(selectNotificationsCount);
    let notificationData = [];

    try {
        const rawNotifications =
            sessionStorage.getItem('notifications')?.replace(/\[settings_link\]/g, '') || '[]';
        notificationData = JSON.parse(rawNotifications);
    } catch (error) {
        /**/
    }

    const errorWarningCount = Array.isArray(notificationData)
        ? notificationData?.filter((item) => item.type === 'error' || item.type === 'warning')
              .length
        : notificationsAll;

    const tabs = [
        { tabName: 'Configurations Overview', tabPath: '/agents/configurations' },
        { tabName: 'Agents', tabPath: '/agents' },
        { tabName: 'Agent Licenses', tabPath: '/agents/licenses' },
        { tabName: 'Auto enroll', tabPath: '/agents/auto-enroll' },
        {
            tabName: (
                <span>
                    Notification
                    {errorWarningCount ? (
                        <span className="notification-count"> ({errorWarningCount})</span>
                    ) : null}{' '}
                </span>
            ),
            tabPath: '/agents/notifications'
        }
        // { tabName: 'API', tabPath: '/agents/api' } - temporary hidden for v1.3.4 release
    ];

    return (
        <div className="w-100" data-testid="AgentsTabs">
            <Header title="Agents" titleIcon={<AgentsIcon fill="#0086C5" />} />
            {hideTabs ? null : <SubMenuTabs tabs={tabs} />}
            <Outlet />
        </div>
    );
}

export default AgentsTabs;

AgentsTabs.defaultProps = {
    hideTabs: false
};

AgentsTabs.propTypes = {
    hideTabs: propTypes.bool
};
