/* eslint-disable no-nested-ternary */
import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import { Dropdown, TableColumn, useTableColumnExtend } from '@nxlog/common-ui/components';

function SelectColumn({
    entry,
    idField = 'id',
    selected = [],
    onChange,
    ids,
    children,
    rowIndex,
    ...props
}) {
    const allSelected = selected.length && (_.isEqual(selected, ids) || selected.includes('*'));
    const id = entry && idField ? entry[idField] : entry;

    const handleSelectOption = (key) => {
        switch (key) {
            case 'all':
                onChange(['*'], true);
                break;
            case 'page':
                if (selected.includes('*')) {
                    onChange([...ids], true);
                } else {
                    onChange([...selected.filter((_id) => !ids.includes(_id)), ...ids]);
                }
                break;
            default:
                onChange([], true);
                break;
        }
    };
    const checked = selected.includes('*') ? !selected.includes(id) : selected.includes(id);
    const handleChange = () => {
        if (selected.includes('*')) {
            if (checked) {
                onChange([...selected, id]);
            } else {
                onChange([...selected.filter((_id) => _id !== id)]);
            }
        } else {
            onChange(_.xor(selected, [id]));
        }
    };
    return useTableColumnExtend(
        {
            className: 'text-center',
            title: (
                <Dropdown
                    options={[
                        { value: 'all', label: 'All' },
                        { value: 'page', label: 'Page' },
                        { value: 'none', label: 'None' }
                    ]}
                    icon={
                        <Form.Check
                            data-testid="select-column-check-all"
                            checked={allSelected}
                            onChange={() => null}
                        />
                    }
                    onClick={handleSelectOption}
                    className="select-column-dropdown"
                />
            ),
            ...props
        },
        <>
            {children ? (_.isFunction(children) ? children(entry, rowIndex) : children) : null}
            <Form.Check
                data-testid="select-column-form-check"
                checked={checked}
                onChange={handleChange}
            />
        </>
    );
}

SelectColumn.propTypes = {
    // List of all identifiers inside the page
    // eslint-disable-next-line react/forbid-prop-types
    ids: propTypes.array,
    // Field to use as identifier, default id.
    idField: propTypes.string,
    // List of selected identifiers
    // eslint-disable-next-line react/forbid-prop-types
    selected: propTypes.array,
    onChange: propTypes.func,
    children: propTypes.func,
    ...TableColumn.propTypes
};

export default SelectColumn;
