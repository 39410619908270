import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Input } from '@nxlog/common-ui/components';
import { templateTagNormalizer } from '../../utils/helpers/strings';
import { createTemplatesFilterStrFromTags } from '../../utils/helpers/tables';
import './searchTemplates.scss';
import useValidateTag from '../../utils/hooks/useValidateTag';

export default function SearchTemplates(props) {
    const { setTagFilterString } = props;
    const [tags, setTags] = useState([]);

    const validTags = ['name', 'content', 'agents', 'comment', 'all'];

    const sampleSearchTags = ['name:', 'module:', 'content:', 'comment:'];

    useEffect(() => {
        setTagFilterString(createTemplatesFilterStrFromTags(tags));
    }, [tags]);

    const { errorMessage, validateTagCallback } = useValidateTag({
        validTags,
        tagNormalizer: templateTagNormalizer
    });

    return (
        <div className="search-templates">
            <Input
                type="text"
                classname="with-search-icon"
                placeholder="Search or filter using tags e.g: module:json"
                isSearch
                isDropdown
                tagName="TemplateTags"
                tags={tags}
                setTags={setTags}
                validTags={validTags}
                validateTag={validateTagCallback}
                sampleSearchTags={sampleSearchTags}
                tagNormalizer={templateTagNormalizer}
                filterDataBySearchTerm={() => {}}
                errorMessage={errorMessage}
            />
        </div>
    );
}

SearchTemplates.defaultProps = {
    setTagFilterString: () => {}
};

SearchTemplates.propTypes = {
    setTagFilterString: propTypes.func
};
