import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseBool } from '../utils/helpers/functions';

// this thunk creator passes error data to rejected action value
export const createAsyncThunkWithErrorHandling = (typePrefix, asyncFunction, options = {}) =>
    createAsyncThunk(
        typePrefix,
        async (params, thunkAPI) => {
            try {
                const response = await asyncFunction(params, thunkAPI);
                return response;
            } catch (error) {
                const errorData =
                    typeof error.response?.data === 'string'
                        ? { message: error.response.data }
                        : error.response?.data;
                return thunkAPI.rejectWithValue(errorData);
            }
        },
        options
    );

const NO_PENDING_CONTROL = parseBool(
    window?.env?.REACT_APP_WITH_NO_PENDING_CONTROL ?? process.env.REACT_APP_WITH_NO_PENDING_CONTROL,
    false
);
const MAX_AWAIT_TIME = 30000;
let pendingThunks = {};

// creates unique string for an action type and a set of params
const namePendingType = (type, params) => {
    const paramString = JSON.stringify(params);
    return type + paramString;
};

export const resetPendingThunks = () => {
    pendingThunks = {};
};

// thunk, created with this helper, would be canceled in case the same action with the same parameters is pending for no more than MAX_AWAIT_TIME
export const createControllableAsyncThunk = (typePrefix, asyncFunction) =>
    NO_PENDING_CONTROL
        ? createAsyncThunkWithErrorHandling(typePrefix, asyncFunction)
        : createAsyncThunk(
              typePrefix,
              async (params, thunkAPI) => {
                  const pendingType = namePendingType(typePrefix, params);

                  const setPending = () => {
                      pendingThunks[pendingType] = Date.now();
                  };

                  const clearPending = () => {
                      delete pendingThunks[pendingType];
                  };

                  try {
                      setPending();
                      const response = await asyncFunction(params, thunkAPI);
                      clearPending();
                      return response;
                  } catch (error) {
                      clearPending();
                      const errorData =
                          typeof error.response?.data === 'string'
                              ? { message: error.response.data }
                              : error.response?.data;
                      return thunkAPI.rejectWithValue(errorData);
                  }
              },
              {
                  // this prop is a condition to cancel thunk before execution
                  condition: (params) => {
                      if (NO_PENDING_CONTROL) {
                          return true;
                      }
                      try {
                          const pendingType = namePendingType(typePrefix, params);
                          if (pendingThunks[pendingType]) {
                              if (Date.now() - pendingThunks[pendingType] > MAX_AWAIT_TIME) {
                                  return true;
                              }
                              return false;
                          }
                          return true;
                      } catch {
                          return true;
                      }
                  }
              }
          );

export default { createAsyncThunkWithErrorHandling, createControllableAsyncThunk };
