/* eslint-disable import/prefer-default-export */

export const MAX_SHOW_SNIPPETS_PER_SECTION = 8;

export const CONFIG_EDITOR_ACTION_TYPE = {
    EDIT: 'edit',
    NEW: 'new'
};

export const CONTENT_TAGS = ['Exec', 'QueryXML'].map((tag) => tag.toLowerCase());

export const ROOT_TAGS = ['input', 'output', 'extension', 'processor', 'route'];

export const MAX_SP_PARAM_FILE_SIZE = 8192;

export const EMPTY_FILE_INPUT_CAPTION = 'No file chosen';
