/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './inputErrorMessagePopover.scss';

export function formatMessage(message) {
    if (!message) return false;
    if (message.split('\n').length > 1) {
        return (
            <ul className="format-message-list-group">
                {message.split('\n').map((line) => (
                    <li key={line}>{line}</li>
                ))}
            </ul>
        );
    }
    return message;
}

function InputErrorMessagePopover({ children, message = '' }) {
    const [showPopover, setShowPopover] = useState(true);

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            setShowPopover(false);
        } else if (event.key === 'Enter') {
            setShowPopover(true);
        }
    };

    return (
        <div
            className="input-error-message-popover"
            onKeyDown={handleKeyDown}
            onFocus={() => setShowPopover(true)}
        >
            {children}
            {message && showPopover ? (
                <div className="input-error-message-popover__message">{formatMessage(message)}</div>
            ) : null}
        </div>
    );
}

InputErrorMessagePopover.propTypes = {
    children: PropTypes.node.isRequired,
    message: PropTypes.string
};

export default InputErrorMessagePopover;
