/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useLayoutProvider, LayoutContext, useApplicationContext } from '@nxlog/common-ui/hooks';
import { AlertBar, ToastNotification } from '@nxlog/common-ui/components';
import { Outlet } from 'react-router-dom';
import { appendCurrentPortToUrl } from '@nxlog/common-ui/utils';
import { Angle, Loader } from '@nxlog/common-ui/dist/components/svgs';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    getFromStorageData,
    putIntoStorageData,
    selectUnavailableStorageItems,
    selectValueFromStorage
} from '../../redux/reducers/storage';
import Sidebar from '../sidebar';
import AuthProvider from '../authProvider';
import {
    getEntitlementsData,
    getModulesData,
    selectIsEnrollmentDisabled,
    selectLicensesAmount,
    selectLicensesLimit,
    setLicensesStatus
} from '../../redux/reducers/licenses';
import {
    removeNotification,
    selectNotifications,
    addPersistedNotification,
    updateNotificationsCount
} from '../../redux/reducers/notifications';
import {
    calculatePercentageOfAgentsLimit,
    getlicensesAlertData,
    getLicensesStatus
} from '../../utils/helpers/functions';
import { getAgentModulesData } from '../../redux/reducers/agents';
import useEnrollmentAddress from '../../utils/hooks/useEnrollmentAddress';
import { useStoreOrgId } from '../../utils/hooks';
import { LICENSES_STATUS, STORAGE_KEYS } from '../../utils/constants/ids';
import UpgradeHostURLFormat from '../../pages/configurations/upgradeHostURLFormat/UpgradeHostURLFormat';
import './layout.scss';

const ALERT_BAR_ID = 'MINDER_ALERT_BAR';

export default function Layout() {
    const { portalUrl, cloudPlatformUrl, isOnpremiseInstance } = useApplicationContext();
    const { orgId } = useStoreOrgId();
    const context = useLayoutProvider();
    const dispatch = useDispatch();
    const notifications = useSelector(selectNotifications);
    const totalNumberOfUsedAgents = useSelector(selectLicensesAmount);
    const limitNumberOfAgents = useSelector(selectLicensesLimit);
    const isEnrollmentDisabled = useSelector(selectIsEnrollmentDisabled);
    const unavailableStorageItems = useSelector(selectUnavailableStorageItems);
    const instanceId = useSelector(selectValueFromStorage(STORAGE_KEYS.INSTANCE_ID));
    const [loading, setLoading] = useState(true);

    const enrollmentHost = useEnrollmentAddress();

    useEffect(() => {
        if (
            Array.isArray(unavailableStorageItems) &&
            unavailableStorageItems.includes(STORAGE_KEYS.IP)
        ) {
            dispatch(putIntoStorageData({ key: STORAGE_KEYS.IP, value: enrollmentHost }));
        }
    }, [
        Array.isArray(unavailableStorageItems) && unavailableStorageItems.includes(STORAGE_KEYS.IP)
    ]);

    useEffect(() => {
        if (
            Array.isArray(unavailableStorageItems) &&
            unavailableStorageItems.includes(STORAGE_KEYS.INSTANCE_ID)
        ) {
            dispatch(putIntoStorageData({ key: STORAGE_KEYS.INSTANCE_ID, value: uuidv4() }));
        }
    }, [
        Array.isArray(unavailableStorageItems) &&
            unavailableStorageItems.includes(STORAGE_KEYS.INSTANCE_ID)
    ]);

    useEffect(() => {
        if (!instanceId) return;
        const savedInstanceId = sessionStorage.getItem(STORAGE_KEYS.INSTANCE_ID);
        if (instanceId !== savedInstanceId) {
            sessionStorage.removeItem('notifications');
            sessionStorage.setItem(STORAGE_KEYS.INSTANCE_ID, instanceId);
        }
        const savedNotifications = JSON.parse(sessionStorage.getItem('notifications')) ?? [];
        if (savedNotifications?.length > 0) {
            dispatch(addPersistedNotification(...savedNotifications));
        }
        dispatch(updateNotificationsCount(savedNotifications.length));
    }, [instanceId]);

    useEffect(() => {
        dispatch(getModulesData());
        dispatch(getEntitlementsData());
        dispatch(getFromStorageData(STORAGE_KEYS.IP)).finally(() => setLoading(false));
        dispatch(getFromStorageData(STORAGE_KEYS.INSTANCE_ID));
        dispatch(getAgentModulesData());
    }, []);

    useEffect(() => {
        if (totalNumberOfUsedAgents && limitNumberOfAgents) {
            const percentage = calculatePercentageOfAgentsLimit(
                totalNumberOfUsedAgents,
                limitNumberOfAgents
            );
            const licenseStatus = getLicensesStatus(percentage, isEnrollmentDisabled);
            const domainUrl = isOnpremiseInstance
                ? cloudPlatformUrl
                : appendCurrentPortToUrl(portalUrl);
            const subscriptionsUrl = `${domainUrl}/orgs/${orgId}/subscriptions`;
            context.setAlertBar(
                ALERT_BAR_ID,
                getlicensesAlertData(licenseStatus, subscriptionsUrl)
            );
            dispatch(setLicensesStatus(LICENSES_STATUS[licenseStatus]));
        }

        return () => {
            context.setAlertBar(ALERT_BAR_ID, null);
        };
    }, [
        totalNumberOfUsedAgents,
        limitNumberOfAgents,
        isEnrollmentDisabled,
        portalUrl,
        cloudPlatformUrl,
        isOnpremiseInstance
    ]);

    const [, setNotificationCounter] = useState({});

    const isHtml = (str) =>
        typeof str === 'string' && str.match(/<(ul|ol|li|p|b|i|a|div|br|span)>/i);

    useEffect(() => {
        notifications?.forEach((notification) => {
            const { type, message, additionalInfo, id } = notification;
            const options = {
                type,
                toastId: id,
                onClose: () => setNotificationCounter((s) => ({ ...s, [id]: 0 }))
            };
            setNotificationCounter((s) => {
                const count = s[id] ?? 0;
                if (count) {
                    toast.update(id, {
                        ...options,
                        render: (
                            <ToastNotification
                                type={type}
                                message={
                                    <>
                                        <div className="multi-notification">
                                            <span className="notification-count">{count + 1}</span>
                                            <span
                                                className="notification-caption"
                                                dangerouslySetInnerHTML={{ __html: message }}
                                            />
                                        </div>
                                        {typeof additionalInfo === 'string' &&
                                        isHtml(additionalInfo) ? (
                                            <NotificationWithHTMLDetails
                                                additionalInfo={additionalInfo}
                                            />
                                        ) : null}
                                    </>
                                }
                                additionalInfo={
                                    typeof additionalInfo === 'string' && !isHtml(additionalInfo)
                                        ? additionalInfo
                                        : null
                                }
                                extendedPreview
                            />
                        )
                    });
                } else {
                    toast(
                        <ToastNotification
                            type={type}
                            message={
                                <>
                                    <span
                                        className="notification-caption"
                                        dangerouslySetInnerHTML={{ __html: message }}
                                    />
                                    {typeof additionalInfo === 'string' &&
                                    isHtml(additionalInfo) ? (
                                        <NotificationWithHTMLDetails
                                            additionalInfo={additionalInfo}
                                        />
                                    ) : null}
                                </>
                            }
                            additionalInfo={
                                typeof additionalInfo === 'string' && !isHtml(additionalInfo)
                                    ? additionalInfo
                                    : null
                            }
                            extendedPreview
                        />,
                        options
                    );
                }
                return { ...s, [id]: count + 1 };
            });
            const newNotification = {
                ...notification
            };
            const initNotificationItem = (item) => {
                if (typeof item.additionalInfo === 'string') {
                    item.additionalInfo = item.additionalInfo.replace(/<\/?[^>]+(>|$)/g, ' ');
                }
                return item;
            };
            newNotification.id = uuidv4();
            const savedNotifications = JSON.parse(sessionStorage.getItem('notifications')) ?? [];
            const currentNotifications = [...savedNotifications, newNotification];
            dispatch(addPersistedNotification(currentNotifications));
            sessionStorage.setItem(
                'notifications',
                JSON.stringify(
                    JSON.parse(JSON.stringify(currentNotifications)).map(initNotificationItem)
                )
            );
            dispatch(removeNotification(notification));
            dispatch(updateNotificationsCount(currentNotifications.length));
        });
    }, [notifications]);

    const hasAlertBar = Boolean(context.alertBar) && Object.keys(context.alertBar).length > 0;

    return (
        <AuthProvider>
            <LayoutContext.Provider value={context}>
                <div
                    className={classNames('page-layout', context.isSidebarCollapsed && 'collapsed')}
                >
                    <div className="container-fluid layout-container">
                        <div className="row min-vh-100">
                            <AlertBar />
                            <div
                                className={classNames(
                                    'col-2 sidebar-column',
                                    hasAlertBar && 'alert-bar-opened'
                                )}
                            >
                                <Sidebar />
                            </div>
                            {loading ? (
                                <div className="col-10 main-column d-flex main-column-loading">
                                    <Loader />
                                </div>
                            ) : (
                                <div
                                    className={classNames(
                                        'col-10 main-column d-flex',
                                        hasAlertBar && 'alert-bar-opened'
                                    )}
                                >
                                    <Outlet />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <UpgradeHostURLFormat />
            </LayoutContext.Provider>
        </AuthProvider>
    );
}

function NotificationWithHTMLDetails({ additionalInfo }) {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const additionalInfoLines =
        typeof additionalInfo === 'string' ? additionalInfo.split('\n') : [];
    if (!additionalInfoLines.length) return null;
    return (
        <div className="notification-additional-info-toggle">
            {isOpen || additionalInfoLines.length === 1 ? (
                <>
                    <div
                        className="notification-additional-info"
                        dangerouslySetInnerHTML={{ __html: additionalInfo }}
                    />
                    {additionalInfoLines.length > 1 && (
                        <button onClick={() => setIsOpen(false)}>
                            {t('view_less')}
                            <Angle.Up />
                        </button>
                    )}
                </>
            ) : (
                <>
                    <div
                        className="notification-additional-info"
                        dangerouslySetInnerHTML={{ __html: additionalInfoLines[0] }}
                    />
                    {additionalInfoLines.length > 1 && (
                        <button onClick={() => setIsOpen(true)}>
                            {t('view_more')}
                            <Angle.Down />
                        </button>
                    )}
                </>
            )}
        </div>
    );
}

NotificationWithHTMLDetails.defaultProps = {
    additionalInfo: null
};

NotificationWithHTMLDetails.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    additionalInfo: propTypes.any
};
