export const exec = {
    label: 'Exec Statement',
    name: 'exec',
    type: 'action',
    getCode: (action, level) => {
        if (!action.code) return '';
        let lines = action.code.split('\n');
        const wList = lines
            .filter((line) => line.match(/[^\s]+/))
            .map((line) => line.length - line.trimStart().length);
        const w = Math.min(...wList);
        if (w) {
            lines = lines.map((line) => line.substring(w));
        }
        const space = '  '.repeat(level);
        return lines.map((line) => `${space}${line}`).join('\n');
    }
};

export default null;
