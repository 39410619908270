import React from 'react';
import PropTypes from 'prop-types';
import { MainHeader as SharedHeader } from '@nxlog/common-ui/app';
import AgentsIcon from '@nxlog/common-ui/dist/components/svgs/agents';
import { logout } from '../../api/endpoints/auth';
import './header.scss';

// async function should return redirectUrl to initiate redirect in MainHeader
const handleLogout = async () => {
    const res = await logout();
    const redirectUrl = res.data?.redirect_url_session_id;
    return redirectUrl ? { redirectUrl } : {};
};

// eslint-disable-next-line no-console
const logError = (error) => console.log(error);

export default function Header(props) {
    const { title, backIcon, handleBackClick, titleIcon } = props;

    return (
        <div className="internal-header-wrapper">
            <SharedHeader
                showOrgs
                logout={handleLogout}
                onLogoutError={logError}
                title={title}
                titleIcon={() => titleIcon}
                handleBackClick={handleBackClick}
                backIcon={backIcon}
            />
        </div>
    );
}

Header.defaultProps = {
    backIcon: null,
    handleBackClick: () => {},
    title: 'Agents',
    titleIcon: <AgentsIcon fill="#0086C5" />
};

Header.propTypes = {
    backIcon: PropTypes.element,
    handleBackClick: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    titleIcon: PropTypes.element
};
