// eslint-disable-next-line import/no-cycle
import { getActionConfigCode } from '../../utils/generateConfigCode';

export const condition = {
    label: 'If-Else Condition',
    name: 'if',
    type: 'action',
    getCode: (action, level) => {
        const code = [];
        const space = '  '.repeat(level);
        if (action.condition_op === 'exists') {
            if (!action.condition_field) return '';
            code.push(`${space}if (${action.condition_field}) {`);
        } else {
            if (!action.condition_field || !action.condition_op || !action.condition_value)
                return '';
            code.push(
                `${space}if (${action.condition_field} ${action.condition_op} ${action.condition_value}) {`
            );
        }
        code.push(...action.then.map((act) => getActionConfigCode(act, level + 1, true)));
        if (action.else.length) {
            code.push(`${space}} else {`);
            code.push(...action.else.map((act) => getActionConfigCode(act, level + 1, true)));
            code.push(`${space}}`);
        } else {
            code.push(`${space}}`);
        }
        return code.join('\n');
    }
};

export default null;
