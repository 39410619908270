import React from 'react';
import { Button, Form } from '@nxlog/common-ui/components';
import { FormProvider } from 'react-hook-form';
import propTypes from 'prop-types';
import FormInput from '../../common/formInput';
import useEnrollmentAddress from '../../../utils/hooks/useEnrollmentAddress';

export default function AgentManagerSettingsForm({
    onSubmit,
    handleBack,
    defaultValues,
    onIpValidation
}) {
    const enrollmentHost = useEnrollmentAddress();

    const ipValidationProps = {
        validate: {
            invalidFormat: onIpValidation
        }
    };

    return (
        <Form onSubmit={onSubmit} FormProvider={FormProvider} defaultValues={defaultValues}>
            <div className="agent-manager-wrapper" data-testid="agent-manager-settings-form">
                <div className="enrollment-ip">
                    <div className="default-ip">
                        <span>Current Enrollment Address: </span>
                        {enrollmentHost}
                    </div>
                    <div className="ip-wrapper">
                        <FormInput
                            name="ip"
                            label="Update Default Enrollment Address:"
                            validationProps={ipValidationProps}
                            placeholder={enrollmentHost}
                        />
                    </div>
                </div>
                <div className="cancel-save-wrapper">
                    <Button onClick={handleBack} className="cancel">
                        Cancel
                    </Button>
                    <Button className="save" type="submit">
                        Save
                    </Button>
                </div>
            </div>
        </Form>
    );
}

AgentManagerSettingsForm.defaultProps = {
    defaultValues: {},
    handleBack: () => {},
    onSubmit: () => {},
    onIpValidation: () => {}
};

AgentManagerSettingsForm.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    defaultValues: propTypes.object,
    handleBack: propTypes.func,
    onSubmit: propTypes.func,
    onIpValidation: propTypes.func
};
