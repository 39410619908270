import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { Table, TableColumn } from '@nxlog/common-ui/components';
import CogIcon from '@nxlog/common-ui/dist/components/svgs/cog';
import TrashIcon from '@nxlog/common-ui/dist/components/svgs/trash';
import { MODULE_STATUS } from '../../../utils/constants/ids';
import './tableModulesModules.scss';

export default function TableStatusModules(props) {
    const { data, title, classname, onDelete, onConfig, onNameClick } = props;

    const handleDelete = (param) => {
        onDelete(param);
    };

    const handleConfig = (param) => {
        onConfig(param);
    };

    const handleNameClick = (param) => {
        onNameClick(param);
    };

    return (
        <div className={classNames('table-status-modules-wrapper', classname)}>
            <div data-testid="table-status-modules-title" className="table-status-modules-title">
                {title}
                <span>({data.length})</span>
            </div>
            <Table
                className="modules-table"
                disableOverlay
                rows={data}
                rowProps={(item) => ({
                    className: classNames(
                        'table-status-modules-row',
                        `${MODULE_STATUS[item.status]}`
                    )
                })}
            >
                <TableColumn name="id" className="column-id" title="#" />
                <TableColumn name="name" className="column-name" title="Name">
                    {(item) => (
                        <button
                            data-testid="table-status-modules-name"
                            className="btn-name"
                            onClick={() => handleNameClick(item.name)}
                        >
                            {item.name}
                        </button>
                    )}
                </TableColumn>
                <TableColumn name="module" className="column-module" title="Type" />
                <TableColumn name="actions" title=" " className="column-actions">
                    {(item) => (
                        <>
                            <button
                                data-testid="table-status-modules-action-config"
                                aria-label="Reset"
                                type="button"
                                className="reset"
                                onClick={() => handleConfig(item.id)}
                            >
                                <CogIcon fill="#4e657e" />
                            </button>
                            <button
                                data-testid="table-status-modules-action-delete"
                                aria-label="Delete"
                                type="button"
                                className="reset delete"
                                onClick={() => handleDelete(item.id)}
                            >
                                <TrashIcon />
                            </button>
                        </>
                    )}
                </TableColumn>
            </Table>
        </div>
    );
}

TableStatusModules.defaultProps = {
    data: [],
    title: 'Input',
    classname: '',
    onDelete: () => {},
    onConfig: () => {},
    onNameClick: () => {}
};

TableStatusModules.propTypes = {
    data: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.string.isRequired,
            name: propTypes.string.isRequired,
            module: propTypes.string.isRequired,
            status: propTypes.string.isRequired
        })
    ),
    title: propTypes.string,
    classname: propTypes.string,
    onDelete: propTypes.func,
    onConfig: propTypes.func,
    onNameClick: propTypes.func
};
