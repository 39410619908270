import { useState } from 'react';
import propTypes from 'prop-types';
import { Button } from '@nxlog/common-ui/components';
import { Angle } from '@nxlog/common-ui/dist/components/svgs';
import WizardStepper from './wizardStepper';
import './wizard.scss';

export default function Wizard({ title, content, endCaption, onEnd }) {
    const [step, setStep] = useState(0);

    const prevStep = () => setStep((s) => s - 1);
    const nextStep = () => setStep((s) => s + 1);

    return (
        <>
            <div className="wizard-content" data-testid="Wizard">
                <h2 className="wizard-title">{title}</h2>
                <WizardStepper
                    steps={content.map((i) => i.step)}
                    current={step}
                    onStepClick={(n) => setStep(n)}
                />
                {content[step]?.markup}
            </div>
            <div className="wizard-footer">
                {step > 0 && (
                    <Button onClick={prevStep} className="button-prev">
                        <Angle.Left />
                        {content[step - 1]?.name || 'Back'}
                    </Button>
                )}
                {step < content.length - 1 ? (
                    <Button onClick={nextStep} className="button-next">
                        {content[step + 1]?.name || 'Next'}
                        <Angle.Right />
                    </Button>
                ) : (
                    <Button onClick={onEnd} className="button-next">
                        {endCaption || 'Finish'}
                        <Angle.Right />
                    </Button>
                )}
            </div>
        </>
    );
}

Wizard.defaultProps = {
    title: '',
    content: [],
    endCaption: '',
    onEnd: null
};

Wizard.propTypes = {
    title: propTypes.oneOfType([propTypes.string, propTypes.object]),
    // eslint-disable-next-line react/forbid-prop-types
    content: propTypes.array,
    endCaption: propTypes.oneOfType([propTypes.string, propTypes.object]),
    onEnd: propTypes.func
};
