import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImBsm = {
    label: 'Basic Security Module (BSM) Audit',
    name: 'im_bsm',
    type: 'collect',
    defaultDirectives: 'Module      im_bsm',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'DeviceFile',
            type: 'string',
            defaultValue: '/dev/auditpipe'
        },
        {
            name: 'EventFile',
            type: 'string',
            defaultValue: '/etc/security/audit_event'
        },
        ...GENERAL_INPUT_DIRECTIVES('im_bsm'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
