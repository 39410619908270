import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { getSolutionPackManifest } from '../../../api/endpoints/solutionPacks';
import { createNotification } from '../../../utils/helpers/functions';
import { TOAST_TYPES } from '../../../utils/constants/ids';
import { addNotifications } from '../../../redux/reducers/notifications';

export const useSolutionPacks = () => {
    const [spInputs, setSPInputs] = useState(null);
    const [spFiles, setSPFiles] = useState(null);
    const [spManifest, setSPManifest] = useState(null);
    const [selectedSP, setSelectedSP] = useState(null);
    const clearSPInputs = () => setSPInputs(null);
    const clearSPFiles = () => setSPFiles(null);
    const clearSelectedSP = () => {
        setSelectedSP(null);
        setSPManifest(null);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchManifest = async () => {
            try {
                const manifest = await getSolutionPackManifest({
                    identifier: selectedSP.identifier,
                    version: selectedSP.version
                });
                setSPInputs(manifest?.params);
                setSPFiles({
                    siemContent: { ...manifest?.siem_content } || {},
                    agentContent: { ...manifest?.agent_content } || {}
                });
                setSPManifest(manifest);
            } catch (err) {
                const errorNote = createNotification(
                    TOAST_TYPES.ERROR,
                    t('notifications_messages.errors.sp_manifest', {
                        name: `${selectedSP.title || selectedSP.identifier}/${selectedSP.version}`
                    }),
                    err.message
                );
                dispatch(addNotifications(errorNote));
                setSelectedSP(null);
            }
        };

        if (selectedSP) {
            fetchManifest();
        }

        if (!selectedSP) {
            clearSPInputs();
            clearSPFiles();
        }
    }, [selectedSP, setSPInputs, setSelectedSP]);

    return {
        spInputs,
        spFiles,
        spManifest,
        selectedSP,
        setSelectedSP,
        clearSelectedSP
    };
};

export default null;
