import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmPerl = {
    label: 'Perl Language',
    type: 'sendTo',
    name: 'om_perl',
    defaultDirectives: 'Module      om_perl',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'PerlCode',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Config',
            type: 'multiline-string-block',
            defaultValue: ''
        },
        {
            name: 'Call',
            type: 'string',
            defaultValue: 'write_data'
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_perl'),
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
