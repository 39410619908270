import { Fragment } from 'react';
import propTypes from 'prop-types';
import './wizardStepper.scss';

export default function WizardStepper({ steps, current, onStepClick }) {
    return (
        <div className="stepper-wrapper" data-testid="WizardStepper">
            {steps.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={i}>
                    {i > 0 && (
                        <div className={i > current ? 'stepper-line' : 'stepper-line done'} />
                    )}
                    <div className="stepper-item">
                        <button
                            onClick={() => onStepClick(i)}
                            className={i > current ? 'stepper-button' : 'stepper-button done'}
                        >
                            {i + 1}
                            <span className="stepper-label">{item}</span>
                        </button>
                    </div>
                </Fragment>
            ))}
        </div>
    );
}

WizardStepper.defaultProps = {
    onStepClick: () => {},
    steps: [],
    current: 0
};

WizardStepper.propTypes = {
    onStepClick: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    steps: propTypes.array,
    current: propTypes.number
};
