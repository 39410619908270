import { useState, useEffect, useCallback } from 'react';

// filter = { path: {'prop1': value1, 'prop2': value1 ...}, filter: {param1: value1, param2: value2 ...}}
const useFilters = (initialFilters = []) => {
    const [filters, setFilters] = useState(initialFilters);

    const saveFiltersToLocalStorage = (allFilters) => {
        localStorage.setItem('appliedFilters', JSON.stringify(allFilters));
    };

    const loadSavedFilters = () => {
        const savedFilters = JSON.parse(localStorage.getItem('appliedFilters')) ?? [];
        if (savedFilters.length > 0) setFilters(savedFilters);
    };

    useEffect(() => {
        loadSavedFilters();
    }, []);

    const applyFilter = useCallback(
        (newFilter) => {
            setFilters((prevFilters) => {
                const updatedFilters = [...prevFilters, newFilter];
                saveFiltersToLocalStorage(updatedFilters);
                return updatedFilters;
            });
        },
        [filters]
    );

    const removeFilter = useCallback(
        (filter) => {
            if (filter === 'all') {
                setFilters([]);
                saveFiltersToLocalStorage([]);
            } else {
                setFilters((prevFilters) => {
                    const removeItems = prevFilters
                        .filter((f) => f.id === filter.id || f.isSubFilter === filter.id)
                        .map((f) => f.id);
                    const updatedFilters = prevFilters.filter(
                        (item) =>
                            !removeItems.includes(item.id) ||
                            initialFilters.map((f) => f.id).includes(item.id)
                    );
                    saveFiltersToLocalStorage(updatedFilters);
                    return updatedFilters;
                });
            }
        },
        [filters, initialFilters]
    );

    const resetFilters = useCallback((resetTo = null) => {
        if (resetTo && Array.isArray(resetTo)) {
            setFilters(resetTo);
            saveFiltersToLocalStorage(resetTo);
        } else {
            setFilters(initialFilters);
            saveFiltersToLocalStorage([]);
        }
    }, []);

    return {
        appliedFilters: filters,
        applyFilter,
        removeFilter,
        resetFilters,
        loadSavedFilters
    };
};

export default useFilters;
