import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmKafka = {
    label: 'Kafka',
    name: 'om_kafka',
    type: 'sendTo',
    defaultDirectives: 'Module      om_kafka',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'BrokerList',
            type: 'string',
            defaultValue: [],
            multiple: true,
            required: true,
            separator: ','
        },
        {
            name: 'Topic',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'CAFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CAThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Compression',
            type: 'select',
            options: ['none', 'gzip', 'snappy', 'lz4'],
            defaultValue: 'none'
        },
        {
            name: 'KeyPass',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Option',
            type: 'string',
            defaultValue: [],
            multiple: true
        },
        {
            name: 'Partition',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'Protocol',
            type: 'select',
            options: ['plaintext', 'ssl', 'sasl_plaintext', 'sasl_ssl'],
            defaultValue: 'plaintext'
        },
        {
            name: 'SASLKerberosServiceName',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SASLKerberosPrincipal',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SASLKerberosKeytab',
            type: 'string',
            defaultValue: ''
        },
        {
            if: (item) => ['sasl_plaintext', 'sasl_ssl'].includes(item.Protocol),
            name: 'SASLMechanism',
            type: 'select',
            options: ['GSSAPI', 'OAUTHBEARER'],
            defaultValue: 'GSSAPI'
        },
        {
            name: 'SASLOAuthBearerClientID',
            type: 'string',
            defaultValue: '',
            required: (item) => ['OAUTHBEARER'].includes(item.SASLMechanism)
        },
        {
            name: 'SASLOAuthBearerClientSecret',
            type: 'string',
            defaultValue: '',
            required: (item) => ['OAUTHBEARER'].includes(item.SASLMechanism)
        },
        {
            name: 'SASLOAuthBearerEndpointURL',
            type: 'string',
            defaultValue: '',
            required: (item) => ['OAUTHBEARER'].includes(item.SASLMechanism)
        },
        {
            name: 'SASLOAuthBearerExtensions',
            type: 'string',
            defaultValue: [],
            multiple: true,
            separator: ','
        },
        {
            if: (item) => ['OAUTHBEARER'].includes(item.SASLMechanism),
            name: 'SASLOAuthBearerScope',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CAPattern',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_kafka'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
