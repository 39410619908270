import { textContentApiService } from '../apiService';

// TODO: possibly needs to migrate from '{ data: value }' to 'value'
export async function putIntoStorage(key, value) {
    const res = await textContentApiService.put(`/api/v1/storage/ui/${key}`, { data: value });
    return res;
}

export async function getFromStorage(key) {
    const res = await textContentApiService.get(`/api/v1/storage/ui/${key}`);
    return res.data;
}

export async function deleteFromStorage(key) {
    const res = await textContentApiService.delete(`/api/v1/storage/ui/${key}`);
    return res;
}
