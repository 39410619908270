import React from 'react';
import propTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { MODULE_LIST } from '../../../../utils/templates';
import './modulesField.scss';

export default function ModulesField(props) {
    const { modules, label, withCount } = props;

    const renderTooltip = (popoverProps) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Popover {...popoverProps} className="modules-popover">
            <h3 data-testid="modules-field-popover-label" className="popover-label">
                {label} ({modules.length})
            </h3>
            <ul className="modules-popover-list">
                {modules.map((m, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={m.type + i} className="modules-popover-item">
                        <span className="module-name">{m.name}</span>
                        {withCount && <span className="module-value">{m.inUse}</span>}
                    </li>
                ))}
            </ul>
        </Popover>
    );

    return (
        <div className="modules-field">
            {modules && (
                <ul className="modules-field-list">
                    {modules.slice(0, 3).map((m, i) => (
                        <li
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${m.type}-${i}`}
                            className="modules-field-item"
                            data-testid="modules-field-item"
                        >
                            <span className="module-name">
                                {m.name}
                                {withCount && <span className="module-value">({m.inUse})</span>}
                            </span>
                        </li>
                    ))}
                    {modules && modules.length > 3 && (
                        <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="bottom"
                            overlay={renderTooltip}
                        >
                            <button type="button" className="modules-popover-button">
                                Show all +{modules.length - 3}
                            </button>
                        </OverlayTrigger>
                    )}
                </ul>
            )}
        </div>
    );
}

ModulesField.defaultProps = {
    modules: MODULE_LIST,
    label: null,
    withCount: false
};

ModulesField.propTypes = {
    modules: propTypes.arrayOf(
        propTypes.shape({
            type: propTypes.string,
            name: propTypes.string,
            connections: propTypes.string,
            inUse: propTypes.number
        })
    ),
    label: propTypes.string,
    withCount: propTypes.bool
};
