import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, Input } from '@nxlog/common-ui/components';
import UsersIcon from '@nxlog/common-ui/dist/components/svgs/users';
import CirclePlusIcon from '@nxlog/common-ui/dist/components/svgs/circle-plus';
import AngleIcon from '@nxlog/common-ui/dist/components/svgs/angle-icon';
import TableUsers from '../../../components/tables/tableUsers';
import Loader from '../../../components/common/loader';
import timeOut from '../../../utils/helpers/functions';
import { UserManagementData, UserManagementInfo } from '../../../resources/static/data';
import { USER_MANAGEMENT_TABLE_ROW_OPTIONS } from '../../../utils/constants/options';
import Header from '../../../components/header';
import '../settings.scss';

function UserInfo(numberOfUsers, name, buttonTitle, filterTable) {
    return (
        <div key={name} className="user-info">
            <h2>{numberOfUsers}</h2>
            <div className="info-component">
                <span>{name}</span>
                <div
                    onClick={() => {
                        filterTable(name);
                    }}
                    role="button"
                    tabIndex="0"
                    onKeyPress={() => {
                        filterTable(name);
                    }}
                >
                    <span>{buttonTitle}</span>
                    <AngleIcon.Down fill="#00a1ed" width="5.12px" height="10px" />
                </div>
            </div>
        </div>
    );
}

export default function UserManagement() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const allData = UserManagementData;
    const [tableData, setTableData] = useState(UserManagementData);
    const [currentPage, setCurrentPage] = useState({ label: 1, value: 1 });
    const [checkedRows, setCheckedRows] = useState(new Map());

    const handleBack = () => {
        navigate('/settings');
    };

    const handleFilterTable = (name) => {
        let typeName = name;
        if (name === 'Warnings') {
            typeName = '';
        } else if (name === 'Untrusted') {
            typeName = 'Basic user';
        }
        const filteredTableData = allData.filter((data) => data.type.includes(typeName));
        setTableData(filteredTableData);
    };

    const handleCheckRow = (row) => {
        const newMap = new Map(checkedRows);
        if (newMap.has(row.id)) {
            newMap.delete(row.id);
        } else {
            newMap.set(row.id, row);
        }
        setCheckedRows(newMap);
    };

    const handleCheckAll = (rows) => {
        const newMap = new Map();
        rows.forEach((item) => {
            newMap.set(item.id, item);
        });
        setCheckedRows(newMap);
    };

    useEffect(() => {
        if (allData.length > 0) setLoading(false);
    }, [allData]);

    timeOut(loading, setLoading);

    return (
        <div className="user-management w-100">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Header
                        backIcon={<AngleIcon.Left fill="#072341" width="14.02px" height="7.18px" />}
                        handleBackClick={handleBack}
                        title="User management"
                        titleIcon={<UsersIcon fill="#0086C5" />}
                    />
                    <Button
                        className="new-agent-btn"
                        type="submit"
                        icon={<CirclePlusIcon fill="#fff" />}
                    >
                        Add new agent
                    </Button>
                    <div className="user-info-wrapper">
                        {UserManagementInfo.map((info) =>
                            UserInfo(info.number, info.role, info.dropdownTitle, handleFilterTable)
                        )}
                    </div>
                    <div className="user-search">
                        <Input
                            type="text"
                            classname="with-search-icon"
                            placeholder="Search or filter using tags e.g: status=OK"
                            isSearch
                            isDropdown
                        />
                        <Dropdown
                            options={USER_MANAGEMENT_TABLE_ROW_OPTIONS}
                            title="Operations"
                            withIcon
                            className="dropdown-actions"
                        />
                    </div>
                    <TableUsers
                        allData={allData}
                        tableData={tableData}
                        checkedRows={checkedRows}
                        onCheckRow={handleCheckRow}
                        onCheckAll={handleCheckAll}
                        setTableData={setTableData}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </>
            )}
        </div>
    );
}
