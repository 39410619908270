import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmHttp = {
    label: 'HTTP(S)',
    type: 'sendTo',
    name: 'om_http',
    defaultDirectives: 'Module      om_http',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'URL',
            type: 'string',
            multiple: true,
            defaultValue: []
        },
        {
            name: 'AddHeader',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'BatchMode',
            type: 'select',
            options: ['none', 'multipart', 'multiline', 'binary', 'jsonarray', 'ndjson'],
            defaultValue: 'none'
        },
        {
            name: 'Compression',
            type: 'select',
            options: ['none', 'gzip', 'deflate'],
            defaultValue: 'none'
        },

        {
            name: 'ContentType',
            type: 'select',
            options: [
                '',
                'text/plain',
                'application/binary',
                'application/json',
                'application/x-ndjson'
            ],
            defaultValue: ''
        },
        {
            name: 'RequestMethod',
            type: 'select',
            options: ['POST', 'PUT'],
            defaultValue: 'POST'
        },
        {
            name: 'HTTPBasicAuthUser',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPBasicAuthPassword',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSAllowExpired',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSAllowUntrusted',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSCADir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCAFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCAThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSearchAllCertStores',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSCertFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCertThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCRLDir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSCRLFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSDHFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSKeyPass',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSSLCipher',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSSLCiphersuites',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'HTTPSSSLCompression',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'HTTPSSSLProtocol',
            type: 'select',
            options: ['SSLv2', 'SSLv3', 'TLSv1', 'TLSv1.1', 'TLSv1.2', 'TLSv1.3'],
            multiple: true,
            separator: ', ',
            defaultValue: []
        },
        {
            name: 'LocalPort',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'OnError',
            type: 'block',
            defaultValue: {},
            block: [
                {
                    name: 'Exec',
                    type: 'multiline-string-block',
                    defaultValue: ''
                },
                {
                    name: 'RetryLimit',
                    type: 'number',
                    defaultValue: ''
                }
            ]
        },
        {
            name: 'Pipeline',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'Proxy',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'Reconnect',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SNI',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UseCNGCertificates',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_http'),
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
