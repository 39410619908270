import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AgentsIcon from '@nxlog/common-ui/dist/components/svgs/agents';
import AngleIcon from '@nxlog/common-ui/dist/components/svgs/angle-icon';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../../components/header';
import { getBaseUrl } from '../../../utils/helpers/functions';
import { URLRegex, ipv4Regex, ipv6Regex } from '../../../utils/constants/regex';
import { addressPortSplit } from '../../../utils/helpers/strings';
import { VALIDATE_PORT_PATTERN } from '../../../utils/constants/formValidation';
import { getFromStorageData, putIntoStorageData } from '../../../redux/reducers/storage';
import { DEFAULT_IP_ADDRESS } from '../../../utils/constants/ids';
import useEnrollmentAddress from '../../../utils/hooks/useEnrollmentAddress';
import AgentManagerSettingsForm from '../../../components/forms/agentManagerSettingsForm';
import '../settings.scss';

export default function AgentManagerSettings() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ip = useEnrollmentAddress();

    const handleBack = () => {
        navigate('/settings');
    };

    useEffect(() => {
        dispatch(getFromStorageData('ip'));
    }, []);

    const isIpValid = (value) => {
        value = value.trim();
        const [address, port] = addressPortSplit(value);

        if (
            !address.match(URLRegex) &&
            !address.match(ipv4Regex) &&
            !address.match(ipv6Regex) &&
            !address.endsWith('localhost')
        ) {
            return 'Invalid Format';
        }

        if (port && !port.match(VALIDATE_PORT_PATTERN.value)) {
            return VALIDATE_PORT_PATTERN.message;
        }

        if (!port) {
            return 'Port is required';
        }

        return true;
    };

    const handleSave = ({ ip: newIpAddress }) => {
        const newTrimmedIp = newIpAddress.trim() || DEFAULT_IP_ADDRESS;
        dispatch(putIntoStorageData({ key: 'ip', value: newTrimmedIp, notification: true }));
    };

    return (
        <div className="settings-page w-100">
            <Header
                title="Agent Manager Settings"
                backIcon={<AngleIcon.Left fill="#072341" width="14.02px" height="7.18px" />}
                handleBackClick={handleBack}
                titleIcon={<AgentsIcon fill="#0086C5" />}
            />
            <AgentManagerSettingsForm
                onSubmit={handleSave}
                handleBack={handleBack}
                defaultValues={{ minder: getBaseUrl(), ip }}
                onIpValidation={isIpValid}
            />
        </div>
    );
}
