/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { WithTooltip } from '@nxlog/common-ui/components';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { isPlainObject, omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { GetApplicationsSubdomains } from '@nxlog/common-ui/dist/utils';
import InfoIcon from '@nxlog/common-ui/dist/components/svgs/info';
import BrowserIcon from '@nxlog/common-ui/dist/components/svgs/browser';
import CogIcon from '@nxlog/common-ui/dist/components/svgs/cog';
import DiplomaIcon from '@nxlog/common-ui/dist/components/svgs/diploma';
import EditIcon from '@nxlog/common-ui/dist/components/svgs/edit';
import LearningIcon from '@nxlog/common-ui/dist/components/svgs/learning';
import PlayIcon from '@nxlog/common-ui/dist/components/svgs/play_circle';
import RestartIcon from '@nxlog/common-ui/dist/components/svgs/restart';
import PlayAltIcon from '@nxlog/common-ui/dist/components/svgs/playalt';
import TrashIcon from '@nxlog/common-ui/dist/components/svgs/trash';
import AgentsIcon from '@nxlog/common-ui/dist/components/svgs/agents';
import ExportIcon from '@nxlog/common-ui/dist/components/svgs/export';
import ClusterIcon from '@nxlog/common-ui/dist/components/svgs/cluster';
import ConfigurationIcon from '@nxlog/common-ui/dist/components/svgs/configuration';
import HomeIcon from '@nxlog/common-ui/dist/components/svgs/home';
import NotificationsIcon from '@nxlog/common-ui/dist/components/svgs/notification';
import SettingsIcon from '@nxlog/common-ui/dist/components/svgs/settings';
import SupportIcon from '@nxlog/common-ui/dist/components/svgs/support';
import WhatIsNewIcon from '@nxlog/common-ui/dist/components/svgs/whatIsNew';
import { hasPermsWithTree } from '@nxlog/common-ui/hooks';
import { LICENSES_STATUS } from '../../constants/ids';
import { ROUTE_PERMISSIONS } from '../../constants/routes';
import { UnassignIcon } from '../../../components/icons';
import { getModuleByName } from '../../../components/configEditor/modules';

export default function timeOut(loading, setLoading, duration = 8000) {
    useEffect(() => {
        const id = setTimeout(() => {
            if (loading) {
                setLoading(false);
            }
        }, duration);

        return () => {
            clearTimeout(id);
        };
    }, []);
}

export function formatDateTime(date, format = 'date.std_with_time') {
    const { t } = useTranslation();
    if (!date?.includes('UTC')) return t(format, { date: new Date(date) });
    const isoDateString = date?.replace(' ', 'T').replace(' UTC', 'Z');
    const dateObject = new Date(isoDateString);
    return t(format, { date: dateObject });
}

export function formatMemoryUsage(bytes = 0) {
    const units = ['GB', 'MB', 'KB', 'Bytes'];
    let val = bytes;
    let unit = units.pop();
    while (val >= 1024 && units.length) {
        val /= 1024;
        unit = units.pop();
    }
    return val ? `${val.toFixed(2)} ${unit}` : '';
}

export function formatMemoryUsageForChart(bytes = 0) {
    const megabytes = bytes / (1024 * 1024);
    return megabytes;
}

export const getStatus = (rowData) => {
    let status;
    if (!rowData?.enrolled) return 'new';
    const online = rowData?.online;
    if (online) status = rowData?.status && rowData?.status.toLowerCase();
    else status = 'offline';
    if (status === 'ok') return 'online';
    return status;
};

export const getConfigStatus = (rowData) => {
    let config;
    if (rowData?.enrolled && rowData?.configured) config = 'Configured';
    else if (rowData?.enrolled) config = 'Enrolled';
    else config = 'New';
    return config;
};

export const getSingleAgentSideBarData = (agentData) => [
    {
        value: agentData?.hostname,
        name: 'Host name'
    },
    {
        value: agentData?.id,
        name: 'ID'
    },
    {
        value: agentData?.polledTime,
        name: 'Agent time'
    },
    {
        value: agentData?.startedTime,
        name: 'Started'
    },
    {
        value: Number(agentData?.cpuLoad).toFixed(2),
        name: 'Load'
    },
    {
        value: agentData?.pid,
        name: 'Pid'
    },
    {
        value: `${formatMemoryUsage(agentData?.memoryUsed)}`,
        name: 'Mem'
    },
    {
        value: agentData?.os,
        name: 'OS'
    },
    {
        value: agentData?.osRelease,
        name: 'OS-Release'
    },
    {
        value: agentData?.arch,
        name: 'Arch'
    },
    {
        value: agentData?.version,
        name: 'Version'
    }
];

export const createWizardRoutes = (wizardRoutes, routes) => {
    const newMap = new Map(routes);
    wizardRoutes.forEach((wizardRoute) => {
        const { name, id } = wizardRoute;
        const inputs = wizardRoute.input.map((input) => {
            const routeData = omit(input, ['moduleType', 'saved']);
            return {
                ...routeData,
                module: routeData.module.value
            };
        });
        const outputs = wizardRoute.output.map((output) => {
            const routeData = omit(output, ['moduleType', 'saved']);
            return {
                ...routeData,
                module: routeData.module.value
            };
        });
        const route = {
            id,
            name,
            priority: '10',
            modules: {
                input: {
                    data: inputs,
                    title: 'Input'
                },
                processor: {
                    data: [],
                    title: 'Processor'
                },
                output: {
                    data: outputs,
                    title: 'Output'
                }
            }
        };
        newMap.set(route.id, { ...route });
    });
    return newMap;
};

export const getOrgId = () => {
    const params = new URL(document?.location).searchParams;
    return params?.get('orgId') || sessionStorage.getItem('orgId');
};

export const getBaseUrl = () => {
    const orgId = getOrgId();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || window.location?.origin;
    return orgId ? `${window.location?.origin}/api/${orgId}` : apiBaseUrl;
};

export function getMinderSubDomain() {
    return GetApplicationsSubdomains().Minder;
}

export const getAgentMenuOptions = ({
    item = null,
    licenseStatus,
    isEnrollmentDisabled = false,
    isSingleAgent = false,
    renewCertificate = false
}) =>
    [
        {
            value: 'restart',
            label: 'Restart',
            icon: <RestartIcon fill="#072341" />,
            hidden: !!item && !item.online
        },
        {
            value: 'start',
            label: 'Start',
            icon: <PlayIcon fill="#26405d" />,
            hidden: !!item && (!item?.online || item.status === 'ok' || !item.persisted)
        },
        {
            value: 'stop',
            label: 'Stop',
            icon: <PlayAltIcon />,
            hidden: !!item && (!item?.online || item.status !== 'ok' || !item.persisted)
        },
        {
            value: 'editConfig',
            label: 'Edit Config',
            icon: <CogIcon />,
            hidden: isSingleAgent || !item?.enrolled
        },
        {
            value: 'updateAndRestart',
            label: 'Update Configuration',
            icon: <LearningIcon />,
            hidden: !!item && !item.enrolled
        },
        {
            value: 'enroll',
            label:
                licenseStatus === LICENSES_STATUS.ERROR || isEnrollmentDisabled ? (
                    <div
                        data-testid="enroll-disabled"
                        className="enrollment-text"
                        onClick={(e) => e.stopPropagation()}
                    >
                        Enroll{' '}
                        <WithTooltip
                            message="Enrollment is disabled due to exhausted licenses."
                            placement="bottom"
                        >
                            <InfoIcon />
                        </WithTooltip>
                    </div>
                ) : (
                    'Enroll'
                ),
            icon: <EditIcon />,
            className:
                licenseStatus === LICENSES_STATUS.ERROR || isEnrollmentDisabled
                    ? 'disabled-enrollment'
                    : undefined,
            hidden: item?.enrolled
        },
        {
            value: 'enrollwithtemplate',
            label:
                licenseStatus === LICENSES_STATUS.ERROR || isEnrollmentDisabled ? (
                    <div
                        data-testid="enroll-with-template-disabled"
                        className="enrollment-text"
                        onClick={(e) => e.stopPropagation()}
                    >
                        Enroll with template
                        <WithTooltip
                            message="Enrollment is disabled due to exhausted licenses."
                            placement="bottom"
                        >
                            <InfoIcon />
                        </WithTooltip>
                    </div>
                ) : (
                    'Enroll with template'
                ),
            icon: <EditIcon />,
            className:
                licenseStatus === LICENSES_STATUS.ERROR || isEnrollmentDisabled
                    ? 'disabled-enrollment'
                    : undefined,
            hidden: item?.enrolled
        },
        {
            value: 'assignTemplate',
            label: 'Assign Template',
            icon: <BrowserIcon />,
            hidden: !!item && !(item.enrolled || item.configured)
        },
        {
            value: 'unassignTemplate',
            label: 'Unassign Template',
            icon: <UnassignIcon />,
            hidden: !!item && !item.templateName
        },
        {
            value: 'refresh',
            label: 'Refresh Status',
            icon: <AgentsIcon />,
            hidden: !item
        },
        {
            value: 'renewCertificate',
            label: 'Renew Certificate',
            icon: <DiplomaIcon />,
            hidden: !renewCertificate && !(item?.enrolled || item?.configured)
        },
        {
            value: 'export',
            label: 'Export',
            icon: <ExportIcon />,
            hidden: !item
        },
        {
            value: 'viewLogs',
            label: 'View Logs',
            icon: <FontAwesomeIcon icon={faFile} />,
            hidden: !item
        },
        {
            value: 'delete',
            label: 'Unenroll',
            icon: <TrashIcon />,
            className: 'delete',
            hidden: !!item && !item.enrolled
        }
    ].filter((option) => !option.hidden);

export function clearAgentTags() {
    localStorage.setItem('appliedAgentTags', JSON.stringify([]));
}

export const parseBool = (value, fallback = value) => {
    if (value === true || value === 'true' || value === 'TRUE') {
        return true;
    }
    if (value === false || value === 'false' || value === 'FALSE') {
        return false;
    }
    return fallback;
};

export const getLicensesStatus = (percentage, isEnrollmentDisabled) => {
    if (percentage < -20 || isEnrollmentDisabled) {
        return LICENSES_STATUS.ERROR;
    }

    if (percentage <= 0 && percentage >= -20) {
        return LICENSES_STATUS.WARNING;
    }

    if (percentage <= 20) {
        return LICENSES_STATUS.SOFT_WARNING;
    }

    return LICENSES_STATUS.OK;
};

export const calculatePercentageOfAgentsLimit = (totalNumberOfUsedAgents, limitNumberOfAgents) =>
    100 -
    Math.round(((totalNumberOfUsedAgents || 0) * 100) / Math.max(1, limitNumberOfAgents || 1));

const getLicenseAlertMessage = (status) => {
    const messages = {
        OK: null,
        SOFT_WARNING: i18next.t('alert_messages.licenses.almost_out_of_licenses'),
        WARNING: i18next.t('alert_messages.licenses.out_of_licenses'),
        ERROR: i18next.t('alert_messages.licenses.out_of_buffer')
    };

    return messages[status];
};

export const getlicensesAlertData = (licenseStatus, redirectURL = null) => {
    const alertMessage = getLicenseAlertMessage(licenseStatus);

    if (alertMessage) {
        return {
            level: 'error',
            message: alertMessage,
            action: {
                label: 'click here',
                apply: () => window.location.assign(redirectURL)
            },
            fixed: false,
            duration_seconds: null
        };
    }

    return null;
};

export const createNotification = (type, message, additionalInfo) => ({
    id: uuidv4(),
    type,
    message,
    additionalInfo,
    date: String(new Date())
});

export const createNotificationInThunk = (payload, type, messageHeader, additionalInfo = null) => {
    const messageArr = [];
    if (messageHeader) {
        messageArr.push(messageHeader);
    }
    if (typeof payload === 'string') {
        messageArr.push(payload);
    }
    if (payload?.message) {
        messageArr.push(payload?.message);
    }
    const caption = messageArr[0];
    let info = additionalInfo;
    if (typeof payload === 'object') {
        const { message, help, ...restProps } = payload;
        const additional =
            Object.keys(restProps)?.length > 0
                ? `Error parameters:\n${Object.entries(restProps)
                      .map((pair) => pair.join(': '))
                      .join('\n')}`
                : null;
        info = help ? `${help}\n${additional || ''}` : additional;
    }
    if (info) {
        messageArr.push(info);
    }
    const fullInfo = messageArr.slice(1).join('\n');
    return createNotification(type, caption, fullInfo);
};

export const isIPAddress = (input) => {
    const ipRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/;
    return ipRegex.test(input);
};

export const ensureFetchedUserHasOrgId = (user, orgId) => {
    const orgExists = user.orgs?.find((o) => o.id === orgId);
    if (orgExists) {
        return user;
    }
    const duplicatedUserData = JSON.parse(JSON.stringify(user));
    duplicatedUserData.orgs?.forEach((o) => {
        o.id = o.id ?? orgId;
    });
    return duplicatedUserData;
};

export const checkRoutePerms = (route, user, orgId) => {
    const routePerm = ROUTE_PERMISSIONS.get(route);
    if (routePerm) {
        const permsTree = user?.orgs?.[orgId]?.permsTree;
        if (!permsTree) {
            return false;
        }
        return hasPermsWithTree(Object.entries(routePerm), permsTree);
    }
    return true;
};

export function isAddressIncomplete(address) {
    const parts = address.split('.');
    return parts.length < 4 && Number(parts[0]) <= 255;
}

export function convertToCIDR(address) {
    const isIPv6 = address.includes(':');
    const parts = isIPv6 ? address.split(':') : address.split('.');
    const cidr = isIPv6 ? parts.length * 16 : parts.length * 8;

    const padLength = isIPv6 ? 8 : 4;
    const zeroPad = isIPv6 ? '0000' : '0';
    while (parts.length < padLength) {
        parts.push(zeroPad);
    }

    const cidrAddress = parts.join(isIPv6 ? ':' : '.');
    return `${cidrAddress}/${cidr}`;
}

export function isCIDR(ip) {
    const cidrRegex = /^(\d{1,3}\.){3}\d{1,3}\/\d{1,2}$/;
    if (!cidrRegex.test(ip)) {
        return false;
    }

    const parts = ip.split('/');
    const subnet = parts[0];
    const prefixLength = parseInt(parts[1], 10);

    if (Number.isNaN(prefixLength) || prefixLength < 0 || prefixLength > 32) {
        return false;
    }

    const subnetParts = subnet.split('.').map((part) => parseInt(part, 10));
    return subnetParts.every((part) => part >= 0 && part <= 255);
}

export function convertIPToUrl(ipString, protocol = 'https') {
    const prefix = /^(http(s)?:\/\/)/.test(ipString) ? '' : `${protocol}://`;
    return prefix + ipString;
}

export const mergeArraysBySameField = (arr1, arr2, field) => {
    const map = new Map(arr1.map((object) => [object[field], object]));

    const recMergeObjs = (obj1, obj2) => {
        const res = { ...obj1 };
        if (obj2) {
            Object.entries(obj2).forEach(([key, value]) => {
                if (isPlainObject(value)) {
                    res[key] = { ...recMergeObjs(res[key], value) };
                } else {
                    res[key] = value;
                }
            });
            return res;
        }
        return res;
    };

    arr2.forEach((object) => {
        if (map.has(object[field])) {
            map.set(object[field], recMergeObjs(map.get(object[field]), object));
        } else {
            map.set(object[field], object);
        }
    });

    return Array.from(map.values());
};

export function extractAdditionalInfoFromErrorMessage(error) {
    let errorMessage;
    try {
        let errorObj;
        if (typeof error === 'string') {
            errorObj = JSON.parse(error);
        } else if (typeof error === 'object') {
            errorObj = error;
        } else {
            errorObj = {
                message: 'unknown error'
            };
        }
        errorMessage = errorObj.message;
        if (Array.isArray(errorObj.notEntitledFeatures) && errorObj.notEntitledFeatures.length) {
            errorMessage = `<b>${errorObj.message}:</b>\n<ul>${errorObj.notEntitledFeatures
                .map((feature) => {
                    const mdl = getModuleByName(feature.feature);
                    return `<li>"${mdl ? mdl.label : feature.feature}" (Entitled: ${
                        feature.entitledLimit
                    }, Used: ${feature.currentUsage}, Requested: ${
                        feature.requestedQuantity
                    })</li>`;
                })
                .join('')}</ul>`;
        }
    } catch {
        errorMessage = typeof error === 'string' ? error : null;
    }
    return errorMessage || 'unknown error';
}

export const processMultiAgentResponse = (response) => {
    const errors = [];
    let count = 0;
    if (Array.isArray(response)) {
        const messages = response
            .filter((item) => item.status === 'error')
            .map(({ error }) => extractAdditionalInfoFromErrorMessage(error));

        // this will group unique errors by agents quantity
        messages
            .filter((message, index, array) => array.indexOf(message) === index)
            .forEach((message) => {
                const messageCount = messages.filter((m) => m === message).length;
                errors.push({ message, count: messageCount });
            });

        count = response.length - messages.length;
    } else {
        return { invalidJSONResponse: true };
    }

    return { count, errors };
};

export function normalizeConfig(data) {
    const normalized = {};

    Object.entries(data).forEach(([key, value]) => {
        const partOfKey = key.split('-')[1];

        if (partOfKey === 'port' || partOfKey === 'host') {
            normalized[partOfKey] = normalized[partOfKey]
                ? [...normalized[partOfKey], value]
                : [value];
        } else {
            normalized[key] = value;
        }
    });

    return normalized;
}

export function GetMenuIcon(props) {
    const { iconName, fill } = props;
    let icon;

    switch (iconName) {
        case 'HomeIcon':
            icon = <HomeIcon fill={fill} />;
            break;
        case 'AgentsIcon':
            icon = <AgentsIcon fill={fill} />;
            break;
        case 'ClusterIcon':
            icon = <ClusterIcon fill={fill} />;
            break;
        case 'ConfigurationIcon':
            icon = <ConfigurationIcon fill={fill} />;
            break;
        case 'LicensesIcon':
            icon = <ConfigurationIcon fill={fill} />;
            break;
        case 'NotificationsIcon':
            icon = <NotificationsIcon fill={fill} />;
            break;
        case 'WhatIsNewIcon':
            icon = <WhatIsNewIcon fill={fill} />;
            break;
        case 'SupportIcon':
            icon = <SupportIcon fill={fill} />;
            break;
        case 'SettingsIcon':
            icon = <SettingsIcon fill={fill} />;
            break;
        default:
            icon = null;
            break;
    }

    return icon;
}

/**
 * Asserts various test cases for validating address inputs including FQDN, IPv4, IPv6, variables, and ports.
 */
export const validateHost = (host) =>
    !![
        {
            if: /^%[a-zA-Z_]+[a-zA-Z0-9_]*%$/,
            then: /./
        },
        {
            if: /\.[0-9]+$/,
            then: /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/
        },
        {
            if: /:[0-9a-fA-F]*$/,
            then: /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/
        },
        {
            if: /:[0-9a-fA-F]*\]$/,
            then: /^\[(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))\]$/
        },
        {
            if: /(\.[a-zA-Z]+[a-zA-Z0-9-_]*$|%)/,
            // eslint-disable-next-line no-useless-escape
            then: /^(?!-)(?!.*\.\.)([A-Za-z0-9-]{1,}\.)*[A-Za-z0-9-]{1,}\.[A-Za-z]{2,}$/
        },
        {
            if: /^[a-zA-Z]+[a-zA-Z0-9-_]*$/,
            then: /./
        }
    ].find((rule) => {
        if (rule.if.test(`${host}`.trim())) {
            if (rule.then.test(`${host}`.trim())) {
                return true;
            }
        }
        return false;
    });

export const validatePort = (host) => (port) => {
    if (!`${port || ''}`.trim()) {
        return !!`${host}`.trim().match(/^%[a-zA-Z0-9_-]+%$/);
    }
    if (
        !`${port}`.trim().match(/^[0-9]+$/) ||
        parseInt(port, 10) > 65535 ||
        parseInt(port, 10) < 1
    ) {
        if (!`${port}`.trim().match(/^%[a-zA-Z0-9_-]+%$/)) {
            return false;
        }
    }
    return true;
};

export function validateHostPort(address) {
    const list = address.trim().split(':');
    const port = list.length > 1 ? list.pop() : '';
    const host = list.join(':');
    if (!validateHost(host) && !validateHost(address)) {
        if (!validatePort(host)(port)) {
            return 'It should be in HOST:PORT format, where HOST is IPv4 or [IPv6] or FQDN.\nPlease enter a valid port number between 1 and 65535.';
        }
        return 'It should be in HOST:PORT format, where HOST is IPv4 or [IPv6] or FQDN.';
    }
    if (!validatePort(host)(port)) {
        return 'Please enter a valid port number between 1 and 65535';
    }
    return true;
}
