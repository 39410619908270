import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import propTypes from 'prop-types';
import { Button } from '@nxlog/common-ui/components';
import FormInput from '../../../common/formInput';
import FormSelect from '../../../common/formSelect';
import AddressesInputs from '../addressesInputs';
import FormTextarea from '../../../common/formTextarea';
import { useTableModuleContext } from '../../../../utils/context/tableModuleContext';
import { VALIDATE_NO_BLANK_SPACES_PATTERN } from '../../../../utils/constants/formValidation';
import '../configTemplateForm.scss';

const onCancel = (reset) =>
    useCallback(() => {
        reset();
    }, [reset]);

const handleShowConfig = (getValues, toggleConfig) =>
    useCallback(() => {
        const data = getValues();
        toggleConfig(data);
    }, [getValues, toggleConfig]);

export default function ConfigTemplateFormContent({ hideName }) {
    const { toggleConfig, isLoading, handleSaveAsTemplateClick } = useTableModuleContext();
    const { getValues, reset, formState } = useFormContext();
    const handleShowConfigCallback = handleShowConfig(getValues, toggleConfig);
    const onCancelCallback = onCancel(reset);

    return (
        <>
            <div data-testid="config-template-form-content" className="config-template-form-block">
                <div className="config-template-form-block-title">Global</div>
                {hideName ? null : (
                    <FormInput
                        classname="config-template-form-input"
                        name="name"
                        label="Template Name"
                        placeholder="Template Name"
                        required
                        pattern={VALIDATE_NO_BLANK_SPACES_PATTERN}
                    />
                )}
                <FormSelect
                    options={[
                        {
                            value: 'connect',
                            label: 'Connect to agent manager'
                        },
                        {
                            value: 'listen',
                            label: 'Listen (accept agent manager connection)'
                        }
                    ]}
                    classname="config-template-form-input"
                    name="connectType"
                    label="Agent connection type"
                    placeholder="Agent connection type"
                    required
                />
                <AddressesInputs name="address" />
                <FormSelect
                    options={[
                        {
                            value: 'INFO',
                            label: 'Info'
                        },
                        {
                            value: 'CRITICAL',
                            label: 'Critical'
                        },
                        {
                            value: 'ERROR',
                            label: 'Error'
                        },
                        {
                            value: 'DEBUG',
                            label: 'Debug'
                        }
                    ]}
                    classname="config-template-form-input"
                    name="logLevel"
                    label="Log level"
                    placeholder="Log level"
                    required
                />
                <FormTextarea
                    classname="config-template-form-text-area"
                    name="verbatimConfig"
                    label="Verbatim config"
                    placeholder="Configuration"
                />
            </div>
            <div className="config-template-form-block">
                <div className="config-template-form-block-title">Comment</div>
                <FormTextarea name="comment" label="" placeholder="Comment" />
            </div>
            <div className="config-template-form-buttons-wrapper">
                <Button className="cancel" onClick={onCancelCallback} disabled={!formState.isDirty}>
                    Cancel
                </Button>
                <div className="flex">
                    <Button
                        className="config"
                        onClick={() => handleSaveAsTemplateClick(getValues())}
                    >
                        Create a template based on this config
                    </Button>
                    <Button
                        data-testid="config-template-form-view"
                        className="config"
                        onClick={handleShowConfigCallback}
                    >
                        View config
                    </Button>
                    <Button
                        data-testid="config-template-form-submit"
                        type="submit"
                        isLoading={isLoading}
                        disabled={isLoading || !formState.isDirty}
                        className="template"
                    >
                        Save Configuration
                    </Button>
                </div>
            </div>
        </>
    );
}

ConfigTemplateFormContent.defaultProps = {
    hideName: false
};

ConfigTemplateFormContent.propTypes = {
    hideName: propTypes.bool
};
