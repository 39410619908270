import { configureStore } from '@reduxjs/toolkit';
import agentsReducer from './reducers/agents';
import templatesReducer from './reducers/templates';
import licensesReducer from './reducers/licenses';
import notificationsReducer from './reducers/notifications';
import storageReducer from './reducers/storage';
import solutionPacksReducer from './reducers/solutionPacks';
import {
    watchProcessesMiddleware,
    updateAgentFilterCountsMiddleware,
    updateTemplateFilterCountsMiddleware
} from './middlewares';

export function createAppStore(preloadedState = null) {
    return configureStore({
        reducer: {
            agents: agentsReducer,
            templates: templatesReducer,
            licenses: licensesReducer,
            notifications: notificationsReducer,
            storage: storageReducer,
            solutionPacks: solutionPacksReducer
        },
        ...(preloadedState ? { preloadedState } : null),
        middleware: (getDefaultMiddleware) => {
            const middlewares = getDefaultMiddleware();

            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line global-require,import/no-extraneous-dependencies
                const { createLogger } = require('redux-logger');

                middlewares.push(createLogger({ collapsed: true }));
            }

            return middlewares.concat(
                watchProcessesMiddleware,
                updateAgentFilterCountsMiddleware,
                updateTemplateFilterCountsMiddleware
            );
        }
    });
}

const store = createAppStore();

export default store;
