import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ToastNotification } from '@nxlog/common-ui/components';
import { t } from 'i18next';
import CirclePlusIcon from '@nxlog/common-ui/dist/components/svgs/circle-plus';
import { useNavigate } from 'react-router-dom';
import TableConfigurations from '../../components/tables/tableConfigurations';
import Loader from '../../components/common/loader';
import {
    getTemplatesItemsCount,
    getTemplatesData,
    selectTemplatesCount,
    selectTemplatesPerPage,
    deleteTemplateData,
    selectTemplatesFilterItems,
    selectTemplates
} from '../../redux/reducers/templates';
import { createFilterString, createSortString } from '../../utils/helpers/strings';
import AssignAgentsSideBar from './assignAgents';
import FiltersDropdown from '../../components/tables/tableConfigurations/filtersDropdown';
import SearchTemplates from '../../components/searchTemplates';
import useDuplicateConfig from './hooks/useDuplicateConfig';
import useSortTable from '../../utils/hooks/useSortTable';
import { APP_AGENT_CONFIGS_EDIT, APP_AGENT_CONFIGS_NEW } from '../../utils/constants/routes';
import useRepeatWhenVisible from '../../utils/hooks/useRepeatWhenVisible';
import './configurations.scss';
import { deleteTemplate, getTemplates, getTemplatesCount } from '../../api/endpoints/templates';
import SelectedTemplates from '../../components/selectedTemplates';
import useSelections from '../../utils/hooks/useSelections';
import { CONFIG_TABLE_ROW_OPTIONS } from '../../utils/constants/options';
import { duplicateTemplateWithParts } from '../../api/operations';

export default function ConfigsPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const itemsPerPage = useSelector(selectTemplatesPerPage);
    const itemsCount = useSelector(selectTemplatesCount);
    const filterList = useSelector(selectTemplatesFilterItems);
    const templates = useSelector(selectTemplates);

    const [refresh, setRefresh] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentConfigs, setCurrentConfigs] = useState(null);
    const [tagFilterString, setTagFilterString] = useState('');
    const [filters, setFilters] = useState([]);
    const [loadingRow, setLoadingRow] = useState(false);

    const initialSorting = [{ name: 'name', order: 'asc' }];
    const { sortFields, addSortField } = useSortTable(initialSorting);

    const stringsArr = [];

    if (Array.isArray(filters) && filters.length) {
        stringsArr.push(`(${createFilterString(filters)})`);
    }

    if (tagFilterString) {
        stringsArr.push(`(${tagFilterString})`);
    }

    const filter = stringsArr.length > 0 ? stringsArr.join(' and ') : '';
    const sortStr = createSortString(sortFields);
    const params = { filter, 'sort-by': sortStr };

    const refreshData = () => {
        // no configs refetch, while assignAgents is open
        if (currentConfigs) return;

        params.offset = itemsPerPage * (currentPage - 1);
        params.limit = itemsPerPage;

        dispatch(getTemplatesItemsCount({ filter }));
        dispatch(getTemplatesData(params));
    };

    // triggers data fetch
    useEffect(() => {
        if (refresh) {
            refreshData();
            setRefresh(false);
        }
    }, [refresh]);

    useRepeatWhenVisible(() => {
        setRefresh(true);
    }, 10000);

    useEffect(() => {
        refreshData();
    }, [itemsPerPage, currentPage, filters, tagFilterString, sortFields]);

    const { handleCopyConfig } = useDuplicateConfig({ setLoading: setLoadingRow });

    const CONFIG_TABLE_ROW_FUNCTIONS = {
        edit: (rows) => {
            navigate(APP_AGENT_CONFIGS_EDIT.replace(':id', rows[0]?.id));
        },
        assignAgents: (rows) => {
            setCurrentConfigs(rows);
        },
        duplicate: (rows) => {
            handleCopyConfig(rows[0]);
        },
        delete: (rows) => {
            dispatch(deleteTemplateData(rows))
                .unwrap()
                .then((res) => {
                    res.forEach((item) => {
                        if (item.status === 'rejected') {
                            toast.error(
                                <ToastNotification
                                    type="error"
                                    message={`${item.name} ${item.message}`}
                                />
                            );
                        } else {
                            toast.success(
                                <ToastNotification
                                    type="error"
                                    message={`${item.name} successfully deleted`}
                                />
                            );
                        }
                    });
                });
        }
    };

    const handleSelectOption = useCallback((value, rows) => {
        CONFIG_TABLE_ROW_FUNCTIONS[value](rows);
    }, []);

    const handleResetConfigs = () => {
        setCurrentConfigs(null);
    };

    const handleAddConfig = () => {
        navigate(APP_AGENT_CONFIGS_NEW);
    };

    const selectedTemplates = useSelections({
        currentPageIds: Array.isArray(templates) ? templates.map((item) => item.id) : [],
        filterString: filter,
        params: {
            'sort-by': params['sort-by']
        },
        loadData: getTemplates,
        loadCount: getTemplatesCount,
        errorMessage: 'notifications_messages.errors.templates_loading_request_error',
        actions: CONFIG_TABLE_ROW_OPTIONS.filter((action) =>
            ['duplicate', 'delete'].includes(action.value)
        ),
        onRefresh: () => {
            setCurrentPage(1);
            params.offset = itemsPerPage * (currentPage - 1);
            params.limit = itemsPerPage;
            dispatch(getTemplatesItemsCount({ filter }));
            dispatch(getTemplatesData(params));
        },
        onAction: async (action, selectedFilter, forEach) => {
            try {
                switch (action) {
                    case 'delete': {
                        const { data } = await deleteTemplate('*', selectedFilter);
                        if (Array.isArray(data)) {
                            const successCount = data.filter(
                                (item) => item.status === 'success'
                            ).length;
                            const errors = data
                                .filter((item) => item.status === 'error')
                                .map((item) => {
                                    try {
                                        return JSON.parse(item.error);
                                    } catch (e) {
                                        return { message: item.error || '' };
                                    }
                                })
                                .map((item) => item?.message || ``);
                            const messages = [];
                            if (successCount) {
                                messages.push({
                                    type: 'success',
                                    message: t(
                                        'notifications_messages.success.templates_deleted_success',
                                        { count: successCount }
                                    )
                                });
                            }
                            if (errors.length) {
                                messages.push({
                                    type: 'error',
                                    message: t(
                                        'notifications_messages.errors.templates_delete_error',
                                        { count: errors.length }
                                    ),
                                    additionalInfo: `<ul>${errors
                                        .filter(
                                            (item, index, array) =>
                                                item && array.indexOf(item) === index
                                        )
                                        .map((item) => `<li>${item}</li>`)
                                        .join('')}</ul>`
                                });
                            }
                            return { messages };
                        }
                        return {};
                    }
                    case 'duplicate':
                        await forEach(
                            (item) => duplicateTemplateWithParts(item.id, `${item.name} copy`),
                            'notifications_messages.success.templates_duplicate_success',
                            'notifications_messages.errors.templates_duplicate_error'
                        );
                        return {};
                    default:
                        return null;
                }
            } catch (e) {
                throw new Error(t('notifications_messages.errors.templates_request_error'));
            }
        }
    });

    const { selectedAll, onSelectAllChange, onCheckedChanged, isChecked, openSelectModal } =
        selectedTemplates;

    return (
        <div className="config-page w-100" data-testid="config-page">
            <div className="w-100">
                <div data-testid="configs-page-search" className="config-search">
                    <SearchTemplates setTagFilterString={setTagFilterString} />
                    <Button
                        data-testid="configs-page-add"
                        className="add-new-config"
                        icon={<CirclePlusIcon fill="#fff" />}
                        onClick={handleAddConfig}
                    >
                        Add new template
                    </Button>
                </div>
                <FiltersDropdown
                    filters={filters}
                    setFilters={setFilters}
                    filterList={filterList}
                />
                <TableConfigurations
                    itemsCount={itemsCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    onSelectOption={handleSelectOption}
                    onSort={addSortField}
                    sortFields={sortFields}
                    onSelectAllChange={onSelectAllChange}
                    selectedAll={selectedAll}
                    onCheckedChanged={onCheckedChanged}
                    isChecked={isChecked}
                />
                {loadingRow && (
                    <div className="row-loader" data-testid="loader">
                        <Loader />
                    </div>
                )}
            </div>
            {openSelectModal ? <SelectedTemplates selectedTemplates={selectedTemplates} /> : null}
            <AssignAgentsSideBar
                isOpen={!!currentConfigs}
                onAssignAgent={refreshData}
                onToggle={handleResetConfigs}
                templates={currentConfigs}
            />
        </div>
    );
}
