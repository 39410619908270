import React from 'react';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';
import './rightSidebar.scss';

export default function RightSidebar(props) {
    const { children, metrics, title, subHeader, classname, isVisible, onClose } = props;

    return (
        <div
            data-testid="right-sidebar"
            className={classNames('right-sidebar', classname, isVisible && 'visible')}
        >
            <div className="right-sidebar-layout" />
            <div className="right-sidebar-content">
                <div className="header">
                    <div data-testid="right-sidebar-title">{title}</div>
                    <button
                        data-testid="right-sidebar-close"
                        type="button"
                        onClick={onClose}
                        aria-label="Close"
                    >
                        <FontAwesomeIcon icon={faXmarkCircle} className="close-icon" />
                    </button>
                </div>
                {subHeader}
                {children}
                <div className="metrics">
                    <div className="metrics-header">Metrics, status</div>
                    {metrics?.map((item) => (
                        <div key={item.value} className="metrics-item">
                            <div
                                data-testid="right-sidebar-metric-name"
                                className="metrics-item-name"
                            >
                                {item.name}
                            </div>
                            <div
                                data-testid="right-sidebar-metric-value"
                                className="metrics-item-value"
                            >
                                {item.value}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

RightSidebar.defaultProps = {
    classname: '',
    title: 'Agent details',
    message: null,
    children: null,
    subHeader: null,
    metrics: [],
    isVisible: false,
    onClose: () => {}
};

RightSidebar.propTypes = {
    classname: propTypes.string,
    isVisible: propTypes.bool,
    onClose: propTypes.func,
    title: propTypes.string,
    message: propTypes.shape({
        type: propTypes.string.isRequired,
        text: propTypes.string.isRequired
    }),
    metrics: propTypes.arrayOf(
        propTypes.shape({
            value: propTypes.string.isRequired,
            name: propTypes.string.isRequired
        })
    ),
    subHeader: propTypes.oneOfType([propTypes.element, propTypes.array]),
    children: propTypes.oneOfType([propTypes.element, propTypes.array])
};
