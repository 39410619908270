/* eslint-disable no-useless-escape */
import {
    EXPECTED_TOKEN,
    RegexSplitMQLKeywords,
    allOperators,
    fieldsText,
    groupOperators,
    notOperators
} from '.';

export default function parseMQLList(mql) {
    const list = `${mql || ''}`.match(RegexSplitMQLKeywords) || [];
    const result = [];
    const openParenthesis = [];
    let whating = [EXPECTED_TOKEN.field, EXPECTED_TOKEN.notOperators];
    let lastField = null;
    let lastOperator = null;
    let lastOperatorIsOpen = false;
    const operatorsGroups = [];
    while (list.length) {
        const item = list.shift();
        if (
            fieldsText.includes(`${item}`.trim().toLowerCase()) &&
            whating.includes(EXPECTED_TOKEN.field)
        ) {
            result.push({
                type: EXPECTED_TOKEN.field,
                value: item
            });
            whating = [EXPECTED_TOKEN.operator, EXPECTED_TOKEN.notOperators];
            lastField = `${item}`.trim().toLowerCase();
            lastOperator = null;
            lastOperatorIsOpen = false;
        } else if (
            allOperators.includes(`${item}`.trim().toUpperCase()) &&
            whating.includes(EXPECTED_TOKEN.operator)
        ) {
            result.push({
                type: EXPECTED_TOKEN.operator,
                value: item
            });
            whating = [EXPECTED_TOKEN.value, EXPECTED_TOKEN.notOperators];
            lastOperator = `${item}`.trim().toUpperCase();
            lastOperatorIsOpen = false;
        } else if (
            groupOperators.includes(`${item}`.trim().toUpperCase()) &&
            whating.includes(EXPECTED_TOKEN.groupOperators)
        ) {
            result.push({
                type: EXPECTED_TOKEN.groupOperators,
                value: item
            });
            whating = [EXPECTED_TOKEN.field, EXPECTED_TOKEN.notOperators];
            lastOperator = `${item}`.trim().toUpperCase();
            lastOperatorIsOpen = false;
        } else if (
            notOperators.includes(`${item}`.trim().toUpperCase()) &&
            whating.includes(EXPECTED_TOKEN.notOperators)
        ) {
            result.push({
                type: EXPECTED_TOKEN.notOperators,
                value: item
            });
            whating = [EXPECTED_TOKEN.field, EXPECTED_TOKEN.notOperators];
            lastOperator = `${item}`.trim().toUpperCase();
            lastOperatorIsOpen = false;
        } else if (['('].includes(`${item}`.trim())) {
            result.push({
                type: `${item}`.trim(),
                value: `${item}`.trim()
            });
            openParenthesis.push(`${item}`.trim());
            lastOperatorIsOpen = true;
            if (lastOperator) operatorsGroups.push(lastOperator);
        } else if ([','].includes(`${item}`.trim()) && whating.includes(EXPECTED_TOKEN.comma)) {
            result.push({
                type: `${item}`.trim(),
                value: `${item}`.trim()
            });
            whating = [EXPECTED_TOKEN.value];
        } else if (
            [')'].includes(`${item}`.trim()) &&
            whating.includes(EXPECTED_TOKEN.closeParenthesis)
        ) {
            if (openParenthesis.length) {
                openParenthesis.pop();
                operatorsGroups.pop();
                result.push({
                    type: `${item}`.trim(),
                    value: `${item}`.trim()
                });
            }
            if (!openParenthesis.length) {
                whating = [EXPECTED_TOKEN.groupOperators];
            } else if (
                operatorsGroups.length &&
                `${operatorsGroups[operatorsGroups.length - 1]}`.trim().toUpperCase() === 'IN'
            ) {
                whating = [EXPECTED_TOKEN.closeParenthesis, EXPECTED_TOKEN.comma];
            } else {
                whating = [EXPECTED_TOKEN.closeParenthesis, EXPECTED_TOKEN.groupOperators];
            }
            lastOperator = null;
            lastOperatorIsOpen = false;
        } else {
            result.push({
                type: EXPECTED_TOKEN.value,
                value: item
            });
            if (openParenthesis.length) {
                if (
                    operatorsGroups.length &&
                    `${operatorsGroups[operatorsGroups.length - 1]}`.trim().toUpperCase() === 'IN'
                ) {
                    whating = [EXPECTED_TOKEN.closeParenthesis, EXPECTED_TOKEN.comma];
                } else {
                    whating = [EXPECTED_TOKEN.closeParenthesis, EXPECTED_TOKEN.groupOperators];
                }
            } else {
                whating = [EXPECTED_TOKEN.groupOperators];
            }
        }
    }
    return {
        result,
        whating,
        lastField,
        lastOperator,
        openParenthesis,
        operatorsGroups,
        lastOperatorIsOpen
    };
}
