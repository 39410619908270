import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImGo = {
    label: 'Go Language',
    name: 'im_go',
    type: 'collect',
    defaultDirectives: 'Module      im_go',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ImportLib',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'ImportFunc',
            type: 'string',
            defaultValue: '',
            required: true
        },
        ...GENERAL_INPUT_DIRECTIVES('im_go'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
