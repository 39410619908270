import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { putIntoStorageData, selectValueFromStorage } from '../../redux/reducers/storage';
import { getMinderSubDomain, parseBool } from '../helpers/functions';
import useStoreTenantId from './useStoreTenantId';
import { DEFAULT_ENROLLMENT_PORT, STORAGE_KEYS } from '../constants/ids';

function isOnprem() {
    return parseBool(window?.env?.REACT_APP_ON_PREMISE ?? process.env.REACT_APP_ON_PREMISE);
}

function getPort() {
    const airGapped = parseBool(
        window?.env?.REACT_APP_AIR_GAPPED ?? process.env.REACT_APP_AIR_GAPPED
    );

    const port =
        DEFAULT_ENROLLMENT_PORT[
            (() => {
                if (airGapped) {
                    return 'STANDALONE';
                }
                if (isOnprem()) {
                    return 'ONPREM';
                }
                return 'PLATFORM';
            })()
        ];
    return port;
}

export default function useEnrollmentAddress() {
    const minderSubDomain = getMinderSubDomain();
    const { tenantId, hostname } = useStoreTenantId();
    const defaultEnrollmentAddress = `${hostname}${getPort() ? `:${getPort()}` : ''}`;
    // if persisted IP, then return it with port
    const persistedIp = useSelector(selectValueFromStorage('ip'));

    // Check enrollment address format : https://nxlogdev.atlassian.net/browse/MIND-2000
    const isOldFormat = persistedIp && isOnprem() && `${persistedIp}`.match(/^agents\.tenant\..+/i);
    const newFormat = isOldFormat && `${persistedIp}`.replace(/^agents\.tenant\./i, 'agents.');

    const dispatch = useDispatch();
    useEffect(() => {
        if (!isOldFormat) return;
        dispatch(putIntoStorageData({ key: STORAGE_KEYS.IP, value: newFormat }));
    }, [isOldFormat]);
    if (isOldFormat) {
        return newFormat;
    }

    if (persistedIp) {
        return persistedIp.split(':').length === 1
            ? `${persistedIp}${getPort() ? `:${getPort()}` : ''}`
            : persistedIp;
    }

    // if no persisted IP
    const tenantExpression = tenantId === 'tenant' ? tenantId : `${tenantId}.tenant`;
    return defaultEnrollmentAddress.replace(
        new RegExp(`^${minderSubDomain}\\.(tenant\\.)?`, 'i'),
        `${minderSubDomain}.${tenantId && !isOnprem() ? `${tenantExpression}.` : ''}`
    );
}

export function useRelayAddress() {
    const minderSubDomain = getMinderSubDomain();
    const relayPort = DEFAULT_ENROLLMENT_PORT.RELAY;
    const enrollmentHost = useEnrollmentAddress()?.replace(getPort(), relayPort);

    const parts = enrollmentHost.split('.');

    if (parts[0] === minderSubDomain) {
        parts[0] = 'relay';
    }

    return parts.join('.');
}
