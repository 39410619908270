import { ALL_FIELDS } from '../../utils/apiFields';
import { parseAgentResourse } from '../../utils/helpers/strings';
import { apiService } from '../apiService';
import { getSolutionPackManifest } from '../endpoints/solutionPacks';
import { addTemplate, deleteTemplateAndParts } from '../endpoints/templates';

export default async function duplicateTemplateWithParts(id, newName) {
    const response = await apiService.get(`/api/v1/templates/${id}?fields=${ALL_FIELDS}`);
    const {
        // eslint-disable-next-line no-unused-vars
        id: _,
        name,
        parts,
        content,
        comment,
        tags,
        builderConfig,
        solutionPackKeys,
        agentResources
    } = response.data;
    let newParts = null;
    if (Array.isArray(parts) && parts.length > 0) {
        const promiseArray = parts.map((partId) => duplicateTemplateWithParts(partId));

        const settledArray = await Promise.allSettled(promiseArray);

        const resArray = settledArray
            .filter(({ status }) => status === 'fulfilled')
            .map(({ value }) => value);
        const rejArray = settledArray
            .filter(({ status }) => status === 'rejected')
            .map(({ reason }) => reason);

        // on error delete created templates and throw an error
        if (rejArray.length > 0) {
            resArray.forEach((res) => deleteTemplateAndParts(res.data.id));
            return Promise.reject(new Error(rejArray.map((err) => err.message).join('/n')));
        }
        newParts = resArray.map((res) => res.data.id);
    }
    const newBody = {
        name: newName || `${name || 'new'} copy`,
        parts: newParts,
        content: newParts ? null : content,
        comment,
        tags,
        builder_config: builderConfig || null
    };

    // the condition of template being an SP part
    if (!newBody.parts && Array.isArray(solutionPackKeys) && solutionPackKeys.length > 0) {
        // solutionPackKeys is an array of strings,
        // currently it contains only one SP per template
        const spKey = solutionPackKeys[0].split('/');

        // get filenames and contents of agent resources
        const agentResEntries = agentResources
            ? agentResources.reduce((arr, record) => [...arr, parseAgentResourse(record)], [])
            : [];

        // get resource names linked to filenames in SP manifest
        const agentResObject = {};
        try {
            const manifest = await getSolutionPackManifest({
                identifier: spKey[0],
                version: spKey[1]
            });
            agentResEntries.forEach(([fileName, fileContent]) => {
                const [resName] = Object.entries(manifest.agent_resource_paths).find(
                    (entry) => entry[1] === fileName
                );
                agentResObject[resName] = fileContent;
            });
        } catch {
            throw new Error(
                `Cannot duplicate, the SP agent resource path ${solutionPackKeys[0]} is unavailable to read.`
            );
        }

        newBody.solution_pack_data = {
            key: { id: spKey[0], version: spKey[1] || '0.0.0' },
            agent_resources: agentResObject
        };
    }
    const postData = await addTemplate(newBody);
    const newId = postData.data;
    return { data: { id: newId, ...newBody, solutionPackKeys, content } };
}
