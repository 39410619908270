import propTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const AgentType = propTypes.shape({
    id: propTypes.string,
    status: propTypes.string,
    hostname: propTypes.string,
    data: propTypes.shape({
        add_time: propTypes.string,
        address: propTypes.string,
        certificate: propTypes.shape({
            digest_sha256: propTypes.string,
            not_after: propTypes.string,
            not_before: propTypes.string,
            subject: propTypes.string
        }),
        // eslint-disable-next-line react/forbid-prop-types
        labels: propTypes.object,
        // eslint-disable-next-line react/forbid-prop-types
        modules: propTypes.object,
        connect_time: propTypes.string,
        enrolled: propTypes.bool,
        hostname: propTypes.string,
        node_address: propTypes.string,
        online: propTypes.bool,
        os: propTypes.string,
        persist: propTypes.bool,
        poll_time: propTypes.string,
        start_time: propTypes.string,
        version: propTypes.string
    })
});
