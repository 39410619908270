import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import { Checkbox, Dropdown } from '@nxlog/common-ui/components';
import { FilterIcon } from '../../../icons';
import './filtersDropdown.scss';

export default function FiltersDropdown(props) {
    const { filters, setFilters, filterList } = props;

    const handleSelectFilter = useCallback(
        (value) => {
            const currentFilter = filterList.find((f) => f.id === value);
            if (!currentFilter) {
                return;
            }
            const filter = filters.find((f) => f.id === value);
            if (filter) {
                setFilters(filters.filter((f) => f.id !== value));
            } else {
                setFilters([...filters, currentFilter]);
            }
        },
        [filters, setFilters]
    );

    const filterOptions = filterList.map(({ id, label, itemsCount }) => ({
        value: id,
        label: (
            <Checkbox
                id={id}
                checked={!!filters.find((f) => f.id === id)}
                textLabel={
                    <>
                        <span>{label}</span>
                        <span>{itemsCount ?? '--'}</span>
                    </>
                }
                disabled={!itemsCount && itemsCount !== 0}
            />
        ),
        className: 'filter-checkbox'
    }));

    const dropdownOptions = [
        { value: 'close', label: 'Status', className: 'filter-group-name' },
        ...filterOptions
    ];

    return (
        <Dropdown
            options={dropdownOptions}
            // TODO: add the filter icon to lib
            icon={
                <>
                    <FilterIcon />
                    Show filters
                </>
            }
            onClick={handleSelectFilter}
            className="dropdown-filters"
            buttonClassname="filters-toggle-button"
            dropdownMenuClassName="dropdown-filters-list"
            closeMenuOnSelect={false}
        />
    );
}

FiltersDropdown.defaultProps = {
    filters: [],
    setFilters: () => {},
    filterList: []
};

FiltersDropdown.propTypes = {
    filters: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.string,
            // eslint-disable-next-line react/forbid-prop-types
            filter: propTypes.oneOfType([propTypes.string, propTypes.object]),
            label: propTypes.string,
            itemsCount: propTypes.oneOfType([propTypes.string, propTypes.number])
        })
    ),
    setFilters: propTypes.func,
    filterList: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.string,
            // eslint-disable-next-line react/forbid-prop-types
            filter: propTypes.oneOfType([propTypes.string, propTypes.object]),
            label: propTypes.string,
            itemsCount: propTypes.oneOfType([propTypes.string, propTypes.number])
        })
    )
};
