import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@nxlog/common-ui/components';
import propTypes from 'prop-types';
import { useToggle } from '@nxlog/common-ui/hooks';
import { AgentType } from '../../../utils/constants/propTypes';
import { capitalizeFirstLetter } from '../../../utils/helpers/strings';
import { formatMemoryUsage, getConfigStatus, getStatus } from '../../../utils/helpers/functions';
import '../selectedAgents.scss';

export default function SelectedAgent(props) {
    const { data, onRemove } = props;
    const [isOpen, setIsOpen] = useToggle();
    const handleRemove = () => {
        onRemove(data?.id);
    };

    return (
        <div
            className={classNames('selected-agent', isOpen && 'with-border')}
            data-testid="SelectedAgent"
        >
            <div className={classNames('title-container', isOpen && 'ghost-title')}>
                <div className="selected-agent-title">
                    <p className="name">{data?.hostname}</p>
                    <p className="ip">{data?.address}</p>
                </div>
                <div className="d-flex">
                    <Button
                        ghostBtn
                        className="agent-action"
                        icon={
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                className={isOpen ? 'active-button' : ''}
                            />
                        }
                        onClick={setIsOpen}
                    />
                    <Button
                        data-testid="selected-agent-remove"
                        ghostBtn
                        className="agent-action close-button"
                        icon={<FontAwesomeIcon icon={faXmark} />}
                        onClick={handleRemove}
                    />
                </div>
            </div>
            <div className={classNames('agent-info', isOpen && 'visible')}>
                <div className="agent-info-item">
                    <div className="title">Status</div>
                    <div className={classNames('text', `status-${getStatus(data) || 'error'}`)}>
                        {capitalizeFirstLetter(getStatus(data)) ?? '-'}
                    </div>
                </div>
                <div className="agent-info-item">
                    <div className="title">EPS</div>
                    <div className="text">{Number(data?.eventsPerSecond).toFixed(2) ?? '-'}</div>
                </div>
                <div className="agent-info-item">
                    <div className="title">Load</div>
                    <div className="text">{Number(data?.cpuLoad).toFixed(2) ?? '-'}</div>
                </div>
                <div className="agent-info-item">
                    <div className="title">Memory use</div>
                    <div className="text">{`${formatMemoryUsage(data?.memoryUsed)}` ?? '-'}</div>
                </div>
                <div className="agent-info-item">
                    <div className="title">Deployment state</div>
                    <div className="text">{getConfigStatus(data)}</div>
                </div>
            </div>
        </div>
    );
}

SelectedAgent.propTypes = {
    data: AgentType.isRequired,
    onRemove: propTypes.func.isRequired
};
