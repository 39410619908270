import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import propTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import EyeIcon from '@nxlog/common-ui/dist/components/svgs/eye';
import LockIcon from '@nxlog/common-ui/dist/components/svgs/lock';
import AngleIcon from '@nxlog/common-ui/dist/components/svgs/angle-icon';
import { Button, Modal } from '@nxlog/common-ui/components';
import FormSwitch from '../../../components/common/formSwitch';
import Header from '../../../components/header';
import './authenticationSettings.scss';

function TestAuthenticationModal({ onHide, show }) {
    const [type, setType] = useState('password');

    const handleSetType = () => {
        if (type === 'password') setType('text');
        else setType('password');
    };
    return (
        <Modal
            isShown={show}
            centered={false}
            classname="auth-dialog"
            contentClassName="modal-right-md"
            onClose={onHide}
            title="Test authentication"
        >
            <Form>
                <Form.Group>
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="text" placeholder="Test 123" />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="password-label">Password</Form.Label>
                    <div className="password-container">
                        <Form.Control
                            className="password-input"
                            type={type}
                            placeholder="********"
                        />
                        <Button
                            className="password-toggle"
                            onClick={handleSetType}
                            icon={<EyeIcon />}
                        />
                    </div>
                </Form.Group>
                <div className="modal-footer">
                    <Button>Cancel</Button>
                    <Button>Test</Button>
                </div>
            </Form>
        </Modal>
    );
}

export default function AuthenticationSettings() {
    const [modalShow, setModalShow] = React.useState(false);
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/settings');
    };

    return (
        <div className="settings-page w-100">
            <Header
                backIcon={<AngleIcon.Left fill="#072341" width="14.02px" height="7.18px" />}
                handleBackClick={handleBack}
                title="Authentication settings"
                titleIcon={<LockIcon fill="#0086C5" />}
            />
            <div className="authentication-wrapper">
                <div className="authentication">
                    <h1>LDAP integration</h1>
                    <div className="row ssl-verify-block">
                        <div className="col enable-ssl">
                            <span>Enable SSL</span>
                            <FormSwitch defaultValue />
                        </div>
                        <div className="col verify-certificate">
                            <span>Verify certificate</span>
                            <FormSwitch defaultValue />
                        </div>
                    </div>
                    <div>
                        <div className="row address-port-block">
                            <Form.Group className="col">
                                <Form.Label>Address</Form.Label>
                                <Form.Control type="text" placeholder="127.0.0.1" />
                            </Form.Group>
                            <Form.Group className="col">
                                <Form.Label>Port</Form.Label>
                                <Form.Control type="text" placeholder="4041" />
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-3">
                            <Form.Label>LDAP bind DN</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>LDAP password</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>LDAP base DN</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                    </div>
                </div>
                <div className="cancel-save-wrapper">
                    <Button onClick={handleBack} className="cancel">
                        Cancel
                    </Button>
                    <Button onClick={() => setModalShow(true)} className="save">
                        Save and verify settings
                    </Button>
                </div>
            </div>
            <TestAuthenticationModal show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    );
}

TestAuthenticationModal.defaultProps = {
    onHide: () => {},
    show: false
};

TestAuthenticationModal.propTypes = {
    onHide: propTypes.func,
    show: propTypes.bool
};
