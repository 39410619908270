import React, { useContext } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from '@nxlog/common-ui/dist/components/button';
import CircleX from '@nxlog/common-ui/dist/components/svgs/circle-x';
import { DragAndDropContext } from '../utils/helper';
import RouteEditorModuleItem from '../routeEditorModuleItem';

function ExtensionModals() {
    const {
        showExtensionModal,
        setShowExtensionModal,
        item,
        update,
        getActionsByDependency,
        isSolutionPack
    } = useContext(DragAndDropContext);

    const deleteExtension = (ext) => () => {
        item.extensions = item.extensions.filter((e) => e !== ext);
        item.update();
    };

    const extensions = item.extensions.filter(
        (ext) => ext.moduleName && !`${ext.moduleName}`.match(/^xm_admin/i)
    );

    return (
        <Offcanvas
            show={!!showExtensionModal}
            onHide={() => setShowExtensionModal(false)}
            placement="end"
            className="configeditor-sidebar"
        >
            <Offcanvas.Body className="configeditor-sidebar-body">
                <div className="configeditor-form-container">
                    <div className="configeditor-sidebar-header">
                        <Offcanvas.Title>Extensions</Offcanvas.Title>
                        <Button
                            icon={<CircleX outlined fill="#072341" />}
                            onClick={() => setShowExtensionModal(false)}
                            data-testid="configeditor-close-btn"
                        />
                    </div>
                    <div className="scroll">
                        <div className="ConfigEditorSectionExtensionsList">
                            {extensions.map((ext, i) => (
                                <RouteEditorModuleItem
                                    key={ext.key || `${ext.moduleName}-${i}`}
                                    mdl={{ module: ext, actions: [] }}
                                    type="extension"
                                    update={update}
                                    onDelete={deleteExtension(ext)}
                                    isUsed={getActionsByDependency(ext.moduleName).length > 0}
                                    isSolutionPack={isSolutionPack}
                                    hideActions
                                />
                            ))}
                            {extensions.length === 0 && (
                                <div className="configeditor-no-extensions">
                                    No extensions found
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="configeditor-sidebar-actions">
                        <Button
                            type="submit"
                            data-testid="configeditor-footer-close-btn"
                            onClick={() => setShowExtensionModal(false)}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default ExtensionModals;
