import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ProgressBar } from 'react-bootstrap';
import './enrollmentProgress.scss';
import { Button } from '@nxlog/common-ui/components';
import { useSelector } from 'react-redux';
import AgentsIcon from '@nxlog/common-ui/dist/components/svgs/agents';
import PauseIcon from '@nxlog/common-ui/dist/components/svgs/pause';
import PlayIcon from '@nxlog/common-ui/dist/components/svgs/play_circle';
import SettingsIcon from '@nxlog/common-ui/dist/components/svgs/settings';
import numberWithSpaces from '../../utils/helpers/numbers';
import { selectOperationStatus } from '../../redux/reducers/agents';

export default function EnrollmentProgress() {
    const [enrollmentRunning, setEnrollmentRunning] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [completedAmount, setCompletedAmount] = useState(0);
    const [withErrors, setWithErrors] = useState(0);

    const agentsOperations = useSelector(selectOperationStatus);

    useEffect(() => {
        const enrollments = Object.entries(agentsOperations).filter((entry) => !!entry[1].enroll);
        const successful = enrollments.filter(([, { enroll }]) => enroll === 'success').length;
        const errors = enrollments.filter(([, { enroll }]) => enroll === 'error').length;
        setEnrollmentRunning(!!(enrollments.length - (successful + errors)));
        setTotalAmount(enrollments.length);
        setCompletedAmount(successful + errors);
        setWithErrors(!!errors.length);
    }, [agentsOperations]);

    const [isEnrollmentInprogress, toggleEnrollmentInprogress] = useState(false);
    const [isEnrollmentDetailsCollapsed, toggleEnrollmentDetails] = useState(false);

    // Will never be called, because button disabled
    // istanbul ignore next
    const handleEnrollmentInprogress = () => {
        toggleEnrollmentInprogress(!isEnrollmentInprogress);
    };
    const handleToggleEnrollment = () => {
        toggleEnrollmentDetails(!isEnrollmentDetailsCollapsed);
    };

    useEffect(() => {
        if (totalAmount < completedAmount) {
            toggleEnrollmentInprogress(!isEnrollmentInprogress);
        } else toggleEnrollmentInprogress(!isEnrollmentInprogress);
        if (enrollmentRunning) toggleEnrollmentInprogress(true);
        else toggleEnrollmentInprogress(false);
    }, [totalAmount, completedAmount]);

    const completedCaption = withErrors
        ? 'Enrollment completed with errors'
        : 'Enrollment complete';
    const caption = isEnrollmentInprogress ? 'Enrollment in progress' : completedCaption;

    return (
        enrollmentRunning && (
            <div className="enrollment-block">
                <Button
                    data-testid="enrollment-progress-toggle-icon"
                    className="enrollment-icon"
                    onClick={handleToggleEnrollment}
                >
                    <SettingsIcon fill="#fff" />
                </Button>
                <div
                    data-testid="enrollment-progress-details"
                    className={classNames(
                        'enrollment-progress-wrapper',
                        isEnrollmentDetailsCollapsed && 'collapse-enrollment-details'
                    )}
                >
                    <div className="enrollment-close-wrapper">
                        <p className="enrollment-text">{caption}</p>
                        <Button
                            data-testid="enrollment-progress-close"
                            className="close"
                            onClick={handleToggleEnrollment}
                        >
                            Close
                        </Button>
                    </div>
                    <div className="progress-wrapper">
                        <Button
                            disabled
                            className="play-pause-icon"
                            // Will never be called, because button disabled
                            // istanbul ignore next
                            onClick={handleEnrollmentInprogress}
                        >
                            {isEnrollmentInprogress ? (
                                <PauseIcon fill="#26405d" />
                            ) : (
                                <PlayIcon fill="#26405d" />
                            )}
                        </Button>
                        <ProgressBar
                            id="progress-bar"
                            variant="progress-custom"
                            now={(completedAmount / totalAmount) * 100}
                        />
                    </div>
                    <div className="agent-block">
                        <AgentsIcon fill="#0086c5" />
                        <span
                            data-testid="enrollment-progress-completed"
                            className="completed-amount"
                        >
                            {numberWithSpaces(completedAmount)}
                        </span>
                        <span data-testid="enrollment-progress-total" className="total-amount">
                            /{numberWithSpaces(totalAmount)}
                        </span>
                    </div>
                </div>
            </div>
        )
    );
}
