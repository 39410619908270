const sampleTemplate = (enrollmentHost) => `define HOST           ${enrollmentHost}
LogLevel              INFO
LogFile               %MYLOGFILE%
<Extension admin>
    Module            xm_admin
    Host              %HOST%
    SocketType        SSL
    AllowUntrusted    True
    RequireCert       False
    <ACL conf>
        Directory     %CONFDIR%
        AllowRead     TRUE
        AllowWrite    TRUE
    </ACL>
    <ACL cert>
        Directory     %CERTDIR%
        AllowRead     TRUE
        AllowWrite    TRUE
    </ACL>
</Extension>`;

export default sampleTemplate;
