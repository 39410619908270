import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImEtw = {
    label: 'Event Tracing for Windows (ETW)',
    name: 'im_etw',
    type: 'collect',
    defaultDirectives: 'Module      im_etw',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'File',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'KernelFlags',
            type: 'select',
            options: [
                'ALPC',
                'CSWITCH',
                'DBGPRINT',
                'DISK_FILE_IO',
                'DISK_IO',
                'DISK_IO_INIT',
                'DISPATCHER',
                'DPC',
                'DRIVER',
                'FILE_IO',
                'FILE_IO_INIT',
                'IMAGE_LOAD',
                'INTERRUPT',
                'MEMORY_HARD_FAULTS',
                'MEMORY_PAGE_FAULTS',
                'NETWORK_TCPIP',
                'NO_SYSCONFIG',
                'PROCESS',
                'PROCESS_COUNTERS',
                'PROFILE',
                'REGISTRY',
                'SPLIT_IO',
                'SYSTEMCALL',
                'THREAD',
                'VAMAP',
                'VIRTUAL_ALLOC'
            ],
            multiple: true,
            separator: ', ',
            defaultValue: []
        },
        {
            name: 'Provider',
            type: 'block',
            defaultValue: [{ Name: '' }],
            defaultField: 'Name',
            multiple: true,
            required: true,
            block: [
                {
                    name: 'Name',
                    type: 'string',
                    required: true,
                    defaultValue: ''
                },
                {
                    name: 'Level',
                    type: 'select',
                    options: ['Critical', 'Error', 'Warning', 'Information', 'Verbose'],
                    defaultValue: ''
                },
                {
                    name: 'MatchAllKeyword',
                    type: 'string',
                    defaultValue: ''
                },
                {
                    name: 'MatchAnyKeyword',
                    type: 'string',
                    defaultValue: ''
                }
            ]
        },
        {
            name: 'Session',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'ParsePacketData',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_INPUT_DIRECTIVES('im_etw'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
