import React, { useState, useCallback, useEffect } from 'react';
import propTypes, { oneOf } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Pagination } from '@nxlog/common-ui/components';
import { Form } from 'react-bootstrap';
import { Sort } from '@nxlog/common-ui/dist/components/svgs';
import {
    selectTemplates,
    selectTemplatesLoading,
    selectTemplatesPerPage,
    setItemsPerPage
} from '../../../redux/reducers/templates';
import Loader from '../../common/loader';
import ConfigurationRow from './configurationRow';
import './tableConfigurations.scss';

export default function TableConfigurations(props) {
    const dispatch = useDispatch();
    const {
        itemsCount,
        currentPage,
        setCurrentPage,
        onSelectOption,
        onSort,
        sortFields,
        selectedAll,
        onSelectAllChange,
        isChecked,
        onCheckedChanged
    } = props;

    const templates = useSelector(selectTemplates);
    const isLoading = useSelector(selectTemplatesLoading);

    const [tableData, setTableData] = useState([]);

    const itemsPerPage = useSelector(selectTemplatesPerPage);

    // checks for changed rows to prevent table rows re-render
    useEffect(() => {
        setTableData(templates);
    }, [templates]);

    const handleClick = (rowData) => {
        onSelectOption('edit', [rowData]);
    };

    useEffect(() => {
        if ((currentPage - 1) * itemsPerPage > itemsCount) {
            const lastPage = Math.ceil(itemsCount / itemsPerPage);
            setCurrentPage(lastPage);
        }
    }, [itemsCount, itemsPerPage, setCurrentPage]);

    const handleChangeItemsPerPage = (value) => {
        dispatch(setItemsPerPage(value));
    };

    const sortTable = useCallback(
        (filterParam) => {
            onSort(filterParam);
        },
        [tableData, setTableData]
    );

    return (
        <div className="table-config-wrapper">
            <div className="table-config">
                <div className="main-table">
                    <div className="table-head">
                        <div className="head-name">
                            <Dropdown
                                options={[
                                    { value: 'all', label: 'All' },
                                    { value: 'page', label: 'Page' },
                                    { value: 'none', label: 'None' }
                                ]}
                                icon={
                                    <Form.Check
                                        data-testid="select-column-check-all"
                                        checked={selectedAll}
                                    />
                                }
                                onClick={onSelectAllChange}
                                className="select-column-dropdown"
                            />
                            <button
                                data-testid="table-configs-sort-by-name"
                                className="sort-button"
                                onClick={() => sortTable({ name: 'name' })}
                            >
                                Sort by name
                                <Sort
                                    direction={
                                        sortFields.find((field) => field.name === 'name')?.order ||
                                        null
                                    }
                                />
                            </button>
                        </div>
                        <div className="head-inputs">
                            <span className="field-name">Collect from</span>
                        </div>
                        <div className="head-outputs">
                            <span className="field-name">Send to</span>
                        </div>
                    </div>
                    {Array.isArray(tableData) && tableData.length
                        ? tableData.map((item) => (
                              // eslint-disable-next-line react/jsx-indent
                              <ConfigurationRow
                                  key={item.id}
                                  item={item}
                                  onClick={() => handleClick(item)}
                                  onSelectOption={onSelectOption}
                                  checked={isChecked(item.id)}
                                  onCheckedChanged={(e) =>
                                      onCheckedChanged(item.id, e?.target?.checked)
                                  }
                              />
                          ))
                        : null}
                    <div className="table-footer">
                        <Pagination
                            className="table-pagination"
                            onPageSizeChange={handleChangeItemsPerPage}
                            itemsAmount={itemsCount}
                            itemsPerPage={itemsPerPage}
                            currentIndex={currentPage ? (currentPage - 1) * itemsPerPage : 0}
                            onPageChange={(pageIndex) => setCurrentPage(pageIndex)}
                        />
                    </div>
                </div>
            </div>
            {isLoading && <Loader className="templates-loader" />}
        </div>
    );
}

TableConfigurations.defaultProps = {
    itemsCount: null,
    currentPage: null,
    setCurrentPage: () => {},
    onSelectOption: () => {},
    onSort: () => {},
    onSelectAllChange: () => {},
    isChecked: () => {},
    onCheckedChanged: () => {},
    selectedAll: false,
    sortFields: []
};

TableConfigurations.propTypes = {
    itemsCount: propTypes.number,
    currentPage: propTypes.number,
    setCurrentPage: propTypes.func,
    onSelectOption: propTypes.func,
    onSort: propTypes.func,
    onSelectAllChange: propTypes.func,
    isChecked: propTypes.func,
    onCheckedChanged: propTypes.func,
    selectedAll: propTypes.bool,
    sortFields: propTypes.arrayOf(
        propTypes.shape({ name: propTypes.string, order: oneOf(['asc', 'desc', undefined]) })
    )
};
