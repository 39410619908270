import React from 'react';
import PropTypes from 'prop-types';

function FilterIcon({ fill = 'currentColor', ...props }) {
    return (
        <svg
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <path
                d="M9.33333 16.5C9.18909 16.5 9.04873 16.4532 8.93333 16.3667L6.26667 14.3667C6.18387 14.3046 6.11667 14.224 6.07038 14.1315C6.0241 14.0389 6 13.9368 6 13.8333V10.0867L1.32267 4.82467C0.99048 4.44992 0.773586 3.98721 0.698053 3.49216C0.62252 2.9971 0.691563 2.49077 0.896882 2.03401C1.1022 1.57725 1.43506 1.18951 1.85545 0.917377C2.27585 0.645249 2.76588 0.500316 3.26667 0.5H12.7333C13.2341 0.500587 13.724 0.64576 14.1442 0.918071C14.5644 1.19038 14.8971 1.57825 15.1022 2.03506C15.3073 2.49187 15.3761 2.99818 15.3004 3.49316C15.2247 3.98815 15.0076 4.45073 14.6753 4.82533L10 10.0867V15.8333C10 16.0101 9.92976 16.1797 9.80474 16.3047C9.67971 16.4298 9.51014 16.5 9.33333 16.5ZM7.33333 13.5L8.66667 14.5V9.83333C8.6668 9.6701 8.72682 9.5126 8.83533 9.39067L13.6807 3.93933C13.8422 3.75672 13.9477 3.53136 13.9843 3.2903C14.0209 3.04924 13.9872 2.80274 13.8872 2.58037C13.7872 2.35801 13.6251 2.16924 13.4204 2.03673C13.2157 1.90421 12.9772 1.83359 12.7333 1.83333H3.26667C3.02297 1.8337 2.78455 1.90436 2.58 2.03684C2.37546 2.16932 2.21347 2.358 2.11347 2.58023C2.01347 2.80247 1.9797 3.04884 2.01622 3.28979C2.05273 3.53074 2.15798 3.75604 2.31933 3.93867L7.16533 9.39067C7.27361 9.5127 7.33338 9.67019 7.33333 9.83333V13.5Z"
                fill={fill}
            />
        </svg>
    );
}

FilterIcon.defaultProps = {
    fill: 'currentColor'
};

FilterIcon.propTypes = {
    fill: PropTypes.string
};

export default FilterIcon;
