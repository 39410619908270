import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmNull = {
    label: 'Null',
    type: 'sendTo',
    name: 'om_null',
    defaultDirectives: 'Module      om_null',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_null'),
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
