import React from 'react';
import { AgentType } from '../../../../utils/constants/propTypes';
import { formatMemoryUsage, formatDateTime } from '../../../../utils/helpers/functions';
import './tableAgentDetails.scss';

export default function TableAgentDetails(props) {
    const { rowData } = props;

    const {
        version,
        os,
        startedTime,
        polledTime,
        modules,
        pid,
        cpuLoad: cpu,
        memoryUsed
    } = rowData.data;

    const modulesCount = (modules && Object.keys(modules).length) || '--';
    const cpuLoad = cpu ? Math.round(cpu * 100) / 100 : '--';
    const startTime = formatDateTime(startedTime);
    const pollTime = formatDateTime(polledTime);

    return (
        <div data-testid="table-agent-details" className="assign-agent-details">
            <div className="agent-details-info">
                <div className="details-title">Details</div>
                <div className="details-info">
                    <div className="single-detail">
                        <div className="title">Started:</div>
                        <div data-testid="table-agent-details-started" className="value">
                            {startTime || '--'}
                        </div>
                    </div>
                    <div className="single-detail">
                        <div className="title">Load:</div>
                        <div data-testid="table-agent-details-cpu" className="value">
                            {cpuLoad}
                        </div>
                    </div>
                    <div className="single-detail">
                        <div className="title">Pid:</div>
                        <div data-testid="table-agent-details-pid" className="value">
                            {pid || '--'}
                        </div>
                    </div>
                    <div className="single-detail">
                        <div className="title">Mem:</div>
                        <div data-testid="table-agent-details-memory" className="value">
                            {formatMemoryUsage(memoryUsed) || '--'}
                        </div>
                    </div>
                </div>
                <div className="details-info">
                    <div className="single-detail">
                        <div className="title">OS:</div>
                        <div data-testid="table-agent-details-os" className="value">
                            {os || '--'}
                        </div>
                    </div>
                    <div className="single-detail">
                        <div className="title">Version:</div>
                        <div data-testid="table-agent-details-version" className="value">
                            {version || '--'}
                        </div>
                    </div>
                    <div className="single-detail">
                        <div className="title">Servertime:</div>
                        <div data-testid="table-agent-details-polled" className="value">
                            {pollTime || '--'}
                        </div>
                    </div>
                    <div className="single-detail">
                        <div className="title">Module count:</div>
                        <div data-testid="table-agent-details-modules" className="value">
                            {modulesCount}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

TableAgentDetails.defaultProps = {
    rowData: null
};

TableAgentDetails.propTypes = {
    rowData: AgentType
};
