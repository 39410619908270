import React, { useState } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Info as InfoIcon, Angle, Wizard } from '@nxlog/common-ui/dist/components/svgs';
import { BANNER_TYPES } from '../../../utils/constants/ids';
import { capitalizeFirstLetter } from '../../../utils/helpers/strings';
import './infoMessage.scss';

export default function InfoMessage({ message: incomingMessage }) {
    const { type, message, help, note } = incomingMessage;
    const { t } = useTranslation();
    const [unwrapped, setUnwrapped] = useState(false);

    const toggleUnwrapped = () => {
        setUnwrapped((w) => !w);
    };

    return (
        <div className={classNames('info-content-message', BANNER_TYPES[type])}>
            <p data-testid="info-message-type" className="info-content-message-title">
                <InfoIcon />
                {capitalizeFirstLetter(BANNER_TYPES[type])}
            </p>
            <p data-testid="info-message-text" className="info-content-message-text">
                {message}
            </p>
            {unwrapped && (
                <>
                    {!!help && (
                        <p data-testid="info-message-help" className="info-content-message-help">
                            <Wizard />
                            {help}
                        </p>
                    )}
                    {!!note && (
                        <p data-testid="info-message-note" className="info-content-message-note">
                            {note}
                        </p>
                    )}
                </>
            )}
            <button
                data-testid="info-message-btn"
                type="button"
                onClick={toggleUnwrapped}
                className="info-content-unwrap-button"
            >
                {(!!help || !!note) && !unwrapped ? (
                    <>
                        {t('view_more')}
                        <Angle.Down />
                    </>
                ) : (
                    <>
                        {t('view_less')}
                        <Angle.Up />
                    </>
                )}
            </button>
        </div>
    );
}

InfoMessage.defaultProps = {
    message: {}
};

InfoMessage.propTypes = {
    message: propTypes.shape({
        type: propTypes.string.isRequired,
        message: propTypes.string.isRequired,
        help: propTypes.string,
        note: propTypes.string
    })
};
