import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmGooglepubsub = {
    label: 'Google Cloud Pub/Sub',
    name: 'om_googlepubsub',
    type: 'sendTo',
    defaultDirectives: 'Module      om_googlepubsub',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'CredentialsFile',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Topic',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'Attributes',
            type: 'key-value',
            defaultValue: [],
            multiple: true
        },
        {
            name: 'Connections',
            type: 'number',
            defaultValue: 1
        },
        {
            name: 'GooglePubsubBatchSize',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'URL',
            type: 'string',
            defaultValue: 'https://pubsub.googleapis.com'
        },
        {
            name: 'Reconnect',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'OutputRequestSize',
            type: 'string',
            defaultValue: ''
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_googlepubsub'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
