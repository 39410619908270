import React, { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { Button } from '@nxlog/common-ui/components';
import { Offcanvas } from 'react-bootstrap';
import AddIcon from '@nxlog/common-ui/dist/components/svgs/add';
import CircleX from '@nxlog/common-ui/dist/components/svgs/circle-x';
import { AGENT_FILTERS } from '../../../utils/helpers/filters';
import TableAssignAgents from '../../../components/tables/tableAssignAgents';
import { useCheckedRecords } from '../../../utils/hooks';
import SelectedAgents from '../../../components/selectedAgents';
import {
    selectPaginatedAgents,
    assignTemplateData,
    selectItemsPerPageValue
} from '../../../redux/reducers/agents';
import timeOut, { clearAgentTags } from '../../../utils/helpers/functions';
import useAgentTable from '../../../utils/hooks/useAgentTable';
import useCreateFilters from '../../../utils/hooks/useCreateFilters';
import { AGENT_SELECTOR } from '../../../utils/constants/ids';
import { createFilterStringFromSelectedIds } from '../../../utils/helpers/strings';
import UnenrollConfirm from '../../../components/modals/unenrollConfirmModal';
import UnassignConfirm from '../../../components/modals/unassignConfirmModal';
import MQLEditor from '../../../components/mqlEditor';
import DisplayAppliedFilters from '../../../components/displayAppliedFilters';
import './assignAgents.scss';

export default function AssignAgentsSideBar({ isOpen, onToggle, templates, onAssignAgent }) {
    const dispatch = useDispatch();

    const itemsPerPageValue = useSelector(selectItemsPerPageValue);
    const paginatedAgents = useSelector(selectPaginatedAgents);
    const [loading, setLoading] = useState(true);

    const initialFilters = [AGENT_FILTERS.enrolled];

    const {
        agents,
        filteredAgentsCount,
        currentPage,
        appliedFilters,
        tags,
        sortFields,
        addSortField,
        addNewTag,
        resetFilters,
        refetchCurrrentPage,
        applyFilter,
        removeFilter,
        handleChangePage,
        AGENT_TABLE_ROW_FUNCTIONS,
        AGENT_TABLE_ROW_FUNCTIONS_BY_FILTER,
        strFilter
    } = useAgentTable(initialFilters);

    const { filters } = useCreateFilters({
        applyFilter,
        removeFilter,
        appliedFilters
    });

    const { checkedRecords, handleRemoveRecord, handleRemoveAll, handleSetRecords } =
        useCheckedRecords(new Map(), AGENT_SELECTOR);

    useEffect(() => {
        if (!isOpen) return () => {};

        refetchCurrrentPage();

        const interval = setInterval(() => {
            refetchCurrrentPage();
        }, 10000);

        return () => {
            clearAgentTags();
            clearInterval(interval);
        };
    }, [tags, appliedFilters, itemsPerPageValue, currentPage, isOpen, sortFields]);

    const handleCloseAssignAgents = () => {
        handleRemoveAll();
        onToggle();
    };

    const handleAssignTemplate = (selectedAgents) => {
        const configId = templates?.[0]?.id;
        const filter = createFilterStringFromSelectedIds(selectedAgents, strFilter, AGENT_SELECTOR);

        dispatch(
            assignTemplateData({
                filter,
                id: configId
            })
        ).then(() => {
            onAssignAgent(configId);
            handleCloseAssignAgents();
        });
    };

    const handleSendTemplates = () => {
        handleAssignTemplate(Array.from(checkedRecords.values()));
    };

    const moduleNames = templates?.map((item) => item.name).join(', ');

    const selectedAgentsData = useMemo(() => Array.from(checkedRecords.values()), [checkedRecords]);

    const handleRemoveItem = (id) => {
        if (selectedAgentsData.map((item) => item.id).includes('*')) {
            handleSetRecords([...selectedAgentsData, { id }]);
        } else {
            handleRemoveRecord(id);
        }
    };

    const [showing, setShowing] = useState(false);

    useEffect(() => {
        if (selectedAgentsData.length > 0) setShowing(true);
        else setShowing(false);
    }, [selectedAgentsData.length]);

    useEffect(() => {
        if (agents.length > 0) setLoading(false);
    }, [agents]);

    timeOut(loading, setLoading);

    const [showConfirmDelete, setShowConfirmDelete] = useState(null);
    const [showConfirmUnassign, setShowConfirmUnassign] = useState(null);
    const [actionLoading, setActionLoading] = useState(false);

    const handleApplyOption = (value, row, usingFilter) => {
        setActionLoading(true);
        const finalActions = () => {
            setActionLoading(false);
            refetchCurrrentPage();
            handleRemoveAll();
        };
        if (usingFilter) {
            return AGENT_TABLE_ROW_FUNCTIONS_BY_FILTER[value](row).finally(finalActions);
        }

        return AGENT_TABLE_ROW_FUNCTIONS[value](row).finally(finalActions);
    };

    const handleSelectOption = (value, row, usingFilter = false, count = 1) => {
        switch (value) {
            case 'delete':
                setShowConfirmDelete([count, value, row, usingFilter]);
                break;
            case 'unassignTemplate':
                setShowConfirmUnassign([count, value, row, usingFilter]);
                break;
            default:
                handleApplyOption(value, row, usingFilter);
        }
    };

    const handleConfirmDelete = () => {
        const [, ...rest] = showConfirmDelete;
        handleApplyOption(...rest);
        setShowConfirmDelete(null);
    };

    const handleConfirmUnassign = () => {
        const [, ...rest] = showConfirmUnassign;
        handleApplyOption(...rest);
        setShowConfirmUnassign(null);
    };

    const authorizedActions = [
        'restart',
        'stop',
        'editConfig',
        'updateAndRestart',
        'refresh',
        'renewCertificate',
        'export',
        'delete'
    ];

    return (
        <>
            <Offcanvas
                data-testid="assign-agents-sidebar"
                show={isOpen}
                placement="end"
                className={classNames('assign-agents-page', showing && 'showing')}
            >
                <Offcanvas.Body
                    className="template-sidebar-body"
                    data-testid="assign-agents-sidebar-body"
                >
                    {showing && (
                        <div className="assign-selected-agents">
                            <SelectedAgents
                                data={selectedAgentsData}
                                strFilter={strFilter}
                                subTitle={
                                    <div className="selected-agents-sub-title">
                                        template name: <span>{moduleNames}</span>
                                    </div>
                                }
                                onRemoveAgent={handleRemoveItem}
                                onRemoveAll={handleRemoveAll}
                                onSelectOption={handleSelectOption}
                                loading={actionLoading}
                                authorizedActions={authorizedActions}
                                footer={
                                    <div className="selected-agents-footer">
                                        <Button
                                            className="cancel"
                                            ghostBtn
                                            onClick={handleRemoveAll}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            data-testid="assign-agents-confirm"
                                            icon={<AddIcon />}
                                            className="save"
                                            onClick={handleSendTemplates}
                                        >
                                            Assign agents
                                        </Button>
                                    </div>
                                }
                            />
                        </div>
                    )}
                    <div className="form-container">
                        <div className="template-sidebar-header">
                            <Offcanvas.Title>
                                <header className="assign-agents-page-header">
                                    <div>
                                        Assign agents <span>({moduleNames})</span>
                                    </div>
                                </header>
                            </Offcanvas.Title>
                            <Button
                                data-testid="assign-agents-sidebar-close"
                                icon={<CircleX outlined fill="#072341" />}
                                onClick={handleCloseAssignAgents}
                            />
                        </div>
                        <div className="scroll">
                            <div className="agent-search-wrapper">
                                <MQLEditor onCreate={addNewTag} />
                                <DisplayAppliedFilters
                                    appliedFilters={appliedFilters}
                                    removeFilter={removeFilter}
                                />
                            </div>
                            <TableAssignAgents
                                appliedFilters={appliedFilters}
                                applyFilter={applyFilter}
                                removeFilter={removeFilter}
                                resetFilters={resetFilters}
                                allDataAmount={filteredAgentsCount}
                                tableData={paginatedAgents}
                                filters={filters}
                                initialFilters={initialFilters}
                                disabledFilterIds={[AGENT_FILTERS.new.id]}
                                onSort={addSortField}
                                checkedRows={checkedRecords}
                                setCheckedRows={handleSetRecords}
                                currentPage={currentPage}
                                setCurrentPage={handleChangePage}
                                onAssignAgents={handleAssignTemplate}
                            />
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <UnenrollConfirm
                show={Boolean(showConfirmDelete)}
                count={showConfirmDelete ? showConfirmDelete[0] : 0}
                onCancel={() => setShowConfirmDelete(false)}
                onConfirm={handleConfirmDelete}
            />
            <UnassignConfirm
                show={Boolean(showConfirmUnassign)}
                count={showConfirmUnassign ? showConfirmUnassign[0] : 0}
                onCancel={() => setShowConfirmUnassign(false)}
                onConfirm={handleConfirmUnassign}
            />
        </>
    );
}

AssignAgentsSideBar.defaultProps = {
    isOpen: false,
    templates: null,
    onToggle: () => {},
    onAssignAgent: () => {}
};

AssignAgentsSideBar.propTypes = {
    isOpen: propTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    templates: propTypes.array,
    onAssignAgent: propTypes.func,
    onToggle: propTypes.func
};
