import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { Button, Dropdown, Pagination } from '@nxlog/common-ui/components';
import SelectedAgent from './selectedAgent';
import { AgentType } from '../../utils/constants/propTypes';
import { addProcess } from '../../redux/reducers/agents';
import { selectIsEnrollmentDisabled, selectLicensesStatus } from '../../redux/reducers/licenses';
import { getAgentMenuOptions } from '../../utils/helpers/functions';
import { LICENSES_STATUS } from '../../utils/constants/ids';
import Loader from '../common/loader';
import useCustomAgentsList from '../../utils/hooks/useCustomAgentsList';
import { createIncludedIdsFilter } from '../../utils/helpers/strings';
import './selectedAgents.scss';

export default function SelectedAgents(props) {
    const {
        data,
        onRemoveAgent,
        onRemoveAll,
        onSelectOption,
        title,
        subTitle,
        handleToggleRunningProcesses,
        footer,
        authorizedActions,
        strFilter
    } = props;
    const dispatch = useDispatch();

    const licenseStatus = useSelector(selectLicensesStatus);
    const isEnrollmentDisabled = useSelector(selectIsEnrollmentDisabled);

    const {
        rows,
        count: amount,
        loading,
        usingFilter,
        getFilter,
        itemsPerPage,
        setItemsPerPage,
        page,
        setPage
    } = useCustomAgentsList({ strFilter, checkedRecords: data });

    const handleSelectOption = (value) => {
        if (
            ['enroll', 'enrollwithtemplate'].includes(value) &&
            licenseStatus === LICENSES_STATUS.ERROR
        )
            return;

        const agents = Array.isArray(data) ? data.map((item) => item.id) : [];
        const date = new Date();
        const processType = value.toLowerCase();

        if (usingFilter) {
            onSelectOption(value, { filter: getFilter('id') }, true, amount);
        } else {
            dispatch(
                addProcess({
                    id: date.toISOString(),
                    agentIds: agents,
                    status: 'inprogress',
                    processType
                })
            );
            handleToggleRunningProcesses(true);
            onSelectOption(value, { filter: createIncludedIdsFilter(agents) }, true, amount);
            if (!['delete', 'enrollwithtemplate'].includes(value)) onRemoveAll();
        }
    };

    const actions = useMemo(() => {
        if (!rows?.length) {
            return null;
        }

        const operations = getAgentMenuOptions({
            licenseStatus,
            renewCertificate: true,
            isEnrollmentDisabled
        });
        const exclusions = [];

        if (rows.every((agent) => !agent.persist && !agent.enrolled)) {
            exclusions.push('delete');
        }

        if (rows.some((agent) => !agent.enrolled)) {
            exclusions.push('assignTemplate', 'unassignTemplate');
            exclusions.push('renewCertificate');
        } else if (rows.every((agent) => !agent.templateName)) {
            exclusions.push('unassignTemplate');
        }

        return operations
            .filter((operation) =>
                Array.isArray(authorizedActions) && authorizedActions.length
                    ? authorizedActions.includes(operation.value)
                    : true
            )
            .filter((operation) => !exclusions.includes(operation.value));
    }, [rows, licenseStatus]);

    return (
        <div data-testid="selected-agents" id="selected-agents" className="selected-agents">
            {(props.loading || loading) && <Loader />}
            <div className="selected-agents-title">
                <div>
                    <h4>
                        {title}{' '}
                        <span className="amount" data-testid="selected-agents-amount">
                            ({amount})
                        </span>
                    </h4>
                    {subTitle}
                    <Button
                        data-testid="selected-agents-remove-all"
                        className="clear-button"
                        onClick={onRemoveAll}
                    >
                        clear all
                    </Button>
                </div>

                {!!actions && (
                    <Dropdown
                        options={actions}
                        title="Operations"
                        className="selected-dropdown"
                        icon={<FontAwesomeIcon icon={faChevronDown} />}
                        onClick={handleSelectOption}
                        disabled={!data?.length}
                        withIcon
                        closeMenuOnSelect
                    />
                )}
            </div>
            <div className="selected-agents-items">
                {!!rows &&
                    rows.map((item) => (
                        <SelectedAgent
                            key={item.id}
                            data={item}
                            isChecked
                            onRemove={onRemoveAgent}
                        />
                    ))}
            </div>
            {amount > itemsPerPage ? (
                <div className="table-footer">
                    <Pagination
                        className="selected-agents-pagination"
                        onPageSizeChange={setItemsPerPage}
                        itemsAmount={amount}
                        itemsPerPage={itemsPerPage}
                        currentIndex={((page ?? 1) - 1) * itemsPerPage}
                        onPageChange={setPage}
                    />
                </div>
            ) : null}
            {footer}
        </div>
    );
}

SelectedAgents.defaultProps = {
    onSelectOption: null,
    loading: false,
    subTitle: null,
    title: 'Selected Agents',
    handleToggleRunningProcesses: () => {},
    onRemoveAll: () => {},
    strFilter: '',
    footer: null,
    authorizedActions: []
};

SelectedAgents.propTypes = {
    title: propTypes.string,
    data: propTypes.arrayOf(AgentType).isRequired,
    subTitle: propTypes.element,
    onRemoveAgent: propTypes.func.isRequired,
    onSelectOption: propTypes.func,
    handleToggleRunningProcesses: propTypes.func,
    onRemoveAll: propTypes.func,
    strFilter: propTypes.string,
    footer: propTypes.element,
    loading: propTypes.bool,
    authorizedActions: propTypes.arrayOf(propTypes.string)
};
