import React, { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
    Pagination,
    Table,
    TableColumn,
    SelectColumn,
    Dropdown
} from '@nxlog/common-ui/components';
import Dots from '@nxlog/common-ui/dist/components/svgs/dots';
import WarningIcon from '@nxlog/common-ui/dist/components/svgs/warningalt';
import Success from '@nxlog/common-ui/dist/components/svgs/success';
import CircleXIcon from '@nxlog/common-ui/dist/components/svgs/circle-x';
import classNames from 'classnames';
import { formatDateTime } from '../../../utils/helpers/functions';
import { removePersistedNotification } from '../../../redux/reducers/notifications';
import { selectItemsPerPageValue, setItemsPerPage } from '../../../redux/reducers/agents';
import sort from '../../../utils/helpers/tables';
import { NOTIFICATIONS_TABLE_ROW_OPTIONS } from '../../../utils/constants/options';
import './tableNotifications.scss';

const typeIcon = (rowData) => {
    let icon;
    switch (rowData.type) {
        case 'success':
            icon = <Success outlined fill="#228D3A" />;
            break;
        case 'error':
            icon = <CircleXIcon outlined fill="#E03520" />;
            break;
        case 'warning':
            icon = <WarningIcon fill="#E67500" />;
            break;
        default:
            break;
    }
    return (
        <>
            {icon}
            <span className={classNames('filter-value', `type-${rowData.type}`)}>
                {rowData.type}
            </span>
        </>
    );
};

export default function TableNotifications(props) {
    const dispatch = useDispatch();
    const {
        checkedRows,
        setCheckedRows,
        tableData,
        setTableData,
        currentPage,
        setCurrentPage,
        allData,
        paginatedTableData
    } = props;
    const itemsPerPage = useSelector(selectItemsPerPageValue);
    const [sortColumn, setSortColumn] = useState({
        type: 'asc',
        additionalInfo: 'asc',
        message: 'asc',
        date: 'asc'
    });

    const handleChangeItemsAmount = (value) => {
        dispatch(setItemsPerPage({ label: value, value }));
    };

    const filterTable = (filterParam) => {
        sort(filterParam, sortColumn, allData, setSortColumn, setTableData);
    };

    const handleSelectRow = useCallback(
        (ids) => {
            const records = paginatedTableData.filter((rec) => ids.includes(rec.id));
            setCheckedRows(records);
        },
        [paginatedTableData, setCheckedRows]
    );

    const handleDeleteNotification = (notificationId) => {
        const notification = {
            id: notificationId
        };
        dispatch(removePersistedNotification(notification));
        const updatedTableData = paginatedTableData.filter((row) => row.id !== notificationId);
        setTableData(updatedTableData);
        sessionStorage.setItem('notifications', JSON.stringify(updatedTableData));
    };

    return (
        <div className="table-notifications-wrapper">
            <div className="table-notifications">
                <div data-testid="table-notifications-table" className="main-table">
                    <Table
                        pageSize={itemsPerPage}
                        className="notification-table"
                        disableOverlay
                        rows={paginatedTableData}
                    >
                        <SelectColumn
                            name="select"
                            className="column-select"
                            idField="id"
                            ids={paginatedTableData?.map((e) => e.id)}
                            selected={[...checkedRows.values()].map(({ id }) => id)} // list of selected IDs
                            onChange={handleSelectRow} // on select callback
                        />
                        <TableColumn
                            name="type"
                            className="column-type"
                            title="Type"
                            direction={sortColumn.type}
                            onSort={() => filterTable('type')}
                        >
                            {typeIcon}
                        </TableColumn>
                        <TableColumn
                            name="message"
                            className="column-name"
                            title="Message"
                            direction={sortColumn.message}
                            onSort={() => filterTable('message')}
                        />
                        <TableColumn
                            name="additionalInfo"
                            className="column-message"
                            title="Additional Info"
                            direction={sortColumn.additionalInfo}
                            onSort={() => filterTable('additionalInfo')}
                        />
                        <TableColumn
                            name="date"
                            className="column-date"
                            title="Time"
                            direction={sortColumn.date}
                            onSort={() => filterTable('date')}
                        >
                            {(entry) => formatDateTime(entry.date)}
                        </TableColumn>
                        <TableColumn name="actions" title="Actions" className="column-actions">
                            {({ id }) => (
                                <Dropdown
                                    options={NOTIFICATIONS_TABLE_ROW_OPTIONS}
                                    onClick={() => {
                                        handleDeleteNotification(id);
                                    }}
                                    icon={<Dots fill="#0081C1" />}
                                    withIcon
                                    className="dropdown-actions"
                                />
                            )}
                        </TableColumn>
                    </Table>
                    <div
                        data-testid="table-notifications-pagination-bottom"
                        className="table-footer"
                    >
                        <Pagination
                            className="table-pagination"
                            onPageSizeChange={handleChangeItemsAmount}
                            itemsAmount={tableData?.length}
                            itemsPerPage={itemsPerPage}
                            currentIndex={((currentPage?.value ?? 1) - 1) * itemsPerPage}
                            onPageChange={(pageIndex) => setCurrentPage({ value: pageIndex })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

TableNotifications.defaultProps = {
    tableData: null,
    setTableData: () => {},
    currentPage: null,
    setCurrentPage: () => {},
    allData: null,
    checkedRows: new Map(),
    setCheckedRows: () => {},
    paginatedTableData: []
};

TableNotifications.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    tableData: propTypes.array,
    setTableData: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    currentPage: propTypes.object,
    setCurrentPage: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    allData: propTypes.array,
    checkedRows: propTypes.instanceOf(Map),
    setCheckedRows: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    paginatedTableData: propTypes.array
};
