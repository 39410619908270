import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImWinperfcount = {
    label: 'Windows Performance Counters',
    name: 'im_winperfcount',
    type: 'collect',
    defaultDirectives: 'Module      im_winperfcount',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Counter',
            type: 'string',
            defaultValue: [{ value: '' }],
            required: true,
            multiple: true
        },
        {
            name: 'PollInterval',
            type: 'number',
            defaultValue: 1
        },
        {
            name: 'UseEnglishCounters',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'AllowInvalidCounters',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        ...GENERAL_INPUT_DIRECTIVES('im_winperfcount'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
