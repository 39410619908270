const BANNER_TYPES = {
    error: 'error',
    success: 'success',
    warning: 'warning'
};

const STORAGE_KEYS = {
    IP: 'ip',
    INSTANCE_ID: 'instance-id'
};

const FILTER_BY_TYPE = {
    all: null,
    warning: '(status = warning and online = yes)',
    error: '(status = error and online = yes)',
    ok: '(status = ok and online = yes)',
    offline: 'online = no'
};

const AGENTS_STATUS = {
    warning: 'warning',
    ok: 'ok',
    error: 'error'
};

const CONFIG_ANSWER_TYPES = {
    failure: 'error',
    success: 'data'
};

const ITEMS_PER_PAGE_OPTIONS = [
    { label: '15 items', value: 15 },
    { label: '25 items', value: 25 },
    { label: '50 items', value: 50 },
    { label: '100 items', value: 100 }
];

const MODULE_STATUS = {
    ok: 'ok',
    error: 'error'
};

const CONNECT_TYPES = {
    listen: 'ListenAddr',
    connect: 'Host'
};

const OUTPUT_TYPES = {
    binary: 'Binary',
    dgram: 'Dgram',
    lineBased: 'LineBased',
    lineBased_CRLF: 'LineBased_CRLF',
    lineBased_LF: 'LineBased_LF'
};

const MODE_TYPES = {
    Table: ({ authKey, sharedKey, storageName, tableName }) => `
    ${authKey ? `AuthKey   ${authKey}` : ''}
    ${sharedKey ? `SharedKey   ${sharedKey}` : ''}
    ${storageName ? `StorageName   ${storageName}` : ''}
    ${tableName ? `TableName   ${tableName}` : ''}`,
    Blob: ({ authKey, sharedKey, storageName, blobName }) => `${
        authKey ? `AuthKey   ${authKey}` : ''
    }
    ${sharedKey ? `SharedKey   ${sharedKey}` : ''}
    ${storageName ? `StorageName   ${storageName}` : ''}
    ${blobName ? `BlobName   ${blobName}` : ''}`,
    Analytics: ({
        authKey,
        sharedKey,
        storageName,
        clientID,
        workspaceID,
        tableName,
        tenantID
    }) => `${authKey ? `AuthKey   ${authKey}` : ''}
    ${sharedKey ? `SharedKey   ${sharedKey}` : ''}
    ${storageName ? `WorkspaceID   ${workspaceID}` : ''}
    ${clientID ? `ClientID   ${clientID}` : ''}
    ${tableName ? `TableName   ${tableName}` : ''}
    ${tenantID ? `TenantID   ${tenantID}` : ''}`
};

const MODAL_TYPE = {
    base: 'advanced',
    advanced: 'base'
};

const AGENT_TABLE_FILTER_PATHS = {
    OS: 'data.os',
    VERSION: 'data.version'
};

const EMPTY_ROUTE = {
    id: null,
    name: '',
    priority: '10',
    modules: {
        input: {
            data: [],
            title: 'Input'
        },
        processor: {
            data: [],
            title: 'Processor'
        },
        output: {
            data: [],
            title: 'Output'
        }
    }
};

const MODULE_LIST = [
    { type: 'im_acct', name: 'BSD/Linux Process Accounting' },
    { type: 'im_aixaudit', name: 'AIX Auditing' },
    { type: 'im_amazons3', name: 'Amazon S3' },
    { type: 'im_azure', name: 'Azure' },
    { type: 'im_batchcompress', name: 'Batched Compression over TCP or SSL' },
    { type: 'im_bsm', name: 'Basic Security Module Auditing' },
    { type: 'im_checkpoint', name: 'Check Point OPSEC' },
    { type: 'im_dbi', name: 'DBI' },
    { type: 'im_etw', name: 'Event Tracing for Windows (ETW)' },
    { type: 'im_exec', name: 'Program' },
    { type: 'im_file', name: 'File' },
    { type: 'im_fim', name: 'File Integrity Monitoring' },
    { type: 'im_go', name: 'Go or Golang' },
    { type: 'im_googlelogging', name: 'Google Cloud Logging' },
    { type: 'im_googlepubsub', name: 'Google Cloud Pub/Sub' },
    { type: 'im_http', name: 'HTTP/HTTPS' },
    { type: 'im_internal', name: 'Internal' },
    { type: 'im_java', name: 'Java' },
    { type: 'im_kafka', name: 'Apache Kafka' },
    { type: 'im_kernel', name: 'Kernel' },
    { type: 'im_linuxaudit', name: 'Linux Audit System' },
    { type: 'im_maces', name: 'macOS Endpoint Security' },
    { type: 'im_maculs', name: 'macOS ULS' },
    { type: 'im_mark', name: 'Mark' },
    { type: 'im_ms365', name: 'Microsoft 365' },
    { type: 'im_mseventlog', name: 'Event Logging for Windows XP/2000/2003' },
    { type: 'im_msvistalog', name: 'Windows Event Log for Windows 2008/Vista and later' },
    { type: 'im_null', name: 'Null' },
    { type: 'im_oci', name: 'OCI' },
    { type: 'im_odbc', name: 'ODBC' },
    { type: 'im_pcap', name: 'Packet Capture' },
    { type: 'im_perl', name: 'Perl' },
    { type: 'im_pipe', name: 'Named Pipes' },
    { type: 'im_python', name: 'Python' },
    { type: 'im_redis', name: 'Redis' },
    { type: 'im_regmon', name: 'Windows Registry Monitoring' },
    { type: 'im_ruby', name: 'Ruby' },
    { type: 'im_salesforce', name: 'Salesforce' },
    { type: 'im_ssl', name: 'SSL/TLS' },
    { type: 'im_systemd', name: 'Systemd' },
    { type: 'im_tcp', name: 'TCP' },
    { type: 'im_testgen', name: 'Test Generator' },
    { type: 'im_udp', name: 'UDP' },
    { type: 'im_uds', name: 'Unix Domain Socket' },
    { type: 'im_winperfcount', name: 'Windows Performance Counters' },
    { type: 'im_wseventing', name: 'Windows Event Forwarding' },
    { type: 'im_zmq', name: 'ZeroMQ' },

    { type: 'om_amazons3', name: 'Amazon S3' },
    { type: 'om_azure', name: 'Microsoft Azure Sentinel' },
    { type: 'om_azuremonitor', name: 'Microsoft Azure Log Ingestion' },
    { type: 'om_batchcompress', name: 'Batched Compression over TCP or SSL' },
    { type: 'om_blocker', name: 'Blocker' },
    { type: 'om_chronicle', name: 'Google Chronicle' },
    { type: 'om_dbi', name: 'DBI' },
    { type: 'om_elasticsearch', name: 'Elasticsearch' },
    { type: 'om_eventdb', name: 'EventDB' },
    { type: 'om_exec', name: 'Program' },
    { type: 'om_file', name: 'File' },
    { type: 'om_go', name: 'Go or Golang' },
    { type: 'om_googlelogging', name: 'Google Cloud Logging' },
    { type: 'om_googlepubsub', name: 'Google Cloud Pub/Sub' },
    { type: 'om_http', name: 'HTTP/HTTPS' },
    { type: 'om_java', name: 'Java' },
    { type: 'om_kafka', name: 'Apache Kafka' },
    { type: 'om_null', name: 'Null' },
    { type: 'om_oci', name: 'OCI' },
    { type: 'om_odbc', name: 'ODBC' },
    { type: 'om_perl', name: 'Perl' },
    { type: 'om_pipe', name: 'Named Pipes' },
    { type: 'om_python', name: 'Python' },
    { type: 'om_raijin', name: 'Raijin' },
    { type: 'om_redis', name: 'Redis' },
    { type: 'om_ruby', name: 'Ruby' },
    { type: 'om_ssl', name: 'SSL/TLS' },
    { type: 'om_tcp', name: 'TCP' },
    { type: 'om_udp', name: 'UDP' },
    { type: 'om_udpspoof', name: 'UDP with IP Spoofing' },
    { type: 'om_uds', name: 'UDS' },
    { type: 'om_webhdfs', name: 'WebHDFS' },
    { type: 'om_zmq', name: 'ZeroMQ' },

    { type: 'xm_admin', name: 'Remote Management' },
    { type: 'xm_aixaudit', name: 'AIX Auditing' },
    { type: 'xm_asl', name: 'Apple System Logs' },
    { type: 'xm_bsm', name: 'Basic Security Module Auditing' },
    { type: 'xm_cef', name: 'CEF' },
    { type: 'xm_charconv', name: 'Character Set Conversion' },
    { type: 'xm_crypto', name: 'Encryption' },
    { type: 'xm_csv', name: 'CSV' },
    { type: 'xm_exec', name: 'External Program Execution' },
    { type: 'xm_filelist', name: 'File Lists' },
    { type: 'xm_fileop', name: 'File Operations' },
    { type: 'xm_gelf', name: 'GELF' },
    { type: 'xm_go', name: 'Go or Golang' },
    { type: 'xm_grok', name: 'Grok Patterns' },
    { type: 'xm_java', name: 'Java' },
    { type: 'xm_json', name: 'JSON' },
    { type: 'xm_kvp', name: 'Key-Value Pairs' },
    { type: 'xm_leef', name: 'LEEF' },
    { type: 'xm_msdns', name: 'DNS Server Debug Log Parsing' },
    { type: 'xm_multiline', name: 'Multi-Line Message Parser' },
    { type: 'xm_netflow', name: 'NetFlow' },
    { type: 'xm_nps', name: 'NPS' },
    { type: 'xm_pattern', name: 'Pattern Matcher' },
    { type: 'xm_perl', name: 'Perl' },
    { type: 'xm_python', name: 'Python' },
    { type: 'xm_resolver', name: 'Resolver' },
    { type: 'xm_rewrite', name: 'Rewrite' },
    { type: 'xm_ruby', name: 'Ruby' },
    { type: 'xm_sap', name: 'SAP' },
    { type: 'xm_snmp', name: 'SNMP Traps' },
    { type: 'xm_stdinpw', name: 'Passwords on standard input' },
    { type: 'xm_syslog', name: 'Syslog' },
    { type: 'xm_w3c', name: 'W3C' },
    { type: 'xm_wtmp', name: 'WTMP' },
    { type: 'xm_xml', name: 'XML' },
    { type: 'xm_zlib', name: 'Compression' },

    { type: 'pm_blocker', name: 'Blocker' },
    { type: 'pm_buffer', name: 'Buffer' },
    { type: 'pm_evcorr', name: 'Event Correlator' },
    { type: 'pm_filter', name: 'Filter' },
    { type: 'pm_hmac', name: 'HMAC Message Integrity' },
    { type: 'pm_hmac_check', name: 'HMAC Message Integrity Checker' },
    { type: 'pm_norepeat', name: 'Message De-Duplicator' },
    { type: 'pm_null', name: 'Null' },
    { type: 'pm_pattern', name: 'Pattern Matcher' },
    { type: 'pm_transformer', name: 'Message Format Converter' },
    { type: 'pm_ts', name: 'Timestamping' }
];

const LICENSES_STATUS = {
    OK: 'OK',
    SOFT_WARNING: 'SOFT_WARNING',
    WARNING: 'WARNING',
    ERROR: 'ERROR'
};

const TOAST_TYPES = {
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    SUCCESS: 'success'
};

const AGENT_SELECTOR = 'id';

const SORT_ORDER = {
    ASC: 'asc',
    DESC: 'desc'
};

const AGENT_TABLE_SORT_OPTIONS = {
    status: { name: 'status', order: SORT_ORDER.ASC },
    hostname: { name: 'hostname', order: SORT_ORDER.ASC },
    agentName: { name: 'agent-name', order: SORT_ORDER.ASC },
    address: { name: 'address', order: SORT_ORDER.ASC },
    eps: { name: 'events-per-second', order: SORT_ORDER.ASC },
    memoryUsed: { name: 'memory-used', order: SORT_ORDER.ASC },
    cpuLoad: { name: 'cpu-load', order: SORT_ORDER.ASC },
    enrolled: { name: 'enrolled', order: SORT_ORDER.ASC },
    agentGroup: { name: 'agent-group', order: SORT_ORDER.ASC },
    templateName: { name: 'template-name', order: SORT_ORDER.ASC }
};

const TEMPLATE_TABLE_SORT_OPTIONS = {
    name: { name: 'name', order: SORT_ORDER.ASC },
    comment: { name: 'comment', order: SORT_ORDER.ASC },
    usedByAgents: { name: 'used-by-agents', order: SORT_ORDER.ASC },
    addedTime: { name: 'added-time', order: SORT_ORDER.ASC },
    updatedTime: { name: 'updated-time', order: SORT_ORDER.ASC }
};

const DEFAULT_IP_ADDRESS = '127.0.0.1:4041';
const DEFAULT_ENROLLMENT_PORT = {
    ONPREM: '5515',
    PLATFORM: '5514',
    STANDALONE: '4041',
    RELAY: '5514'
};
const DEFAULT_MINDER_ADDRESS = '127.0.0.1:8080';
const DEFAULT_AMOUNT = null;

const AGENT_FILES_TO_SYNC = {
    cert: ['.nxlog.id', '.nxlog.id.sig', 'agent-ca.pem', 'agent-cert.pem', 'agent-key.pem'],
    conf: ['managed.conf']
};

export {
    SORT_ORDER,
    TEMPLATE_TABLE_SORT_OPTIONS,
    AGENT_TABLE_SORT_OPTIONS,
    AGENT_SELECTOR,
    DEFAULT_AMOUNT,
    DEFAULT_IP_ADDRESS,
    DEFAULT_ENROLLMENT_PORT,
    DEFAULT_MINDER_ADDRESS,
    TOAST_TYPES,
    LICENSES_STATUS,
    FILTER_BY_TYPE,
    MODE_TYPES,
    ITEMS_PER_PAGE_OPTIONS,
    BANNER_TYPES,
    AGENTS_STATUS,
    MODULE_STATUS,
    MODAL_TYPE,
    CONFIG_ANSWER_TYPES,
    AGENT_TABLE_FILTER_PATHS,
    CONNECT_TYPES,
    OUTPUT_TYPES,
    EMPTY_ROUTE,
    MODULE_LIST,
    STORAGE_KEYS,
    AGENT_FILES_TO_SYNC
};
