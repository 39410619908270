import { useEffect, useRef, useState } from 'react';
import { defaultOperators, fields, groupOperators, notOperators } from '.';
import parseMQLList from './parseMQLList';
import { getAgentsCount } from '../../../api/endpoints/agents';

export default function useMQLAutoComplete({ onChange, onCreate, value }) {
    const [showAutocomplete, setShowAutocomplete] = useState(true);
    const [selectedItemIsChildOfEditor, setSelectedItemIsChildOfEditor] = useState(false);
    const [autocomleteListVisible, setAutocomleteListVisible] = useState(false);
    const [data, setData] = useState(null);
    const [filter, setFilter] = useState('');
    const [autoCompleteList, setAutoCompleteList] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const mqlEditorRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState({
        top: mqlEditorRef.current?.offsetHeight || 39,
        left: 36
    });
    const rangeRef = useRef(null);
    const hasFocus = mqlEditorRef.current === document.activeElement;
    const [filteredAutoCompleteList, setFilteredAutoCompleteList] = useState([]);
    const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(false);
    const getCurrentMQLValue = () =>
        `${mqlEditorRef.current?.textContent || ''}`.trim().replace(/\s/g, ' ');

    useEffect(() => {
        if (hasFocus || autocomleteListVisible || getCurrentMQLValue().length > 0) {
            setIsPlaceholderVisible(false);
        } else {
            setIsPlaceholderVisible(true);
        }
    }, [hasFocus, autocomleteListVisible, getCurrentMQLValue()]);

    useEffect(() => {
        const visible =
            selectedItemIsChildOfEditor &&
            showAutocomplete &&
            hasFocus &&
            filteredAutoCompleteList.length > 0;
        const setVisible = () => setAutocomleteListVisible(visible);
        if (!visible) {
            const t = setTimeout(setVisible, 100);
            return () => clearTimeout(t);
        }
        setVisible();
        return () => null;
    }, [selectedItemIsChildOfEditor, showAutocomplete, hasFocus, filteredAutoCompleteList.length]);

    useEffect(() => {
        const list = autoCompleteList.filter(
            (item) =>
                item.toLowerCase().startsWith(filter.toLowerCase()) ||
                item.toLowerCase().startsWith(`"${filter.toLowerCase()}`)
        );
        list.push(
            ...autoCompleteList.filter((item) => item.toLowerCase().includes(filter.toLowerCase()))
        );
        setFilteredAutoCompleteList([...new Set(list)]);
    }, [autoCompleteList, filter]);

    useEffect(() => {
        const selectedElement = document.querySelector('.mql-editor-autocomplete-item.selected');
        if (selectedElement) {
            selectedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [selectedIndex]);

    useEffect(() => {
        if (data && Array.isArray(data.whating)) {
            const newAutoCompleteList = [];
            Promise.all(
                data.whating.map(async (item) => {
                    switch (item) {
                        case 'field':
                            newAutoCompleteList.push(...fields.map((field) => field.text));
                            break;
                        case 'operator':
                            if (data?.lastField) {
                                const field = fields.find(({ text }) => text === data.lastField);
                                if (field) {
                                    newAutoCompleteList.push(...field.operators);
                                } else {
                                    newAutoCompleteList.push(...defaultOperators);
                                }
                            } else {
                                newAutoCompleteList.push(...defaultOperators);
                            }
                            break;
                        case 'comma':
                            newAutoCompleteList.push(',');
                            break;
                        case 'groupOperators':
                            newAutoCompleteList.push(...groupOperators);
                            break;
                        case 'notOperators':
                            newAutoCompleteList.push(...notOperators);
                            break;
                        case 'value':
                            if (data?.lastField) {
                                const field = fields.find(({ text }) => text === data.lastField);
                                if (field) {
                                    if (typeof field.values === 'function') {
                                        newAutoCompleteList.push(...(await field.values(filter)));
                                    } else if (Array.isArray(field.values)) {
                                        newAutoCompleteList.push(...field.values);
                                    }
                                    if (['string', 'uuid'].includes(field.type)) {
                                        if (!`${filter}`.trim().match(/^"|"$/i)) {
                                            newAutoCompleteList.unshift(`"${filter}"`);
                                        }
                                    }
                                    if (
                                        ['LIKE'].includes(
                                            `${data?.lastOperator}`.trim().toUpperCase()
                                        )
                                    ) {
                                        if (`${filter}`.trim() && !`${filter}`.includes('%')) {
                                            newAutoCompleteList.push(
                                                `${filter}`.replace(/^"?%?(.*)%?"?$/i, `"%$1%"`)
                                            );
                                            newAutoCompleteList.push(
                                                `${filter}`.replace(/^"?%?(.*)%?"?$/i, `"%$1"`)
                                            );
                                            newAutoCompleteList.push(
                                                `${filter}`.replace(/^"?%?(.*)%?"?$/i, `"$1%"`)
                                            );
                                        }
                                    }
                                }
                            }
                            if (
                                ['NOT', 'IN', '!'].includes(data?.lastOperator) &&
                                !data?.lastOperatorIsOpen
                            ) {
                                newAutoCompleteList.unshift('( ... )');
                            }
                            break;
                        default:
                            break;
                    }
                })
            ).then(() => {
                const uniqueAutoCompleteList = [...new Set(newAutoCompleteList)];
                setAutoCompleteList(uniqueAutoCompleteList);
            });
        }
    }, [data, filter]);

    const handleInput = () => {
        const { top, left } = mqlEditorRef.current.getBoundingClientRect();
        const selection = window.getSelection();
        if (selection.rangeCount > 0 && mqlEditorRef.current.contains(selection.anchorNode)) {
            rangeRef.current = selection.getRangeAt(0);
            const rect = rangeRef.current.getBoundingClientRect();
            setCursorPosition({
                top: Math.max(rect.top - top + 30, mqlEditorRef.current?.offsetHeight || 39),
                left: Math.max(rect.left - left, 36)
            });
            setSelectedIndex(0);
        }
        if (onChange) onChange({ target: { value: getCurrentMQLValue() } });
        setShowAutocomplete(true);

        const textNodes = [];
        const childNodes = Array.from(mqlEditorRef.current.childNodes);
        childNodes.forEach((child) => {
            if (child.nodeName === 'SPAN') {
                if (!child.textContent) {
                    child.remove();
                    return;
                }
                const textNodesList = Array.from(child.childNodes).filter(
                    (node) => node.nodeType === Node.TEXT_NODE
                );
                if (textNodesList.length > 1 && child.childNodes.length === textNodesList.length) {
                    textNodesList.forEach((textNode) => {
                        if (textNode.textContent) {
                            const spanNode = document.createElement('span');
                            spanNode.textContent = textNode.textContent;
                            child.before(spanNode);
                        }
                    });
                    child.remove();
                }
            }
        });

        const walker = document.createTreeWalker(
            mqlEditorRef.current,
            NodeFilter.SHOW_TEXT,
            null,
            false
        );
        while (walker.nextNode()) {
            if (rangeRef.current && walker.currentNode === rangeRef.current.endContainer) {
                textNodes.push(
                    rangeRef.current.endContainer.textContent.substr(0, rangeRef.current.endOffset)
                );
                break;
            }
            textNodes.push(walker.currentNode.textContent);
        }
        const textContent = textNodes.join('');
        const listKeys = textContent.split(/\s+/i);
        const newFilter = listKeys.pop() || '';
        setFilter(newFilter);
        setData({ ...parseMQLList(listKeys.join(' ')), listKeys, textContent, newFilter });
    };

    useEffect(() => {
        const handleSelectionChange = () => {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const isChildOfEditor = mqlEditorRef.current.contains(selection.anchorNode);
                const selectedText = selection.toString();
                setSelectedItemIsChildOfEditor(!selectedText && isChildOfEditor);
            } else {
                setSelectedItemIsChildOfEditor(false);
            }
        };
        document.addEventListener('selectionchange', handleSelectionChange);
        return () => {
            document.removeEventListener('selectionchange', handleSelectionChange);
        };
    }, []);

    const insertSelectedItem = (txt) => {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = rangeRef.current;
            try {
                range.setStart(range.startContainer, range.startOffset - filter.length);
            } catch (error) {
                /**/
            }
            try {
                range.deleteContents();
            } catch (error) {
                /**/
            }
            const parsedMQL = parseMQLList(`${getCurrentMQLValue()} ${txt} `);
            let openAutoComplete = false;
            if (!parsedMQL.whating.includes('groupOperators')) {
                txt = `${txt}\u00A0`;
                openAutoComplete = true;
            }
            if (txt.includes('( ... )')) {
                txt = txt.replace('( ... )', '(\u00A0\u00A0)');
            }
            const textNode = document.createTextNode(txt);
            const index = textNode.textContent.length + (txt.includes(')') ? -3 : 0);
            range.insertNode(textNode);
            const newRange = document.createRange();
            newRange.selectNode(textNode);
            newRange.setStart(textNode, index);
            newRange.setEnd(textNode, index);
            newRange.collapse(true);
            selection.removeAllRanges();
            selection.addRange(newRange);
            handleInput({ target: newRange });
            setShowAutocomplete(openAutoComplete);
        }
    };

    const seletcEndOfString = () => {
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(mqlEditorRef.current);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
        mqlEditorRef.current.focus();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
            if (event.key === 'ArrowUp') {
                setSelectedIndex(Math.max(selectedIndex - 1, 0));
                return;
            }
            setSelectedIndex(Math.min(selectedIndex + 1, filteredAutoCompleteList.length - 1));
            return;
        }

        if (event.key === 'Enter') {
            event.preventDefault();
            if (autocomleteListVisible) {
                insertSelectedItem(filteredAutoCompleteList[selectedIndex]);
                return;
            }
            const { whating } = parseMQLList(`${getCurrentMQLValue()} `);
            if (whating.includes('groupOperators') && getCurrentMQLValue()) {
                const mql = getCurrentMQLValue();
                seletcEndOfString();
                getAgentsCount(mql)
                    .then(() => {
                        if (onCreate) {
                            mqlEditorRef.current.textContent = '';
                            onCreate(mql);
                            onChange({ target: { value: mql } });
                        }
                    })
                    .catch(({ message }) => {
                        if (typeof message === 'string') {
                            const match = message.match(/at ([0-9]+)\.\.([0-9]+)$/i);
                            if (match) {
                                const [start, end] = match.slice(1).map(Number);
                                const errorSpan = document.createElement('span');
                                errorSpan.className = 'error';
                                // eslint-disable-next-line prefer-destructuring
                                errorSpan.title = message.split(', input:')[0];
                                errorSpan.textContent = mql.substring(start, end);

                                const beforeText = document.createTextNode(mql.substring(0, start));
                                const afterText = document.createTextNode(mql.substring(end));

                                mqlEditorRef.current.innerHTML = '';
                                mqlEditorRef.current.appendChild(beforeText);
                                mqlEditorRef.current.appendChild(errorSpan);
                                mqlEditorRef.current.appendChild(afterText);
                                seletcEndOfString();
                            }
                        }
                    });
                return;
            }
        }

        if (event.key === 'Escape') {
            event.preventDefault();
            setShowAutocomplete(false);
        }
    };

    useEffect(() => {
        if (typeof value === 'string' && mqlEditorRef.current) {
            if (getCurrentMQLValue() !== value) {
                mqlEditorRef.current.textContent = value;
            }
        }
    }, [value]);

    const handleKeyUp = (event) => {
        if (['ArrowUp', 'ArrowDown', 'Enter', 'Escape'].includes(event.key)) {
            event.preventDefault();
            return;
        }
        handleInput(event);
        const spans = mqlEditorRef.current.querySelectorAll('span');
        spans.forEach((span) => {
            span.className = '';
        });
    };

    const handleClickItem = (item) => () => {
        setSelectedIndex(filteredAutoCompleteList.indexOf(item));
        insertSelectedItem(item);
    };

    const handleBlur = () => {
        setShowAutocomplete(false);
    };

    const handleFocus = (event) => {
        handleInput(event);
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const text = event.clipboardData.getData('text/plain');
        document.execCommand('insertText', false, text);
        handleInput(event);
    };

    return {
        cursorPosition,
        mqlEditorRef,
        autocomleteListVisible,
        filteredAutoCompleteList,
        selectedIndex,
        handleBlur,
        handleClickItem,
        handleInput,
        handleKeyDown,
        handleKeyUp,
        handlePaste,
        handleFocus,
        isPlaceholderVisible
    };
}
