import { createSlice } from '@reduxjs/toolkit';
import { deleteFromStorage, getFromStorage, putIntoStorage } from '../../api/endpoints/storage';
import { DEFAULT_AMOUNT } from '../../utils/constants/ids';
import { createAsyncThunkWithErrorHandling } from '../asyncThunkHelper';

export const getFromStorageData = createAsyncThunkWithErrorHandling(
    'storage/getFromStorage',
    async (key) => {
        const response = await getFromStorage(key);
        if (typeof response === 'object') {
            return response.data || null;
        }
        return response;
    }
);

export const putIntoStorageData = createAsyncThunkWithErrorHandling(
    'storage/putIntoStorage',
    async ({ key, value }) => {
        const response = await putIntoStorage(key, value);
        return response.data;
    }
);

export const deleteFromStorageData = createAsyncThunkWithErrorHandling(
    'storage/deleteFromStorage',
    async (key) => {
        const response = await deleteFromStorage(key);
        return response.data;
    }
);

const initialState = {
    loading: false,
    storage: {
        tenantId: null,
        ip: null,
        agentsLimit: DEFAULT_AMOUNT,
        $unavailableItems: []
    },
    error: null
};

const storageSlice = createSlice({
    name: 'storage',
    initialState,
    reducers: {
        setTenantId: (state, { payload }) => {
            state.tenantId = payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFromStorageData.fulfilled, (state, action) => {
                const key = action.meta.arg;
                state.storage[key] = action.payload;
                state.loading = false;
                if (!Array.isArray(state.storage.$unavailableItems))
                    state.storage.$unavailableItems = [];
                state.storage.$unavailableItems = state.storage.$unavailableItems.filter(
                    (name) => name !== key
                );
            })
            .addCase(getFromStorageData.rejected, (state, action) => {
                state.error = action.payload;
                const key = action.meta.arg;
                if (!Array.isArray(state.storage.$unavailableItems))
                    state.storage.$unavailableItems = [];
                if (!state.storage.$unavailableItems.includes(key)) {
                    state.storage.$unavailableItems = [...state.storage.$unavailableItems, key];
                }
                state.loading = false;
            })
            .addCase(putIntoStorageData.fulfilled, (state, action) => {
                const { key, value } = action.meta.arg;
                state.storage[key] = value;
                state.loading = false;
            })
            .addCase(putIntoStorageData.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(deleteFromStorageData.fulfilled, (state, action) => {
                const key = action.meta.arg;
                delete state.storage[key];
                state.loading = false;
            })
            .addCase(deleteFromStorageData.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
});

export const selectValueFromStorage = (key) => (state) => state.storage.storage[key];

export const selectUnavailableStorageItems = (state) => state.storage.storage.$unavailableItems;

export const { setTenantId } = storageSlice.actions;

export default storageSlice.reducer;
