import { GetMenuIcon } from '../../utils/helpers/functions/index';

export const modules = [
    {
        data: [
            {
                id: '1',
                name: 'in internal',
                module: 'Internal messages input (im_internal)',
                status: 'ok'
            },
            {
                id: '2',
                name: 'Internal',
                module: 'Event tracing for wuindows (im_eow)',
                status: 'error'
            }
        ],
        title: 'Input'
    },
    {
        data: [
            {
                id: '1',
                name: 'Norep',
                module: 'pm_norepeat',
                status: 'ok'
            }
        ],
        title: 'Processors'
    },
    {
        data: [
            {
                id: '2',
                name: 'In internal',
                module: 'Internal messages input (im_internal)',
                status: 'ok'
            }
        ],
        title: 'Output'
    }
];

export const SidebarMenuTop = [
    {
        name: 'Home',
        path: '/',
        icon: GetMenuIcon({ iconName: 'HomeIcon' })
    },
    {
        name: 'Agents',
        path: '/agents',
        icon: GetMenuIcon({ iconName: 'AgentsIcon' })
    },
    {
        name: 'Configuration templates',
        path: '/configuration',
        icon: GetMenuIcon({ iconName: 'ConfigurationIcon' })
    },
    {
        name: 'Licenses',
        path: '/licenses',
        icon: GetMenuIcon({ iconName: 'LicensesIcon' })
    }
];

export const UserManagementInfo = [
    {
        number: 16,
        role: 'Super Admin',
        dropdownTitle: 'See all super admin',
        options: [
            { value: 'admin', label: 'Admin' },
            { value: 'basicUser', label: 'Basic user' },
            { value: 'manager', label: 'Manager' },
            { value: 'superUser', label: 'Super User' },
            { value: 'user', label: 'User' }
        ]
    },
    {
        number: 25,
        role: 'Manager',
        dropdownTitle: 'See all manager',
        options: [
            { value: 'manager1', label: 'Manager1' },
            { value: 'manager2', label: 'Manager2' },
            { value: 'manager3', label: 'Manager3' }
        ]
    },
    {
        number: 125,
        role: 'Warnings',
        dropdownTitle: 'See all user',
        options: [
            { value: 'admin', label: 'Admin' },
            { value: 'basicUser', label: 'Basic user' },
            { value: 'manager', label: 'Manager' },
            { value: 'superUser', label: 'Super User' },
            { value: 'user', label: 'User' }
        ]
    },
    {
        number: 12,
        role: 'Untrusted',
        dropdownTitle: 'See all basic user',
        options: [
            { value: 'admin', label: 'Admin' },
            { value: 'basicUser', label: 'Basic user' },
            { value: 'manager', label: 'Manager' },
            { value: 'superUser', label: 'Super User' },
            { value: 'user', label: 'User' }
        ]
    }
];

export const ApiSecurityData = [
    {
        id: 1,
        name: 'Squid flexitarian master cleanse food truck palo1',
        status: 'Active',
        created_by: 'User name1',
        created_on: '2021.11.20. I 07:00',
        expires_on: '2021.11.20. I 08:00'
    },
    {
        id: 2,
        name: 'Squid flexitarian master cleanse food truck palo2',
        status: 'Disabled',
        created_by: 'User name2',
        created_on: '2021.11.20. I 08:00',
        expires_on: '2021.11.20. I 09:00'
    },
    {
        id: 3,
        name: 'Squid flexitarian master cleanse food truck palo3',
        status: 'Active',
        created_by: 'User name3',
        created_on: '2021.11.20. I 09:00',
        expires_on: '2021.11.20. I 10:00'
    }
];

export const UserManagementData = [
    {
        id: 1,
        name: 'Precious Harwood',
        type: 'Super Admin',
        access: 'Full',
        active: 'Yes',
        account: '2021.10.06. I 08:25',
        password: '2021.10.06. I 08:25',
        login: '2021.10.06. I 08:25'
    },
    {
        id: 2,
        name: 'Candice Mcculloch',
        type: 'Manager',
        access: 'Single group',
        active: 'Yes',
        account: 'Never',
        password: 'Never',
        login: '2021.10.06. I 08:25'
    },
    {
        id: 3,
        name: 'Zayna Hendrix',
        type: 'Basic user',
        access: 'Full',
        active: 'No',
        account: 'Never',
        password: '2021.10.06. I 08:25',
        login: '2021.10.06. I 08:25'
    },
    {
        id: 4,
        name: 'Haris Stanley',
        type: 'User',
        access: 'Basic',
        active: 'No',
        account: 'Never',
        password: '2021.10.06. I 08:25',
        login: '2021.10.06. I 08:25'
    },
    {
        id: 5,
        name: 'Emily Jordan',
        type: 'User',
        access: 'Single group',
        active: 'No',
        account: 'Never',
        password: 'Never',
        login: '2021.10.06. I 08:25'
    },
    {
        id: 6,
        name: 'Ilyas Vang',
        type: 'Manager',
        access: 'Single group',
        active: 'Yes',
        account: 'Never',
        password: '2021.10.06. I 08:25',
        login: '2021.10.06. I 08:25'
    },
    {
        id: 7,
        name: 'Osian Mcclain',
        type: 'Super Admin',
        access: 'Full',
        active: 'Yes',
        account: '2021.10.06. I 08:25',
        password: '2021.10.06. I 08:25',
        login: '2021.10.06. I 08:25'
    },
    {
        id: 8,
        name: 'Arisha Devlin',
        type: 'Manager',
        access: 'Single group',
        active: 'Yes',
        account: '2021.10.06. I 08:25',
        password: 'Never',
        login: '2021.10.06. I 08:25'
    },
    {
        id: 9,
        name: 'Emilie Cassidy',
        type: 'Super Admin',
        access: 'Full',
        active: 'Yes',
        account: '2021.10.06. I 08:25',
        password: '2021.10.06. I 08:25',
        login: '2021.10.06. I 08:25'
    },
    {
        id: 10,
        name: 'Anwar Nelson',
        type: 'Manager',
        access: 'Single group',
        active: 'Yes',
        account: '2021.10.06. I 08:25',
        password: '2021.10.06. I 08:25',
        login: '2021.10.06. I 08:25'
    },
    {
        id: 11,
        name: 'Tomasz Melendez',
        type: 'Super Admin',
        access: 'Full',
        active: 'Yes',
        account: '2021.10.06. I 08:25',
        password: '2021.10.06. I 08:25',
        login: '2021.10.06. I 08:25'
    },
    {
        id: 12,
        name: 'Raveena Bullock',
        type: 'Manager',
        access: 'Full',
        active: 'Yes',
        account: '2021.10.06. I 08:25',
        password: '2021.10.06. I 08:25',
        login: '2021.10.06. I 08:25'
    }
];

export const SidebarMenuBottom = [
    {
        name: 'Notifications',
        path: 'notifications',
        icon: GetMenuIcon({ iconName: 'NotificationsIcon' }),
        position: 'b'
    },
    {
        name: 'Product download',
        path: 'product-download',
        icon: GetMenuIcon({ iconName: 'SupportIcon' }),
        position: 'b'
    },
    {
        name: "What's new",
        path: 'what-is-new',
        icon: GetMenuIcon({ iconName: 'WhatIsNewIcon' }),
        position: 'b'
    },
    {
        name: 'Support',
        path: 'support',
        icon: GetMenuIcon({ iconName: 'SupportIcon' }),
        position: 'b'
    },
    {
        name: 'Settings',
        path: 'settings',
        icon: GetMenuIcon({ iconName: 'SettingsIcon' }),
        position: 'b'
    }
];

export const SearchData = ['iri', 'Alexa', 'Google', 'Facebook', 'Twitter', 'Linkedin', 'Sinkedin'];

export const ClusterData = [
    {
        id: 1,
        status: 'ok',
        clustername: 'Node-5',
        role_of_members: 'Follower',
        uptime: '2021.02.16. I 12:45',
        timezone: '(UTC+01:00) Budapest',
        cpu_percentage: '80 %',
        load: 1,
        memory: '68 %',
        agent_connections: 4,
        connectMode: 3,
        listenMode: 1,
        evtRecvd: 171965,
        evtDrop: 0,
        evtFwd: 171965,
        queueSize: 0,
        queueLimit: 0,
        batchSize: 50,
        cpuDates: [
            ['2000-06-05', 116],
            ['2000-06-06', 129],
            ['2000-06-07', 135],
            ['2000-06-08', 86],
            ['2000-06-09', 73],
            ['2000-06-10', 85],
            ['2000-06-11', 73],
            ['2000-06-12', 68],
            ['2000-06-13', 92],
            ['2000-06-14', 130],
            ['2000-06-15', 245],
            ['2000-06-16', 139],
            ['2000-06-17', 115],
            ['2000-06-18', 111],
            ['2000-06-19', 309],
            ['2000-06-20', 206],
            ['2000-06-21', 137],
            ['2000-06-22', 128],
            ['2000-06-23', 85],
            ['2000-06-24', 94],
            ['2000-06-25', 71],
            ['2000-06-26', 106],
            ['2000-06-27', 84],
            ['2000-06-28', 93],
            ['2000-06-29', 85],
            ['2000-06-30', 73],
            ['2000-07-01', 83],
            ['2000-07-02', 125],
            ['2000-07-03', 107],
            ['2000-07-04', 82],
            ['2000-07-05', 44],
            ['2000-07-06', 72],
            ['2000-07-07', 106],
            ['2000-07-08', 107],
            ['2000-07-09', 66],
            ['2000-07-10', 91],
            ['2000-07-11', 92],
            ['2000-07-12', 113],
            ['2000-07-13', 107],
            ['2000-07-14', 131],
            ['2000-07-15', 111],
            ['2000-07-16', 64]
        ],
        memoryDates: [
            ['2000-06-05', 116],
            ['2000-06-06', 129],
            ['2000-06-07', 135],
            ['2000-06-08', 86],
            ['2000-06-09', 73],
            ['2000-06-10', 85],
            ['2000-06-11', 73],
            ['2000-06-12', 68],
            ['2000-06-13', 92],
            ['2000-06-14', 130],
            ['2000-06-15', 245],
            ['2000-06-16', 139],
            ['2000-06-17', 115],
            ['2000-06-18', 111],
            ['2000-06-19', 309],
            ['2000-06-20', 206],
            ['2000-06-21', 137],
            ['2000-06-22', 128],
            ['2000-06-23', 85],
            ['2000-06-24', 94],
            ['2000-06-25', 71],
            ['2000-06-26', 106],
            ['2000-06-27', 84],
            ['2000-06-28', 93],
            ['2000-06-29', 85],
            ['2000-06-30', 73],
            ['2000-07-01', 83],
            ['2000-07-02', 125],
            ['2000-07-03', 107],
            ['2000-07-04', 82],
            ['2000-07-05', 44],
            ['2000-07-06', 72],
            ['2000-07-07', 106],
            ['2000-07-08', 107],
            ['2000-07-09', 66],
            ['2000-07-10', 91],
            ['2000-07-11', 92],
            ['2000-07-12', 113],
            ['2000-07-13', 107],
            ['2000-07-14', 131],
            ['2000-07-15', 111],
            ['2000-07-16', 64]
        ]
    },
    {
        id: 2,
        status: 'ok',
        clustername: 'Node-4',
        role_of_members: 'Follower',
        uptime: '2021.03.16. I 12:45',
        timeZone: '(UTC+01:00) Budapest',
        cpu_percentage: '46 %',
        load: 4,
        memory: '68 %',
        agent_connections: 2,
        connectMode: 2,
        listenMode: 1,
        evtRecvd: 191965,
        evtDrop: 0,
        evtFwd: 141965,
        queueSize: 0,
        queueLimit: 0,
        batchSize: 50,
        cpuDates: [
            ['2000-06-05', 116],
            ['2000-06-06', 129],
            ['2000-06-07', 135],
            ['2000-06-08', 86],
            ['2000-06-09', 73],
            ['2000-06-10', 85],
            ['2000-06-11', 73],
            ['2000-06-12', 68],
            ['2000-06-13', 92],
            ['2000-06-14', 130],
            ['2000-06-15', 245],
            ['2000-06-16', 139],
            ['2000-06-17', 115],
            ['2000-06-18', 111],
            ['2000-06-19', 309],
            ['2000-06-20', 206],
            ['2000-06-21', 137],
            ['2000-06-22', 128],
            ['2000-06-23', 85],
            ['2000-06-24', 94],
            ['2000-06-25', 71],
            ['2000-06-26', 106],
            ['2000-06-27', 84],
            ['2000-06-28', 93],
            ['2000-06-29', 85],
            ['2000-06-30', 73],
            ['2000-07-01', 83],
            ['2000-07-02', 125],
            ['2000-07-03', 107],
            ['2000-07-04', 82],
            ['2000-07-05', 44],
            ['2000-07-06', 72],
            ['2000-07-07', 106],
            ['2000-07-08', 107],
            ['2000-07-09', 66],
            ['2000-07-10', 91],
            ['2000-07-11', 92],
            ['2000-07-12', 113],
            ['2000-07-13', 107],
            ['2000-07-14', 131],
            ['2000-07-15', 111],
            ['2000-07-16', 64]
        ],
        memoryDates: [
            ['2000-06-05', 116],
            ['2000-06-06', 129],
            ['2000-06-07', 135],
            ['2000-06-08', 86],
            ['2000-06-09', 73],
            ['2000-06-10', 85],
            ['2000-06-11', 73],
            ['2000-06-12', 68],
            ['2000-06-13', 92],
            ['2000-06-14', 130],
            ['2000-06-15', 245],
            ['2000-06-16', 139],
            ['2000-06-17', 115],
            ['2000-06-18', 111],
            ['2000-06-19', 309],
            ['2000-06-20', 206],
            ['2000-06-21', 137],
            ['2000-06-22', 128],
            ['2000-06-23', 85],
            ['2000-06-24', 94],
            ['2000-06-25', 71],
            ['2000-06-26', 106],
            ['2000-06-27', 84],
            ['2000-06-28', 93],
            ['2000-06-29', 85],
            ['2000-06-30', 73],
            ['2000-07-01', 83],
            ['2000-07-02', 125],
            ['2000-07-03', 107],
            ['2000-07-04', 82],
            ['2000-07-05', 44],
            ['2000-07-06', 72],
            ['2000-07-07', 106],
            ['2000-07-08', 107],
            ['2000-07-09', 66],
            ['2000-07-10', 91],
            ['2000-07-11', 92],
            ['2000-07-12', 113],
            ['2000-07-13', 107],
            ['2000-07-14', 131],
            ['2000-07-15', 111],
            ['2000-07-16', 64]
        ]
    },
    {
        id: 3,
        status: 'offline',
        clustername: 'Node-3',
        role_of_members: '-',
        uptime: '2021.04.16. I 12:45',
        timeZone: '(UTC+01:00) Budapest',
        cpu_percentage: '0 %',
        load: 4,
        memory: '0 %',
        agent_connections: 2,
        connectMode: 1,
        listenMode: 1,
        evtRecvd: 177965,
        evtDrop: 0,
        evtFwd: 181965,
        queueSize: 0,
        queueLimit: 0,
        batchSize: 50,
        cpuDates: [
            ['2000-06-05', 116],
            ['2000-06-06', 129],
            ['2000-06-07', 135],
            ['2000-06-08', 86],
            ['2000-06-09', 73],
            ['2000-06-10', 85],
            ['2000-06-11', 73],
            ['2000-06-12', 68],
            ['2000-06-13', 92],
            ['2000-06-14', 130],
            ['2000-06-15', 245],
            ['2000-06-16', 139],
            ['2000-06-17', 115],
            ['2000-06-18', 111],
            ['2000-06-19', 309],
            ['2000-06-20', 206],
            ['2000-06-21', 137],
            ['2000-06-22', 128],
            ['2000-06-23', 85],
            ['2000-06-24', 94],
            ['2000-06-25', 71],
            ['2000-06-26', 106],
            ['2000-06-27', 84],
            ['2000-06-28', 93],
            ['2000-06-29', 85],
            ['2000-06-30', 73],
            ['2000-07-01', 83],
            ['2000-07-02', 125],
            ['2000-07-03', 107],
            ['2000-07-04', 82],
            ['2000-07-05', 44],
            ['2000-07-06', 72],
            ['2000-07-07', 106],
            ['2000-07-08', 107],
            ['2000-07-09', 66],
            ['2000-07-10', 91],
            ['2000-07-11', 92],
            ['2000-07-12', 113],
            ['2000-07-13', 107],
            ['2000-07-14', 131],
            ['2000-07-15', 111],
            ['2000-07-16', 64]
        ],
        memoryDates: [
            ['2000-06-05', 116],
            ['2000-06-06', 129],
            ['2000-06-07', 135],
            ['2000-06-08', 86],
            ['2000-06-09', 73],
            ['2000-06-10', 85],
            ['2000-06-11', 73],
            ['2000-06-12', 68],
            ['2000-06-13', 92],
            ['2000-06-14', 130],
            ['2000-06-15', 245],
            ['2000-06-16', 139],
            ['2000-06-17', 115],
            ['2000-06-18', 111],
            ['2000-06-19', 309],
            ['2000-06-20', 206],
            ['2000-06-21', 137],
            ['2000-06-22', 128],
            ['2000-06-23', 85],
            ['2000-06-24', 94],
            ['2000-06-25', 71],
            ['2000-06-26', 106],
            ['2000-06-27', 84],
            ['2000-06-28', 93],
            ['2000-06-29', 85],
            ['2000-06-30', 73],
            ['2000-07-01', 83],
            ['2000-07-02', 125],
            ['2000-07-03', 107],
            ['2000-07-04', 82],
            ['2000-07-05', 44],
            ['2000-07-06', 72],
            ['2000-07-07', 106],
            ['2000-07-08', 107],
            ['2000-07-09', 66],
            ['2000-07-10', 91],
            ['2000-07-11', 92],
            ['2000-07-12', 113],
            ['2000-07-13', 107],
            ['2000-07-14', 131],
            ['2000-07-15', 111],
            ['2000-07-16', 64]
        ]
    },
    {
        id: 4,
        status: 'error',
        clustername: 'Node-2',
        role_of_members: 'Leader',
        uptime: '2021.05.16. I 12:45',
        timeZone: '(UTC+01:00) Budapest',
        cpu_percentage: '80 %',
        load: 1,
        memory: '68 %',
        agent_connections: 4,
        connectMode: 3,
        listenMode: 1,
        evtRecvd: 172965,
        evtDrop: 0,
        evtFwd: 171965,
        queueSize: 0,
        queueLimit: 0,
        batchSize: 30,
        cpuDates: [
            ['2000-06-05', 116],
            ['2000-06-06', 129],
            ['2000-06-07', 135],
            ['2000-06-08', 86],
            ['2000-06-09', 73],
            ['2000-06-10', 85],
            ['2000-06-11', 73],
            ['2000-06-12', 68],
            ['2000-06-13', 92],
            ['2000-06-14', 130],
            ['2000-06-15', 245],
            ['2000-06-16', 139],
            ['2000-06-17', 115],
            ['2000-06-18', 111],
            ['2000-06-19', 309],
            ['2000-06-20', 206],
            ['2000-06-21', 137],
            ['2000-06-22', 128],
            ['2000-06-23', 85],
            ['2000-06-24', 94],
            ['2000-06-25', 71],
            ['2000-06-26', 106],
            ['2000-06-27', 84],
            ['2000-06-28', 93],
            ['2000-06-29', 85],
            ['2000-06-30', 73],
            ['2000-07-01', 83],
            ['2000-07-02', 125],
            ['2000-07-03', 107],
            ['2000-07-04', 82],
            ['2000-07-05', 44],
            ['2000-07-06', 72],
            ['2000-07-07', 106],
            ['2000-07-08', 107],
            ['2000-07-09', 66],
            ['2000-07-10', 91],
            ['2000-07-11', 92],
            ['2000-07-12', 113],
            ['2000-07-13', 107],
            ['2000-07-14', 131],
            ['2000-07-15', 111],
            ['2000-07-16', 64]
        ],
        memoryDates: [
            ['2000-06-05', 116],
            ['2000-06-06', 129],
            ['2000-06-07', 135],
            ['2000-06-08', 86],
            ['2000-06-09', 73],
            ['2000-06-10', 85],
            ['2000-06-11', 73],
            ['2000-06-12', 68],
            ['2000-06-13', 92],
            ['2000-06-14', 130],
            ['2000-06-15', 245],
            ['2000-06-16', 139],
            ['2000-06-17', 115],
            ['2000-06-18', 111],
            ['2000-06-19', 309],
            ['2000-06-20', 206],
            ['2000-06-21', 137],
            ['2000-06-22', 128],
            ['2000-06-23', 85],
            ['2000-06-24', 94],
            ['2000-06-25', 71],
            ['2000-06-26', 106],
            ['2000-06-27', 84],
            ['2000-06-28', 93],
            ['2000-06-29', 85],
            ['2000-06-30', 73],
            ['2000-07-01', 83],
            ['2000-07-02', 125],
            ['2000-07-03', 107],
            ['2000-07-04', 82],
            ['2000-07-05', 44],
            ['2000-07-06', 72],
            ['2000-07-07', 106],
            ['2000-07-08', 107],
            ['2000-07-09', 66],
            ['2000-07-10', 91],
            ['2000-07-11', 92],
            ['2000-07-12', 113],
            ['2000-07-13', 107],
            ['2000-07-14', 131],
            ['2000-07-15', 111],
            ['2000-07-16', 64]
        ]
    },
    {
        id: 5,
        status: 'error',
        clustername: 'Node-2',
        role_of_members: 'Leader',
        uptime: '2021.05.16. I 12:45',
        timeZone: '(UTC+01:00) Budapest',
        cpu_percentage: '80 %',
        load: 1,
        memory: '68 %',
        agent_connections: 4,
        connectMode: 3,
        listenMode: 2,
        evtRecvd: 171065,
        evtDrop: 0,
        evtFwd: 171065,
        queueSize: 0,
        queueLimit: 0,
        batchSize: 60,
        cpuDates: [
            ['2000-06-05', 116],
            ['2000-06-06', 129],
            ['2000-06-07', 135],
            ['2000-06-08', 86],
            ['2000-06-09', 73],
            ['2000-06-10', 85],
            ['2000-06-11', 73],
            ['2000-06-12', 68],
            ['2000-06-13', 92],
            ['2000-06-14', 130],
            ['2000-06-15', 245],
            ['2000-06-16', 139],
            ['2000-06-17', 115],
            ['2000-06-18', 111],
            ['2000-06-19', 309],
            ['2000-06-20', 206],
            ['2000-06-21', 137],
            ['2000-06-22', 128],
            ['2000-06-23', 85],
            ['2000-06-24', 94],
            ['2000-06-25', 71],
            ['2000-06-26', 106],
            ['2000-06-27', 84],
            ['2000-06-28', 93],
            ['2000-06-29', 85],
            ['2000-06-30', 73],
            ['2000-07-01', 83],
            ['2000-07-02', 125],
            ['2000-07-03', 107],
            ['2000-07-04', 82],
            ['2000-07-05', 44],
            ['2000-07-06', 72],
            ['2000-07-07', 106],
            ['2000-07-08', 107],
            ['2000-07-09', 66],
            ['2000-07-10', 91],
            ['2000-07-11', 92],
            ['2000-07-12', 113],
            ['2000-07-13', 107],
            ['2000-07-14', 131],
            ['2000-07-15', 111],
            ['2000-07-16', 64]
        ],
        memoryDates: [
            ['2000-06-05', 116],
            ['2000-06-06', 129],
            ['2000-06-07', 135],
            ['2000-06-08', 86],
            ['2000-06-09', 73],
            ['2000-06-10', 85],
            ['2000-06-11', 73],
            ['2000-06-12', 68],
            ['2000-06-13', 92],
            ['2000-06-14', 130],
            ['2000-06-15', 245],
            ['2000-06-16', 139],
            ['2000-06-17', 115],
            ['2000-06-18', 111],
            ['2000-06-19', 309],
            ['2000-06-20', 206],
            ['2000-06-21', 137],
            ['2000-06-22', 128],
            ['2000-06-23', 85],
            ['2000-06-24', 94],
            ['2000-06-25', 71],
            ['2000-06-26', 106],
            ['2000-06-27', 84],
            ['2000-06-28', 93],
            ['2000-06-29', 85],
            ['2000-06-30', 73],
            ['2000-07-01', 83],
            ['2000-07-02', 125],
            ['2000-07-03', 107],
            ['2000-07-04', 82],
            ['2000-07-05', 44],
            ['2000-07-06', 72],
            ['2000-07-07', 106],
            ['2000-07-08', 107],
            ['2000-07-09', 66],
            ['2000-07-10', 91],
            ['2000-07-11', 92],
            ['2000-07-12', 113],
            ['2000-07-13', 107],
            ['2000-07-14', 131],
            ['2000-07-15', 111],
            ['2000-07-16', 64]
        ]
    },
    {
        id: 6,
        status: 'ok',
        clustername: 'Node-1',
        role_of_members: 'Follower',
        uptime: '2021.05.16. I 12:45',
        timeZone: '(UTC+01:00) Budapest',
        cpu_percentage: '46 %',
        load: 1,
        memory: '88 %',
        agent_connections: 2,
        connectMode: 3,
        listenMode: 1,
        evtRecvd: 101965,
        evtDrop: 1,
        evtFwd: 181965,
        queueSize: 0,
        queueLimit: 0,
        batchSize: 40,
        cpuDates: [
            ['2000-06-14', 130],
            ['2000-06-15', 245],
            ['2000-06-16', 139],
            ['2000-06-17', 115],
            ['2000-06-18', 111],
            ['2000-06-19', 309],
            ['2000-06-20', 206],
            ['2000-06-21', 137],
            ['2000-06-22', 128],
            ['2000-06-23', 85],
            ['2000-06-24', 94],
            ['2000-06-25', 71],
            ['2000-06-26', 106],
            ['2000-06-27', 84],
            ['2000-06-28', 93],
            ['2000-06-29', 85],
            ['2000-06-30', 73],
            ['2000-07-01', 83],
            ['2000-07-02', 125],
            ['2000-07-03', 107],
            ['2000-07-04', 82],
            ['2000-07-05', 44],
            ['2000-07-06', 72],
            ['2000-07-07', 106],
            ['2000-07-08', 107],
            ['2000-07-09', 66],
            ['2000-07-10', 91],
            ['2000-07-11', 92],
            ['2000-07-12', 113],
            ['2000-07-13', 107],
            ['2000-07-14', 131],
            ['2000-07-15', 111],
            ['2000-07-16', 64]
        ],
        memoryDates: [
            ['2000-06-05', 116],
            ['2000-06-08', 86],
            ['2000-07-14', 131],
            ['2000-07-15', 111],
            ['2000-07-16', 64]
        ]
    }
];

export const TableData = [
    {
        id: 1,
        agentStatus: 'error',
        agentName: 'agent-1',
        agentIp: '172.16.21.1',
        agentEps: 0,
        agentMemory: '1 250.02 MB',
        agentLoad: '0.01',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'relay_out'
    },
    {
        id: 2,
        agentStatus: 'error',
        agentName: 'agent-2',
        agentIp: '172.16.22.2',
        agentEps: 0,
        agentMemory: '6 MB',
        agentLoad: '0.02',
        agentDeployment: 'configured',
        agentGroup: '-',
        agentTemplate: 'relay_in'
    },
    {
        id: 3,
        agentStatus: 'warning',
        agentName: 'agent-3',
        agentIp: '192.178.16.211',
        agentEps: 0,
        agentMemory: '1 250.02 MB',
        agentLoad: '1',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Windows Base'
    },
    {
        id: 4,
        agentStatus: 'warning',
        agentName: 'agent-4',
        agentIp: '192.178.200.11',
        agentEps: 0,
        agentMemory: '252 MB',
        agentLoad: '5',
        agentDeployment: 'configured',
        agentGroup: 'netdev relays',
        agentTemplate: 'Windows IIS'
    },
    {
        id: 5,
        agentStatus: 'ok',
        agentName: 'agent-5',
        agentIp: '192.178.11.2',
        agentEps: 0,
        agentMemory: '12 MB',
        agentLoad: '1',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Linux Base'
    },
    {
        id: 6,
        agentStatus: 'ok',
        agentName: 'agent-6',
        agentIp: '192.178.200.11',
        agentEps: 0,
        agentMemory: '252 MB',
        agentLoad: '2',
        agentDeployment: 'not configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'TCP Syslog collector'
    },
    {
        id: 7,
        agentStatus: 'ok',
        agentName: 'agent-7',
        agentIp: '172.18.21.1',
        agentEps: 0,
        agentMemory: '2 MB',
        agentLoad: '1',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'UDP Syslog collector'
    },
    {
        id: 8,
        agentStatus: 'new',
        agentName: 'agent-8',
        agentIp: '192.178.16.211',
        agentEps: 0,
        agentMemory: '1 250.02 MB',
        agentLoad: '0.01',
        agentDeployment: 'not configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Netflow collector'
    },
    {
        id: 9,
        agentStatus: 'new',
        agentName: 'agent-9',
        agentIp: '192.178.200.11',
        agentEps: 0,
        agentMemory: '252 MB',
        agentLoad: '5',
        agentDeployment: 'not configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Database audit'
    },
    {
        id: 10,
        agentStatus: 'error',
        agentName: 'agent-10',
        agentIp: '172.16.21.1',
        agentEps: 0,
        agentMemory: '6 525.05 MB',
        agentLoad: '0.01',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Windows IIS'
    },
    {
        id: 11,
        agentStatus: 'error',
        agentName: 'agent-11',
        agentIp: '172.16.22.2',
        agentEps: 0,
        agentMemory: '12 125.05 MB',
        agentLoad: '0.02',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'relay_in'
    },
    {
        id: 12,
        agentStatus: 'error',
        agentName: 'agent-12',
        agentIp: '172.16.22.3',
        agentEps: 0,
        agentMemory: '6 125.05 MB',
        agentLoad: '0.02',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'relay_in'
    },
    {
        id: 13,
        agentStatus: 'error',
        agentName: 'agent-13',
        agentIp: '172.16.21.1',
        agentEps: 0,
        agentMemory: '1 250.02 MB',
        agentLoad: '0.01',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'relay_out'
    },
    {
        id: 14,
        agentStatus: 'error',
        agentName: 'agent-14',
        agentIp: '172.16.22.2',
        agentEps: 0,
        agentMemory: '6 MB',
        agentLoad: '0.02',
        agentDeployment: 'configured',
        agentGroup: '-',
        agentTemplate: 'relay_in'
    },
    {
        id: 15,
        agentStatus: 'warning',
        agentName: 'agent-15',
        agentIp: '192.178.16.211',
        agentEps: 0,
        agentMemory: '1 250.02 MB',
        agentLoad: '1',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Windows Base'
    },
    {
        id: 16,
        agentStatus: 'warning',
        agentName: 'agent-16',
        agentIp: '192.178.200.11',
        agentEps: 0,
        agentMemory: '252 MB',
        agentLoad: '5',
        agentDeployment: 'configured',
        agentGroup: 'netdev relays',
        agentTemplate: 'Windows IIS'
    },
    {
        id: 17,
        agentStatus: 'ok',
        agentName: 'agent-17',
        agentIp: '192.178.11.2',
        agentEps: 0,
        agentMemory: '12 MB',
        agentLoad: '1',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Linux Base'
    },
    {
        id: 18,
        agentStatus: 'ok',
        agentName: 'agent-18',
        agentIp: '192.178.200.11',
        agentEps: 0,
        agentMemory: '252 MB',
        agentLoad: '2',
        agentDeployment: 'not configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'TCP Syslog collector'
    },
    {
        id: 19,
        agentStatus: 'ok',
        agentName: 'agent-19',
        agentIp: '172.18.21.1',
        agentEps: 0,
        agentMemory: '2 MB',
        agentLoad: '1',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'UDP Syslog collector'
    },
    {
        id: 20,
        agentStatus: 'new',
        agentName: 'agent-20',
        agentIp: '192.178.16.211',
        agentEps: 0,
        agentMemory: '1 250.02 MB',
        agentLoad: '0.01',
        agentDeployment: 'not configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Netflow collector'
    },
    {
        id: 21,
        agentStatus: 'new',
        agentName: 'agent-21',
        agentIp: '192.178.200.11',
        agentEps: 0,
        agentMemory: '252 MB',
        agentLoad: '5',
        agentDeployment: 'not configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Database audit'
    },
    {
        id: 22,
        agentStatus: 'error',
        agentName: 'agent-22',
        agentIp: '172.16.21.1',
        agentEps: 0,
        agentMemory: '6 525.05 MB',
        agentLoad: '0.01',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Windows IIS'
    },
    {
        id: 23,
        agentStatus: 'error',
        agentName: 'agent-23',
        agentIp: '172.16.22.2',
        agentEps: 0,
        agentMemory: '12 125.05 MB',
        agentLoad: '0.02',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'relay_in'
    },
    {
        id: 24,
        agentStatus: 'error',
        agentName: 'agent-24',
        agentIp: '172.16.22.3',
        agentEps: 0,
        agentMemory: '125 MB',
        agentLoad: '0.02',
        agentDeployment: 'configured',
        agentGroup: 'Windows IIS',
        agentTemplate: 'relay_in'
    },
    {
        id: 25,
        agentStatus: 'error',
        agentName: 'agent-25',
        agentIp: '172.16.21.1',
        agentEps: 0,
        agentMemory: '1 250.02 MB',
        agentLoad: '0.01',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'relay_out'
    },
    {
        id: 26,
        agentStatus: 'error',
        agentName: 'agent-26',
        agentIp: '172.16.22.2',
        agentEps: 0,
        agentMemory: '6 MB',
        agentLoad: '0.02',
        agentDeployment: 'configured',
        agentGroup: '-',
        agentTemplate: 'relay_in'
    },
    {
        id: 27,
        agentStatus: 'warning',
        agentName: 'agent-27',
        agentIp: '192.178.16.211',
        agentEps: 0,
        agentMemory: '1 250.02 MB',
        agentLoad: '1',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Windows Base'
    },
    {
        id: 28,
        agentStatus: 'warning',
        agentName: 'agent-28',
        agentIp: '192.178.200.11',
        agentEps: 0,
        agentMemory: '252 MB',
        agentLoad: '5',
        agentDeployment: 'configured',
        agentGroup: 'netdev relays',
        agentTemplate: 'Windows IIS'
    },
    {
        id: 29,
        agentStatus: 'ok',
        agentName: 'agent-29',
        agentIp: '192.178.11.2',
        agentEps: 0,
        agentMemory: '12 MB',
        agentLoad: '1',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Linux Base'
    },
    {
        id: 30,
        agentStatus: 'ok',
        agentName: 'agent-30',
        agentIp: '192.178.200.11',
        agentEps: 0,
        agentMemory: '252 MB',
        agentLoad: '2',
        agentDeployment: 'not configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'TCP Syslog collector'
    },
    {
        id: 31,
        agentStatus: 'ok',
        agentName: 'agent-31',
        agentIp: '172.18.21.1',
        agentEps: 0,
        agentMemory: '2 MB',
        agentLoad: '1',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'UDP Syslog collector'
    },
    {
        id: 32,
        agentStatus: 'new',
        agentName: 'agent-32',
        agentIp: '192.178.16.211',
        agentEps: 0,
        agentMemory: '1 250.02 MB',
        agentLoad: '0.01',
        agentDeployment: 'not configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Netflow collector'
    },
    {
        id: 33,
        agentStatus: 'new',
        agentName: 'agent-33',
        agentIp: '192.178.200.11',
        agentEps: 0,
        agentMemory: '252 MB',
        agentLoad: '5',
        agentDeployment: 'not configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Database audit'
    },
    {
        id: 34,
        agentStatus: 'error',
        agentName: 'agent-34',
        agentIp: '172.16.21.1',
        agentEps: 0,
        agentMemory: '6 525.05 MB',
        agentLoad: '0.01',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'Windows IIS'
    },
    {
        id: 35,
        agentStatus: 'error',
        agentName: 'agent-35',
        agentIp: '172.16.22.2',
        agentEps: 0,
        agentMemory: '12 125.05 MB',
        agentLoad: '0.02',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'relay_in'
    },
    {
        id: 36,
        agentStatus: 'error',
        agentName: 'agent-36',
        agentIp: '172.16.22.3',
        agentEps: 0,
        agentMemory: '6 125.05 MB',
        agentLoad: '0.02',
        agentDeployment: 'configured',
        agentGroup: 'Windows relays',
        agentTemplate: 'relay_in'
    }
];

export const TemplatesData = [
    {
        id: 1,
        status: 'draft',
        name: 'relay_out',
        comment: 'Consectetur adipiscing elit, sed do eiusmod.',
        agentCount: 0,
        create: '2021.10.08.',
        lastModification: '2021.10.08. I 12:45'
    },
    {
        id: 2,
        status: 'ready',
        name: 'relay_in',
        comment: 'Adipiscing elit, sed do eiusmod.',
        agentCount: 2,
        create: '2021.10.02.',
        lastModification: '2021.10.06. I 08:25'
    },
    {
        id: 3,
        status: 'ready',
        name: 'Windows Base',
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.',
        agentCount: 5,
        create: '2021.10.02.',
        lastModification: '2021.10.06. I 08:25'
    },
    {
        id: 4,
        status: 'draft',
        name: 'relay_out',
        comment: 'Consectetur adipiscing elit, sed do eiusmod.',
        agentCount: 0,
        create: '2021.10.08.',
        lastModification: '2021.10.08. I 12:45'
    },
    {
        id: 5,
        status: 'ready',
        name: 'relay_in',
        comment: 'Adipiscing elit, sed do eiusmod.',
        agentCount: 2,
        create: '2021.10.02.',
        lastModification: '2021.10.06. I 08:25'
    },
    {
        id: 6,
        status: 'ready',
        name: 'Windows Base',
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.',
        agentCount: 5,
        create: '2021.10.02.',
        lastModification: '2021.10.06. I 08:25'
    },
    {
        id: 7,
        status: 'draft',
        name: 'relay_out',
        comment: 'Consectetur adipiscing elit, sed do eiusmod.',
        agentCount: 0,
        create: '2021.10.08.',
        lastModification: '2021.10.08. I 12:45'
    },
    {
        id: 8,
        status: 'ready',
        name: 'relay_in',
        comment: 'Adipiscing elit, sed do eiusmod.',
        agentCount: 2,
        create: '2021.10.02.',
        lastModification: '2021.10.06. I 08:25'
    },
    {
        id: 9,
        status: 'ready',
        name: 'Windows Base',
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.',
        agentCount: 5,
        create: '2021.10.02.',
        lastModification: '2021.10.06. I 08:25'
    },
    {
        id: 10,
        status: 'draft',
        name: 'relay_out',
        comment: 'Consectetur adipiscing elit, sed do eiusmod.',
        agentCount: 0,
        create: '2021.10.08.',
        lastModification: '2021.10.08. I 12:45'
    },
    {
        id: 11,
        status: 'ready',
        name: 'relay_in',
        comment: 'Adipiscing elit, sed do eiusmod.',
        agentCount: 2,
        create: '2021.10.02.',
        lastModification: '2021.10.06. I 08:25'
    },
    {
        id: 12,
        status: 'ready',
        name: 'Windows Base',
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.',
        agentCount: 5,
        create: '2021.10.02.',
        lastModification: '2021.10.06. I 08:25'
    },
    {
        id: 13,
        status: 'draft',
        name: 'relay_out',
        comment: 'Consectetur adipiscing elit, sed do eiusmod.',
        agentCount: 0,
        create: '2021.10.08.',
        lastModification: '2021.10.08. I 12:45'
    },
    {
        id: 14,
        status: 'ready',
        name: 'relay_in',
        comment: 'Adipiscing elit, sed do eiusmod.',
        agentCount: 2,
        create: '2021.10.02.',
        lastModification: '2021.10.06. I 08:25'
    },
    {
        id: 15,
        status: 'ready',
        name: 'Windows Base',
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.',
        agentCount: 5,
        create: '2021.10.02.',
        lastModification: '2021.10.06. I 08:25'
    }
];

export const NotificationsData = [
    {
        id: 1,
        date: '2021.10.06. I 08:25',
        text: 'Alert me when a component goes down',
        message: 'Unsuccesful agent configured',
        type: 'error'
    },
    {
        id: 2,
        date: '2021.09.28. I 16:05',
        text: 'High VM CPU Utilization',
        message: 'Agents Enrolled 110 500/124 858',
        type: 'warning'
    },
    {
        id: 3,
        date: '2021.09.25. I 20:15',
        text: 'Enrollment completed with errors',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
        type: 'completed'
    },
    {
        id: 4,
        date: '2021.10.06. I 08:25',
        text: 'Alert me when a component goes down',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
        type: 'error'
    },
    {
        id: 5,
        date: '2021.09.28. I 16:05',
        text: 'High VM CPU Utilization',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
        type: 'warning'
    },
    {
        id: 6,
        date: '2021.09.25. I 20:15',
        text: 'Enrollment completed with errors',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
        type: 'completed'
    },
    {
        id: 7,
        date: '2021.10.06. I 08:25',
        text: 'Alert me when a component goes down',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
        type: 'error'
    },
    {
        id: 8,
        date: '2021.09.28. I 16:05',
        text: 'High VM CPU Utilization',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
        type: 'warning'
    },
    {
        id: 9,
        date: '2021.09.25. I 20:15',
        text: 'Enrollment completed with errors',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
        type: 'completed'
    }
];

export const ErrorData = [
    { name: 'Test', details: 'Test' },
    { name: 'Test', details: 'Test' },
    { name: 'Test', details: 'Test' },
    { name: 'Test', details: 'Test' },
    { name: 'Test', details: 'Test' },
    { name: 'Test', details: 'Test' },
    { name: 'Test', details: 'Test' },
    { name: 'Test', details: 'Test' },
    { name: 'Test', details: 'Test' },
    { name: 'Test', details: 'Test' }
];

export const MessageData = [
    {
        id: '1',
        message: 'Test',
        type: 'success',
        additionalInfoTitle: 'testtitlesuccess',
        additionalInfo: ErrorData
    },
    {
        id: '2',
        message: 'Test',
        type: 'error',
        additionalInfoTitle: 'testtitleerror',
        additionalInfo: ErrorData
    },
    {
        id: '3',
        message: 'Test',
        type: 'error',
        additionalInfoTitle: 'testtitleerror',
        additionalInfo: ErrorData
    }
];

export const ROUTES_MODULE_DATA = [
    {
        id: '1',
        name: 'Test 01',
        priority: 'default',
        title: 'Routes 1',
        modules: [
            {
                data: [
                    {
                        id: '1',
                        name: 'in internal',
                        module: 'Internal messages input (im_internal)'
                    },
                    {
                        id: '2',
                        name: 'Internal',
                        module: 'Event tracing for wuindows (im_eow)'
                    }
                ],
                title: 'Input'
            },
            {
                data: [
                    {
                        id: '1',
                        name: 'Norep',
                        module: 'pm_norepeat'
                    }
                ],
                title: 'Processors'
            },
            {
                data: [
                    {
                        id: '2',
                        name: 'In internal',
                        module: 'Internal messages input (im_internal)'
                    }
                ],
                title: 'Output'
            }
        ]
    }
];

export const EXTENSION_MODULE_DATA = {
    data: [
        {
            id: '1',
            name: 'in internal',
            module: 'Internal messages input (im_internal)'
        },
        {
            id: '2',
            name: 'Internal',
            module: 'Event tracing for wuindows (im_eow)'
        }
    ],
    title: 'Extension'
};

export const extensionModuleData = [
    {
        id: '1',
        name: 'Json',
        type: 'xm_json'
    },
    {
        id: '2',
        name: 'Kvp',
        type: 'xm_kvp'
    }
];

export const sampleUser = {
    id: '0d9c1161-6698-40af-a0a7-ab208dc74162',
    email: 'test@test.com', // required by the common lib
    firstname: 'Unregistered', // used in the header component
    lastname: 'Testuser', // used in the header component
    use_last_org_id: false, // required by the common lib
    is_backoffice: false, // used in the sidebar component
    default_logsearch_section: '/logsearch', // used in the sidebarcomponent
    orgs: {
        // required by the common lib
        '0183cb73-242f-7c6f-9be2-cd30698b97ed': {
            id: '0183cb73-242f-7c6f-9be2-cd30698b97ed', // required by the common lib
            name: 'Unregistered Test Org', // required by the common lib
            status: 'active', // required by the common lib
            roles: [
                {
                    id: 24,
                    name: 'User and Role Management',
                    perms: [
                        {
                            application_group: 'portal',
                            id: 5,
                            permission_type: 'read',
                            resource: 'users'
                        },
                        {
                            application_group: 'minder',
                            id: 10,
                            permission_type: 'read',
                            resource: 'users'
                        }
                    ]
                }
            ],
            permsTree: {
                // required by the common lib
                // this is needed to show minder sidebar menu items
                agents: {
                    read: true,
                    update: true
                },
                users: {
                    read: true,
                    update: true
                },
                roles: {
                    read: true,
                    update: true
                },
                organizations: {
                    read: true,
                    create: true
                },
                payments: {
                    read: true,
                    update: true
                },
                logs: {
                    read: true
                },
                licenses: {
                    read: true,
                    update: true
                },
                products: {
                    read: true,
                    update: true
                },
                subscriptions: {
                    create: true,
                    read: true,
                    update: true,
                    delete: true
                },
                application_download: {
                    read: false
                }
            }
        }
    },
    defaultOrgId: '0183cb73-242f-7c6f-9be2-cd30698b97ed' // required by the common lib
};

export const airGappedUser = {
    id: '0d9c1161-6698-40af-a0a7-ab208dc74162',
    email: 'no email', // required by the common lib
    firstname: 'standalone', // used in the header component
    lastname: 'user', // used in the header component
    use_last_org_id: false, // required by the common lib
    is_backoffice: false, // used in the sidebar component
    default_logsearch_section: '/logsearch', // used in the sidebarcomponent
    orgs: {
        // required by the common lib
        '0183cb73-242f-7c6f-9be2-cd30698b97ed': {
            id: '0183cb73-242f-7c6f-9be2-cd30698b97ed', // required by the common lib
            name: 'standalone', // required by the common lib
            status: 'active', // required by the common lib
            roles: [
                {
                    id: 24,
                    name: 'User and Role Management',
                    perms: [
                        {
                            application_group: 'minder',
                            id: 10,
                            permission_type: 'read',
                            resource: 'agents'
                        }
                    ]
                }
            ],
            permsTree: {
                // required by the common lib
                // this is needed to show minder sidebar menu items
                agents: {
                    read: true,
                    update: true
                }
            }
        }
    },
    defaultOrgId: '0183cb73-242f-7c6f-9be2-cd30698b97ed' // required by the common lib
};

export const sampleOrg = {
    id: '0183cb73-242f-7c6f-9be2-cd30698b97ed', // required by the common lib
    name: 'Unregistered Test Org', // required by the common lib
    status: 'active', // required by the common lib
    roles: [
        {
            id: 24,
            name: 'User and Role Management',
            perms: [
                {
                    application_group: 'portal',
                    id: 5,
                    permission_type: 'read',
                    resource: 'users'
                },
                {
                    application_group: 'minder',
                    id: 10,
                    permission_type: 'read',
                    resource: 'users'
                }
            ]
        }
    ],
    permsTree: {
        // required by the common lib
        agents: {
            read: true,
            update: true
        },
        users: {
            read: true,
            update: true
        },
        roles: {
            read: true,
            update: true
        },
        organizations: {
            read: true,
            create: true
        },
        payments: {
            read: true,
            update: true
        },
        logs: {
            read: true
        },
        licenses: {
            read: true,
            update: true
        },
        products: {
            read: true,
            update: true
        },
        subscriptions: {
            read: true,
            update: true
        }
    },
    plan: 'basic' // status list: ["basic", "freemium", "premium", "trial"]
};

export const sampleLicensedModulesData = {
    num_agents: 3,
    total_licensable_nodes: 20,
    input_modules: {
        im_batchcompress: {
            module_count: 1,
            connection_count: 5
        },
        im_file: {
            module_count: 3
        },
        im_http: {
            module_count: 1,
            connection_count: 5
        },
        im_ssl: {
            module_count: 1,
            connection_count: 0
        },
        im_tcp: {
            module_count: 2,
            connection_count: 12
        },
        im_udp: {
            module_count: 1,
            connection_count: 3
        },
        im_wseventing: {
            module_count: 1
        }
    },
    output_modules: {
        om_null: {
            module_count: 3
        }
    },
    processor_modules: {},
    extension_modules: {
        xm_admin: {
            module_count: 3
        },
        xm_fileop: {
            module_count: 3
        },
        xm_syslog: {
            module_count: 3
        }
    }
};

export const sampleLicenseModuleLimits = {
    inputs: 25,
    outputs: 25,
    extensions: 25,
    processors: 25
};
