import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmExec = {
    label: 'External Program',
    type: 'sendTo',
    name: 'om_exec',
    defaultDirectives: 'Module      om_exec',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Command',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'Arg',
            type: 'string',
            multiple: true,
            defaultValue: []
        },
        {
            name: 'Restart',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_exec'),
        ...GENERAL_DIRECTIVES
    ]
};
export default null;
