import React from 'react';
import { Navigate } from 'react-router-dom';

/**
 * User navigated to this page
 *  after selecting an organization from organizations list on main header.
 */
function PageChangeOrganization() {
    const query = new URLSearchParams(window.location.search);
    const pathname = query.get('path');

    /**
     * Navigate user to "portal|minder" /org/:orgId/change,
     *  to ensure to read changed orgId on "portal"
     *  because on some pages we don't have orgId in params
     *  and don't know to what organization need to switch
     *
     *  In query param "path" set a page
     *    which user should be redirected back from /org/:orgId/change
     */
    return <Navigate to={pathname} replace />;
}

export default PageChangeOrganization;
