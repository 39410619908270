import { getAgentsFiltersCounts, updateAgentsFilterItems } from '../reducers/agents';

// this middleware triggers agent filter counts update
const updateAgentFilterCountsMiddleware = (store) => (next) => (action) => {
    const res = next(action);
    if (action.type === updateAgentsFilterItems.type) {
        store.dispatch(getAgentsFiltersCounts(action.payload));
    }
    return res;
};

export default updateAgentFilterCountsMiddleware;
