import propTypes from 'prop-types';
import { useApplicationContext } from '@nxlog/common-ui/hooks';
import { Modal } from '@nxlog/common-ui/components';
import { useNavigate } from 'react-router-dom';
import useEnrollmentAddress from '../../../utils/hooks/useEnrollmentAddress';
import useStoreOrgId from '../../../utils/hooks/useStoreOrgId';
import tutorialContent from '../../../assets/contents/tutorialContent';
import Wizard from '../../wizard';
import './tutorialModal.scss';

export default function TutorialModal({ onHide, show }) {
    const { portalUrl } = useApplicationContext();
    const enrollmentHost = useEnrollmentAddress();
    const { orgId } = useStoreOrgId();

    const navigate = useNavigate();
    const redirect = () => navigate('/agents/configurations');

    return (
        <Modal
            data-testid="tutorial-modal"
            isShown={show}
            onClose={onHide}
            title=""
            classname="agents-tutorial"
            aria-labelledby="agents-tutorial"
        >
            <Wizard
                title={
                    <>
                        You want to connect some agents? <br />
                        We can help!
                    </>
                }
                content={tutorialContent({ portalUrl, orgId, enrollmentHost })}
                endCaption="Go to Templates"
                onEnd={redirect}
            />
        </Modal>
    );
}

TutorialModal.defaultProps = {
    onHide: () => {},
    show: false
};

TutorialModal.propTypes = {
    onHide: propTypes.func,
    show: propTypes.bool
};
