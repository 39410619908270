import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@nxlog/common-ui/components';
import CloseIcon from '@nxlog/common-ui/dist/components/svgs/close-icon';

function DisplayAppliedFilters({ appliedFilters, removeFilter }) {
    return (
        <div className="agent-search">
            <div className="input-wrapper">
                <div className="tags-wrapper">
                    {Array.isArray(appliedFilters) &&
                        appliedFilters
                            .filter(({ isTag }) => isTag)
                            .map((tag) => (
                                <span className="tag" key={`tag-${tag.id}`}>
                                    <Button
                                        className="close-icon"
                                        onClick={() => removeFilter(tag)}
                                        icon={<CloseIcon fill="#fff" />}
                                    />
                                    <span className="tag-text">{tag.filter}</span>
                                </span>
                            ))}
                </div>
            </div>
        </div>
    );
}

DisplayAppliedFilters.propTypes = {
    appliedFilters: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            isTag: PropTypes.bool,
            filter: PropTypes.string
        })
    ).isRequired,
    removeFilter: PropTypes.func.isRequired
};

export default DisplayAppliedFilters;
