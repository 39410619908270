import { GENERAL_DIRECTIVES, GENERAL_OUTPUT_DIRECTIVES } from '../generalDirectives';

export const OmOdbc = {
    label: 'ODBC',
    name: 'om_odbc',
    type: 'sendTo',
    defaultDirectives: 'Module      om_odbc',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ConnectionString',
            type: 'string',
            defaultValue: '',
            required: true
        },
        {
            name: 'SQL',
            type: 'multiline-string',
            defaultValue: ''
        },
        ...GENERAL_OUTPUT_DIRECTIVES('om_odbc'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
