import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from '@nxlog/common-ui/components';
import ClusterIcon from '@nxlog/common-ui/dist/components/svgs/cluster';
import { createNotification } from '../../utils/helpers/functions';
import { addNotifications } from '../../redux/reducers/notifications';
import Loader from '../../components/common/loader';
import TableClusters from '../../components/tables/tableClusters';
import Header from '../../components/header';
import { getClusters } from '../../api/endpoints/cluster';
import './cluster.scss';

export default function ClusterPage() {
    const [loading, setLoading] = useState(true);
    const [clusterData, setClusterData] = useState([]);
    const [tags, setTags] = useState([]);
    const dispatch = useDispatch();

    const pushNotification = (type, message) => {
        const notification = createNotification(type, message);
        dispatch(addNotifications(notification));
    };

    const initNodeItem = (item) => {
        item.address = item.restApi ? `${item.restApi.host}:${item.restApi.port}` : '';
        item.rpcAddress = item.nodeRpc ? `${item.nodeRpc.host}:${item.nodeRpc.port}` : '';
        return item;
    };

    useEffect(() => {
        setLoading(true);
        getClusters()
            .then((response) => {
                if (response && Array.isArray(response.nodes)) {
                    setClusterData(response.nodes.map(initNodeItem));
                }
            })
            .catch((error) => {
                pushNotification(
                    'error',
                    error?.message?.message || 'There was an error processing your request.'
                );
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    let rows = clusterData;
    if (Array.isArray(tags) && tags.length > 0) {
        rows = clusterData.filter((row) =>
            tags.some((tag) =>
                JSON.stringify(Object.values(row)).toLowerCase().includes(tag.toLowerCase())
            )
        );
    }

    return (
        <div className="cluster-page w-100">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Header title="Cluster" titleIcon={<ClusterIcon fill="#0086C5" />} />
                    <div data-testid="cluster-page-search" className="cluster-search">
                        <Input
                            type="text"
                            classname="with-search-icon"
                            tagName="ClusterTags"
                            placeholder="Search"
                            isSearch
                            tags={tags}
                            setTags={setTags}
                            filterDataBySearchTerm={() => {}}
                        />
                    </div>
                    <TableClusters allData={rows} />
                </>
            )}
        </div>
    );
}
