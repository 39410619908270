import { GENERAL_DIRECTIVES, GENERAL_INPUT_DIRECTIVES } from '../generalDirectives';

export const ImBatchcompress = {
    label: 'NXLog Transport',
    name: 'im_batchcompress',
    type: 'collect',
    defaultDirectives: 'Module      im_batchcompress',
    fields: [
        {
            name: 'Name',
            type: 'string',
            required: true,
            defaultValue: ''
        },
        {
            name: 'ListenAddr',
            type: 'string',
            value: '0.0.0.0:2514',
            defaultValue: '0.0.0.0:2514',
            requiredInConfigText: true,
            required: true
        },
        {
            name: 'Port',
            type: 'number',
            defaultValue: ''
        },
        {
            name: 'AllowIP',
            type: 'string',
            multiple: true,
            defaultValue: []
        },
        {
            name: 'BlockIP',
            type: 'string',
            multiple: true,
            defaultValue: []
        },
        {
            name: 'AllowExpired',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'AllowUntrusted',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'CADir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CAFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CAThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SearchAllCertStores',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'FALSE'
        },
        {
            name: 'CertFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertKeyFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertThumbprint',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CRLDir',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CRLFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'DHFile',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'KeyPass',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'RequireCert',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: 'TRUE'
        },
        {
            name: 'SSLCipher',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SSLCiphersuites',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'SSLProtocol',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CAPattern',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'CertPattern',
            type: 'string',
            defaultValue: ''
        },
        {
            name: 'UseCNGCertificates',
            type: 'select',
            options: ['TRUE', 'FALSE'],
            defaultValue: ''
        },
        ...GENERAL_INPUT_DIRECTIVES('im_batchcompress'),
        ...GENERAL_DIRECTIVES
    ]
};

export default null;
