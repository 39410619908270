import React, { useEffect } from 'react';
import { getTemplates, updateTemplate } from '../../../api/endpoints/templates';
import { updateDirectiveValueInConfig } from '../../../components/configEditor/utils/parser';
import useEnrollmentAddress, { useRelayAddress } from '../../../utils/hooks/useEnrollmentAddress';

function UpgradeHostURLFormat() {
    const enrollmentAddress = useEnrollmentAddress();
    const relayAddress = useRelayAddress();

    useEffect(() => {
        getTemplates({
            filter: 'content regex (?s:.)*\\s+(?i:host)\\s+(?i:(agents|relay))\\.(?i:tenant)\\.(?s:.)*'
        }).then(({ data }) =>
            (Array.isArray(data) ? data : []).forEach((item) => {
                let { content } = item;
                const updateValue = ({ value }) =>
                    `${value || ''}`
                        .trim()
                        .toLowerCase()
                        .match(/^agents\.tenant\./)
                        ? enrollmentAddress
                        : value;
                const updateRelayValue = ({ value }) =>
                    `${value || ''}`
                        .trim()
                        .toLowerCase()
                        .match(/^relay\.tenant\./)
                        ? relayAddress
                        : value;
                content = updateDirectiveValueInConfig('xm_admin.host', content, updateValue);
                content = updateDirectiveValueInConfig(
                    'om_batchcompress.host',
                    content,
                    updateRelayValue
                );
                if (content !== item.content) {
                    updateTemplate(item.id, { content });
                }
            })
        );
    }, []);

    return null;
}

export default React.memo(UpgradeHostURLFormat);
