import classNames from 'classnames';
import React from 'react';
import propTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { Select } from '@nxlog/common-ui/components';
import './formSelect.scss';

function FormSelect(props) {
    const { label, classname, required, placeholder, name, options, disabled } = props;

    const { control } = useFormContext();

    return (
        <div data-testid="form-select" className={classNames('select-form-container', classname)}>
            <label data-testid="form-select-label" htmlFor={name}>
                {label}
                {required && (
                    <FontAwesomeIcon data-testid="form-select-asterisk" icon={faAsterisk} />
                )}
            </label>
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => (
                    <Select
                        classname={classNames(disabled && 'disabled')}
                        defaultValue={options.find((item) => item.value === value)}
                        placeholder={placeholder}
                        dropdownOptions={options}
                        onClick={({ value: selectValue }) => {
                            // eslint-disable-next-line no-debugger
                            // debugger;
                            onChange(selectValue);
                        }}
                    />
                )}
            />
        </div>
    );
}

FormSelect.defaultProps = {
    label: '',
    classname: '',
    required: false,
    placeholder: '',
    disabled: false
};

FormSelect.propTypes = {
    label: propTypes.string,
    classname: propTypes.string,
    required: propTypes.bool,
    placeholder: propTypes.string,
    options: propTypes.arrayOf(
        propTypes.shape({
            value: propTypes.string.isRequired,
            label: propTypes.string.isRequired
        })
    ).isRequired,
    name: propTypes.string.isRequired,
    disabled: propTypes.bool
};

export default FormSelect;
