import React from 'react';
import propTypes from 'prop-types';
import { Button, Modal } from '@nxlog/common-ui/components';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { TOAST_TYPES } from '../../../utils/constants/ids';
import { createNotification } from '../../../utils/helpers/functions';
import { addNotifications } from '../../../redux/reducers/notifications';
import { addTemplate } from '../../../api/endpoints/templates';
import { getTemplatesData } from '../../../redux/reducers/templates';

function CreateTemplateFromConfigModal(props) {
    const { config, onClose } = props;
    const dispatch = useDispatch();

    const pushNotification = (type, message, additionalInfo) => {
        const notification = createNotification(type, message, additionalInfo);
        dispatch(addNotifications(notification));
    };

    const handleSaveAsTemplate = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const name = formData.get('name') || '';
        const comment = formData.get('comment') || '';
        if (!name.trim()) return;
        addTemplate({ name, comment, content: config })
            .then((result) => {
                if (result && typeof result.data === 'string') {
                    pushNotification(
                        TOAST_TYPES.SUCCESS,
                        i18next.t('notifications_messages.success.save_template')
                    );
                }
                dispatch(getTemplatesData({ fields: 'id,name,tags' }));
                if (onClose) onClose();
            })
            .catch(() => {
                pushNotification(
                    TOAST_TYPES.ERROR,
                    i18next.t('notifications_messages.errors.save_template')
                );
            });
    };

    return (
        <Modal
            isShown={!!`${config || ''}`.trim()}
            onClose={onClose}
            title="Configuration Information"
            classname="save-config-as-template"
            aria-labelledby="Save agent configuration as template"
        >
            <form onSubmit={handleSaveAsTemplate}>
                <div className="modal-content">
                    <section className="ConfigEditorSection">
                        <div className="configinfos">
                            <div className="input-form-container">
                                <label htmlFor="name">Name</label>
                                <input
                                    className="input-form template-name"
                                    placeholder="Name"
                                    id="name"
                                    name="name"
                                    required
                                />
                            </div>
                            <div className="input-form-container">
                                <label htmlFor="comment">Comment</label>
                                <input
                                    className="input-form"
                                    placeholder="Comment"
                                    id="comment"
                                    name="comment"
                                />
                            </div>
                        </div>
                    </section>
                </div>
                <div className="modal-footer">
                    <Button type="button" ghostBtn onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="submit">Save</Button>
                </div>
            </form>
        </Modal>
    );
}

CreateTemplateFromConfigModal.defaultProps = {
    config: null
};

CreateTemplateFromConfigModal.propTypes = {
    config: propTypes.string,
    onClose: propTypes.func.isRequired
};

export default CreateTemplateFromConfigModal;
