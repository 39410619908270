import propTypes from 'prop-types';
import { CircleExclamation } from '../../icons';

function AlertHeading(props) {
    const { children } = props;

    return (
        <div className="d-flex align-items-start">
            <CircleExclamation width={24} height={24} color="#E67500" />
            <span className="ms-2 me-4">{children}</span>
        </div>
    );
}

AlertHeading.defaultProps = {
    children: ''
};

AlertHeading.propTypes = {
    children: propTypes.oneOfType([propTypes.string, propTypes.element, propTypes.array])
};

export default AlertHeading;
